<div id="layout" class="f_col layout_sm">
  <app-view-title [bgImage]="api.env.theme.header_background">{{'manageNews.articles' | translate}}</app-view-title>
  <div class="scroll_wrapper">
    <div class="f_grow mt_20">
      <app-slide-element leftIcon="newspaper" (btnClicked)="nav.to('manage/manage-news-articles')" class="mb_10">
        {{'manageNews.articles' | translate}}
      </app-slide-element>
      <app-slide-element leftIcon="file-lines" (btnClicked)="nav.to('manage/manage-news-new')" class="mb_10">
        {{'manageNews.new_article' | translate}}
      </app-slide-element>
    </div>
  </div>
  <app-back-btn position="botLeft" [navTo]="{route: 'user'}"></app-back-btn>
</div>
