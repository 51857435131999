import {Component, OnInit} from '@angular/core';
import {environment} from 'src/environments/environment';
import {LoaderService, NavigateService} from 'ngx-satoris';
import {latLng, tileLayer, Map, Marker, Icon} from 'leaflet';

interface markerPlace {
  name: string | null;
  pos: L.LatLngLiteral | null;
  distance?: number;
  info?: string;
}

@Component({
  selector: 'app-activation-code-map',
  templateUrl: './activation-code-map.component.html',
  styleUrls: ['./activation-code-map.component.scss']
})
export class ActivationCodeMapComponent implements OnInit {
  map: Map;
  places: markerPlace[];
  env = environment;

  centerIcon = new Icon({
    iconUrl: 'assets-countries/marker-icon-2x.png',
    iconRetinaUrl: 'assets-countries/marker-icon.png',
    shadowUrl: 'assets-countries/marker-shadow.png',
    iconSize: [24, 36],
    iconAnchor: [12, 36]
  });

  options: L.MapOptions = {
    layers: [
      tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {maxZoom: this.env.activationMap.maxZoom || 18, attribution: '© OpenStreetMap'})
    ],
    zoom: this.env.activationMap.zoom || 13,
    center: latLng(this.env.activationMap.center.lat, this.env.activationMap.center.lng)
  };

  center: L.LatLngLiteral | L.LatLng = latLng(this.env.activationMap.center.lat, this.env.activationMap.center.lng);
  zoom = this.env.activationMap.zoom || 13;
  markers: L.Marker[] = [];

  constructor(public nav: NavigateService, public loader: LoaderService) {
    this.places = this.env.activationLocations;
    this.places.map(place => {
      const marker = new Marker(place.pos, {draggable: false, title: place.name, icon: this.centerIcon});
      if(place.name) marker.bindTooltip(`${place.name}`, {permanent: true}).openTooltip();
      if(place.info) marker.bindPopup(`${place.info}`).openPopup();
      this.markers.push(marker);
    });
  }

  ngOnInit(): void {
    // Try HTML5 geolocation.
    if(navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position: GeolocationPosition) => {
        const pos = latLng(position.coords.latitude, position.coords.longitude);

        if(this.map) this.map.flyTo(this.center, this.zoom);
        if(pos) this.center = pos;
      },
      () => this.loader.loading(true, {type: 'error', message: 'err.activationCodeMap.unableToGetYourLocation'}));
    } else {
      // Browser doesn't support Geolocation
      this.loader.loading(true, {type: 'error', message: 'err.activationCodeMap.notSupported'});
    }
  }

  onMapReady(map: Map){
    this.map = map;
  }
}
