<div id="layout" class="f_col">
  <app-view-title bgImage="assets-countries/header_background.png" fromRoute="forgot-pin" [chooseLangBtn]="!api.userInfo?.nationalNumber">{{'forgotPin.title' | translate}}</app-view-title>
  <div class="scroll_wrapper">
    <div class="f_grow">
      <app-view-deco-image imageUrl="assets-countries/undraw_forgot-password.svg"></app-view-deco-image>
      <app-paragraph>{{'forgotPin.desc' | translate}}</app-paragraph>
      <app-button  (btnClicked)="resetPin()" class="fullWidth mt_40">{{'reset' | translate}}</app-button>
    </div>
    <div class="flex mt_40" [class.mb_10]="!api.userInfo.nationalNumber">
      <app-back-btn (btnClicked)="nav.to('secret-code-valid', undefined, data)"></app-back-btn>
    </div>
  </div>
</div>
