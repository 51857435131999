import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ApiService} from '../../shared/services/api.service';
import {LangService, LoaderService, NavigateService} from 'ngx-satoris';
import {ScanService} from '../../shared/services/scan.service';
import {TimeoutError} from 'rxjs';
import {User} from '../../shared/models/user';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BadgeComponent implements OnInit, OnDestroy {
  qrValue: string;
  interval: NodeJS.Timeout;
  time = 30;
  isQr: boolean;

  constructor(public nav: NavigateService,
    public api: ApiService,
    private scan: ScanService,
    private loader: LoaderService,
    private lang: LangService) { }

  ngOnInit(): void {
    this.getBadge();
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }

  getBadge() {
    this.qrValue = '';
    this.isQr = false;
    this.time = 30;
    this.loader.loading(true);
    clearInterval(this.interval);
    const user: User = this.api.currentPlace.users?.find(u => u.id === this.api.userInfo.id);
    return this.api.badge(undefined, false).then((badge: string) => this.scan.setUserScanValue(badge).then(res => {
      if(user && user.name) {
        const aud = JSON.parse(res.data.aud);
        aud.n = user.name;
        res.data.aud = JSON.stringify(aud);
      }
      this.qrValue = this.scan.setQrCode(res, true);
      setTimeout(() => {
        this.isQr = true;
        this.loader.loading(false);
      });
      this.interval = setInterval(() => {
        this.time--;
        if(this.time <= 0) {
          clearInterval(this.interval);
          this.getBadge();
        }
      }, 1000);
    })).catch((err) => {
      clearInterval(this.interval);
      if(!(err instanceof TimeoutError)) {
        this.loader.loading(true, {type: 'error', message: String(err).indexOf(': ') > -1 ? err : this.lang.transform('userPermissions.error')});
      } else {
        this.loader.loading(true, {type: 'error', message: 'badge.timeout', btnLabel: 'global.retry', custom: {closeBtnLabel: 'global.close'}}).then(done => {
          if(done) {
            this.getBadge();
          } else {
            this.nav.to('user');
          }
        });
      }
    });
  }
}
