import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {DevicesService, LangService, LoaderService, NavigateService} from 'ngx-satoris';
import {getStoredItem, removeStoredItem, setStoredItem} from 'src/app/shared/utils/storage';
import {ApiService} from '../../shared/services/api.service';
import {SyncService} from '../../shared/services/sync.service';
import {SecureStorageService} from '../../shared/services/secure-storage.service';
import {InitializeService} from '../../shared/services/initialize.service';
import {AccountType} from 'src/app/shared/models/user';

@Component({
  selector: 'app-more',
  templateUrl: './more.component.html',
  styleUrls: ['./more.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MoreComponent implements OnInit {
  demoModeInterval: NodeJS.Timeout;
  isOnlineManual = getStoredItem('manualOffline');
  jwts: [];
  datas: any;
  addMode: boolean;

  constructor(public nav: NavigateService,
    public api: ApiService,
    public sync: SyncService,
    public init: InitializeService,
    private lang: LangService,
    private loader: LoaderService,
    public devices: DevicesService,
    public secure: SecureStorageService) {
  }

  ngOnInit(): void {
    this.jwts = getStoredItem('jwts');
    this.datas = getStoredItem('digid.cache.infos');
    if((this.api.userInfo?.placeAdminView || this.api.userInfo?.workerView) && this.jwts.length < 2) {
      this.addMode = true;
    }
  }

  disableDemoMode(pressDown: boolean) {
    clearInterval(this.demoModeInterval);
    if(pressDown) {
      let time = 3;
      this.demoModeInterval = setInterval(() => {
        time--;
        if(time <= 0) {
          this.nav.demoMode = !this.nav.demoMode;
          setStoredItem('demoMode', this.nav.demoMode);
          console.log('👻👻👻 DEMO MODE IS NOW:', this.nav.demoMode ? 'ON 👻👻👻' : 'OFF 👻👻👻');
          clearInterval(this.demoModeInterval);
        }
      }, 1000);
    }
  }

  deleteUser() {
    this.loader.loading(true, {type: 'info', message: this.lang.transform('more.delete_user_sure'), btnLabel: this.lang.transform('confirm')}).then((done) =>{
      if(done) {
        this.api.deleteUser().then(() => {
          this.api.userRole.isCustomer ? this.api.signOut(true) : this.changeToCustomer();
        });
      }
    });
  }

  changeToCustomer() {
    if(this.api.userRole.isSuperAdmin) {
      this.api.signOut(true);
      return;
    }
    this.loader.loading(true, {type: 'warn', message: this.lang.transform(this.api.userRole.isCustomer ? 'ask.signout' : 'ask.changeCustomer'), btnLabel: this.lang.transform('confirm')}).then((done) =>{
      if(done) {
        const updatedJwts = getStoredItem('jwts').map((storedAccount: AccountType) => storedAccount.email === this.api.account.email ? {...this.api.account, jwt: ''} : storedAccount);
        this.api.signOut(false);
        this.api.accountJwt = this.api.getCustomerAccount();
        setStoredItem('jwts', updatedJwts);
        this.api.checkJwt().then(() => {
          this.nav.to('user');
          setTimeout(() => {
            this.loader.loading(true, {type: 'info', message: this.lang.transform('admin.disconnect')});
          }, 500);
        });
      }
    });
  }

  toSetPrimaryDevice() {
    this.loader.loading(true, {type: 'info', message: '"' + this.api.userInfo.deviceName + '" ' +this.lang.transform('sign_in.other_device'), btnLabel: 'global.yes', custom: {closeBtnLabel: 'global.no'}}).then((done: boolean) => {
      if(done) {
        this.api.setPrincipalMode = true;
        this.nav.to('activation-step-intro');
      } else {
        localStorage.setItem('setPrincipalMode', new Date().toISOString());
      }
    });
  }

  follow(link: string) {
    window.open(link, '_blank');
  }

  disableManualOffline() {
    this.api.ping().then(() => {
      removeStoredItem('manualOffline');
      this.sync.isOnlineSubject.next(true);
      this.isOnlineManual = undefined;
      this.loader.loading(true, {type: 'valid', message: this.lang.transform('serverComeBack')});
    }).catch(() => {
      this.loader.loading(true, {type: 'error', message: this.lang.transform('no.serverOnline'), custom: {icon: 'wifi'}});
    });
  }

  signoutButton() {
    if(this.api.userRole.isCustomer) {
      this.loader.loading(true, {type: 'warn', message: this.lang.transform('ask.signout'), btnLabel: this.lang.transform('confirm')}).then((done) =>{
        if(done) {
          this.api.signOut(true);
        }
      });
    } else {
      this.changeToCustomer();
    }
  }
}
