import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ApiService} from '../../../../shared/services/api.service';
import {Results} from '../../../../shared/models/backend-message';
import {Article, ArticleType} from '../../../../shared/models/article';
import {LangService, LoaderService, NavigateService} from 'ngx-satoris';
import {isBefore, isAfter} from 'date-fns';

@Component({
  selector: 'app-manage-news-articles',
  templateUrl: './manage-news-articles.component.html',
  styleUrls: ['./manage-news-articles.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ManageNewsArticlesComponent implements OnInit{

  articles: Article[];
  types = ArticleType;

  constructor(public api: ApiService,
    public lang: LangService,
    public nav: NavigateService,
    private loader: LoaderService) {
  }

  ngOnInit() {
    this.loader.loading(true);
    this.api.searchArticle(new Date()).then((articles: Results<Article[]>) => {
      this.articles = articles.result.sort((a, b) => a.metaId.localeCompare(b.metaId));

      this.loader.loading(false);
    }).catch(() => {
      this.loader.loading(true, {type: 'error', message: 'articles.search_error'});
    });
  }

  isVisible(article: Article) {
    return isBefore(new Date(article.visibilityFrom), new Date()) && isAfter(new Date(article.visibilityTo), new Date());
  }

  articlesByType(type: ArticleType | unknown) {
    return this.articles?.filter(article => article.type === type);
  }

  protected readonly ArticleType = ArticleType;
}
