import {Component, HostListener, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {ApiService} from '../../shared/services/api.service';
import {NavigateService} from 'ngx-satoris';
import {InitializeService} from 'src/app/shared/services/initialize.service';

/**
 * CardComponent is responsible for displaying the front and back images of a card.
 */
@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CardComponent implements OnInit {
  @Input() imageRecto: string;
  @Input() imageVerso: string;
  @Input() size: 'sm' | 'md' | 'lg' = 'md';
  @Input() noPointerEvents = false;

  turnAround = true;

  constructor(public nav: NavigateService, 
    public api: ApiService,
    public init: InitializeService) {}

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.adjustBorderRadiusForCards();
  }

  ngOnInit() {
    this.adjustBorderRadiusForCards();
    setTimeout(() => {
      this.toggleTurnAround();
    }, 100);
  }


  /**
   * Toggles the `turnAround` property, causing the card to flip.
   */
  toggleTurnAround() {
    this.turnAround = !this.turnAround;
  }

  /**
   * Adjusts the border radius for both card sides according to the current window size.
   */
  private adjustBorderRadiusForCards(): void {
    const cardA = document.querySelector('.card_sideA');
    const cardB = document.querySelector('.card_sideB');

    if(cardA) {
      this.adjustBorderRadius(cardA as HTMLElement);
    }
    if(cardB) {
      this.adjustBorderRadius(cardB as HTMLElement);
    }
  }

  /**
   * Adjusts the border radius of the given element based on its current size.
   * @param element {HTMLElement} - The element for which the border radius should be adjusted.
   */
  private adjustBorderRadius(element: HTMLElement): void {
    const originalWidth = 1000;
    const originalHeight = 632;
    const originalBorderRadius = 40;

    const currentWidth = element.clientWidth;
    const currentHeight = element.clientHeight;

    const widthRatio = currentWidth / originalWidth;
    const heightRatio = currentHeight / originalHeight;
    const avgRatio = (widthRatio + heightRatio) / 2;

    const newBorderRadius = Math.ceil(originalBorderRadius * avgRatio);

    // Apply the new border radius to the element.
    element.style.borderRadius = `${newBorderRadius}px`;
  }
}
