<div id="layout" class="f_col layout_sm news__view">
  <app-view-title [bgImage]="api.env.theme.header_background">{{'news.title' | translate}}</app-view-title>
  <div class="scroll_wrapper">
    <ng-container *ngFor="let type of ArticleType | enumToArray">
      <ng-container *ngIf="articlesByType(type.value)?.length > 0">
        <h2>{{('article.type.' + type.key) | translate}}</h2>
        <div class="scroll_h">
          <div  *ngFor="let item of articlesByType(type.value); index as i;">
            <app-modal-features
              (click)="item.link ? nav.open(item.link) : nav.to('news/' + item.metaId, undefined, {state: {article: item, fromRoute: 'news'}})"
              [title]="item.title"
              [subtitle]="item.description"
              [hasLink]="!!item.link"
              [date]="item.visibilityFrom | date: 'dd-MM-yyyy'"
              [bgImage]="item.image"></app-modal-features>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
