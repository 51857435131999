import {Component, ViewEncapsulation} from '@angular/core';
import {ApiService} from '../../../shared/services/api.service';
import {NavigateService} from 'ngx-satoris';
import {getStoredItem} from '../../../shared/utils/storage';
import {countries} from 'src/app/shared/models/countries';

@Component({
  selector: 'app-welcome-hello',
  templateUrl: './welcome-hello.component.html',
  styleUrls: ['./welcome-hello.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WelcomeHelloComponent {
  countries = countries;

  constructor(public api: ApiService, public nav: NavigateService) {
    getStoredItem('welcomeDone') && this.nav.to('user');
  }
}
