<div id="scanner" class="loader_scan">
  <span id="invisibleScanBlock"></span>
  <div #scanner_wrapper [hidden]="!started || qrResultString" class="scanner_wrapper">
    <div id="scanner_placeholder">
      <div #scanner_square class="scanner_square"></div>
      <div *ngIf="this.availableDevices?.length > 1" class="scanner_bottom">
        <fa-icon icon="camera-rotate" (click)="nextCamera()" class="scanner_camera"></fa-icon>
      </div>
    </div>
    <div class="zxing-wrapper" [hidden]="!this.hasPermission">
      <zxing-scanner #zscanner
                     [(device)]="currentDevice"
                     [autostart]="true"
                     [enable]="true"
                     [autofocusEnabled]="true"
                     [previewFitMode]="'cover'"
                     (scanSuccess)="!qrResultString && onCodeResult($event)"
                     (scanComplete)="onScanComplete($event)"
                     (scanError)="scanError($event)"
                     (permissionResponse)="onHasPermission($event)"
                     (camerasFound)="onCamerasFound($event)"
                     (autostarted)="autoStarted()"
                     playsinline>
      </zxing-scanner>
    </div>
  </div>
  <ng-container class="loader_processing" *ngIf="qrResultString">
    <img src="assets/images/3-dots-scale.svg" alt="spinner" width="100" height="100">
    <div class="mt_20">{{'scanner.processing' | translate}}</div>
  </ng-container>
</div>
