import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from 'src/app/shared/services/api.service';
import {AccountType, OtpType} from '../../shared/models/user';
import {DevicesService, FormService, LangService, LoaderService, NavigateService} from 'ngx-satoris';
import {SecureStorageService} from 'src/app/shared/services/secure-storage.service';
import {getStoredItem, setStoredItem} from 'src/app/shared/utils/storage';
import {SyncService} from 'src/app/shared/services/sync.service';
import {environment} from '../../../environments/environment';
import {parseError} from 'src/app/shared/utils/string';
import {TimeoutError} from 'rxjs';
import {Router} from '@angular/router';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SignInComponent implements OnInit {
  form: FormGroup;
  submitted: boolean;
  accounts: AccountType[] = getStoredItem('jwts') || [];
  askOtp: boolean;
  signType: OtpType = OtpType.EMAIL;
  showOTPCorrect: boolean;
  showOTPError: boolean;
  backupButton = false;
  otpDisabled: boolean;
  connecting: boolean;
  otpDisabledTime = 10000;
  otp: string;
  OtpType = OtpType;
  debounceTimer: number = 0;
  data: { signType?: OtpType, email?: string, phone?: string };

  constructor(public forms: FormService,
              public nav: NavigateService,
              public api: ApiService,
              private formBuilder: FormBuilder,
              private lang: LangService,
              private loader: LoaderService,
              private secure: SecureStorageService,
              private device : DevicesService,
              private sync: SyncService,
              private router: Router) {
    this.data = <any> this.router.getCurrentNavigation().extras.state;
    this.changeOtpType(this.data?.signType || OtpType.EMAIL);
    this.api.signOut(false);
    this.api.mobileBack(() => this.nav.to('home'));
  }

  ngOnInit(): void {
    this.api.signOut(true, false);
    this.setForm();
  }

  setForm() {
    this.form = this.formBuilder.group({
      email: [this.data?.email || '', [
        Validators.required,
        Validators.email
      ]],
      ['phone' + environment.country]: [this.data?.phone || ''],
      password: [this.form?.get('password')?.value ? this.form.get('password').value : '', [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(30)
      ]],
      otp: ['', Validators.pattern(/[0-9]{6}/)]
    }, {
      /*validator: this.forms.customPatternValidator('password', [/[A-Z]/, /[a-z]/, /[0-9]/, /[~`!@#$%^&*()\-_+={[}\]|:;"'<,>.?/]/], 3)*/
    });
  }

  submitForm() {
    if(!this.sync.isOnline) {
      this.loader.loading(true, {type: 'warn', message: this.lang.transform('no.connection.signIn')});
    } else {
      this.submitted = true;
      if(this.forms.getFormErrors(this.form).length < 1) {
        this.connecting = true;
        this.submitted = false;
        this.api.userPlaceId = undefined;
        this.loginWeak();
      }
    }
  }

  loginWeak() {
    this.showOTPError = false;
    this.otpDisabled = false;
    this.showOTPCorrect = false;
    this.api.signOut(true, false);
    this.loader.loading(true);
    return this.api.consumeLoginWeak(this.form.get(this.signType === OtpType.EMAIL ? 'email' : ('phone' + environment.country)).value, this.form.get('password').value, this.otp, undefined).then((status: number) => {
      this.loader.loading(false);
      if(status === 204) {
        this.debounceTimer = this.signType === OtpType.PHONE ? 180000 : 5000;
        this.loader.loading(false);
        this.connecting = false;
        this.askOtp = true;
        return;
      } else if(this.askOtp) {
        this.showOTPCorrect = true;
      }

      const index = this.accounts.findIndex(object => object.email === this.form.get('email').value);
      if(index > -1) {
        this.accounts[index] = {
          name: this.form.get(this.signType === OtpType.EMAIL ? 'email' : ('phone' + environment.country)).value,
          email: this.form.get(this.signType === OtpType.EMAIL ? 'email' : ('phone' + environment.country)).value,
          jwt: this.api.accountJwt?.jwt
        };
      } else {
        this.accounts.push({
          name: this.form.get(this.signType === OtpType.EMAIL ? 'email' : ('phone' + environment.country)).value,
          email: this.form.get(this.signType === OtpType.EMAIL ? 'email' : ('phone' + environment.country)).value,
          jwt: this.api.accountJwt?.jwt
        });
      }
      setStoredItem('jwts', this.accounts);

      if(this.device.isDevices('cordova')) {
        this.loader.loading(true);
        return this.api.exchange(undefined, undefined, true, true).then((res) => this.secure.setEncryptSecureDatas('longJWt', res.jwt).then(() => this.finishLogin())).catch(() => {
          this.api.signOut(true, false);
          this.loader.loading(false);
          this.submitted = false;
          this.otpDisabled = false;
          this.connecting = false;
          return false;
        });
      } else {
        return this.finishLogin();
      }
    }, err => {
      this.loader.loading(false);
      const error = parseError(err);
      if(!this.sync.isOnline) {
        return this.loader.loading(true, {type: 'warn', message: this.lang.transform('no.connection.signIn')});
      }
      if(error.baseError === 'user.notConfirmed'){
        return this.nav.to('account-valid', undefined, {state: {signType: this.signType, accountToActivate: this.form.get(this.signType === OtpType.EMAIL ? 'email' : 'phone').value}});
      }
      if(this.askOtp){
        this.backupButton = true;
        this.showOTPError = true;
        this.otpDisabled = true;
        setTimeout(() => {
          this.otpDisabled = false;
          this.connecting = false;
        }, this.otpDisabledTime);
      }
      if(!(err instanceof TimeoutError) && err.status !== 0) {
        if((typeof err === 'string' ) && err.includes('Could not validate user')) {
          this.loader.loading(true, {type: 'warn', message: this.lang.transform('otp.login.fail')});
        } else if(error.baseError.includes('user.otpTooSoon')) {
          const timer = Number(error.baseError.split(' ')[1]) * 1000;
          this.debounceTimer = timer;
          this.loader.loading(true, {type: 'warn', message: 'user.otpTooSoon.DYN.server'});
        } else {
          this.loader.loading(true, {type: 'warn', message: error.tradError});
        }
      }
      this.submitted = false;
    });
  }

  checkOTPCode(e: string[]) {
    const codeOk = e ? e?.length >= 6 : this.otp?.length >= 6;
    this.otp = e ? e.join('') : this.otp;
    if(codeOk) {
      if(this.form.controls.otp.valid) {
        this.connecting = true;
        return this.loginWeak();
      } else {
        this.loader.loading(true, {type: 'warn', message: 'otp.pattern'});
      }
    }
  }

  private finishLogin() {
    return this.api.info(undefined, true).then(() => {
      this.loader.loading(false);
      if(this.api.userRole?.isCustomer || this.api.userRole?.isSuperAdmin) {
        this.pinRedirect();
      } else {
        this.api.signOut(true);
        this.loader.loading(true, {type: 'warn', message: this.lang.transform('sign_in.login_as_customer')});
      }
    }).catch(() => {
      this.api.signOut(true, false);
      this.loader.loading(false);
      this.backupButton = true;
      this.submitted = false;
      this.otpDisabled = false;
      this.connecting = false;
    });
  }

  private pinRedirect() {
    this.loader.loading(false);
    if(this.api.userInfo?.pinHash) {
      this.nav.toPin(undefined, 'user');
    } else {
      this.nav.to('secret-code');
    }
  }

  closeOtp() {
    this.askOtp = false;
    this.otp = '';
    this.form.get('otp').setValue('');
    this.showOTPError = false;
    // this.form.get(this.signType === OtpType.EMAIL ? 'email' : 'phone').setValue('');
    this.form.get('password').setValue('');
  }

  changeOtpType(to?: OtpType) {
    if(to !== undefined) {
      this.signType = to;
    } else {
      this.signType = this.signType === OtpType.EMAIL ? OtpType.PHONE : OtpType.EMAIL;
    }
    setTimeout(() => {
      if(this.signType === OtpType.EMAIL) {
        this.form.get('phone' + environment.country).clearValidators();
        this.form.get('phone' + environment.country).updateValueAndValidity();
        this.form.get('email').setValidators([Validators.required, Validators.email]);
        this.form.get('email').updateValueAndValidity();
      } else {
        this.form.get('phone' + environment.country).setValidators([Validators.required, Validators.pattern(environment.phonePattern)]);
        this.form.get('phone' + environment.country).updateValueAndValidity();
        this.form.get('email').clearValidators();
        this.form.get('email').updateValueAndValidity();
      }
    });
  }
}
