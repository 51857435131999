import {Component, NgZone, OnInit, ViewEncapsulation} from '@angular/core';
import {Capacitor} from '@capacitor/core';
import {ApiService} from '../../../../../shared/services/api.service';
import {EnrollService} from '../../../../../shared/services/enroll.service';
import {DevicesService, LangService, LoaderService} from 'ngx-satoris';
import {MrzNfcDataInterface, MRZType} from '../../../../../shared/models/enroll';
import {format} from 'date-fns';
import getCountryISO2 from 'country-iso-3-to-2';

declare const cordova: any;
declare const SatorisOcr: any;

@Component({
  selector: 'app-activation-step-scan-mrz-nfc',
  templateUrl: './activation-step-scan-mrz-nfc.component.html',
  styleUrls: ['./activation-step-scan-mrz-nfc.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ActivationStepScanMrzNfcComponent implements OnInit{

  regionName = new Intl.DisplayNames([this.lang.readLangString.toLowerCase()], {
    type: 'region'
  });
  validDatas: MrzNfcDataInterface;
  nfcFoundTimeout: NodeJS.Timeout;

  constructor(public api: ApiService,
    public enroll: EnrollService,
    private devices: DevicesService,
    private loader: LoaderService,
    private lang: LangService,
    private zone: NgZone) {
    if(this.api.userInfo.nationalNumber && this.api.userRole.isCustomer) {
      this.api.setPrincipalMode = true;
    }
  }

  ngOnInit() {
    setTimeout(() => this.startNFC(), 500);
  }

  ngOnDestroy() {
    this.stopNFC();
    clearTimeout(this.nfcFoundTimeout);
    this.api.setPrincipalMode = false;
  }

  stopNFC() {
    SatorisOcr.stopWatch();
    document.removeEventListener('onNFCReadError', () => this.loader.loading(false));
    document.removeEventListener('onNFCReadSuccess', () => this.loader.loading(false));
    document.removeEventListener('onNFCFound', () => this.loader.loading(false));
    this.validDatas = undefined;
  }

  startNFC(){
    SatorisOcr.startWatch(this.enroll.form.controls[this.enroll.fc.DOC_NUMBER].value,
      format(new Date(this.enroll.form.controls[this.enroll.fc.DATE_OF_BIRTH].value),
        Capacitor.getPlatform() !== 'ios' ? 'yyyy-MM-dd' : 'yyMMdd'),
      format(new Date(this.enroll.form.controls[this.enroll.fc.DOC_EXPIRY_DATE].value),
        Capacitor.getPlatform() !== 'ios' ? 'yyyy-MM-dd' : 'yyMMdd'),
      console.log, console.error);
    document.addEventListener('onNFCFound', () => this.zone.run(() => {
      this.zone.run(() => {
        this.loader.loading(true);
        this.nfcFoundTimeout = setTimeout(() => {
          this.loader.loading(false);
          this.stopNFC();
        }, 20000);
      });
    }));
    document.addEventListener('onNFCReadSuccess', (datas: any) => {
      this.zone.run(() => {
        if(this.devices.isDevices('cordova-ios') || this.devices.isDevices('ios')) {
          this.enroll.form.controls[this.enroll.fc.NFC].setValue(JSON.parse(datas.detail.substring(0, datas.detail.length - 1).substring(1)));
        } else {
          this.enroll.form.controls[this.enroll.fc.NFC].setValue(JSON.parse(datas.detail));
        }
        this.nfcDataProcessing();
      });
    });
    document.addEventListener('onNFCReadError', () => {
      this.zone.run(() => {
        this.stopNFC();
        this.loader.loading(true, {type: 'error', message: this.lang.transform('error.nfc'), btnLabel: this.lang.transform('btn.retry')}).then((retry: boolean) => {
          if(retry) {
            this.startNFC();
          } else {
            this.enroll.navigate('previous');
          }
        });
      });
    });
  }

  nfcDataProcessing() {
    const nfcProcessing = (nfcDatas : any) =>{
      const newDatas = {...nfcDatas};
      if(newDatas.personal_number) {
        newDatas.personal_number = newDatas.personal_number.trim();
      }
      if(newDatas.primary_identifier && newDatas.secondary_identifier) {
        newDatas.global_name = newDatas.secondary_identifier + ' ' +newDatas.primary_identifier;
      }
      if(newDatas.issuing_authority) {
        newDatas.issuing_authority = this.regionName.of(getCountryISO2(newDatas.issuing_state));
      }
      if(newDatas.nationality) {
        newDatas.country = this.regionName.of(getCountryISO2(newDatas.nationality));
      }
      if( newDatas.date_of_issue) {
        newDatas.date_of_issue = newDatas.date_of_issue.substring(0, 4) + '-' + newDatas.date_of_issue.substring(4, 6) + '-' + newDatas.date_of_issue.substring(6, newDatas.date_of_issue.length);
      }
      if(newDatas.place_of_birth) {
        newDatas.place_of_birth = newDatas.place_of_birth.replaceAll('[', '').replaceAll(']', '').toLowerCase();
      }
      if(newDatas.issuing_authority) {
        newDatas.issuing_authority = newDatas.issuing_authority.toLowerCase().replaceAll('<', '').replaceAll('>', '');
      }
      if(newDatas.document_number) {
        newDatas.document_number = newDatas.document_number.toUpperCase().replaceAll('<', '').replaceAll('>', '');
      }
      if(newDatas.issuer) {
        newDatas.issuer = newDatas.issuer.substring(7, 25).toLowerCase();
      }
      return newDatas;
    };

    let formData: MrzNfcDataInterface = undefined;
    if(!this.enroll.form.controls[this.enroll.fc.MRZ].value) {
      formData = {
        document_number: this.enroll.form.controls[this.enroll.fc.DOC_NUMBER].value,
        birth_date: this.enroll.form.controls[this.enroll.fc.DATE_OF_BIRTH].value,
        expiry_date: this.enroll.form.controls[this.enroll.fc.DOC_EXPIRY_DATE].value
      };
    }

    if(this.enroll.MRZType === MRZType.TD1 || this.enroll.MRZType === MRZType.TD3){
      this.zone.run(() => {
        const newNFC = nfcProcessing(this.enroll.form.controls[this.enroll.fc.NFC].value);
        if(this.enroll.MRZType === MRZType.TD1 && newNFC.personal_number) {
          this.enroll.form.controls[this.enroll.fc.NNI].setValue(newNFC.personal_number);
        }
        this.validDatas = {...(formData ? formData : this.enroll.form.controls[this.enroll.fc.MRZ].value), ...newNFC};
      });
    } else {
      this.loader.loading(true, {type: 'error', message: this.lang.transform('global.error')});
    }
    this.loader.loading(false);
  }
}
