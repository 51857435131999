<div id="layout" class="f_col" [class.layout_sm]="!api.userRole.isCustomer">
  <app-view-title bgImage="assets-countries/header_background.png" fromRoute="activation-step-done" toRoute="choose-lang" [chooseLangBtn]="api.userRole.isCustomer && true">
    {{(this.api.setPrincipalMode ? 'activation.step.intro.resetAccount.title' : 'activation.step.done.title') | translate}}
  </app-view-title>
  <div class="scroll_wrapper">
    <app-view-deco-image maxWidth="300px" class="fadeIn" imageUrl="assets-countries/Valid.svg"></app-view-deco-image>
    <app-paragraph class="mb_40">{{(this.api.setPrincipalMode ? 'principalMode.set' : api.userRole.isCustomer ?  'activation.step.done.desc' : 'activation.step.admin.done.desc') | translate}}</app-paragraph>
    <div class="f_align_end f_grow ">
      <app-button (btnClicked)="done()" class="fullWidth">
        {{'global.close' | translate}}
      </app-button>
    </div>
  </div>
</div>
