<div id="layout" class="f_col layout_dk">
  <img id="custom_bg" ngSrc="assets-countries/home.png" width="200" height="200">
  <div class="scroll_wrapper">
    <div class="mt_20 f_grow f_col f_center">
      <h2 *ngIf="isQr" class="text_center">{{'badge.title' | translate}}</h2>
      <app-qr-code *ngIf="isQr" [value]="qrValue" [timer]="time"></app-qr-code>
    </div>
    <div class="f_col mb_10 mt_20">
      <app-button color="secondary" (btnClicked)="nav.to('user')" class="fullWidth">{{'global.close' | translate}}</app-button>
    </div>
  </div>
</div>
