<div id="webcam" [class.fullHeight]="fullHeight">
  <div id="scanner_placeholder">
    <div class="scanner_square" *ngIf="showWebcam && deviceReady"></div>
    <div class="scanner_bottom">
      <app-back-btn color="none" (click)="backUrlRedirect(backUrl)"></app-back-btn>
      <span id="takeScreenshot" (click)="triggerSnapshot()"><span></span></span>
      <fa-icon *ngIf="multipleWebcamsAvailable" class="scanner_camera" icon="camera-rotate" (click)="showNextWebcam(true)"></fa-icon>
    </div>
  </div>
  <webcam id="webcam_element" [height]="500" [width]="500" *ngIf="showWebcam" [hidden]="!deviceReady" 
          [trigger]="triggerObservable"
          [allowCameraSwitch]="false"
          [switchCamera]="nextWebcamObservable"
          [videoOptions]="videoOptions"
          [imageQuality]="imageQuality"
          [videoInitialized]="videoInitialized" 
          mirrorImage="never"
          (imageCapture)="handleImage($event)"
          (cameraSwitched)="cameraWasSwitched($event)"
          (initError)="handleInitError($event)"
  ></webcam>
</div>
