import {Component, NgZone, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import * as pkijs from 'pkijs';
import {v1 as uuidv1} from 'uuid';
import {SecureStorageService} from 'src/app/shared/services/secure-storage.service';
import {DevicesService, LangService, LoaderService, NavigateService} from 'ngx-satoris';
import {ActivatedRoute} from '@angular/router';
import {Claim, ClaimShareParams, ClaimType} from 'src/app/shared/models/claim';
import {ApiService} from 'src/app/shared/services/api.service';
import {ScanService} from 'src/app/shared/services/scan.service';
import {PermissionsService} from 'src/app/shared/services/permissions.service';
import {parseError} from 'src/app/shared/utils/string';
import {SyncService} from 'src/app/shared/services/sync.service';
import {checkValidityOfIssuerAuth} from 'src/app/shared/utils/verification';
import {PersonnalDatasService} from 'src/app/shared/services/personnal-datas.service';
import {TimeoutError} from 'rxjs';
import {IsoSchemaContent} from 'src/app/shared/models/user';

declare const bluetoothle: any;
declare const window: any;
declare const CBOR: any;

const DEC: {[id: string]: string} = {
  '-': '+',
  '_': '/',
  '.': '='
};
const ENC: {[id: string]: string} = {
  '+': '-',
  '/': '_',
  '=': '.'
};
const EmbeddedCBORGenTypes = {
  Uint8Array: (gen: any, obj: Uint8Array) => {
    if(!gen._pushTag(0b00011000)) {
      return false;
    }
    return CBOR.Encoder._pushArrayBuffer(gen, obj.buffer);
  }
};

@Component({
  selector: 'app-share-claim',
  templateUrl: './share-claim.component.html',
  styleUrls: ['./share-claim.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ShareClaimComponent implements OnInit, OnDestroy {

  timer = 1 * 60;
  params: ClaimShareParams;
  btnClose = false;

  qrValue: string;
  time: number;
  interval: NodeJS.Timeout;

  crypto = pkijs.getCrypto(true);
  isoClaim: Claim;
  bleScanStarted = false;
  bleConnectedAddress: string;
  bleServiceUUID: string;
  privateKey: CryptoKey;
  publicKey: CryptoKey;
  uniqueShareClaim = '';
  readerMessages = 1;
  deviceMessages = 1;
  bleMtu = 0;
  isClaimSelf = false;
  sessionTranscript: any[];
  skReader: CryptoKey;
  skDevice: CryptoKey;
  numberOfChunks: number;
  isQrReady: boolean;
  isTransfer: boolean;
  chunkProgress: number;
  chunkProgressSpeed: number;
  pingBtInterval: ReturnType<typeof setInterval>;
  dunkan: boolean;

  CHUNK_PERCENT = 75; // Percentage of a progressbar while we sent all data
  CLOSE_TIMEOUT = 30000;

  wakeLock: any = null;

  constructor(public nav: NavigateService,
    public api: ApiService,
    private secure: SecureStorageService,
    private devices: DevicesService,
    private loader: LoaderService,
    private lang: LangService,
    private zone: NgZone,
    private scan: ScanService,
    private permissions: PermissionsService,
    private route: ActivatedRoute,
    private sync: SyncService,
    private personnal: PersonnalDatasService) {
    this.route.queryParams.subscribe((params: any) => {
      if(params) {
        this.params = {
          claimType: JSON.parse(params.claimType),
          isoMode: JSON.parse(params.isoMode),
          selectedClaims: params.selectedClaims,
          fromRoute: params.fromRoute
        };
        if(this.personnal.isClaimTypeClaimSelf(this.params?.claimType)) {
          this.isClaimSelf = true;
        }
      }
      const selectedClaims: ClaimType[] = JSON.parse(this.params.selectedClaims);
      if(selectedClaims.length === 1) {
        this.uniqueShareClaim = selectedClaims[0] + '';
      }
    });
  }

  ngOnInit(): void {
    this.loader.loading(true);
    if(this.devices.isDevices('cordova')) {
      if('wakeLock' in navigator) {
        try {
          navigator.wakeLock.request('screen').then((wl: any) => {
            this.wakeLock = wl;
          });
        } catch (err) {
          console.error(err);
        }
      }
    }

    if(this.params?.isoMode) {
      if(!window.CBOR) {
        this.back();
        setTimeout(() => {
          this.loader.loading(true, {type: 'error', message: this.lang.transform('route.noCBOR')});
        }, 500);
        return;
      }
      this.locationHandler();
      this.btHandler();
    } else {
      if(!this.sync.isOnline) {
        this.nav.to('user');
        setTimeout(() => {
          this.loader.loading(true, {type: 'error', message: this.lang.transform('route.online')});
        }, 500);
      }
    }
    if(Object.keys(this.api.claims)?.length > 0 && JSON.parse(this.params.selectedClaims).length > 0) {
      this.isoClaim = this.api.claims[this.params.claimType];
      if(this.params.isoMode && this.devices.isDevices('cordova')) {
        if(!bluetoothle.isInitialized()) {
          this.initBLE();
        } else {
          bluetoothle.isConnected((status: any) => {
            if(status.isConnected) {
              this.disposeBLEConnection(true, true, this.share.bind(this));
            } else {
              this.share();
            }
          }, this.share.bind(this), {});
        }
      } else {
        this.share();
      }
    } else {
      this.back();
    }
  }

  ngOnDestroy() {
    this.permissions.removeHandlerPermissions();
    this.disposeBLEConnection(true, true);
    this.disposeBLEScan();
    clearInterval(this.interval);
    clearInterval(this.pingBtInterval);
    if(this.devices.isDevices('cordova') && this.wakeLock){
      this.wakeLock.release().then(() => {
        this.wakeLock = null;
      });
    }
  }

  private back(message?: string) {
    if(message) {
      this.loader.loading(true, {type: 'error', message}).then(() => this.nav.to(this.params.fromRoute, undefined, {queryParams: this.params}));
    } else {
      this.nav.to(this.params.fromRoute, undefined, {queryParams: this.params});
    }
  }

  onClose() {
    this.btnClose = true;
    this.back();
  }

  initBLE() {
    bluetoothle.initialize(({status}: any) => {
      if(status === 'enabled') {
        this.share();
      } else {
        console.error('Bluetooth init status:', status);
      }
    }, {
      'request': true,
      'restoreKey': 'didble'
    });
  }

  disposeBLEScan() {
    if(this.bleScanStarted) {
      bluetoothle.stopScan(() => {
        this.bleScanStarted = false;
      });
    }
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  disposeBLEConnection(closeByUs: boolean, force = false, callback?: Function) {
    if(closeByUs && this.bleConnectedAddress) {
      this.sendOverChannel(this.sliceIntoChunks(Array.from(CBOR.encodeOne({status: 20})), this.bleMtu - 1), () => {
        if(this.bleConnectedAddress || force) {
          this.skReader = undefined;
          this.skDevice = undefined;
          bluetoothle.disconnect(() => {
            this.bleConnectedAddress = undefined;
            bluetoothle.close(console.log, console.log, {address: this.bleConnectedAddress});
            if(callback) callback();
          }, () => callback && callback(), {address: this.bleConnectedAddress});
        } else if(callback) callback();
      });
    }
  }

  private sliceIntoChunks(arr: any[], chunkSize: number) {
    const res = [];
    for(let i = 0; i < arr.length; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize);
      res.push(chunk);
    }
    return res;
  }

  private receiverClosedConnection() {
    if(this.chunkProgress < this.CHUNK_PERCENT) {
      this.isTransfer = false;
      this.back(this.btnClose ? undefined : 'api.shareClaim.receiverClose');
    } else {
      this.chunkProgressSpeed = 0.03625;
      this.chunkProgress = 100;
    }
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  private negotiateMtu(callback: Function) {
    this.devices.isDevices('cordova-ios') ? callback() : bluetoothle.mtu((status: any) => {
      this.bleMtu = Math.max(this.bleMtu, status.mtu - 3);
      callback();
    }, callback, {
      address: this.bleConnectedAddress,
      mtu: 512
    });
  }

  private share() {
    this.loader.loading(true);
    clearInterval(this.interval);
    this.qrValue = '';
    this.time = this.timer;
    if(this.params?.isoMode) {
      if(this.secure.fullJwk?.hash !== this.api.userInfo?.deviceHash) {
        this.loader.loading(true, {type: 'error', message: this.lang.transform('api.shareClaim.noClaim')}).then(() => {
          this.back();
        });
        return;
      } else if(!checkValidityOfIssuerAuth(this.api.claims[this.params.claimType], 10 * 60000)) {
        this.loader.loading(true, {type: 'error', message: this.lang.transform('issuerAuth.expired.get')}).then(() => {
          this.back();
        });
        return;
      }
      this.zone.run(() => {
        this.crypto.generateKey({
          name: 'ECDH',
          namedCurve: 'P-256'
        }, true, ['deriveKey']).then(({privateKey, publicKey}) => {
          this.privateKey = privateKey;
          this.publicKey = publicKey;
          return this.crypto.exportKey('jwk', publicKey);
        }).then(jwk => {
          this.bleServiceUUID = uuidv1().toUpperCase();
          const engagementObject = new Map();
          engagementObject.set(0, '1.0');
          const e011 = new Map();
          e011.set(1, 2);
          e011.set(-1, 1);
          e011.set(-2, new Uint8Array(bluetoothle.stringToBytes(atob(jwk.x.replace(/[-_.]/g, (m) => DEC[m])))));
          e011.set(-3, new Uint8Array(bluetoothle.stringToBytes(atob(jwk.y.replace(/[-_.]/g, (m) => DEC[m])))));
          engagementObject.set(1, [1, new Uint8Array(CBOR.encodeOne(e011))]);
          const e012 = new Map();
          e012.set(0, false);
          e012.set(1, true);
          e012.set(11, Uint8Array.from(this.bleServiceUUID.replace(/-/g, '').match(/.{1,2}/g).map(byte => parseInt(byte, 16))));
          engagementObject.set(2, [[2, 1, e012]]);

          this.qrValue = 'mdoc:' + btoa(String.fromCharCode.apply(null, CBOR.encodeOne(engagementObject, {genTypes: EmbeddedCBORGenTypes}))).replace(/[+/=]/g, (m) => ENC[m]).replace(/\.+$/g, '');

          // Start bluetooth
          bluetoothle.startScan((r: any) => {
            if(!this.dunkan && r.status === 'scanResult') {
              this.dunkan = true;
              // this.disposeBLEScan();
              bluetoothle.connect(() => {
                this.bleConnectedAddress = r.address;
                bluetoothle.requestConnectionPriority(console.log, console.log, {
                  'address': this.bleConnectedAddress,
                  'connectionPriority': 'high'
                });
                this.negotiateMtu(() => {
                  bluetoothle.discover(() => {
                    let bufferResponse: number[] = [];
                    bluetoothle.subscribe((data: any) => {
                      if(!data.value) return;
                      const bytes = bluetoothle.encodedStringToBytes(data.value);
                      const arr: number[] = Array.from(bytes);
                      if(arr[0] === 2) {
                        // Request to end
                        this.zone.run(() => this.receiverClosedConnection());
                      }
                    }, (): any => undefined, {
                      address: r.address,
                      service: this.bleServiceUUID,
                      characteristic: '00000005-A123-48CE-896B-4C76973373E6'
                    });
                    bluetoothle.subscribe((data: any) => {
                      if(!data.value) return;
                      const bytes = bluetoothle.encodedStringToBytes(data.value.replace(/=/g, ''));
                      const arr: number[] = Array.from(bytes);
                      this.bleMtu = Math.max(this.bleMtu, arr.length);
                      const messagePart: number[] = arr.slice(1);
                      bufferResponse = bufferResponse.concat(messagePart);
                      if(arr[0] === 0) {
                        const request = this.secure.hexEncode(bluetoothle.bytesToString(bufferResponse));
                        bufferResponse = [];
                        const requestAndKey = CBOR.decode(request);
                        if(requestAndKey.status === 20) {
                          this.zone.run(() => this.receiverClosedConnection());
                          return;
                        }
                        //After some timeout receiver trying to send request again, but without eReaderKey inside
                        if(!requestAndKey.eReaderKey && !this.skReader) return;
                        if(!this.skReader) {
                          this.sessionTranscript = [new Uint8Array(CBOR.encodeOne(engagementObject, {genTypes: EmbeddedCBORGenTypes})), requestAndKey.eReaderKey, null];
                          const sessionTranscriptBytes = CBOR.encodeOne(new Uint8Array(CBOR.encodeOne(this.sessionTranscript, {genTypes: EmbeddedCBORGenTypes})), {genTypes: EmbeddedCBORGenTypes});
                          const eReaderKey: Map<number, any> = CBOR.decode(requestAndKey.eReaderKey.value);
                          Promise.all([
                            this.crypto.importKey('jwk', {
                              x: btoa(bluetoothle.bytesToString(eReaderKey.get(-2))).replace(/[+/=]/g, (m) => ENC[m]).replace(/\.+$/g, ''),
                              y: btoa(bluetoothle.bytesToString(eReaderKey.get(-3))).replace(/[+/=]/g, (m) => ENC[m]).replace(/\.+$/g, ''),
                              kty: jwk.kty,
                              crv: 'P-256'
                            }, {
                              name: 'ECDH',
                              namedCurve: 'P-256'
                            }, false, []).then(key => this.crypto.deriveKey({
                              name: 'ECDH',
                              public: key
                            }, this.privateKey, {
                              name: 'HKDF',
                              hash: 'SHA-256'
                            }, false, ['deriveKey'])),
                            this.crypto.digest('SHA-256', sessionTranscriptBytes)
                          ]).then(([commonKey, salt]) => Promise.all([
                            this.crypto.deriveKey({name: 'HKDF', salt: salt, info: new Uint8Array([83, 75, 82, 101, 97, 100, 101, 114]), hash: 'SHA-256'},
                              commonKey, {name: 'AES-GCM', length: 256}, true, ['decrypt']),
                            this.crypto.deriveKey({name: 'HKDF', salt: salt, info: new Uint8Array([83, 75, 68, 101, 118, 105, 99, 101]), hash: 'SHA-256'},
                              commonKey, {name: 'AES-GCM', length: 256}, true, ['encrypt'])
                          ]).then(([skReader, skDevice]) => {
                            this.readerMessages = 1;
                            this.deviceMessages = 1;
                            this.skReader = skReader;
                            this.skDevice = skDevice;
                            this.processMessage(requestAndKey.data);
                          })).catch(console.error);
                        } else {
                          this.processMessage(requestAndKey.data);
                        }
                      }
                    }, () => {
                      this.zone.run(() => this.receiverClosedConnection());
                    }, {
                      address: r.address,
                      service: this.bleServiceUUID,
                      characteristic: '00000007-A123-48CE-896B-4C76973373E6'
                    });
                    bluetoothle.write((): any => undefined, (): any => undefined, {
                      address: r.address,
                      service: this.bleServiceUUID,
                      characteristic: '00000005-A123-48CE-896B-4C76973373E6',
                      value: 'AQ==',
                      type: 'noResponse'
                    });
                  }, console.error, {address: r.address});
                });
              }, (error: any) => {
                //TODO: If duplicate
                console.log('Connect error:', error);
              }, {
                address: r.address,
                autoConnect: false,
                transport: 0
              });
            } else {
              this.bleScanStarted = true;
            }
          }, console.error, {
            services: [this.bleServiceUUID],
            allowDuplicates: false,
            scanMode: bluetoothle.SCAN_MODE_LOW_LATENCY,
            matchMode: bluetoothle.MATCH_MODE_AGGRESSIVE,
            matchNum: 1,
            callbackType: bluetoothle.CALLBACK_TYPE_ALL_MATCHES
          });
          this.shareReady();
        }).catch(err => {
          this.loader.loading(true, {type: 'error', message: String(err).indexOf(': ') > -1 ? err : this.lang.transform('api.shareClaim.error')});
        });
      });
    } else {
      this.isQrReady = false;
      const selectedClaims: ClaimType[] = JSON.parse(this.params.selectedClaims);
      const claims: Claim[] = Object.values(this.api.claims).filter(c => selectedClaims.indexOf(c.type) > -1);
      if(claims.length === 0) this.back();
      this.loader.loadingElement('shareClaim', true, 'lg');
      Promise.all(claims.map(c => this.api.shareClaim(c.id, this.time * 1000))).then(readUuids => {
        this.qrValue = this.scan.setQrCode(readUuids.map((r, i) => ({
          type: claims[i].type,
          id: claims[i].id,
          uuid: r
        })), true);
        this.shareReady();
      }).catch(err => {
        if(!(err instanceof TimeoutError)){
          const error = parseError(err);
          if(error.baseError === 'client.extended.pinBlocked') {
            this.redirectQr(true);
          } else {
            this.loader.loading(true, {type: 'error', message: String(err).indexOf(': ') > -1 ? err : this.lang.transform('api.shareClaim.error')});
          }
        } else {
          this.back();
        }
      });
    }
  }

  private closeConnection() {
    this.sendOverChannel(this.sliceIntoChunks(Array.from(CBOR.encodeOne({status: 20})), this.bleMtu - 1), () => {
      this.back();
    });
  }

  private processMessage(data: Uint8Array) {
    this.decryptMessage(data).then(requestCbor => {
      const request = CBOR.decode(requestCbor);
      const fields: string[] = [];
      const keptFields: string[] = [];
      for(let i = 0; i < request.docRequests.length; i++) {
        const itemRequests = CBOR.decode(request.docRequests[i].itemsRequest.value);
        const isoSchema = this.api.userInfo.server.isoSchemasVersionned[this.api.userInfo.server.isoSchemasVersion];
        if(itemRequests.docType !== isoSchema[this.isoClaim.type].doc) {
          return this.loader.loading(true, {type: 'error', message: this.lang.transform('api.shareClaim.err_wrong_doc') + ': ' + itemRequests.docType}).then(() =>{
            this.closeConnection();
            return;
          });
        }
        Object.getOwnPropertyNames(itemRequests.nameSpaces).forEach(namespace => {
          Object.getOwnPropertyNames(itemRequests.nameSpaces[namespace]).forEach(field => {
            fields.push(field);
            if(itemRequests.nameSpaces[namespace][field]) keptFields.push(field);
          });
        });
      }
      //TODO: Maybe add some vibro through vibration API - navigator.vibrate(500);

      const tradFields = fields.map(f => this.lang.transform('share.claims.iso.fields.DYN.' + f)).join(', ');
      const tradKeptFields = keptFields.map(f => this.lang.transform('share.claims.iso.fields.DYN.' + f)).join(', ');
      const confirmFitler = keptFields.length > 0 ? this.lang.transform('share.claims.iso.content.keptFields', {fields: tradFields, keptFields: tradKeptFields}) : this.lang.transform('share.claims.iso.content', {fields: tradFields});
      if(confirm(confirmFitler)) {
        this.generateFullResponse(this.isoClaim, this.sessionTranscript, false, fields).then((response: Uint8Array) => {
          this.encryptMessage(response).then(buf => {
            const response = CBOR.encodeOne({data: buf}, {highWaterMark: 1024000});
            this.chunkProgress = 0;
            this.numberOfChunks = Math.floor(Array.from(response).length / (this.bleMtu - 1));
            this.isTransfer = true;
            this.zone.run(() => this.startPingBT(this.bleConnectedAddress));
            this.sendOverChannel(this.sliceIntoChunks(Array.from(response), this.bleMtu - 1), () => {
              // We're done!
              setTimeout(() => this.disposeBLEConnection(true, true), 450);
              // TODO: register on server this read?
              this.zone.run(() => {
                this.chunkProgressSpeed = 4;
                this.chunkProgress = 95;
                bluetoothle.isConnected((s: any) => {
                  if(!s.isConnected) {
                    clearInterval(this.pingBtInterval);
                    this.transferDone();
                  } else {
                    setTimeout(() => {
                      if(this.isTransfer) {
                        this.isTransfer = false;
                        this.back();
                        this.loader.loading(true, {type: 'error', message: this.lang.transform('api.shareClaim.error_not_closed')});
                      }
                    }, this.CLOSE_TIMEOUT);
                  }
                },
                console.error,
                {
                  address: this.bleConnectedAddress
                });
              });
            });
          });
        });
      } else {
        this.closeConnection();
      }
    });
  }

  private sendOverChannel(chunks: number[][], callback: () => any) {
    const chunk = chunks.shift();
    bluetoothle.write(() => {
      if(chunks.length === 0) callback();
      else setTimeout(() => this.sendOverChannel(chunks, callback), this.devices.isDevices('cordova-ios') ? 30 : 10);
    }, (): any => undefined, {
      address: this.bleConnectedAddress,
      service: this.bleServiceUUID,
      characteristic: '00000006-A123-48CE-896B-4C76973373E6',
      value: bluetoothle.bytesToEncodedString(new Uint8Array([chunks.length === 0 ? 0 : 1, ...chunk])),
      type: 'noResponse'
    });
    this.chunkProgress = Math.floor(((this.numberOfChunks - chunks.length) / this.numberOfChunks) * this.CHUNK_PERCENT);
  }

  private getPayloadKeyValue(payload: any, doc: IsoSchemaContent, namespaceIndex: number, fieldIndex: number): [string, string] {
    const fieldConfig: string | string[] = doc.namespaces[namespaceIndex].fields[fieldIndex];
    if(typeof fieldConfig !== 'object') {
      const derivedFieldFunction = doc.namespaces[namespaceIndex].derivedFields[fieldConfig];
      if(derivedFieldFunction) {
        return [fieldConfig, eval(derivedFieldFunction)(payload)];
      }
      return [fieldConfig, payload[fieldConfig]];
    }
    return [fieldConfig[0], eval(fieldConfig[2])(payload[fieldConfig[0]])];
  }

  private generateFullResponse(claim: Claim, sessionTranscript: any[], all: boolean, fields: string[]) {
    const doc: IsoSchemaContent = this.api.userInfo.server.isoSchemasVersionned[this.api.userInfo.server.isoSchemasVersion][claim.type];
    const payload = JSON.parse(claim.serialized);
    const salt = JSON.parse(claim.serializedSalt);
    // Find age_over_ to respond
    const includedAgeOverFields: string[] = [];
    const presentAgeFields = Object.getOwnPropertyNames(salt).filter(k => k.startsWith('age_over_')).map(k => parseInt(k.substring(9), 10)).sort();
    fields.forEach(field => {
      if(field.startsWith('age_over_')) {
        const age = parseInt(field.substring(9), 10);
        let bestAgeFound: number;
        for(let l = 0; l < presentAgeFields.length; l++) {
          let [_, value] = ['', ''];
          for(let i = 0; i < doc.namespaces.length; i++) {
            for(let j = 0; j < doc.namespaces[i].fields.length; j++) {
              if(doc.namespaces[i].fields[j] === 'age_over_' + presentAgeFields[l]) {
                [_, value] = this.getPayloadKeyValue(payload, doc, i, j) as [string, string];
                break;
              }
            }
          }
          if(value && presentAgeFields[l] >= age) {
            bestAgeFound = presentAgeFields[l]; // Want the lowest such, so break
            break;
          } else if(presentAgeFields[l] < age) {
            bestAgeFound = presentAgeFields[l];
          }
        }
        if(bestAgeFound) includedAgeOverFields.push('age_over_' + bestAgeFound);
      }
    });
    // Fill namespaces
    const namespaces: {[id: string]: any[]} = {};
    const deviceNamespaces: {[id: string]: any} = {};
    for(let i = 0; i < doc.namespaces.length; i++) {
      const namespace: any[] = [];
      const deviceNamespace: any = {};
      for(let j = 0; j < doc.namespaces[i].fields.length; j++) {
        const [key, value] = this.getPayloadKeyValue(payload, doc, i, j);
        if(value === undefined || (!all && fields.indexOf(key) === -1)) continue;
        if(key.startsWith('age_over_') && includedAgeOverFields.indexOf(key) === -1) continue;
        namespace.push(Uint8Array.from(CBOR.encodeOne({
          digestID: 1000 * i + j,
          random: salt[key],
          elementIdentifier: key,
          elementValue: value
        }, {highWaterMark: 1024000, dateType: 'string'})));
        deviceNamespace[key] = value;
      }
      namespaces[doc.namespaces[i].namespace] = namespace;
      deviceNamespaces[doc.namespaces[i].namespace] = deviceNamespace;
    }
    const deviceNamespacesBytes = Uint8Array.from(CBOR.encodeOne(deviceNamespaces, {highWaterMark: 1024000}));
    // Sign
    const plaintext = CBOR.encodeOne(Uint8Array.from(CBOR.encodeOne([
      'DeviceAuthentication',
      sessionTranscript,
      doc.doc,
      deviceNamespacesBytes
    ], {genTypes: EmbeddedCBORGenTypes, highWaterMark: 1024000})), {genTypes: EmbeddedCBORGenTypes, highWaterMark: 1024000});
    return this.secure.retrieveSecretKey().then(() => {
      const headerMap = new Map();
      headerMap.set(1, -7);
      const encodedProtectedHeaders = CBOR.encodeOne(headerMap, {highWaterMark: 1024000});
      const fullPlainText = CBOR.encodeOne(['Signature1', encodedProtectedHeaders, new ArrayBuffer(0), plaintext], {highWaterMark: 1024000});
      return this.crypto.sign({
        name: 'ECDSA',
        hash: 'SHA-256'
      }, this.secure.privateDeviceKey, fullPlainText).then(sign => CBOR.encodeOne({
        version: '1.0',
        documents: [{
          docType: doc.doc,
          issuerSigned: {
            nameSpaces: namespaces,
            issuerAuth: CBOR.decode(claim.issuerAuth)
          },
          deviceSigned: {
            nameSpaces: deviceNamespacesBytes,
            deviceAuth: {
              deviceSignature: [encodedProtectedHeaders, {}, Uint8Array.from([]), sign]
            }
          }
        }],
        status: 0
      }, {genTypes: EmbeddedCBORGenTypes, highWaterMark: 1024000}));
    });
  }

  private decryptMessage(data: BufferSource) {
    return this.crypto.decrypt({
      name: 'AES-GCM',
      iv: new Int8Array([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, this.readerMessages]),
      tagLength: 128
    }, this.skReader, data).then(msg => {
      this.readerMessages++;
      return msg;
    });
  }

  private encryptMessage(data: BufferSource) {
    return this.crypto.encrypt({
      name: 'AES-GCM',
      iv: new Int8Array([0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, this.deviceMessages]),
      tagLength: 128
    }, this.skDevice, data).then(msg => {
      this.deviceMessages++;
      return msg;
    });
  }

  private shareReady() {
    this.loader.loading(false);
    setTimeout(()=>{
      this.loader.loading(false);
      this.isQrReady = true;
    }, 500);
    this.interval = setInterval(() => {
      this.time--;
      if(this.time <= 0) {
        if(!this.params.isoMode){
          if(!this.sync.isOnline){
            this.nav.to('user');
            setTimeout(() => {
              this.loader.loading(true, {type: 'error', message: this.lang.transform('share.claim.offline')});
            }, 500);
            return;
          }
        }
        if(!this.isTransfer){
          this.redirectQr();
        } else {
          clearInterval(this.interval);
        }
      }
    }, 1000);
  }

  startPingBT(address: string) {
    this.pingBtInterval = setInterval(() => {
      bluetoothle.isConnected((s: any) => {
        if(!s.isConnected) {
          clearInterval(this.pingBtInterval);
          if(this.chunkProgress < this.CHUNK_PERCENT) this.loader.loading(true, {type: 'warn', message: this.lang.transform('api.shareClaim.receiverClose')}).then(() => this.back());
        }
      }, console.error, {address});
    }, 1000);
  }

  transferDone() {
    clearInterval(this.pingBtInterval);
    this.nav.to('share-claim-done', 500, {state: {claimType: this.isoClaim.type}, queryParams: this.params});
    setTimeout(() => {
      this.isTransfer = false;
    }, 500);
  }

  btHandler(register = true) {
    window?.cordova?.plugins?.diagnostic?.registerBluetoothStateChangeHandler(register ? (location: string)=>{
      if(location === window?.cordova?.plugins?.diagnostic?.bluetoothState?.POWERED_OFF){
        this.nav.to('user-id-card');
        setTimeout(() => {
          this.loader.loading(true, {type: 'warn', message: this.lang.transform('share.permissions.bluetoothOff')});
        });
      }
    } : false);
  }

  locationHandler(register = true) {
    window?.cordova?.plugins?.diagnostic?.registerLocationStateChangeHandler(register ? (location: string)=>{
      if(location === window?.cordova?.plugins?.diagnostic?.locationMode?.LOCATION_OFF){
        this.nav.to('user-id-card');
        setTimeout(() => {
          this.loader.loading(true, {type: 'warn', message: this.lang.transform('share.permissions.locationOff')});
        });
      }
    } : false);
  }

  redirectQr(errorMessage = false){
    if(errorMessage){
      setTimeout(() => {
        this.loader.loading(true, {type: 'warn', message: this.lang.transform('share.claim.pinBlocked')});
      }, 500);
    }
    this.isQrReady = false;
    this.qrValue = '';
    return this.nav.toPin('claim-card', 'share-claim', undefined, undefined, {queryParams: this.params}, 0);
  }

  protected readonly JSON = JSON;
}
