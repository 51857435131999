<div id="layout" class="f_col" [class.layout_sm]="!api.userRole.isCustomer">
  <app-view-title bgImage="assets-countries/header_background.png" fromRoute="activation-step-voucher" toRoute="choose-lang" [chooseLangBtn]="api.userRole.isCustomer">
    {{'activation.step.voucher.title' | translate}}
  </app-view-title>
  <div class="scroll_wrapper">
    <app-view-deco-image maxWidth="300px" imageUrl="assets-countries/undraw_phone.svg"></app-view-deco-image>
    <app-paragraph innerhtml="{{'activation.step.voucher.desc' | translate}}"></app-paragraph>
    <div class="f_grow mt_20 mb_20">
      <form id="form" [formGroup]="enroll.form" (ngSubmit)="enroll.submitForm(enroll.getCurrentStep())">
        <app-input type="text" [formGrp]="enroll.form" [formCtrlName]="enroll.fc.VOUCHER" label="form.voucher" icon="mobile-screen-button"></app-input>
        <app-errors-list [dark]="true" *ngIf="!enroll.form.controls[enroll.fc.VOUCHER].valid && enroll.form.controls[enroll.fc.VOUCHER].touched" [formErrors]="forms.getFormErrors(enroll.form, enroll.fc.VOUCHER)"></app-errors-list>
      </form>
    </div>
    <div class="f_align_end f_grow">
      <div class="fullWidth flex gap_10 mb_20">
        <app-back-btn (btnClicked)="enroll.navigate('previous')"></app-back-btn>
        <app-button [disabled]="!enroll.form.controls[enroll.fc.VOUCHER].valid || !enroll.form.controls[enroll.fc.VOUCHER].value" (btnClicked)="progress()" class="fullWidth">
          {{'global.continue' | translate}}
        </app-button>
      </div>
    </div>
  </div>
</div>
