import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ApiService} from 'src/app/shared/services/api.service';
import {BiometricService} from 'src/app/shared/services/biometric.service';
import {DevicesService, LangService, LoaderService, NavigateService} from 'ngx-satoris';

declare const window: any;

@Component({
  selector: 'app-more-security-biometric',
  templateUrl: './more-security-biometric.component.html',
  styleUrls: ['./more-security-biometric.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MoreSecurityBiometricComponent implements OnInit{
  constructor(public api: ApiService,
    public biometric: BiometricService,
    public nav: NavigateService,
    public loader: LoaderService,
    public lang: LangService,
    public device: DevicesService) {
  }
  ngOnInit(): void {
    if(this.biometric.currentlyValidated) {
      this.loader.loading(true, {type: 'valid', message: this.lang.transform(this.device.isDevices('cordova-ios') ? 'biometric.faceID.setSuccess' : 'fingerprints.register.success')});
      this.biometric.currentlyValidated = false;
    }
  }

  data: any;

  handleBiometricClicked() {
    if(!this.biometric.biometric.enrolled) {
      return this.loader.loading(true, {type: 'warn', message: 'notEnrolledBiometric'});
    }
    if(!this.biometric.biometric.isGranted && this.biometric.biometric.isGranted !== null ) {
      window?.cordova?.plugins?.diagnostic?.switchToSettings();
    } else {
      if(this.biometric.biometric.isRegistered) {
        this.loader.loading(true, {type: 'warn', message: this.lang.transform(this.device.isDevices('cordova-ios') ? 'ask.faceID.unset' : 'ask.fingerprint.unset'), btnLabel: this.lang.transform('global.confirm')}).then((result: boolean) => {
          if(result) {
            this.biometric.unsetBiometric();
            setTimeout(() => {
              this.loader.loading(true, {type: 'valid', message: this.lang.transform(this.device.isDevices('cordova-ios') ? 'biometricIOS.unregister.success' : 'biometricANDROID.unregister.success')});
            }, 300);
          }
        });
      } else {
        this.nav.toPin('more-security-biometric', 'more-security-biometric', true, undefined, {state: {setBiometric: true}});
      }
    }
  }
}
