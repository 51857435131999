<div id="layout" class="sign_in f_col">
  <app-view-title bgImage="assets-countries/header_background.png" fromRoute="sign-in"  toRoute="choose-lang" [chooseLangBtn]="true">{{'signIn.title' | translate}}</app-view-title>
  <div class="scroll_wrapper">
    <app-view-deco-image imageUrl="assets-countries/undraw_welcome.svg"></app-view-deco-image>
    <app-slide-element *ngIf="!askOtp"  [leftIcon]="signType === OtpType.EMAIL ? 'phone' : 'message'" (btnClicked)="changeOtpType()" class="mb_10">
      {{(signType=== OtpType.EMAIL ? 'signin.phone' : 'signin.email') | translate}}
    </app-slide-element>
    <app-paragraph *ngIf="!askOtp" class="mb_20" align="center" textAlign="center" [innerHtml]="(signType=== OtpType.EMAIL ? 'signin.state.email' : 'signin.state.phone') | translate"></app-paragraph>
    <div *ngIf="askOtp" >
      <app-paragraph textAlign="center">{{(signType === OtpType.EMAIL ?  'login.ask_otp.email' : 'login.ask_otp.sms') | translate}}</app-paragraph>
      <app-pin [disableBackupButton]="otpDisabled" [backupButton]="backupButton" formCtrlName="otp" [formGrp]="form"  [disabledTimer]="otpDisabledTime" [disabledAnim]="otpDisabled" [disabled]="connecting" [showCode]="true" [showCorrect]="showOTPCorrect" [showError]="showOTPError" (codeOutput)="checkOTPCode($event)" (backupSubmit)="checkOTPCode($event)"></app-pin>
    </div>
    <div *ngIf="!askOtp">
      <form id="form" [formGroup]="form" (ngSubmit)="submitForm()">
        <app-input *ngIf="signType === OtpType.EMAIL" type="email" formCtrlName="email" [formGrp]="form" label="form.email"></app-input>
        <app-input *ngIf="signType === OtpType.PHONE" type="tel" [formCtrlName]="'phone' + api.env.country" [formGrp]="form" label="form.sms" prefix="225"></app-input>
        <app-input type="password" formCtrlName="password" [formGrp]="form" label="form.password" class="mt_24"></app-input>
        <app-errors-list [dark]="true" *ngIf="submitted" [formErrors]="forms.getFormErrors(this.form)"></app-errors-list>
      </form>
      <div class="f_end mt_10 mb_20">
        <a (click)="nav.to('forgot-password', undefined, {state:
          {
            signType: this.signType,
            email: this.form.get('email')?.value,
            phone: this.form.get(('phone' + api.env.country)).value}
        })">{{'signIn.forgotPassword' | translate}}</a>
      </div>
    </div>
  </div>
    <div class="f_align_end f_grow mb_30">
      <app-back-btn (btnClicked)="askOtp ? closeOtp() : nav.to('home')"></app-back-btn>
      <div class="fullWidth ml_10" [hidden]="askOtp">
        <app-button type="submit" [initStartDebounce]="true" [showRemainingDebounceTime]="true" [debounceTimeInMilliseconds]="debounceTimer" formId="form" class="fullWidth">{{'login.sign_in' | translate}}</app-button>
      </div>
    </div>
</div>
