<div id="layout" class="f_col container layout_sm">
    <app-view-title bgImage="assets-countries/header_background.png">{{'more.security.otp' | translate}}</app-view-title>
    <div class="scroll_wrapper">
      <div *ngIf="!showOtp" class="f_grow f_col">
        <app-view-deco-image imageUrl="assets-countries/{{data.otp === 'email' ? 'undraw_mail_otp.svg' : 'undraw_mobile_encryption.svg'}}"></app-view-deco-image>
        <div class="f_grow f_col flex">
          <app-paragraph textAlign="center" class="mt_10 mb_20">{{(data.otp === 'email' ? 'otp.desc.email' : 'otp.desc.sms') | translate}}  {{data.otp === 'email' ? api.userInfo.accountName : ''}}</app-paragraph>
          <form class="mt_20 mb_20" id="form" [formGroup]="form" (ngSubmit)="submitForm()" *ngIf="data.otp === 'sms'">
            <app-input prefix="225" type='tel' formCtrlName='phone' [formGrp]="form" label='form.sms'></app-input>
            <app-errors-list [dark]="true" *ngIf="submitted" [formErrors]="forms.getFormErrors(this.form)"></app-errors-list>
          </form>
        </div>
        <div class="flex gap_10">
          <app-back-btn [navTo]="{route: 'more-security-otp-explain'}"></app-back-btn>
          <app-button *ngIf="api.userInfo.otpType === OtpType.EMAIL" type="submit" formId="form" class="fullWidth">{{'confirm' | translate}}</app-button>
          <app-button (btnClicked)="submitForm()" *ngIf="api.userInfo.otpType === OtpType.PHONE" type="button" formId="form" class="fullWidth">{{'confirm' | translate}}</app-button>
        </div>
      </div>

      <div *ngIf="showOtp"  class="f_col f_align_c f_center f_grow">
        <app-paragraph textAlign="center">{{('login.ask_otp.sms' | translate)}}</app-paragraph>
        <app-pin  [showCode]="true" [showCorrect]="showOTPCorrect" [showError]="showOTPError" (codeOutput)="checkOTPCode($event)"></app-pin>
        <app-back-btn position="botLeft" (btnClicked)="resetOtp()"></app-back-btn>
      </div>
    </div>
  </div>
