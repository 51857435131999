import {Component, ViewChild, ViewEncapsulation} from '@angular/core';
import {ButtonComponent, LangService, LoaderService, NavigateService} from 'ngx-satoris';
import {ApiService} from '../../shared/services/api.service';
import {OtpType} from 'src/app/shared/models/user';
import {Router} from '@angular/router';
import {truncateString} from 'src/app/shared/utils/string';

@Component({
  selector: 'app-account-valid',
  templateUrl: './account-valid.component.html',
  styleUrls: ['./account-valid.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AccountValidComponent{
  @ViewChild('buttonResendOtp') buttonResendOtp: ButtonComponent;

  data: {
    signType: OtpType,
    accountToActivate: string,
  };
  truncateString = truncateString;
  OtpType = OtpType;
  JSON = JSON;
  isSubmitting = false;

  constructor(public nav: NavigateService,
    public api: ApiService,
    private loader: LoaderService,
    private lang: LangService,
    private router: Router) {
    this.data = <any> this.router.getCurrentNavigation()?.extras?.state;
    if(!this.data?.accountToActivate && !this.data?.signType) {
      this.nav.to('home');
    }
  }

  resendLink() {
    setTimeout(() => {
      this.isSubmitting = false;
    }, 55000)
    if(!this.isSubmitting) {
      this.isSubmitting = true;
      this.api.rearm(this.data.accountToActivate).then(() => {
        this.buttonResendOtp.startTimer();
        this.loader.loading(true, {type: 'valid', message: this.lang.transform(this.data.signType === OtpType.EMAIL ? 'otp.successResend.email' : 'otp.successResend.sms')});
      });
    }
  }
}
