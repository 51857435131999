import {Component, OnDestroy, ViewEncapsulation} from '@angular/core';
import {differenceInYears} from 'date-fns';
import {
  Claim,
  ClaimIdCard,
  ClaimSelf,
  ClaimSelfForAdmin,
  ScanBadgeData
} from '../../../shared/models/claim';
import {countries, Country} from '../../../shared/models/countries';
import {ApiService} from '../../../shared/services/api.service';
import {ScanService} from '../../../shared/services/scan.service';
import {BlockArray, LoaderService, NavigateService} from 'ngx-satoris';
import {PersonnalDatasService} from 'src/app/shared/services/personnal-datas.service';
import {environment} from 'src/environments/environment';
import {SyncService} from '../../../shared/services/sync.service';
import {Place} from '../../../shared/models/place';

@Component({
  selector: 'app-scan-result',
  templateUrl: './scan-result.component.html',
  styleUrls: ['./scan-result.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ScanResultComponent implements OnDestroy {
  scanBadgeData: ScanBadgeData;
  selfClaimShare: ClaimSelfForAdmin;
  personnalDataShare: BlockArray[] = [];

  constructor(public scan: ScanService,
    public nav: NavigateService,
    public api: ApiService,
    public sync: SyncService,
    private loader: LoaderService,
    private personnalDatas: PersonnalDatasService) {
    if(!this.scan.claimScanData || !this.scan.claims) {
      if(!this.scan.userScanData) {
        this.loader.loading(true, {type: 'warn', message: 'noIDinQR.error'}).then(() => {
          this.nav.toPerm('scan-result', 'scan', 'CAMERA');
        });
      } else {
        this.scanBadgeData = JSON.parse(this.scan.userScanData?.data?.aud);
        this.scanBadgeData.userId = this.scan.userScanData?.data?.sig;
        this.scanBadgeData.pi && this.api.place(this.scanBadgeData.pi, false).then((place: Place) => {
          this.scanBadgeData.img = place.b64Logo || '';
        });
      }
    }
    if(this.scan?.claimScanData && this.scan?.claimScanData?.some(claim => this.personnalDatas.isClaimTypeClaimSelf(claim.type))) {
      this.personnalDataShare = this.personnalDatas.transformClaimToSelfClaim(this.scan.claims as ClaimSelf[],
        undefined, true, true, false);
    }
  }

  ngOnDestroy() {
    this.scan.claimScanData = undefined;
    this.scan.claims = undefined;
    this.scan.placeControlClaims = undefined;
  }

  checkAge(claim: Claim): { adult: boolean, countryAge: number } {
    const id: ClaimIdCard = JSON.parse(claim.serialized);
    const country: Country = countries.find(x => x.name === environment.country);
    return {adult: differenceInYears(new Date(), new Date(id.date_of_birth)) > country.age, countryAge: country.age};
  }

  protected readonly JSON = JSON;
}
