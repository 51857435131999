<div id="layout" class="f_col layout_dk">
  <img id="custom_bg" ngSrc="assets-countries/home.png" width="200" height="200">
  <div class="scroll_wrapper">
    <div class="f_grow f_col f_center">
      <div class="f_center mb_10">
        <h2>{{'version.update.title' | translate}}</h2>
      </div>
      <app-paragraph textAlign="center">{{'version.update.desc' | translate}}</app-paragraph>
      <div class="mt_20">
        <app-button [disabled]="true">{{'version.update.now' | translate}}</app-button>
        <app-button class="mt_10" color="secondary" (btnClicked)="nav.to('dashboard')">{{'version.update.later' | translate}}</app-button>
      </div>
    </div>
  </div>
</div>
