import {Component, ViewEncapsulation} from '@angular/core';
import {I18nLang, LangService, LoaderService, NavigateService} from 'ngx-satoris';
import {ApiService} from 'src/app/shared/services/api.service';
import {formatDistanceToNow, differenceInMinutes} from 'date-fns';
import {fr, enUS} from 'date-fns/locale';
import {truncateString} from 'src/app/shared/utils/string';
import {PersonnalDatasService} from 'src/app/shared/services/personnal-datas.service';

@Component({
  selector: 'app-personnal-data-manage',
  templateUrl: './personnal-data-manage.component.html',
  styleUrls: ['./personnal-data-manage.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PersonnalDataManageComponent {

  isReload = false;
  truncateString = truncateString;
  constructor(public api: ApiService, 
    public nav: NavigateService,
    private loader: LoaderService,
    private lang: LangService,
    public personnalDatas: PersonnalDatasService) {}


  calculateTimeAgo(createdAt: string) {
    if(createdAt) {
      const createdDate = new Date(createdAt);
      return formatDistanceToNow(createdDate, {addSuffix: true, locale: this.api.userInfo.lang === I18nLang.FR ? fr : enUS});
    } else {
      return this.lang.transform('manageSelf.noDate');
    }
  }

  popupHelper() {
    this.loader.loading(true, {type: 'info', message: 'managePopup.helper'});
  }

  refetchClaimAdmin() {
    if(this.isReload) {
      this.loader.loading(true, {type: 'warn', message: this.lang.transform('refresh.alreadyOpen')});
    }
    this.isReload = true;
    this.loader.loadingElement('bottom', true, 'xs');
    setTimeout(() =>this.personnalDatas.selfClaimsAdmin().then(() => {
      this.loader.loadingElement('bottom', false);
      this.isReload = false;
    }), 1000);
  }
  
  checkAssignedAt(id: string, date: Date, onlyColor= false) {
    if(date){
      const isDiffMinutes = differenceInMinutes(new Date(), new Date(date)) > 5;
      if(!isDiffMinutes) {
        if(onlyColor) { 
          return 'var(--clr-btn-red)';
        } else {
          this.loader.loading(true, {type: 'warn', message: this.lang.transform('manage.alreadyOpen'), btnLabel: 'global.confirm'}).then((res) => {
            if(res){
              this.nav.to('personnal-data-manage/' + id);
            }
          });
        }
      } else {
        !onlyColor && this.nav.to('personnal-data-manage/' + id);
      }
    } else {
      !onlyColor && this.nav.to('personnal-data-manage/' + id);
    }
  }

  onScroll(event: Event) {
    const target = event.target as HTMLElement;
    if(target.offsetHeight + target.scrollTop >= target.scrollHeight) {
      this.personnalDatas.scrollNumberOpenSelfClaims += this.personnalDatas.scrollNumberOpenSelfClaims;
      this.isReload = true;
      this.loader.loadingElement('bottom', true, 'xs');
      setTimeout(() =>{
        this.personnalDatas.selfClaimsAdmin().then(() => {
          this.isReload = false;
          this.loader.loadingElement('bottom', false);
        });
      }, 1000);
    }
  }
}
