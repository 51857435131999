import {Component, ViewEncapsulation} from '@angular/core';
import {NavigateService} from 'ngx-satoris';
import {ApiService} from 'src/app/shared/services/api.service';
import {ActivatedRoute} from '@angular/router';
import {ClaimShareParams} from '../../../../shared/models/claim';

@Component({
  selector: 'app-share-claim-done',
  templateUrl: './share-claim-done.component.html',
  styleUrls: ['./share-claim-done.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ShareClaimDoneComponent {
  params: ClaimShareParams;

  constructor(public api: ApiService, public nav: NavigateService, private route: ActivatedRoute) {
    this.route.queryParams.subscribe((params: any) => {
      if(params) {
        this.params = {
          claimType: JSON.parse(params.claimType),
          isoMode: JSON.parse(params.isoMode),
          selectedClaims: params.selectedClaims,
          fromRoute: params.fromRoute
        };
      }
    });
  }
}
