import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ApiService} from '../../../../shared/services/api.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DateRange, FormService, I18nLang, LangService, LoaderService, NavigateService} from 'ngx-satoris';
import {Article, ArticleType} from '../../../../shared/models/article';
import {v1 as uuidv1} from 'uuid';
import {Router} from '@angular/router';

@Component({
  selector: 'app-manage-news-new',
  templateUrl: './manage-news-new.component.html',
  styleUrls: ['./manage-news-new.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ManageNewsNewComponent implements OnInit {
  thumbnail: string;
  form: FormGroup;
  submitted: boolean;
  typeOptions: { value: string; name: string }[] = Object.values(ArticleType)
    .filter(value => typeof value === 'number')
    .map((value: any) => ({value: value, name: 'article.type.' + ArticleType[value]}));
  languageOptions: { value: string; name: string }[] = Object.values(I18nLang)
    .filter(value => typeof value === 'number')
    .map((value: any) => ({value: value, name: 'form.lang.' + I18nLang[value]}));

  minFromDate: Date = new Date();
  minToDate: Date = new Date();
  maxFromDate: Date = undefined;
  defaultFromDate: Date = new Date();
  defaultToDate: Date = undefined;
  metaId = uuidv1();
  data: Article;

  constructor(public api: ApiService,
      public formBuilder: FormBuilder,
      public forms: FormService,
      public nav: NavigateService,
      private router: Router,
      private loader: LoaderService,
      private lang: LangService) {
    this.data = <any> this.router.getCurrentNavigation()?.extras?.state?.article;
  }

  ngOnInit() {
    this.setForm();
  }

  setForm(resetData = false) {
    this.form = this.formBuilder.group({
      language: [this.data?.lang+'' || this.lang.readLangString, Validators.required],
      title: [this.data?.title, Validators.required],
      subtitle: [this.data?.description, Validators.required],
      articleType: [this.data?.type, Validators.required],
      thumbnail: [this.data?.image, Validators.required],
      contentType: [this.data?.link && true],
      link: [this.data?.link],
      content: [this.data?.content, Validators.required],
      from: [this.data?.visibilityFrom ? new Date(this.data.visibilityFrom) : new Date(), Validators.required],
      to: [this.data?.visibilityTo && new Date(this.data.visibilityTo), Validators.required]
    });

    if(this.data) {
      this.defaultToDate = new Date(this.data.visibilityTo);
      this.defaultFromDate = new Date(this.data.visibilityFrom);
    }

    if(resetData) {
      this.data = undefined;
    }
  }

  submit() {
    this.submitted = true;
    if(this.forms.getFormErrors(this.form).length < 1) {
      this.loader.loading(true);
      const article: Article = {
        title: this.form.controls.title.value,
        image: this.form.controls.thumbnail.value,
        description: this.form.controls.subtitle.value,
        type: Number(this.form.controls.articleType.value),
        visibilityFrom: this.form.controls.from.value,
        visibilityTo: this.form.controls.to.value,
        date: new Date().toISOString(),
        location: '',
        link: this.form.controls.link.value || '',
        lang: Number(this.form.controls.language.value),
        creator_id: this.api.userInfo.id,
        metaId: this.data?.metaId ? this.data.metaId : this.metaId,
        content: this.form.controls.content.value,
        id: this.data?.id
      };
      if(this.data) {
        this.api.updateArticle(article).then(() => {
          this.loader.loading(true, {type: 'valid', message: this.lang.transform('news.new.updated')}).then(() => {
            this.submitted = false;
            this.nav.to('manage-news-articles/' + this.data.metaId, undefined, {queryParams: {lang: this.form.controls.language.value}});
          });
        }).catch(() => {
          this.loader.loading(true, {type: 'error', message: this.lang.transform('news.new.error')});
        });
      } else {
        this.api.createArticle(article).then(() => {
          this.loader.loading(true, {type: 'valid', message: this.lang.transform('news.new.success'), btnLabel: 'global.yes', custom: {closeBtnLabel: 'global.no'}}).then((done: boolean) => {
            if(done) {
              this.submitted = false;
              this.languageOptions = this.languageOptions.filter((lang) => Number(lang.value) !== Number(this.form.controls.language.value));
              this.form.controls.language.setValue('');
              this.form.controls.language.updateValueAndValidity();
              this.typeOptions = this.typeOptions.filter((type) => Number(type.value) === Number(this.form.controls.articleType.value));
            } else {
              this.nav.to('manage-news-articles/' + (this.data && this.data.metaId ? this.data.metaId : this.metaId), undefined, {queryParams: {lang: this.form.controls.language.value}});
            }
          });
        }).catch(() => {
          this.loader.loading(true, {type: 'error', message: this.lang.transform('news.new.error')});
        });
      }
    }
  }

  inputFile(event: any) {
    const file: File = event.file;
    const reader = new FileReader();
    reader.onload = () => {
      this.thumbnail = reader.result.toString();
      this.form.controls.thumbnail.setValue(this.thumbnail);
    };
    reader.readAsDataURL(file);
  }

  changeContentType() {
    if(this.form.controls.contentType.value) {
      this.form.controls.link.setValidators([Validators.required, Validators.pattern(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/)]);
      this.form.controls.content.setValidators([]);
    } else {
      this.form.controls.link.setValidators([]);
      this.form.controls.content.setValidators([Validators.required]);
    }
    this.form.controls.link.updateValueAndValidity();
    this.form.controls.content.updateValueAndValidity();
  }

  changeDateFromTo(event: DateRange) {
    this.minToDate = event.dateFrom;
    this.maxFromDate = event.dateTo;
  }

  changeLang(value: number) {
    if(this.data) {
      this.defaultFromDate = undefined;
      this.api.searchArticle(new Date(), value, this.data.metaId).then((articles) => {
        if(articles.result.length > 0) {
          this.data = articles.result[0];
          this.typeOptions = Object.values(ArticleType)
            .filter(value => typeof value === 'number')
            .map((value: any) => ({value: value, name: 'article.type.' + ArticleType[value]}));
          this.setForm();
        } else {
          this.loader.loading(true, {type: 'info', message: this.lang.transform('news.new.no_lang')});
          this.typeOptions = this.typeOptions.filter((type) => Number(type.value) === Number(this.data.type));
          this.data.lang = value;
          this.metaId = this.data.metaId;
          this.setForm(true);
        }
      });
    }
  }

  deleteArticle() {
    this.loader.loading(true, {type: 'warn', message: this.lang.transform('news.new.delete'), btnLabel: this.lang.transform('confirm')}).then((done) => {
      if(done) {
        this.api.deleteArticle(this.data.id).then(() => {
          this.loader.loading(true, {type: 'valid', message: this.lang.transform('news.new.deleted')});
        }).catch(() => {
          this.loader.loading(true, {type: 'error', message: this.lang.transform('news.new.error')});
        });
      }
    });
  }
}
