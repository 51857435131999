import {Component, ViewEncapsulation} from '@angular/core';
import {ApiService} from '../../../../shared/services/api.service';
import {NavigateService} from 'ngx-satoris';

@Component({
  selector: 'app-more-security-more-security-change-pin',
  templateUrl: './more-security-change-pin.component.html',
  styleUrls: ['./more-security-change-pin.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MoreSecurityChangePinComponent {
  constructor(public api: ApiService, public nav: NavigateService) {
    this.api.resetPin = false;
  }
}
