<div id="layout" class="f_col">
    <app-view-title bgImage="assets-countries/header_background.png" fromRoute="welcome-explain" toRoute="choose-lang" [chooseLangBtn]="true">{{'welcomeBiometric.title' | translate}}</app-view-title>
    <div class="scroll_wrapper">
      <app-view-deco-image imageUrl="assets-countries/fingerprint.svg" *ngIf="device.isDevices('cordova-android')"></app-view-deco-image>
      <app-view-deco-image imageUrl="assets-countries/faceid.svg" *ngIf="device.isDevices('cordova-ios')"></app-view-deco-image>
      <div class="f_col gap_20 mb_20">
        <app-paragraph>{{'welcomeBiometric.desc' | translate}}</app-paragraph>
          <app-paragraph class="mb_10">{{(device.isDevices('cordova-ios') ? 'welcomeBiometric.faceID.desc' : 'welcomeBiometric.fingerprint.desc') | translate}}</app-paragraph>
          <app-button (btnClicked)="biometricCheck()"> {{(device.isDevices('cordova-ios') ? 'faceID.button.set' : 'fingerprint.button.set') | translate }}</app-button>
      </div>
      <div class="f_align_end f_grow ">
        <div class="fullWidth ml_10 flex gap_10">
          <app-back-btn (btnClicked)="nav.to('welcome-explain')"></app-back-btn>
          <app-button class="fullWidth" (btnClicked)="skip()">{{ 'skip' | translate }}</app-button>
        </div>
      </div>
    </div>
  </div>
