import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {ApiService} from './api.service';
import {NavigateService} from 'ngx-satoris';
import {InitializeService} from './initialize.service';
import {getStoredItem} from '../utils/storage';

@Injectable()
export class UserUnknownGuardService implements CanActivate {
  constructor(public api: ApiService, private nav: NavigateService, private initialize: InitializeService) {
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    try {
      const allowJwt = route.data.allowJwt;

      const savedJwt = getStoredItem('jwt');
      if(!allowJwt && savedJwt?.jwt) {
        this.nav.to('user');
        return true; 
      }
      clearInterval(this.api.infoInterval);
      this.initialize.removeSplashScreen();
      return true;
    } catch (e) {
      this.api.signOut();
      this.initialize.removeSplashScreen();
      return true;
    }
  }
}
