import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ApiService} from '../../shared/services/api.service';
import {DevicesService, LangService, LoaderService, NavigateService} from 'ngx-satoris';
import {SyncService} from '../../shared/services/sync.service';
import {getStoredItem} from 'src/app/shared/utils/storage';
import {SecureStorageService} from '../../shared/services/secure-storage.service';
import {differenceInMinutes} from 'date-fns';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserComponent implements OnInit {
  constructor(public api: ApiService,
    public nav: NavigateService,
    public sync: SyncService,
    private lang: LangService,
    private loader: LoaderService,
    private devices: DevicesService,
    private secure: SecureStorageService) {
    if(this.api?.userRole?.isWorker && !this.api?.userPlaceId) {
      this.loader.loading(true, {type: 'error', message: this.lang.transform('error.noPlace')}).then(()=>{
        this.nav.to('choose-place');
      });
    }
    !getStoredItem('welcomeDone') && this.nav.to('welcome-hello');
  }

  ngOnInit() {
    if(this.api.userInfo.usesLoginOtp){
      this.api.setPrincipalMode = false;
      if(localStorage.getItem('setPrincipalMode') && differenceInMinutes(new Date(), new Date(localStorage.getItem('setPrincipalMode'))) < 15) return;
      if(this.devices.isDevices('cordova') && this.api.userRole.isCustomer && this.secure.fullJwk?.hash !== this.api.userInfo.deviceHash) {
        this.loader.loading(true, {type: 'info', message: '"' + this.api.userInfo.deviceName + '" ' +this.lang.transform('sign_in.other_device'), btnLabel: 'global.yes', custom: {closeBtnLabel: 'global.no'}}).then((done: boolean) => {
          if(done) {
            this.api.setPrincipalMode = true;
            this.nav.to('activation-step-intro');
          } else {
            localStorage.setItem('setPrincipalMode', new Date().toISOString());
          }
        });
      }
    }
  }

  protected readonly JSON = JSON;
}

