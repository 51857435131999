import {ValidatorFn} from '@angular/forms';
import {CountryName} from './countries';
import {ModelInterface} from './entity';
import {IconProp} from 'ngx-satoris';

export enum ClaimType {
  IDENTITY = 0,
  ID_CARD = 1,
  DRIVING_LICENSE = 2,
  ADULT = 3,
  VACCINATION = 4,
  HEALTH_CARD = 5,
  PASSPORT = 6,
  TRANSPORT_CARD = 7,
  RESIDENT_CARD = 8,
  CEDEAO_CARD = 9,
  ALIEN_CARD = 10,
  ADDITIONAL_INFO_CONTACT_EMAIL = 11,
  RNPP = 12,
  SOTRA_CARD = 13,
  MUGEFCI_CARD = 14,
  ADDITIONAL_INFO_ADDRESS = 15,
  ADDITIONAL_INFO_HEALTH = 16,
  ADDITIONAL_INFO_BANK = 17,
  ADDITIONAL_INFO_CONTACT_PHONE_NR = 18
}

export enum ClaimSelfAlt {
  CONTACT = 'CONTACT',
}

export type ClaimTypeClaimSelf = ClaimType.ADDITIONAL_INFO_ADDRESS | ClaimType.ADDITIONAL_INFO_CONTACT_EMAIL | ClaimType.ADDITIONAL_INFO_CONTACT_PHONE_NR;

export interface Claim extends ModelInterface<string> {
  createdAt?: string;
  expiresAt?: number;
  revokedAt?: string;
  place_id?: string;
  user__id?: number;
  created_by_user__id?: number;
  revoked_by_user__id?: number;
  serialized?: string;
  signature?: string;
  externalId?: string;
  type?: ClaimType;
  fetchable?: boolean;
  fetchedAt?: string;
  readUuid?: string;
  readUuidUntil?: string;
  imageUrlRecto?: string;
  imageUrlVerso?: string;
  serializedSalt?: string;
  issuerAuth?: string; // Only returned when using fetchClaim
}

export interface ClaimSelf extends ModelInterface<string> {
  createdAt: string;
  user__id: number;
  approved_by_user__id: number;
  rejected_by_user__id: number;
  serialized?: string;
  unique?: boolean;
  rejectReason: string;
  proof?: string;
  proof2?: string;
  proof3?: string;
  type: ClaimType;
  assignedAt: string;
  state?: ClaimSelfDetails['state']
  assigned_user__id: number;
}

export interface UserScanData {
  sig: number;
  exp: number;
  iat: number;
  nat: string;
  aud: string;
  uid: string;
}

export interface UserScan {
  data: UserScanData;
  xy: {device_x: string, device_y: string};
}

export interface ClaimScan {
  type?: ClaimType;
  id: string;
  uuid: string;
}

export interface ScanShareData {
  service: string,
  requestId: string,
  nni: string,
  claims: Claim[]
}

export interface ScanBadgeData {
  e: string; // email
  n: string; // name
  p: number[];  // permissions
  pcr: number[]; // placeCreateClaims
  pco: number[]; // placeControlClaims
  pi: string; // placeId
  pl: string; // placeLongName
  img?: string; // image
  userId: number; // user id
}

export interface DrivingPrivileges {
  vehicle_category_code: string;
  issue_date: string;
  expiry_date: string;
}

export interface ClaimDriverLicenceCard extends Claim{
  family_name: string;
  given_name: string;
  birth_date: string;
  issue_date: string;
  expiry_date: string;
  issuing_country: string;
  issuing_authority: string;
  document_number: string;
  portrait: string;
  administrative_number: string;
  sex: number;
  height: number;
  weight: number;
  eye_colour: string;
  hair_colour: string;
  birth_place?: string;
  resident_address: string;
  portrait_capture_date: string;
  issuing_jurisdiction: string;
  nationality: string;
  resident_city: string;
  resident_state: string;
  resident_postal_code: string;
  resident_country: string;
  family_name_national_character: string;
  given_name_national_character: string;
  signature_usual_mark: string;
  driving_privileges: DrivingPrivileges[];
}

export interface ClaimIdCard extends Claim {
  date_of_birth: string | number;
  expiry_date?: number | string;
  first_name: string[];
  middle_name?: string[];
  last_name: string[];
  gender: 'M' | 'F';
  image_url: string;
  mrz: {line1: string, line2: string, line3: string};
  nationality?: string;
  place_of_birth?: string;
  can?: number;
  height?: number;
  profession?: string;
  doc_nr: string;
  occupation?: string;
  personal_address?: string;
  telephone_number?: string;
  personal_number?:number;
  district?: string;
  division?: string;
  subLocation?: string;
  administrative_number?: string;
  issue_place?: string;
  issue_date?: string;
  location?: string;
}

export interface ClaimResidentCard extends Claim {
  date_of_birth: string | number;
  expiry_date?: number | string;
  first_name: string[];
  middle_name?: string[];
  last_name: string[];
  gender: 'M' | 'F';
  image_url: string;
  mrz: {line1: string, line2: string, line3: string};
  nationality?: string;
  place_of_birth?: string;
  height?: number;
  profession?: string;
  doc_nr: string;
  residence_adr_1?: string;
  residence_adr_2?: string;
  issue_place?: string;
}

export interface ClaimPassport extends Claim {
  date_of_birth: string | number;
  expiry_date?: number | string;
  first_name: string[];
  middle_name?: string[];
  last_name: string[];
  gender: 'M' | 'F';
  image_url: string;
  mrz: {line1: string, line2: string, line3: string};
  nationality?: string;
  place_of_birth?: string;
  can?: number;
  height?: number;
  profession?: string;
  doc_nr: string;
  personal_address?: string;
  telephone_number?: string;
  personal_number?:number;
  district?: string;
  division?: string;
  subLocation?: string;
  administrative_number?: string;
  issue_place?: string;
  issue_date?: string;
  location?: string;
}

export interface ClaimHealthCard extends Claim {
  first_name: string[];
  last_name: string[];
  portrait: string;
  ssn?: string;
  issue_date?: string;
}

export interface ClaimTransportCard extends Claim {
  first_name: string[];
  last_name: string[];
  portrait: string;
  ssn?: string;
  birth_date?: string;
  transport_nr?: string;
  issue_date?: string;
}

export interface ClaimRNPP extends Claim {
  attestation_number: string;
  center: string;
  country_of_birth: string;
  date_of_birth: string;
  expiry_date: string;
  father_first_name: string;
  father_last_name: string;
  first_name: string[];
  image_url: string;
  issue_date: string;
  last_name: string[];
  middle_name: string[];
  mother_first_name: string;
  mother_last_name: string;
  name_of_signing_authority: string;
  nationality: string;
  profession: string;
  residence_adr_1: string;
  residence_adr_2: string;
  serial_number: string;
  support_document: string;
  support_document_date: string;
  support_document_num: string;
  title_of_signing_authority: string;
  town_of_birth: string;
  town_of_residence: string;
}

export interface Claims {
  [ClaimType.IDENTITY]?: Claim,
  [ClaimType.ID_CARD]?: ClaimIdCard,
  [ClaimType.DRIVING_LICENSE]?: ClaimDriverLicenceCard,
  [ClaimType.ADULT]?: Claim,
  [ClaimType.VACCINATION]?: Claim,
  [ClaimType.HEALTH_CARD]?: ClaimHealthCard,
  [ClaimType.PASSPORT]?: ClaimPassport,
  [ClaimType.TRANSPORT_CARD]?: ClaimTransportCard,
  [ClaimType.RESIDENT_CARD]?: ClaimResidentCard,
  [ClaimType.CEDEAO_CARD]?: Claim,
  [ClaimType.ALIEN_CARD]?: Claim
  [ClaimType.ADDITIONAL_INFO_CONTACT_EMAIL]?: Claim,
  [ClaimType.ADDITIONAL_INFO_CONTACT_PHONE_NR]?: Claim,
  [ClaimType.RNPP]?: ClaimRNPP,
  [ClaimType.SOTRA_CARD]?: Claim,
  [ClaimType.MUGEFCI_CARD]?: Claim,
  [ClaimType.ADDITIONAL_INFO_ADDRESS]?: Claim,
  [ClaimType.ADDITIONAL_INFO_HEALTH]?: Claim,
  [ClaimType.ADDITIONAL_INFO_BANK]?: Claim
}

export enum ClaimSelfSubType {
  EMAIL = 'email',
  PHONE_NR = 'phone_nr',
  ADDRESS = 'address'
}

export interface Icao {
  face: {
    data: string,
    format: 'jpg' | 'jp2' | 'unknown',
    raw: string
  };
  personalDetails: {
    fullName: string,
    profession: string,
    birthDate: number,
    birthPlace: string,
    personalNumber: string,
    telephoneNumber: string,
    personalAddress: string,
    raw: string
  };
  documentDetails: {
    issueDate: number,
    issuingAuthority: string,
    raw: string
  };
  mrz: {
    documentCode: string,
    issuingState: string,
    primaryIdentifier: string,
    secondaryIdentifier: string,
    nationality: string,
    documentNumber: string,
    dateOfBirth: number,
    gender: 'M' | 'F',
    dateOfExpiry: number,
    optionalData1: string,
    optionalData2: string,
    raw: string
  };
  pin: number;
  sodRaw: string;
}

export enum ClaimSelfSubAddress {
  ADDRESSLINE1 = 'addressLine1',
  ADDRESSLINE2 = 'addressLine2',
  POSTALCODE = 'postalCode',
  CITY = 'city',
  COUNTRY = 'country',
  REGION = 'region',
}

export interface CountryClaim {
  claimType: ClaimType;
  selectedClaims: string;
  label: string;
  icon: IconProp;
  isoMode: boolean;
  userPage: boolean;
}

export interface ClaimShareParams {
  claimType: ClaimType;
  isoMode: boolean;
  selectedClaims: string;
  fromRoute?: string;
}

export enum ClaimSelfState {
  NONE = 'NONE',
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  CHANGING = 'CHANGING',
  REJECTED = 'REJECTED'
}

export interface ClaimsSelf {
    [ClaimSelfAlt.CONTACT]?: ClaimSelfDetails &  {
      [ClaimType.ADDITIONAL_INFO_CONTACT_EMAIL]?: ClaimSelfDetails;
      [ClaimType.ADDITIONAL_INFO_CONTACT_PHONE_NR]?: ClaimSelfDetails;
    },
    [ClaimType.ADDITIONAL_INFO_ADDRESS]?: ClaimSelfDetails & {
      [ClaimSelfSubAddress.ADDRESSLINE1]?: ClaimSelfDetails;
      [ClaimSelfSubAddress.ADDRESSLINE2]?: ClaimSelfDetails;
      [ClaimSelfSubAddress.POSTALCODE]?: ClaimSelfDetails;
      [ClaimSelfSubAddress.CITY]?: ClaimSelfDetails;
      [ClaimSelfSubAddress.COUNTRY]?: ClaimSelfDetails;
      [ClaimSelfSubAddress.REGION]?: ClaimSelfDetails;
    };
    [ClaimType.ADDITIONAL_INFO_HEALTH]?: undefined;
    [ClaimType.ADDITIONAL_INFO_BANK]?: undefined;
    [ClaimType.IDENTITY]?: undefined;
    [ClaimType.ID_CARD]?: undefined;
    [ClaimType.DRIVING_LICENSE]?: undefined;
    [ClaimType.ADULT]?: undefined;
    [ClaimType.VACCINATION]?: undefined;
    [ClaimType.HEALTH_CARD]?: undefined;
    [ClaimType.PASSPORT]: undefined;
    [ClaimType.TRANSPORT_CARD]: undefined;
    [ClaimType.RESIDENT_CARD]: undefined;
    [ClaimType.CEDEAO_CARD]: undefined;
    [ClaimType.ALIEN_CARD]: undefined;
    [ClaimType.RNPP]: undefined;
    [ClaimType.SOTRA_CARD]: undefined;
    [ClaimType.MUGEFCI_CARD]: undefined;
}

export interface ClaimSelfDetails {
  id?: string;
  state?: {
    value: ClaimSelfState;
    changingRefusal?: boolean;
    rejectReason?: {
      reasonMessage : string;
      labelRefusal?: string[] | [];
    };
    user_id?: number;
  };
  metadataValue?: ClaimSelfDetails[];
  label?: string;
  icon?: IconProp;
  actionIcon?: any;
  type?: ClaimType;
  assignedAt?: string;
  stateIcon?: {
    icon: IconProp;
    iconColor: string;
    actionIcon?: any;
  };
  isProof?: boolean;
  title?: string;
  value?: any;
  created_at?: string;
  skipTo?: string;
  proofDocument?: string;
  subType?: string;
  selectOptions?: { value: CountryName; name: string; }[];
  validators?: ValidatorFn[];
  data?: any;
  arrayElement?: any;
}

export type ClaimSelfForAdmin = ClaimSelf & ClaimSelfDetails;
