import {Component, Injector, OnInit, ViewEncapsulation} from '@angular/core';
import {DevicesService, LoaderService, NavigateService} from 'ngx-satoris';
import {ApiService} from 'src/app/shared/services/api.service';
import {ActivatedRoute} from '@angular/router';
import {ClaimShareParams, CountryClaim} from '../../../../shared/models/claim';
import {PermissionsService} from 'src/app/shared/services/permissions.service';
import {SyncService} from 'src/app/shared/services/sync.service';
import {environment} from '../../../../../environments/environment';
import {checkValidityOfIssuerAuth} from 'src/app/shared/utils/verification';
import {UserValidGuardService} from 'src/app/shared/services/user-valid-guard.service';
import {SecureStorageService} from 'src/app/shared/services/secure-storage.service';

@Component({
  selector: 'app-share-claim-mode',
  templateUrl: './share-claim-mode.component.html',
  styleUrls: ['./share-claim-mode.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ShareClaimModeComponent implements OnInit {

  btAvailable: boolean;
  params: ClaimShareParams;

  constructor(public api: ApiService,
    public nav: NavigateService,
    public sync: SyncService,
    private device: DevicesService,
    private permissions: PermissionsService,
    private loader: LoaderService,
    private route: ActivatedRoute,
    private injector: Injector,
    private secure: SecureStorageService) {
    this.route.queryParams.subscribe((params: any) => {
      if(params) {
        this.params = {
          claimType: JSON.parse(params.claimType),
          isoMode: environment.countryClaims.find((x: CountryClaim) => x.claimType === JSON.parse(params.claimType)).isoMode,
          selectedClaims: params.selectedClaims,
          fromRoute: 'share-claim-mode'
        };
      }
    });
  }

  ngOnInit() {
    this.loader.loading(false);
    this.permissions.getBTState();
  }

  navPermission() {
    const claimType = this.api.claims[this.params.claimType];
    const issuerAuthValid = checkValidityOfIssuerAuth(claimType, 10 * 60000);
    const guard = this.injector.get(UserValidGuardService);

    const loaderType = 'error';
    let message: string;
    let action: any;

    if(this.secure.fullJwk?.hash !== this.api.userInfo?.deviceHash && this.api.userInfo.usesLoginOtp) {
      message = 'api.shareClaim.noClaim';
    } else if(!claimType?.issuerAuth) {
      if(this.sync.isOnline) {
        message = 'issuerAuth.recover';
        action = guard.backgroundClaims([claimType]);
      } else {
        message = 'issuerAuth.recoverNoInternet';
      }
    } else if(!issuerAuthValid) {
      if(this.sync.isOnline) {
        message = 'issuerExpired';
        action = guard.backgroundClaims([claimType]);
      } else {
        message = 'issuerExpiredNoInternet';
      }
    }
    if(message) {
      if(action) {
        return setTimeout(() => this.loader.loading(true, {type: loaderType, message: message}).then(() => action));
      } else {
        return setTimeout(() => this.loader.loading(true, {type: loaderType, message: message}));
      }
    }

    this.loader.loading(true);
    this.permissions.checkIso().then(() => {
      const hasBluetoothPermission = this.permissions.stateOfPermissions.bluetooth.hasPermission;
      const isBluetoothPoweredOn = this.permissions.stateOfPermissions.bluetooth.state === 'powered_on';
      const hasLocationPermission = this.permissions.stateOfPermissions.localisation.hasPermission;
      const isLocationEnabled = this.permissions.stateOfPermissions.localisation.state;
      const isAndroidDevice = this.device.isDevices('cordova-android');
      const isIOSDevice = this.device.isDevices('cordova-ios');
      if(
        hasBluetoothPermission &&
        isBluetoothPoweredOn &&
        ((isAndroidDevice && hasLocationPermission && isLocationEnabled) ||
          (isIOSDevice))
      ) {
        this.loader.loading(false);
        this.nav.toPin('share-claim-mode', 'share-claim', undefined, undefined, {queryParams: this.params}, 0);
      } else {
        this.loader.loading(false);
        this.nav.toPerm('share-claim-mode', 'share-claim', 'BLUETOOTH', {state: {extras: {queryParams: this.params}}}, undefined);
      }
    });
  }

  shareIntoCountry() {
    this.loader.loading(true);
    this.params.isoMode = false;
    this.nav.toPin('share-claim-mode', 'share-claim', undefined, undefined, {queryParams: this.params});
  }
}
