import {Component, ViewEncapsulation} from "@angular/core";
import {FormService} from "ngx-satoris";
import {EnrollService} from "src/app/shared/services/enroll.service";

@Component({
  selector: 'app-activation-step-form-user-local-inputs',
  template: `
`,
  encapsulation: ViewEncapsulation.None
})
export class ActivationStepFormUserLocalInputsComponent {
  constructor(public forms: FormService,
              public enroll: EnrollService) {}
}