import {Component, ViewEncapsulation} from '@angular/core';
import {NavigateService, DevicesService} from 'ngx-satoris';
import {ClaimShareParams} from 'src/app/shared/models/claim';
import {ApiService} from 'src/app/shared/services/api.service';
import {PersonnalDatasService} from 'src/app/shared/services/personnal-datas.service';
import {isObjectEmpty} from 'src/app/shared/utils/verification';

@Component({
  selector: 'app-personnal-data-all',
  templateUrl: './personnal-data-all.component.html',
  styleUrls: ['./personnal-data-all.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PersonnalDataAllComponent {
  params: ClaimShareParams;
  disabledShare = false;
  isNoSelf = isObjectEmpty(this.personnalDatas.selfClaims);

  constructor(public api: ApiService,
    public nav: NavigateService,
    public device: DevicesService,
    public personnalDatas: PersonnalDatasService) {

    const allShareClaims = Object.keys(this.api.claims)
      .map(key => {
        const numberKey = +key;
        if(!isNaN(numberKey) && this.personnalDatas.isClaimTypeClaimSelf(numberKey)) {
          return numberKey;
        }
      })
      .filter(claim => claim !== undefined);

    this.params = {
      claimType: allShareClaims[0],
      isoMode: false,
      selectedClaims: JSON.stringify(allShareClaims),
      fromRoute: 'personnal-data-all'
    };
    if(allShareClaims.length === 0) {
      this.disabledShare = true;
    }
  }

  actionIcon(){
    return () => this.nav.to('personnal-data-explain', undefined, {state: {fromRoute: 'personnal-data-all'}});
  }
}
