<div id="qrCode" [class.show]="!!value" [class.hide]="!value || !show">
  <div class="f_col f_grow f_center">
    <div class="qr_container">
      <div class="qr_border">
        <div class="qr_border_overflow">
          <qrcode [qrdata]="value" [width]="500" [errorCorrectionLevel]="'M'" [elementType]="'url'"></qrcode>
        </div>
      </div>
    </div>
    <!-- TOOD:Do we really need to hardcode margins? -->
    <div id="timer" class="mt_20 mb_20" *ngIf="timer">
      <span>{{timer}}</span>
      <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" [style.animation-duration]="animTime + 's'">
        <circle cx="50" cy="50" r="50"/>
      </svg>
    </div>
  </div>
</div>
