import {Router} from '@angular/router';
import {User} from '../models/user';
import {DevicesService, I18nLang, LangService} from 'ngx-satoris';
import {App as CapacitorApp} from '@capacitor/app';
import {differenceInSeconds} from 'date-fns';
import {EventEmitter} from '@angular/core';

declare const window: any;
declare const Promise: PromiseConstructor & {
  allConcurrent: <T>(n: number) => ((promiseProxies: (() => Promise<T>)[]) => Promise<T[]>);
};

export function promiseAllStepN<T>(n: number, list: (() => Promise<T>)[]): Promise<T[]> {
  if(!list || !list.length) return Promise.resolve([]);
  const tail = list.splice(n);
  const head = list;
  const resolved: any[] = [];
  let processed = 0;
  return new Promise(resolve => {
    head.forEach(x => {
      const res = x();
      resolved.push(res);
      res.then((y: any) => {
        runNext();
        return y;
      });
    });
    function runNext() {
      if(processed === tail.length) {
        resolve(Promise.all(resolved));
      } else {
        resolved.push(tail[processed]().then((x: any) => {
          runNext();
          return x;
        }));
        processed++;
      }
    }
  });
}
Promise.allConcurrent = <T>(n: number) => (list: (() => Promise<T>)[]) => promiseAllStepN(n, list);

export class Application {
  get readLang(): I18nLang {
    if(this.userInfo) {
      return this.userInfo.lang;
    } else {
      return this.lang.readLang;
    }
  }

  userInfo: User;
  backButtonLastClicked = 0;
  private backBtnEmitter = new EventEmitter();

  constructor(protected router: Router, protected lang: LangService, protected device: DevicesService) {
    document.addEventListener('deviceready', () => {
      this.mobileBackListener();
    });
  }

  openSupport() {
    window.toggleGlpi();
  }

  mobileBackListener() {
    CapacitorApp.addListener('backButton', ({canGoBack}) => {
      const now = Date.now();
      if(differenceInSeconds(now, this.backButtonLastClicked) < 2) {
        return;
      }
      if(canGoBack) {
        this.backBtnEmitter.emit();
      }
      this.backButtonLastClicked = now;
    });
  }

  mobileBack(callback: () => void) {
    const sub = this.backBtnEmitter.subscribe(() => {
      callback();
      sub.unsubscribe();
    });
  }
}
