import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NavComponent} from '../../../components/nav/nav.component';
import {ApiService} from '../../../shared/services/api.service';
import {IdentityMessage, ServiceMessage} from '../../../shared/models/message';
import {ServiceInterface} from '../../../shared/models/services';
import {ClaimType} from '../../../shared/models/claim';
import {Subscription} from 'rxjs';
import {SyncService} from 'src/app/shared/services/sync.service';
import {LoaderService, NavigateService} from 'ngx-satoris';

@Component({
  selector: 'app-service-iframe',
  templateUrl: './service-iframe.component.html',
  styleUrls: ['./service-iframe.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ServiceIframeComponent implements OnInit, OnDestroy {
  service: ServiceInterface;
  private subscription: Subscription;
  constructor(private route: ActivatedRoute, 
  private api: ApiService, 
  private router: Router,
  private sync: SyncService,
  private loader: LoaderService,
  private nav: NavigateService) {
    this.data = <any> this.router.getCurrentNavigation()?.extras?.state;
  }
  data: {
    fromRoute: string
  };

  ngOnInit() {
    NavComponent.prototype.backToButton = this.data?.fromRoute ? this.data?.fromRoute  : 'services';
    this.route.queryParams
      .subscribe(params => {
        if(params?.service) {
          this.service = JSON.parse(params.service);
        }
      });
    this.subscription = this.sync.isOnline$.subscribe(isOnline => {
      if(!isOnline){
        this.loader.loading(true);
        setTimeout(() => this.loaderOffline(), 2000);
      } else {
        this.loaderOffline();
      }
    });
  }


  loaderOffline() {
    if(!this.sync.isOnline) {
      this.loader.loading(true, {type: 'error', message: 'iframe.offline', custom: {icon: 'wifi', closeBtnLabel: 'global.back'}}).then(() => !this.sync.isOnline && this.loader.loading(true, {type: 'warn', message: 'iframe.ask.return', btnLabel: 'global.confirm'}).then((valid: boolean) => valid ? this.nav.to('user') : this.loaderOffline()));
    } else {
      this.loader.loading(false);
    }
  }
  ngOnDestroy() {
    NavComponent.prototype.backToButton = undefined;
    this.subscription.unsubscribe();
  }

  loaded() {
    this.service.message.forEach(message => {
      switch(message) {
      case ServiceMessage.IDENTITY:
        this.identity();
        break;
      case ServiceMessage.DISABLE_MENU:
        this.disableMenu();
        break;
      }
    });
  }

  identity() {
    const data: IdentityMessage = {
      nni: this.api.userInfo.nationalNumber,
      can: this.api.claims[ClaimType.ID_CARD].can,
      date_of_birth: new Date(this.api.claims[ClaimType.ID_CARD].date_of_birth),
      doc_nr: this.api.claims[ClaimType.ID_CARD].doc_nr,
      expiry_date: new Date(this.api.claims[ClaimType.ID_CARD].expiry_date),
      first_name: this.api.claims[ClaimType.ID_CARD].first_name,
      gender: this.api.claims[ClaimType.ID_CARD].gender,
      height: this.api.claims[ClaimType.ID_CARD].height,
      image_url: this.api.claims[ClaimType.ID_CARD].image_url,
      last_name: this.api.claims[ClaimType.ID_CARD].last_name,
      middle_name: this.api.claims[ClaimType.ID_CARD].middle_name,
      mrz: this.api.claims[ClaimType.ID_CARD].mrz,
      nationality: this.api.claims[ClaimType.ID_CARD].nationality,
      place_of_birth: this.api.claims[ClaimType.ID_CARD].place_of_birth,
      profession: this.api.claims[ClaimType.ID_CARD].profession,
      signature: this.api.claims[ClaimType.ID_CARD].signature
    }; //TODO replace when MR is ready with new Claims variable
    (document.getElementById('service-iframe') as HTMLIFrameElement).contentWindow.postMessage({type: ServiceMessage.IDENTITY, data}, '*');
  }

  disableMenu() {
    (document.getElementById('service-iframe') as HTMLIFrameElement).contentWindow.postMessage({type: ServiceMessage.DISABLE_MENU}, '*');
  }
}
