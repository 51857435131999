import {Component, ViewEncapsulation} from '@angular/core';
import {ApiService} from 'src/app/shared/services/api.service';
import {NavigateService} from 'ngx-satoris';

@Component({
  selector: 'app-manage-user',
  templateUrl: './manage-user.component.html',
  styleUrls: ['./manage-user.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ManageUserComponent {

  constructor(public nav: NavigateService, public api: ApiService) {}

}
