import {ClaimIdCard, ClaimSelfAlt, ClaimSelfDetails, ClaimSelfSubType, ClaimType, CountryClaim} from 'src/app/shared/models/claim';
import {CountryName} from '../app/shared/models/countries';
import {ServiceInterface} from '../app/shared/models/services';
import {ServiceMessage} from '../app/shared/models/message';
import {FormGroup, Validators} from '@angular/forms';
import {EnrollForm, EnrollStep, FC, StepConfig} from 'src/app/shared/models/enroll';
import {I18nLang} from 'ngx-satoris';

export const environment = {
  production: false,
  appVersion: [1, 6, 10],
  baseUri: 'https://did-api-dev.satoris.lu/api/v1/',
  urlDiscriminant: '.lu',
  pushPublicKey: 'BJoOgLqv0k3x5gELVY9EDOOqKSIwtOP-2xdkse9G74WexSmpPahPPAF577C5KpjiIlqurdK8-rq7LEZKxUwirSg',
  activationLocations: [
    {name: 'ONECI Vallon', pos: {lat: 5.3674167794902345, lng: -3.987619662410561}, info: '<strong>Adresse Postale :</strong>Abidjan, Cocody, Rue J109,<br /><strong>Téléphone :</strong> (+225) 27 20 23 96 60'},
    {name: 'ONECI le plateau', pos: {lat: 5.332829876737427, lng: -4.021714808088595}, info: '<strong>Adresse Postale :</strong> BP V168 Abidjan 19, Boulevard Botreau Roussel, Abidjan-Plateau<br /><strong>Téléphone :</strong> (+225) 27 20 30 79 00 / 27 20 25 45 59<br /><strong>Call Center :</strong> (+225) 27 20 23 96 60 / 27 20 30 79 40 / 1340<br /><strong>Fax :</strong> (+225) 27 20 24 29 13<br /><strong>Email :</strong> mailto:demandeenrolement@oneci.ci / mailto:standard_accueil@oneci.ci / mailto:reclamationCNI@oneci.ci<br /><strong>Horaires :</strong> 08h00 à 12h00 / 13h00 à 17h00'}
  ],
  activationMap: {
    center: {lat: 5.3674167794902345, lng: -3.987619662410561},
    zoom: 13,
    maxZoom: 18
  },
  theme: {
    logo: 'assets-countries/utopia_emblem.svg',
    background: 'assets-countries/home.png',
    header_background: 'assets-countries/header_background.png',
    country_flag: 'assets-countries/flag.svg'
  },
  ocrType: 0,
  templateBytes: <number[]>undefined,
  templateFactor: 1,
  country: CountryName.UTO,
  supportedLanguages: [I18nLang.EN, I18nLang.FR],
  phonePattern: '',
  center: 'centre',
  name: 'DigitalID',
  services: [
    {
      title: 'service.birth_certificate',
      icon: 'baby',
      url: 'https://civc-app-test.semlex-ci.com',
      message: [ServiceMessage.DISABLE_MENU, ServiceMessage.IDENTITY],
      active: true
    }
  ] as ServiceInterface[],
  countryClaims: [
    {
      claimType: ClaimType.PASSPORT,
      selectedClaims: JSON.stringify([ClaimType.PASSPORT, ClaimType.ADULT]),
      icon: 'passport',
      isoMode: false,
      userPage: true
    },
    {
      claimType: ClaimType.ID_CARD,
      selectedClaims: JSON.stringify([ClaimType.IDENTITY, ClaimType.ID_CARD, ClaimType.ADULT]),
      icon: 'id-card',
      isoMode: true,
      userPage: true
    },
    {
      claimType: ClaimType.DRIVING_LICENSE,
      selectedClaims: JSON.stringify([ClaimType.DRIVING_LICENSE, ClaimType.ADULT]),
      icon: 'car-side',
      isoMode: true,
      userPage: true
    },
    {
      claimType: ClaimType.HEALTH_CARD,
      selectedClaims: JSON.stringify([ClaimType.HEALTH_CARD, ClaimType.ADULT]),
      icon: 'heart-pulse',
      isoMode: false,
      userPage: true
    },
    {
      claimType: ClaimType.TRANSPORT_CARD,
      selectedClaims: JSON.stringify([ClaimType.TRANSPORT_CARD, ClaimType.ADULT]),
      icon: 'bus',
      isoMode: false,
      userPage: true
    }
  ] as CountryClaim[],
  selfClaims: {
    [ClaimSelfAlt.CONTACT]: {
      label: 'personnalData.contact',
      icon: 'address-book',
      skipTo: '',
      [ClaimType.ADDITIONAL_INFO_CONTACT_EMAIL]: {
        isProof: false,
        metadataValue: [{
          label: ClaimSelfSubType.EMAIL,
          icon: 'envelope',
          subType: 'email',
          validators: [Validators.email, Validators.required]
        }]
      }
    }
  } as ClaimSelfDetails,
  stepsConfig: [
    {
      type: 'STEP',
      steps: [
        EnrollStep.SCAN_MRZ_CHOICE
      ]
    },
    {
      type: 'CHOICE',
      steps: [
        [
          EnrollStep.SCAN_MRZ_SCAN
        ],
        [
          EnrollStep.DOC_NUMBER,
          EnrollStep.DOC_EXPIRATION_DATE,
          EnrollStep.DATE_OF_BIRTH
        ]
      ]
    },
    {
      type: 'STEP',
      steps: [
        EnrollStep.SCAN_MRZ_NFC,
        EnrollStep.ASK_NNI,
        EnrollStep.DRAW_SIGNATURE,
        EnrollStep.DESC_TAKE_FACE_PICTURE,
        EnrollStep.TAKE_FACE_PICTURE,
        EnrollStep.ASK_DEVICE_NAME,
        EnrollStep.CONFIRM
      ]
    }
  ] as StepConfig[],
  stepsConfigAdmin: [
    {
      type: 'STEP',
      steps: [
        EnrollStep.QR_SCAN
      ]
    },
    {
      type: 'CHOICE',
      steps: [
        [
          EnrollStep.ASK_CAN,
          EnrollStep.DRAW_SIGNATURE,
          EnrollStep.FINGERPRINT,
          EnrollStep.NFC_SCAN_ID_CARD
        ],
        [
          EnrollStep.FORM_USER,
          EnrollStep.DRAW_SIGNATURE,
          EnrollStep.DESC_TAKE_FACE_PICTURE,
          EnrollStep.TAKE_FACE_PICTURE,
          EnrollStep.CONFIRM
        ]
      ]
    }
  ] as StepConfig[],
  stepsConfigSuperAdmin: [] as StepConfig[],
  controlsToCheckAdmin: [
    FC.FIRST_NAME,
    FC.MIDDLE_NAME,
    FC.LAST_NAME,
    FC.DATE_OF_BIRTH,
    FC.GENDER,
    FC.ISSUE_DATE,
    FC.COUNTRY,
    FC.ISSUE_PLACE,
    FC.DOC_EXPIRY_DATE,
    FC.HEIGHT,
    FC.NATIONALITY,
    FC.DISTRICT,
    FC.DIVISION,
    FC.SUB_LOCATION,
    FC.LOCATION,
    FC.DOC_NUMBER
  ] as FC[],
  enrollForm: {
    [FC.DEVICE_NAME]: '',
    [FC.MRZ]: '',
    [FC.DOC_NUMBER]: '',
    [FC.DOC_EXPIRY_DATE]: '',
    [FC.DATE_OF_BIRTH]: '',
    [FC.SIGNATURE]: '',
    [FC.FACE_PICTURE]: '',
    [FC.NNI]: '',
    [FC.NFC]: ''
  } as EnrollForm,
  enrollFormAdmin: {
    [FC.FIRST_NAME]: '',
    [FC.MIDDLE_NAME]: '',
    [FC.LAST_NAME]: '',
    [FC.DATE_OF_BIRTH]: '',
    [FC.GENDER]: '',
    [FC.COUNTRY]: '',
    [FC.NATIONALITY]: '',
    [FC.PERSONAL_NUMBER]: '',
    [FC.DISTRICT]: '',
    [FC.DOC_NUMBER]: '',
    [FC.ISSUE_PLACE]: '',
    [FC.ISSUE_DATE]: '',
    [FC.HEIGHT]: '',
    [FC.SUB_LOCATION]: '',
    [FC.DIVISION]: '',
    [FC.LOCATION]: '',
    [FC.OCCUPATION]: '',
    [FC.MRZ]: '',
    [FC.NFC]: '',
    [FC.NNI]: '',
    [FC.CAN]: '',
    [FC.DOC_EXPIRY_DATE]: '',
    [FC.SIGNATURE]: '',
    [FC.FACE_PICTURE]: '',
    [FC.USER_ID]: '',
    [FC.DEVICE_XY]: {
      x: '',
      y: ''
    },
    [FC.FINGERPRINT]: {
      finger: '',
      image: ''
    }
  } as EnrollForm,
  PRODUCT_SUB_KEY_IOS: '',
  PRODUCT_SUB_KEY_ANDROID: '',
  debounce_pin: 10, // in seconds,
  supportSource: 'https://glpi-app.satoris.lu/install.js?project=digitalid&lang=fr&position=bottomRight&btnVisible=false',
  eulaLink: 'https://satoris.notion.site/Terms-of-Use-a9619979eb6c4bda909fc2333e43650c?pvs=4',
  privacyLink: 'https://satoris.notion.site/Privacy-Policy-a8b05e88593649b78f1694983f783f15?pvs=4'
};

export const completeIdCardAdmin = (serialized: ClaimIdCard, form: FormGroup) => {
  serialized.date_of_birth = new Date(form.controls[FC.DATE_OF_BIRTH].value.trim()).toISOString().split('T')[0];
  serialized.issue_place = form.controls[FC.ISSUE_PLACE].value.trim();
  serialized.issue_date = new Date(form.controls[FC.ISSUE_DATE].value.trim()).toISOString();
  serialized.height = Number(form.controls[FC.HEIGHT].value.trim());
  serialized.district = form.controls[FC.DISTRICT].value.trim();
  serialized.division = form.controls[FC.DIVISION].value.trim();
  serialized.subLocation = form.controls[FC.SUB_LOCATION].value.trim();
  serialized.location = form.controls[FC.LOCATION].value.trim();
  serialized.occupation = form.controls[FC.OCCUPATION].value.trim();
};
