export interface IdentityMessage {
  nni: string;
  can: number;
  date_of_birth: Date;
  doc_nr: string;
  expiry_date: Date;
  first_name: string[];
  gender: 'F' | 'M';
  height: number;
  image_url: string;
  last_name: string[];
  middle_name: string[];
  mrz: {line1: string, line2: string, line3: string};
  nationality: string;
  place_of_birth: string;
  profession: string;
  signature: string;
}

export enum ServiceMessage {
  DISABLE_MENU = 'DISABLE_MENU',
  IDENTITY = 'IDENTITY',
}
