<div id="layout" class="f_col layout_sm">
  <app-view-title [bgImage]="api.env.theme.header_background">{{(data ? 'manageNews.update_article' : 'manageNews.new_article') | translate}}</app-view-title>
  <div class="scroll_wrapper">
    <div class="f_grow mt_20">
      <form id="form" [formGroup]="form" (ngSubmit)="submit()">
        <app-input (output)="changeLang($event)" type="select" formCtrlName="language" [formGrp]="form" [selectOptions]="languageOptions" label="article.language_choice" class="mt_24"></app-input>

        <div class="divider mt_24"></div>

        <app-input type="text" formCtrlName="title" [formGrp]="form" label="form.title" class="mt_24"></app-input>
        <app-input type="text" formCtrlName="subtitle" [formGrp]="form" label="form.subtitle" class="mt_24"></app-input>
        <app-input type="select" formCtrlName="articleType" [formGrp]="form" [selectOptions]="typeOptions" label="form.articleType" class="mt_24"></app-input>

        <div class="divider mt_24"></div>

        <p>{{'news.thumbnail' | translate}}</p>
        <app-input type="text" formCtrlName="thumbnail" [formGrp]="form" label="form.thumbnail" class="mt_24"></app-input>
        <div class="divider mt_24"></div>
        <p>{{'news.preview' | translate}}</p>
        <div class="flex f_center mb_30">
          <app-modal-features [title]="form.controls['title'].value" [subtitle]="form.controls['subtitle'].value"
                              [bgImage]="form.controls['thumbnail'].value"></app-modal-features>
        </div>

        <div class="divider mt_24"></div>

        <p>{{'news.content' | translate}}</p>
        <div class="mb_30">
          <app-checkbox-element [formGrp]="form" formCtrlName="contentType" text="news.contentIsLink"
                                (onChange)="changeContentType()"></app-checkbox-element>
        </div>
        <app-wysiwyg *ngIf="!form.controls['contentType'].value" [formGrp]="form" formCtrlName="content"></app-wysiwyg>
        <app-input *ngIf="form.controls['contentType'].value" type="text" formCtrlName="link" [formGrp]="form"
                   label="form.link"></app-input>

        <div class="divider mt_24"></div>

        <p>{{'news.fromTo' | translate}}</p>
        <app-date-range *ngIf="defaultFromDate" (onChange)="changeDateFromTo($event)" [formGrp]="form" [minFrom]="minFromDate" [minTo]="minToDate" [maxFrom]="maxFromDate" [maxTo]="undefined" [defaultTo]="defaultToDate" [defaultFrom]="defaultFromDate" formCtrlNameFrom="from" formCtrlNameTo="to"></app-date-range>

        <app-errors-list [dark]="true" *ngIf="submitted"
                         [formErrors]="this.forms.getFormErrors(this.form)"></app-errors-list>
      </form>
    </div>
    <app-button type="submit" formId="form" class="fullWidth mt_20">{{'news.publish' | translate}}</app-button>
    <div class="mt_10">
      <app-button class="fullWidth" color="red" (btnClicked)="deleteArticle()">{{'global.delete' | translate}}</app-button>
    </div>
  </div>
  <app-back-btn position="botLeft" (btnClicked)="nav.to(data ? 'news/'+data.metaId : 'manage/manage-news', undefined, {state: {fromRoute: 'manage/manage-news-articles', article: data}})"></app-back-btn>
</div>
