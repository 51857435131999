<div id="layout" class="f_col container layout_sm">
  <app-view-title bgImage="assets-countries/header_background.png">{{'more.security.fingerprint' | translate}}</app-view-title>
  <div class="scroll_wrapper">
    <app-view-deco-image imageUrl="assets-countries/fingerprint.svg"></app-view-deco-image>
    <app-paragraph class="mt_10 mb_10">{{'welcomeBiometric.fingerprint.desc' | translate}}</app-paragraph>
    <div class="f_grow">
      <app-button *ngIf="!fpRegistered" (btnClicked)="nav.toPin('more-security-fingerprint', 'more-security-fingerprint', true, undefined, {state: {setBiometric: true}})">{{ 'fingerprints.button.set' | translate }}</app-button>
      <app-button *ngIf="fpRegistered" (btnClicked)="unsetFP()">{{ 'fingerprints.button.unset' | translate }}</app-button>
    </div>
  </div>
  <app-back-btn position="botLeft" [navTo]="{route: 'more-security'}"></app-back-btn>
</div>
