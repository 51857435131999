<div id="webcamImage" class="loader_webcam" *ngIf="picture" [class.layout_sm]="!api.userRole.isCustomer">
  <img class="face-picture" [class.layout_sm]="!api.userRole.isCustomer" [src]="picture" alt="">
  <div class="webcam_btns f_center" [class.layout_sm]="!api.userRole.isCustomer">
    <fa-stack>
      <fa-icon icon="circle" stackItemSize="1x" [ngStyle]="{'color': 'var(--clr-lt)'}"></fa-icon>
      <fa-icon icon="circle-xmark" stackItemSize="1x" [ngStyle]="{'color': 'var(--clr-btn-red)'}" (click)="close()"></fa-icon>
    </fa-stack>
    <fa-stack>
      <fa-icon icon="circle" stackItemSize="1x" [ngStyle]="{'color': 'var(--clr-lt)'}"></fa-icon>
      <fa-icon icon="circle-check" stackItemSize="1x" [ngStyle]="{'color': 'var(--clr-btn-success)'}" (click)="onConfirm()"></fa-icon>
    </fa-stack>
  </div>
</div>
<app-webcam [fullHeight]="api.userRole.isCustomer && true" *ngIf="!picture" backUrl="previous" (output)="setPicture($event)" [imageQuality]="imageQuality" [videoOptions]="videoOptions"></app-webcam>
<div class="relative camera-upload" *ngIf="api.userRole.isAdmin && !picture">
  <app-button icon="upload" class="camera-button-upload">
  </app-button>
  <input class="input-upload" type="file"  id="image" name="image" (change)="uploadImage($event)">
</div>
