<div id="layout" class="f_col container layout_sm">
  <app-view-title bgImage="assets-countries/header_background.png" class="portrait_only">{{('userShare.DYN.' + params?.claimType)  | translate}}</app-view-title>
  <div class="scroll_wrapper">
    <div class="f_grow f_col f_center">
      <app-paragraph class="mt_20" textAlign="center">{{'share.mode.within' | translate}}</app-paragraph>
      <app-view-deco-image
        [disabled]="!sync.isOnline"
        [colorDisabledIcon]="!sync.isOnline ? '#f51d2a' : undefined"
        [disabledIcon]="!sync.isOnline ? 'wifi' : undefined"
        margin="sm"
        imageUrl="assets-countries/flag.svg"
        (click)="sync.isOnline ? shareIntoCountry() : sync.loaderOffline()">
      </app-view-deco-image>
      <app-paragraph textAlign="center">{{'share.mode.outside' | translate}}</app-paragraph>
      <app-view-deco-image
        margin="sm"
        imageUrl="assets/images/WORLD_flag.svg"
        (click)="navPermission()">
      </app-view-deco-image>
      <app-paragraph textAlign="center" class="ltext">{{'share.mode.desc' | translate}}</app-paragraph>
    </div>
    <div class="mb_10 f_col portrait_only">
      <div class="flex mt_20">
        <app-back-btn (btnClicked)="nav.to('claim-card', undefined, {queryParams: params})"></app-back-btn>
      </div>
    </div>
  </div>
</div>
