<div id="layout" class="f_center">
  <div class="f_grow"
    leaflet 
    (leafletMapReady)="onMapReady($event)"
    [leafletOptions]="options"
    [leafletZoom]="zoom"
    [leafletCenter]="center"
    [leafletLayers]="markers">
  </div>
  <div id="codeMap_bottom">
    <app-back-btn (click)="nav.to('activation-code-info')"></app-back-btn>
  </div>
</div>
