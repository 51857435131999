<div id="layout" class="f_col" [class.layout_sm]="!api.userRole.isCustomer">
  <app-view-title bgImage="assets-countries/header_background.png" fromRoute="activation-step-ask-pin" toRoute="choose-lang" [chooseLangBtn]="api.userRole.isCustomer && true">
    {{'activation.step.ask_pin.title' | translate}}
  </app-view-title>
  <div class="scroll_wrapper">
    <div class="f_center mt_40 nfcReadForm">
      <div class="nfcReadForm__img-container">
        <img class="nfcReadForm__img" src="assets-countries/idCard_pinLock.webp" alt=""/>
      </div>
    </div>
    <app-paragraph class="mt_20" *ngIf="nfc?.icao?.pin && nfc?.icao?.pin !== 3" textAlign="center">{{'nfc.read.self.scan.pinRemain' | translate}}: <strong>{{nfc.icao?.pin}}</strong></app-paragraph>
    <app-paragraph class="mt_20">{{'nfc.read.self.pin.desc' | translate}}</app-paragraph>
    <div class="f_grow mt_20">
      <form id="form" [formGroup]="enroll.form" (ngSubmit)="cleanAndSubmit()">
        <app-input [maxLength]="6" pattern="[0-9]*" type="pin" [formGrp]="enroll.form" [formCtrlName]="enroll.fc.PIN" label="form.pin" icon="hashtag"></app-input>
        <app-errors-list [dark]="true" *ngIf="!enroll.form.controls[enroll.fc.PIN].valid && enroll.form.controls[enroll.fc.PIN].touched" [formErrors]="forms.getFormErrors(enroll.form, enroll.fc.PIN)"></app-errors-list>
      </form>
    </div>

    <div class="f_align_end f_grow ">
      <app-back-btn (btnClicked)="enroll.navigate('previous')"></app-back-btn>
      <div class="fullWidth ml_10">
        <app-button [disabled]="!enroll.form.controls[enroll.fc.PIN].valid" (btnClicked)="this.enroll.navigate('next');" class="fullWidth">
          {{'global.continue' | translate}}
        </app-button>
      </div>
    </div>
  </div>
</div>
