import {Component, ViewEncapsulation} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {DevicesService, LoaderService, NavigateService} from 'ngx-satoris';
import {PermissionsService} from 'src/app/shared/services/permissions.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavComponent {
  fromRoute: string;
  backToButton: string;
  buttonTimeouts: {[key: string]: NodeJS.Timeout} = {};
  lastClicked = 0;
  navDisabled = false;

  constructor(public nav: NavigateService,
              private router: Router,
              private loader: LoaderService,
              private permissions: PermissionsService,
              private device: DevicesService) {
    router.events.pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.fromRoute = event.url;
      });
  }

  isActive(type: 'user' | 'services' | 'compass' | 'more'): boolean {
    switch(type) {
    case 'user':
      return this.router.url.includes('user');
    case 'services':
      return this.router.url.includes('/service');
    case 'compass':
      return this.router.url.includes('/news');
    case 'more':
      return this.router.url.includes('more');
    default:
      return false;
    }
  }

  checkNav(route: 'user' | 'service' | 'news' | 'more' | 'scan') {
    if(this.navDisabled) {
      return;
    }

    if(route === 'scan') {
      this.debounceScan();
    } else {
      this.debounceButton(route);
    }
  }

  debounceButton(route: 'user' | 'service' | 'news' | 'more') {
    if(this.router.url === '/' + route || this.buttonTimeouts[route]){
      return;
    }

    this.navDisabled = true;
    this.nav.to(route);
    this.buttonTimeouts[route] = setTimeout(() => {
      delete this.buttonTimeouts[route];
      this.navDisabled = false;
    }, 200);
  }

  goToScan() {
    if(this.device.isDevices('cordova')){
      this.permissions.checkCameraPermission().then(() => {
        const isCameraPermissionGranted = this.permissions.stateOfPermissions.camera.hasPermission;
        if(isCameraPermissionGranted){
          this.nav.to('scan');
        } else {
          this.nav.toPerm('activation', 'scan', 'CAMERA');
        }
      });
    } else {
      this.nav.toPerm('activation', 'scan', 'CAMERA');
    }
  }

  debounceScan() {
    const now = Date.now();


    if(this.router.url === '/scan' || this.buttonTimeouts.scan) {
      return;
    }

    if(now - this.lastClicked < 2000) {
      this.navDisabled = true;
      this.loader.loading(true);

      this.buttonTimeouts.scan = setTimeout(() => {
        this.goToScan();
        this.loader.loading(false);
        delete this.buttonTimeouts.scan;
        this.navDisabled = false;
      }, 2000);
    } else {

      this.goToScan();
    }

    this.lastClicked = now;
  }

  backButton() {
    this.loader.loading(true, {type: 'warn', message: 'iframe.ask.return', btnLabel: 'global.yes', custom: {closeBtnLabel: 'global.no'}}).then((valid: boolean) => valid ? this.nav.to(this.backToButton) : this.loader.loading(false));
  }

}
