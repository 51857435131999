<div id="layout" class="sign_in f_col layout_sm" [class.layout_sm]="api.userInfo && api.userInfo.nationalNumber">
  <app-view-title bgImage="assets-countries/header_background.png">{{'profile.newPassword' | translate}}</app-view-title>
  <div class="scroll_wrapper">
    <app-view-deco-image *ngIf="!showOtp" imageUrl="assets-countries/undraw_forgot-password.svg"></app-view-deco-image>
    <div *ngIf="!showOtp" class="f_grow">
      <form id="form" [formGroup]="form" (ngSubmit)="submitForm()">
        <app-input type="password" formCtrlName="oldPassword" [formGrp]="form" label="form.password_old" class="mt_24"></app-input>
        <app-input type="password" formCtrlName="password" (output)="checkPswdStrength()"
                   [formGrp]="form" label="form.password_new" class="mt_24"></app-input>
        <div class="pswd_strength mt_4">
          <span class="pswd_strength_bar" *ngIf="pswdStrength >= 20"></span>
          <span class="pswd_strength_bar" *ngIf="pswdStrength >= 40"></span>
          <span class="pswd_strength_bar" *ngIf="pswdStrength >= 70"></span>
          <span class="pswd_strength_bar" *ngIf="pswdStrength >= 90"></span>
        </div>
        <app-input type="password" formCtrlName="password2"
                   [formGrp]="form" label="form.password_new2" class="mt_24"></app-input>
        <app-errors-list [dark]="true" *ngIf="submitted" [formErrors]="forms.getFormErrors(this.form)"></app-errors-list>
      </form>
    </div>
    <div *ngIf="!showOtp" class="f_align_end f_grow mt_30">
      <app-back-btn [navTo]="{route: 'more-security'}"></app-back-btn>
      <div class="ml_10 fullWidth">
        <app-button type="submit" formId="form" class="fullWidth">{{'profile.btn.change_password' | translate}}</app-button>
      </div>
    </div>
    <div *ngIf="showOtp"  class="f_col f_align_c f_center f_grow">
      <app-paragraph textAlign="center">{{(api.userInfo.otpType === OtpType.EMAIL ? 'login.ask_otp.email' : 'login.ask_otp.sms') | translate}}</app-paragraph>
      <app-pin  [showCode]="true" [showCorrect]="showOTPCorrect" [showError]="showOTPError" (codeOutput)="checkOTPCode($event)"></app-pin>
      <app-back-btn position="botLeft" (btnClicked)="resetOtp()"></app-back-btn>
    </div>
  </div>
</div>
