<div id="layout" class="f_col" [class.layout_sm]="!api.userRole.isCustomer">
    <app-view-title>{{'userActivate.form.title' | translate}} (#{{scan.userScanData?.data?.sig}})</app-view-title>
    <div class="scroll_wrapper">

      <!-- Common form elements for all countries -->
      <form id="form" class="mt_40" [formGroup]="enroll.form">
          <app-input [formGrp]="enroll.form" [formCtrlName]="enroll.fc.FIRST_NAME" label="form.firstName" icon="i-cursor"></app-input>
          <app-input [formGrp]="enroll.form" [formCtrlName]="enroll.fc.MIDDLE_NAME" label="form.middleName" icon="i-cursor" class="mt_24"></app-input>
          <app-input [formGrp]="enroll.form" [formCtrlName]="enroll.fc.LAST_NAME" label="form.lastName" icon="i-cursor" class="mt_24"></app-input>
          <app-input type="date" [formGrp]="enroll.form" [formCtrlName]="enroll.fc.DATE_OF_BIRTH" label="form.birthDate" icon="calendar-days" class="mt_24"></app-input>
          <app-input type="select" [formGrp]="enroll.form" [formCtrlName]="enroll.fc.GENDER" label="form.gender" icon="venus-mars" class="mt_24" [selectOptions]="genderSO" ></app-input>
          <app-input type="select" [formGrp]="enroll.form" [formCtrlName]="enroll.fc.COUNTRY" label="form.country" icon="globe-africa" class="mt_24" [selectOptions]="countriesSO" ></app-input>
          <app-input type="select" [formGrp]="enroll.form" [formCtrlName]="enroll.fc.NATIONALITY" label="form.nationality" icon="flag" class="mt_24" [selectOptions]="nationalitiesSO" ></app-input>
          <app-input type="number" [formGrp]="enroll.form" [formCtrlName]="enroll.fc.PERSONAL_NUMBER" [maxLength]="11" label="form.personalNr" icon="hashtag" class="mt_24"></app-input>
          <app-input type="number" [formGrp]="enroll.form" [formCtrlName]="enroll.fc.DOC_NUMBER" label="form.docNr" icon="hashtag" class="mt_24"></app-input>

          <!-- Country-specific form elements will go here -->
          <app-activation-step-form-user-local-inputs></app-activation-step-form-user-local-inputs>

          <!-- Common MRZ display for all countries -->
          <div *ngIf="this.enroll.form.controls[this.enroll.fc.MRZ].value" class="currentMRZ mt_20">
            <p class="f_between"><span *ngFor="let letter of split(this.enroll.form.controls[this.enroll.fc.MRZ].value.line1)">{{letter}}</span></p>
            <p class="f_between"><span *ngFor="let letter of split(this.enroll.form.controls[this.enroll.fc.MRZ].value.line2)">{{letter}}</span></p>
            <p class="f_between"><span *ngFor="let letter of split(this.enroll.form.controls[this.enroll.fc.MRZ].value.line3)">{{letter}}</span></p>
          </div>
          <app-errors-list [dark]="true" *ngIf="enroll.areAnyControlsInvalidAndTouched()" [formErrors]="enroll.getFilteredFormErrors()"></app-errors-list>
      </form>

      <div class="f_col mt_40 scanner_back">
        <app-button [disabled]="!enroll.areAllControlsValid()" (btnClicked)="continueForm()" formId="form">{{'global.continue' | translate}}</app-button>
      </div>
    </div>
    <app-back-btn position="botLeft" (btnClicked)="goBack()"></app-back-btn>
  </div>
