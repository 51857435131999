<div id="layout" class="f_col layout_dk loader_qr">
  <img id="custom_bg" ngSrc="assets-countries/home.png" width="200" height="200">
  <div class="scroll_wrapper">
    <div class="f_grow f_align_c f_center mt_40">
      <app-qr-code *ngIf="qrValue" [value]="qrValue" [timer]="time"></app-qr-code>
    </div>
  </div>
  <div class="mb_30">
    <app-button color="secondary" (btnClicked)="goBack()" class="fullWidth">{{'global.close' | translate}}</app-button>
  </div>
</div>
