<div id="layout" class="f_col container layout_sm">
    <app-view-title bgImage="assets-countries/header_background.png">{{'more.security.otp' | translate}}</app-view-title>
    <div class="scroll_wrapper">
      <app-view-deco-image imageUrl="assets-countries/undraw_otp.svg"></app-view-deco-image>
      <app-paragraph class="mt_10 mb_10">{{'otp.desc.explain' | translate}}</app-paragraph>
      <app-paragraph textAlign="center" class="mt_10 mb_10">{{('otp.actual.DYN.' + api.userInfo.otpType) | translate}}</app-paragraph>
      <div class="f_col">
        <app-slide-element class="flex_grow" [disabled]="api.userInfo.otpType === OtpType.EMAIL"  leftIcon="envelope" (btnClicked)="api.userInfo.otpType === OtpType.EMAIL ? alreadyOtp() :  nav.to('more-security-otp', undefined, {state: {
            otp: 'email'
          }})">
            {{('more.security.otp.email') | translate}}
          </app-slide-element>
          <div class="mt_10">
              <app-slide-element [disabled]="api.userInfo.otpType === OtpType.PHONE"  leftIcon="message" (btnClicked)="api.userInfo.otpType === OtpType.PHONE ? alreadyOtp() : nav.to('more-security-otp', undefined, {state: {
                otp: 'sms'
              }})">
                {{('more.security.otp.sms') | translate}}
              </app-slide-element>
          </div>
      </div>
      <app-back-btn class="mt_10" [navTo]="{route: 'more-security'}"></app-back-btn>
    </div>
  </div>

