<div id="layout" class="f_col" [class.layout_sm]="!api.userRole.isCustomer">
  <app-view-title bgImage="assets-countries/header_background.png" fromRoute="activation-step-ask-can" toRoute="choose-lang" [chooseLangBtn]="api.userRole.isCustomer && true">
    {{'activation.step.ask_can.title' | translate}}
  </app-view-title>
  <div class="scroll_wrapper">
    <div class="f_center mt_40 nfcReadForm">
      <div class="nfcReadForm__img-container">
        <img class="nfcReadForm__img" src="assets-countries/idCard_front-can.webp" alt=""/>
      </div>
    </div>
    <app-paragraph class="mt_20">{{'nfc.read.self.form.desc' | translate}}</app-paragraph>
    <div class="f_grow mt_20" [class.mb_20]="!api.userRole.isCustomer">
      <form id="form" [formGroup]="enroll.form" (ngSubmit)="enroll.submitForm(enroll.getCurrentStep())">
        <app-input [maxLength]="6" type="text" [pattern]="'[0-9\s]{6}'" [formGrp]="enroll.form" [formCtrlName]="enroll.fc.CAN" label="form.can" icon="hashtag"></app-input>
        <app-errors-list [dark]="true" *ngIf="!enroll.form.controls[enroll.fc.CAN].valid && enroll.form.controls[enroll.fc.CAN].touched" [formErrors]="forms.getFormErrors(enroll.form, enroll.fc.CAN)"></app-errors-list>
      </form>
    </div>

    <div class="f_align_end f_grow ">
      <app-back-btn (btnClicked)="enroll.navigate('previous')"></app-back-btn>
      <div class="fullWidth ml_10">
        <app-button [disabled]="!enroll.form.controls[enroll.fc.CAN].valid" (btnClicked)=" enroll.navigate('next');" class="fullWidth">
          {{'global.continue' | translate}}
        </app-button>
      </div>
    </div>
  </div>
</div>
