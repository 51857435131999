<div id="layout" class="f_col">
  <app-view-title bgImage="assets-countries/header_background.png" fromRoute="share-claim-done" [chooseLangBtn]="api.userRole.isCustomer && true">{{'share-claim-done.title' | translate}}</app-view-title>
  <div class="scroll_wrapper">
    <app-view-deco-image class="fadeIn mb_40" imageUrl="assets-countries/Valid.svg"></app-view-deco-image>
    <div class="f_grow">
      <app-paragraph>{{'share-claim-done.desc' | translate}}</app-paragraph>
      <div class="f_col mt_40">
        <app-button (click)="nav.to('claim-card', undefined, {queryParams: params})">{{'global.close' | translate}}</app-button>
      </div>
    </div>
  </div>
</div>
