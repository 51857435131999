import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../shared/services/api.service';
import {
  FormService, LoaderService,
  NavigateService
} from 'ngx-satoris';
import {setStoredItem} from 'src/app/shared/utils/storage';
import {Router} from '@angular/router';
import {OtpType} from 'src/app/shared/models/user';
import {environment} from 'src/environments/environment';


@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ForgotPasswordComponent implements OnInit {

  form: FormGroup;
  submitted: boolean;
  pswdStrength: number;
  signType: OtpType = OtpType.EMAIL;
  OtpType = OtpType;
  phone: string;

  // data: { proAccount: boolean, email?: string, phone?: string, signType?: OtpType, identifier?: string };
  data: any;
  constructor(public forms: FormService,
    public nav: NavigateService,
    public api: ApiService,
    private formBuilder: FormBuilder,
    private loader: LoaderService,
    private router: Router) {
    this.data = <any> this.router.getCurrentNavigation().extras.state;
    this.phone = `phone${environment.country}`;
    this.signType = this.data?.signType || OtpType.EMAIL;
  }

  ngOnInit(): void {
    if(this.signType === OtpType.PHONE){
      this.setFormForgotPasswordPhone();
    } else {
      this.data?.proAccount ? this.setFormForgotPasswordPro() : this.setFormForgotPasswordCustomer();
    }
  }

  setFormForgotPasswordCustomer() {
    this.form = this.formBuilder.group({
      email: [this.data?.email || '', [
        Validators.required,
        Validators.email
      ]]
    });
  }

  setFormForgotPasswordPro() {
    this.form = this.formBuilder.group({
      identifier: [this.data?.identifier || '', [
        Validators.required
      ]]
    });
  }

  setFormForgotPasswordPhone() {
    this.form = this.formBuilder.group({
      [this.phone]: [this.data?.phone || '', [
        Validators.required,
        Validators.pattern(environment.phonePattern)
      ]]
    });
  }

  submitForm() {
    this.submitted = true;
    if(this.forms.getFormErrors(this.form).length < 1) {
      this.loader.loading(true);
      setStoredItem('recover', this.form.get(this.data?.proAccount ? 'identifier' : this.signType === OtpType.PHONE ? this.phone : 'email').value);
      this.api.reset(this.form.get(this.data?.proAccount ? 'identifier' : this.signType === OtpType.PHONE ? this.phone : 'email').value).then(() => {
        const state = {
          proAccount: this.data?.proAccount,
          signType: this.signType,
          email: this.form.get('email')?.value,
          phone: this.form.get(this.phone)?.value
        };
        this.loader.loading(false);
        this.nav.to('recover', undefined, {state});
      }).catch(() => {
        this.loader.loading(false);
      });
    }
  }

  checkPswdStrength() {
    const password = this.form.get('password').value;
    let score = 0;
    const letters: any = {};

    for(let i = 0; i < password.length; i++) {
      letters[password[i]] = (letters[password[i]] || 0) + 1;
      score += 5.0 / letters[password[i]];
    }

    const variations: any = {
      digits: /\d/.test(password),
      lower: /[a-z]/.test(password),
      upper: /[A-Z]/.test(password),
      nonWords: /\W/.test(password)
    };

    let variationCount = 0;
    for(const check in variations) {
      variationCount += (variations[check]) ? 1 : 0;
    }
    score += (variationCount - 1) * 10;
    this.pswdStrength = Math.trunc(score) <= 100 ? Math.trunc(score) : 100;
  }

  navBack() {
    if(!this.api.userInfo) {
      this.nav.to('sign-in', undefined, {state: {signType: this.data?.signType, email: this.form.get('email')?.value, phone: this.data?.phone}});
    } else {
      this.nav.toPin('forgot-password', 'sign-in-pro', undefined, undefined, {state: {identifier: this.form.get('identifier')?.value}});
    }
  }
}
