import {Component} from '@angular/core';
import {ApiService} from 'src/app/shared/services/api.service';

@Component({
  selector: 'app-security-provider',
  templateUrl: './security-provider.component.html',
  styleUrls: ['./security-provider.component.scss']
})
export class SecurityProviderComponent {

  constructor(public api: ApiService) { }
}
