import {AngularSignaturePadModule} from '@almothafar/angular-signature-pad';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatMenuModule} from '@angular/material/menu';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSliderModule} from '@angular/material/slider';
import {ZXingScannerModule} from '@zxing/ngx-scanner';
import {QRCodeModule} from 'angularx-qrcode';
import {WebcamModule} from 'ngx-webcam';
import {SharedModule} from '../shared/shared.module';
import {ActivityListComponent} from './activity-list/activity-list.component';
import {CardComponent} from './card/card.component';
import {DialogChoosePlaceComponent} from './dialogs/dialog-choose-place/dialog-choose-place.component';
import {DialogPermissionsComponent} from './dialogs/dialog-permissions/dialog-permissions.component';
import {NavComponent} from './nav/nav.component';
import {QrCodeComponent} from './qr-code/qr-code.component';
import {ScannerComponent} from './scanner/scanner.component';
import {ProToggleComponent} from './pro-toggle/pro-toggle.component';
import {NgxSatorisModule, FontAwesomeModule} from 'ngx-satoris';
import {SignatureComponent} from './signature-pad/signature-pad.component';
import {ShareClaimDoneComponent} from '../views/user/share-claim/share-claim-done/share-claim-done.component';
import {SplashScreenComponent} from './splash-screen/splash-screen.component';
import {BadgeCardComponent} from './badge/badge-card.component';
import {WysiwygComponent} from './wysiwyg/wysiwyg.component';
import {NgxEditorModule} from 'ngx-editor';
import {ModalFeaturesComponent} from './modal-features/modal-features.component';
import {ModalArticleComponent} from './modal-article/modal-article.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    ReactiveFormsModule,
    MatSliderModule,
    MatMenuModule,
    MatButtonModule,
    MatSlideToggleModule,
    MatDialogModule,
    ZXingScannerModule,
    MatDividerModule,
    QRCodeModule,
    WebcamModule,
    AngularSignaturePadModule,
    NgxSatorisModule,
    FontAwesomeModule,
    NgOptimizedImage,
    NgxEditorModule
  ],
  exports: [
    NavComponent,
    ProToggleComponent,
    ScannerComponent,
    QrCodeComponent,
    CardComponent,
    ActivityListComponent,
    SignatureComponent,
    SplashScreenComponent,
    ShareClaimDoneComponent,
    BadgeCardComponent,
    SplashScreenComponent,
    WysiwygComponent,
    ModalFeaturesComponent,
    ModalArticleComponent
  ],
  declarations: [
    NavComponent,
    ProToggleComponent,
    ScannerComponent,
    QrCodeComponent,
    CardComponent,
    DialogChoosePlaceComponent,
    DialogPermissionsComponent,
    ActivityListComponent,
    SignatureComponent,
    SplashScreenComponent,
    ShareClaimDoneComponent,
    BadgeCardComponent,
    ShareClaimDoneComponent,
    WysiwygComponent,
    ModalFeaturesComponent,
    ModalArticleComponent
  ]
})
export class ComponentsModule {}
