import {Injectable} from '@angular/core';
import {DevicesService, LangService} from 'ngx-satoris';
import {getStoredItem, removeStoredItem} from '../utils/storage';

declare const window: any;

@Injectable({
  providedIn: 'root'
})
export class BiometricService {

  biometric:{
    faceID: boolean,
    touchID: boolean,
    isRegistered: null | boolean,
    isGranted: null | boolean,
    enrolled: boolean
  } = {
      faceID: false,
      touchID: false,
      isRegistered: false,
      isGranted: null,
      enrolled: false
    };

  currentlyValidated: boolean;
  
  constructor(private lang: LangService,
    private device: DevicesService) {}

  setBiometric(code?: string): Promise<string | void> {
    return new Promise((resolve, reject) => {
      window?.Fingerprint?.registerBiometricSecret({
        description: this.lang.transform('fingerprints.dialog.setSecret'),
        secret: code,
        invalidateOnEnrollment: true,
        disableBackup: true // always disabled on Android
      }, (status: any) => {
        resolve(status);
      }, (err: any) => {
        reject(err);
      } );
    });
  }


  unsetBiometric() {
    this.biometric ={
      faceID: false,
      touchID: false,
      isRegistered: false,
      enrolled: this.biometric.enrolled,
      isGranted: this.biometric.isGranted
    };
    removeStoredItem('biometric');
  }

  checkAuth(): Promise<string> {
    return new Promise((resolve, reject) => {
      window?.Fingerprint?.loadBiometricSecret({
        description: this.lang.transform('fingerprints.dialog.getSecret'),
        disableBackup: true // always disabled on Android
      }, (secret: string) => {
        resolve(secret);
      }, (err: any) => {
        reject(err);
      });
    });
  }


  initBiometrics(): Promise<void> {
    return new Promise((resolve) => {
      if(this.device.isDevices('cordova')) {
        window?.Fingerprint?.isAvailable((type: any) => {
          this.biometric = {
            faceID: type === 'face',
            touchID: type === 'finger',
            enrolled: true,
            isRegistered:
                getStoredItem('biometric') !== undefined
                  ? getStoredItem('biometric')
                  : null,
            isGranted:
                getStoredItem('biometric') !== undefined
                  ? getStoredItem('biometric')
                  : null
          };
          resolve();
        },
        (err: any) => {
          if(err.code === window?.Fingerprint?.BIOMETRIC_NOT_ENROLLED) {
            this.biometric.enrolled = false;
          }
          if(err.code === window?.Fingerprint?.BIOMETRIC_UNAVAILABLE) {
            this.biometric.isGranted = false;
          } else {
            this.unsetBiometric();
          }
          resolve();
        });
      } else {
        this.unsetBiometric();
        resolve();
      }
    });
  }
}
