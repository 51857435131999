import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ZXingScannerModule} from '@zxing/ngx-scanner';
import {QRCodeModule} from 'angularx-qrcode';
import * as Hammer from 'hammerjs';
import {WebcamModule} from 'ngx-webcam';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ComponentsModule} from './components/components.module';
import {SharedModule} from './shared/shared.module';
import {
  ActivationCodeInfoComponent
} from './views/activation/activation-code/activation-code-info/activation-code-info.component';
import {
  ActivationCodeMapComponent
} from './views/activation/activation-code/activation-code-info/activation-code-map/activation-code-map.component';
import {ActivationCodeComponent} from './views/activation/activation-code/activation-code.component';
import {ActivationComponent} from './views/activation/activation.component';
import {BadgeComponent} from './views/badge/badge.component';
import {ForgotPasswordComponent} from './views/forgot-password/forgot-password.component';
import {ForgotPinComponent} from './views/forgot-pin/forgot-pin.component';
import {HomeComponent} from './views/home/home.component';
import {ChooseAccountComponent} from './views/more/choose-account/choose-account.component';
import {ChooseLangComponent} from './views/more/choose-lang/choose-lang.component';
import {ChoosePlaceComponent} from './views/more/choose-place/choose-place.component';
import {MoreComponent} from './views/more/more.component';
import {NewsComponent} from './views/news/news.component';
import {PasswordChangedComponent} from './views/password-changed/password-changed.component';
import {RecoverComponent} from './views/recover/recover.component';
import {ScanResultComponent} from './views/scan/scan-result/scan-result.component';
import {ScanComponent} from './views/scan/scan.component';
import {SecretCodeValidComponent} from './views/secret-code/secret-code-valid/secret-code-valid.component';
import {SecretCodeComponent} from './views/secret-code/secret-code.component';
import {SignInProComponent} from './views/sign-in-pro/sign-in-pro.component';
import {SignInComponent} from './views/sign-in/sign-in.component';
import {SignUpComponent} from './views/sign-up/sign-up.component';
import {ShareClaimComponent} from './views/user/share-claim/share-claim.component';
import {UserComponent} from './views/user/user.component';
import {UserPermissionsComponent} from './views/user-permissions/user-permissions.component';
import {DevicePermissionsComponent} from './views/device-permissions/device-permissions.component';
import {
  faArrowLeft,
  faArrowRight,
  faArrowRightArrowLeft,
  faArrowRightFromBracket,
  faAt,
  faBan,
  faBarcode,
  faBell,
  faBomb,
  faBriefcase,
  faBuilding,
  faBus,
  faCalendarDay,
  faCalendarDays,
  faCameraRotate,
  faCarSide,
  faCheck,
  faCheckCircle,
  faCircle,
  faCircleCheck,
  faCircleDown,
  faCircleInfo,
  faCircleMinus,
  faCirclePlus,
  faCircleQuestion,
  faCircleXmark,
  faCity,
  faClock,
  faCompass,
  faCopy,
  faEarthAfrica,
  faEllipsis,
  faEnvelope,
  faEye,
  faEyeSlash,
  faFaceSmile,
  faFile,
  faFingerprint,
  faFlag,
  faGear,
  faGlobe,
  faGlobeAfrica,
  faHandHoldingMedical,
  faHashtag,
  faHeadset,
  faICursor,
  faIdCard,
  faInfo,
  faJedi,
  faKey,
  faLanguage,
  faLocationArrow,
  faLocationDot,
  faLock,
  faMagnifyingGlass,
  faMagnifyingGlassArrowRight,
  faMagnifyingGlassLocation,
  faMobileAlt,
  faPassport,
  faPenToSquare,
  faPersonBooth,
  faPersonDigging,
  faPersonHalfDress,
  faPhone,
  faQrcode,
  faRectangleList,
  faRoad,
  faRulerVertical,
  faShare,
  faShareNodes,
  faShieldHalved,
  faSquare,
  faTriangleExclamation,
  faUser,
  faUserCheck,
  faUserGear,
  faUserGroup,
  faUserLock,
  faUserPen,
  faUserPlus,
  faUsers,
  faUserTie,
  faVenusMars,
  faVirusCovid,
  faWifi,
  faMessage
} from '@fortawesome/free-solid-svg-icons';
import {MoreSecurityComponent} from './views/more/more-security/more-security.component';
import {
  MoreSecurityFingerprintComponent
} from './views/more/more-security/more-security-fingerprint/more-security-fingerprint.component';
import {ChangePasswordComponent} from './views/more/more-security/change-password/change-password.component';
import {
  DevicesService,
  DialogsService,
  faAddressBook,
  faAddressCard, faArrowsUpDown, faBaby, faBellConcierge, faBolt, faBuildingColumns,
  faCakeCandles,
  faCalendar,
  faCalendarCheck,
  faCalendarXmark,
  faCamera, faCar, faClockRotateLeft, faCode,
  faCreditCard, faDatabase, faGavel,
  faLightbulb, faNotesMedical, faPerson, faPersonDress,
  faQuestion, faFileLines, faNewspaper,
  faRotate,
  faRotateRight,
  faStreetView,
  faUpload, faWallet,
  faX,
  FontAwesomeModule,
  FormService,
  LangService,
  LoaderService,
  NavigateService,
  NgxSatorisModule,
  TranslatePipe, VersionComponent, faArrowUpRightFromSquare,
  faHeartPulse,
  faCrown
} from 'ngx-satoris';
import {en_strings} from './shared/i18n/en';
import {fr_strings} from './shared/i18n/fr';
import {NgOptimizedImage} from '@angular/common';
import {ShareClaimModeComponent} from './views/user/share-claim/share-claim-mode/share-claim-mode.component';
import {WebcamComponent} from './components/webcam/webcam.component';
import {WelcomeHelloComponent} from './views/welcome/welcome-hello/welcome-hello.component';
import {WelcomeExplainComponent} from './views/welcome/welcome-explain/welcome-explain.component';
import {WelcomeDoneComponent} from './views/welcome/welcome-done/welcome-done.component';
import {InitializeService} from './shared/services/initialize.service';
import {MoreSecurityBiometricComponent} from './views/more/more-security/more-security-biometric/more-security-biometric.component';
import {WelcomeBiometricComponent} from './views/welcome/welcome-biometric/welcome-biometric.component';
import {AngularSignaturePadModule} from '@almothafar/angular-signature-pad';
import {ActivationStepChoiceComponent} from './views/activation/steps/activation-step-choice/activation-step-choice.component';
import {ActivationStepScanMrzComponent} from './views/activation/steps/activation-step-scan-mrz/activation-step-scan-mrz.component';
import {ActivationStepDocNumberComponent} from './views/activation/steps/activation-step-doc-number/activation-step-doc-number.component';
import {ActivationStepDocExpDateComponent} from './views/activation/steps/activation-step-doc-exp-date/activation-step-doc-exp-date.component';
import {ActivationStepDateOfBirthComponent} from './views/activation/steps/activation-step-date-of-birth/activation-step-date-of-birth.component';
import {ActivationStepDrawSignatureComponent} from './views/activation/steps/activation-step-draw-signature/activation-step-draw-signature.component';
import {ActivationStepTakeFacePictureComponent} from './views/activation/steps/activation-step-take-face-picture/activation-step-take-face-picture.component';
import {ActivationStepAskCanComponent} from './views/activation/steps/activation-step-ask-can/activation-step-ask-can.component';
import {ActivationStepAskPinComponent} from './views/activation/steps/activation-step-ask-pin/activation-step-ask-pin.component';
import {ActivationStepNfcScanIdCardComponent} from './views/activation/steps/activation-step-nfc-scan-id-card/activation-step-nfc-scan-id-card.component';
import {ActivationStepConfirmComponent} from './views/activation/steps/activation-step-confirm/activation-step-confirm.component';
import {ActivationStepIntroComponent} from './views/activation/steps/activation-step-intro/activation-step-intro.component';
import {ActivationStepDoneComponent} from './views/activation/steps/activation-step-done/activation-step-done.component';
import {ActivationStepScanMrzChoiceComponent} from './views/activation/steps/activation-step-scan-mrz/activation-step-scan-mrz-choice/activation-step-scan-mrz-choice.component';
import {ActivationStepScanMrzScanComponent} from './views/activation/steps/activation-step-scan-mrz/activation-step-scan-mrz-scan/activation-step-scan-mrz-scan.component';
import {ActivationStepScanMrzNfcComponent} from './views/activation/steps/activation-step-scan-mrz/activation-step-scan-mrz-nfc/activation-step-scan-mrz-nfc.component';
import {ActivationStepAskNniComponent} from './views/activation/steps/activation-step-ask-nni/activation-step-ask-nni.component';
import {MoreActivityComponent} from './views/more/more-activity/more-activity.component';
import {ServicesComponent} from './views/services/services.component';
import {ServiceIframeComponent} from './views/services/service-iframe/service-iframe.component';
import {SafePipe} from './shared/safe.pipe';
import {ActivationStepQrScanComponent} from './views/activation/steps/activation-step-qr-scan/activation-step-qr-scan.component';
import {ActivationStepFormUserComponent} from './views/activation/steps/activation-step-form-user/activation-step-form-user.component';
import {ActivationStepFormUserLocalInputsComponent} from './views/activation/steps/activation-step-form-user/activation-step-form-user-local-inputs.component';
import {SecurityProviderComponent} from './views/security-provider/security-provider.component';
import {MoreSecurityOtpComponent} from './views/more/more-security/more-security-otp/more-security-otp.component';
import {MoreSecurityOtpExplainComponent} from './views/more/more-security/more-security-otp/more-security-otp-explain/more-security-otp-explain.component';
import {ClaimDataComponent} from './views/user/claim-data/claim-data.component';
import {ClaimCardComponent} from './views/user/claim-card/claim-card.component';
import {MoreSecurityChangePinComponent} from './views/more/more-security/more-security-change-pin/more-security-change-pin.component';
import {ScanShareComponent} from './views/scan/scan-share/scan-share.component';
import {JwtInterceptorService} from './shared/services/jwt-interceptor.service';
import {ActivationStepAskDeviceNameComponent} from './views/activation/steps/activation-step-ask-device-name/activation-step-ask-device-name.component';
import {AccountValidComponent} from './views/otp-valid/account-valid.component';
import {ManageNewsComponent} from './views/manage/manage-news/manage-news.component';
import {ManageNewsArticlesComponent} from './views/manage/manage-news/manage-news-articles/manage-news-articles.component';
import {ManageNewsNewComponent} from './views/manage/manage-news/manage-news-new/manage-news-new.component';
import {ActivationStepFingerprintComponent} from './views/activation/steps/activation-step-fingerprint/activation-step-fingerprint.component';
import {PersonnalDatasExplainComponent} from './views/user/personnal-data/personnal-data-explain/personnal-data-explain.component';
import {PersonnalData} from './views/user/personnal-data/personnal-data.component';
import {PersonnalDataSubTypeComponent} from './views/user/personnal-data/personnal-data-type/personnal-data-subtype/personnal-data-subtype.component';
import {PersonnalDataTypeComponent} from './views/user/personnal-data/personnal-data-type/personnal-data-type.component';
import {PersonnalDataAllComponent} from './views/user/personnal-data/personnal-data-all/personnal-data-all.component';
import {PersonnalDataManageComponent} from './views/user/personnal-data/personnal-data-manage/personnal-data-manage.component';
import {PersonnalDataManageIdComponent} from './views/user/personnal-data/personnal-data-manage/personnal-data-manage-id/personnal-data-manage-id.component';
import {PersonnalDataManageRefusalComponent} from './views/user/personnal-data/personnal-data-manage/personnal-data-manage-refusal/personnal-data-manage-refusal.component';
import {SubscriptionComponent} from './views/subscription/subscription.component';
import {ActivationStepVoucherComponent} from './views/activation/steps/activation-step-voucher/activation-step-voucher.component';
import {UpdateVersionComponent} from './views/update-version/update-version.component';
import {NewsSelectComponent} from './views/news/news-select/news-select.component';
import {ScanShareResultComponent} from './views/scan/scan-share-result/scan-share-result.component';
import {ActivationStepDescTakeFacePictureComponent} from './views/activation/steps/activation-step-desc-take-face-picture/activation-step-desc-take-face-picture.component';

export class HammerConfig extends HammerGestureConfig {
  overrides = {
    swipe: {direction: Hammer.DIRECTION_ALL}
  };
}

const init_app = (initService: InitializeService) => () => initService.initialize();

@NgModule({
  declarations: [
    AppComponent,
    SignInComponent,
    ForgotPasswordComponent,
    SignUpComponent,
    UserComponent,
    AccountValidComponent,
    HomeComponent,
    SecretCodeComponent,
    SecretCodeValidComponent,
    RecoverComponent,
    PasswordChangedComponent,
    MoreComponent,
    NewsComponent,
    ScanComponent,
    BadgeComponent,
    ActivationComponent,
    ScanResultComponent,
    ShareClaimComponent,
    ForgotPinComponent,
    SignInProComponent,
    ChooseAccountComponent,
    ActivationCodeComponent,
    ActivationCodeInfoComponent,
    ChoosePlaceComponent,
    ActivationCodeMapComponent,
    ChooseLangComponent,
    UserPermissionsComponent,
    ChangePasswordComponent,
    ChooseLangComponent,
    UserPermissionsComponent,
    DevicePermissionsComponent,
    MoreSecurityComponent,
    MoreSecurityFingerprintComponent,
    ShareClaimModeComponent,
    WebcamComponent,
    WelcomeHelloComponent,
    WelcomeExplainComponent,
    WelcomeDoneComponent,
    MoreSecurityBiometricComponent,
    WelcomeBiometricComponent,
    ActivationStepChoiceComponent,
    ActivationStepScanMrzComponent,
    ActivationStepDocNumberComponent,
    ActivationStepDocExpDateComponent,
    ActivationStepDateOfBirthComponent,
    ActivationStepDrawSignatureComponent,
    ActivationStepTakeFacePictureComponent,
    ActivationStepAskCanComponent,
    ActivationStepAskPinComponent,
    ActivationStepNfcScanIdCardComponent,
    ActivationStepConfirmComponent,
    ActivationStepIntroComponent,
    ActivationStepDoneComponent,
    ActivationStepScanMrzChoiceComponent,
    ActivationStepScanMrzScanComponent,
    ActivationStepScanMrzNfcComponent,
    ActivationStepAskNniComponent,
    MoreActivityComponent,
    ServicesComponent,
    ServiceIframeComponent,
    SafePipe,
    ActivationStepQrScanComponent,
    ActivationStepFormUserComponent,
    ActivationStepFormUserLocalInputsComponent,
    SecurityProviderComponent,
    ClaimDataComponent,
    ClaimCardComponent,
    SecurityProviderComponent,
    MoreSecurityOtpComponent,
    MoreSecurityOtpExplainComponent,
    MoreSecurityChangePinComponent,
    ScanShareComponent,
    ActivationStepAskDeviceNameComponent,
    ActivationStepFingerprintComponent,
    ActivationStepVoucherComponent,
    PersonnalData,
    PersonnalDataSubTypeComponent,
    PersonnalDataTypeComponent,
    PersonnalDatasExplainComponent,
    PersonnalDataAllComponent,
    PersonnalDataManageComponent,
    PersonnalDataManageIdComponent,
    PersonnalDataManageRefusalComponent,
    SubscriptionComponent,
    UpdateVersionComponent,
    ManageNewsComponent,
    ManageNewsArticlesComponent,
    ManageNewsNewComponent,
    NewsSelectComponent,
    ScanShareResultComponent,
    ActivationStepDescTakeFacePictureComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HammerModule,
    AppRoutingModule,
    ComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    QRCodeModule,
    ZXingScannerModule,
    WebcamModule,
    AngularSignaturePadModule,
    MatCheckboxModule,
    HttpClientModule,
    LeafletModule,
    FontAwesomeModule,
    NgxSatorisModule,
    NgOptimizedImage,
    VersionComponent
  ],
  providers: [
    TranslatePipe,
    LangService,
    DialogsService,
    FormService,
    LoaderService,
    NavigateService,
    DevicesService,
    InitializeService,
    SafePipe,
    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      deps: [InitializeService],
      multi: true
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerConfig
    },
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptorService, multi: true}
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
  constructor(private ngxSatoris: NgxSatorisModule, private lang: LangService, private deviceService: DevicesService) {
    this.deviceService.checkDevices();
    this.lang.setTranslations({en: en_strings, fr: fr_strings});
    this.ngxSatoris.addIcons([faArrowLeft,
      faArrowRight,
      faArrowRightArrowLeft,
      faArrowRightFromBracket,
      faAt,
      faBarcode,
      faBomb,
      faBriefcase,
      faBuilding,
      faCalendarDay,
      faCalendarDays,
      faCarSide,
      faCheck,
      faCircle,
      faCircleCheck,
      faCircleInfo,
      faCirclePlus,
      faCircleQuestion,
      faCircleXmark,
      faCity,
      faClock,
      faEarthAfrica,
      faEnvelope,
      faFlag,
      faGear,
      faGlobe,
      faGlobeAfrica,
      faHashtag,
      faIdCard,
      faInfo,
      faICursor,
      faJedi,
      faKey,
      faLanguage,
      faLocationArrow,
      faLocationDot,
      faLock,
      faMagnifyingGlass,
      faMagnifyingGlassArrowRight,
      faMagnifyingGlassLocation,
      faMobileAlt,
      faPassport,
      faPersonBooth,
      faPersonDigging,
      faPhone,
      faQrcode,
      faRoad,
      faRulerVertical,
      faShieldHalved,
      faSquare,
      faTriangleExclamation,
      faUser,
      faUsers,
      faUserGear,
      faUserGroup,
      faUserPen,
      faUserPlus,
      faUserTie,
      faVenusMars,
      faVirusCovid,
      faCheckCircle,
      faCameraRotate,
      faEye,
      faEyeSlash,
      faPenToSquare,
      faUserCheck,
      faCircleMinus,
      faCircleDown,
      faShare,
      faShareNodes,
      faBan,
      faUserLock,
      faBell,
      faCompass,
      faFingerprint,
      faFaceSmile,
      faBus,
      faEllipsis,
      faHeadset,
      faWifi,
      faIdCard,
      faWifi,
      faRectangleList,
      faPersonHalfDress,
      faEye,
      faFile,
      faCopy,
      faRotateRight,
      faUpload,
      faEyeSlash,
      faCreditCard,
      faAddressCard,
      faFile,
      faHandHoldingMedical,
      faCalendar,
      faLightbulb,
      faCamera,
      faCakeCandles,
      faCalendarXmark,
      faCalendarCheck,
      faBuildingColumns,
      faBaby,
      faBellConcierge,
      faWallet,
      faClockRotateLeft,
      faCreditCard,
      faCode,
      faArrowsUpDown,
      faGavel,
      faCar,
      faPerson,
      faPersonDress,
      faClockRotateLeft,
      faMessage,
      faBolt,
      faDatabase,
      faNotesMedical,
      faAddressBook,
      faQuestion,
      faX,
      faStreetView,
      faRotate,
      faMessage,
      faNewspaper,
      faFileLines,
      faPenToSquare,
      faArrowUpRightFromSquare,
      faHeartPulse,
      faCrown
    ]);
  }
}
