import {Component, ViewEncapsulation} from '@angular/core';
import {LoaderService, NavigateService} from 'ngx-satoris';
import {ApiService} from '../../shared/services/api.service';
import {SyncService} from '../../shared/services/sync.service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ServicesComponent {
  constructor(public nav: NavigateService, 
    public api: ApiService, 
    public sync: SyncService,
    private loader: LoaderService) {
  }

  stringify(obj: any) {
    return JSON.stringify(obj);
  }

  disabledServices() {
    return this.loader.loading(true, {type: 'warn', message: 'services.disabled'});
  }
}
