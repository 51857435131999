import {Component, EventEmitter, HostBinding, Input, OnInit, Output} from '@angular/core';
import {checkImage} from '../../shared/utils/checkImage';

@Component({
  selector: 'app-modal-features',
  templateUrl: './modal-features.component.html',
  styleUrls: ['./modal-features.component.scss']
})
export class ModalFeaturesComponent implements OnInit {

  @Input() title: string;
  @Input() subtitle: string;
  @Input() bgImage: string;
  @Input() date: string;
  @Input() hasLink: boolean;
  @Output() clicked: EventEmitter<any> = new EventEmitter<any>();
  @HostBinding('class') @Input('class') classList = '';

  ngOnInit(): void {
    if(this.bgImage) {
      checkImage(this.bgImage).then((isLoaded) => {
        if(!isLoaded) {
          this.bgImage = './assets/images/article_blank.png';
        }
      });
    }
  }
}
