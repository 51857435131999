import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {UserPlatformRole} from './shared/models/user';
import {UserUnknownGuardService} from './shared/services/user-unknown-guard.service';
import {UserValidGuardService} from './shared/services/user-valid-guard.service';
import {
  ActivationCodeInfoComponent
} from './views/activation/activation-code/activation-code-info/activation-code-info.component';
import {
  ActivationCodeMapComponent
} from './views/activation/activation-code/activation-code-info/activation-code-map/activation-code-map.component';
import {ActivationCodeComponent} from './views/activation/activation-code/activation-code.component';
import {ActivationComponent} from './views/activation/activation.component';
import {BadgeComponent} from './views/badge/badge.component';
import {DevicePermissionsComponent} from './views/device-permissions/device-permissions.component';
import {AccountValidComponent} from './views/otp-valid/account-valid.component';
import {ForgotPasswordComponent} from './views/forgot-password/forgot-password.component';
import {ForgotPinComponent} from './views/forgot-pin/forgot-pin.component';
import {HomeComponent} from './views/home/home.component';
import {ChooseAccountComponent} from './views/more/choose-account/choose-account.component';
import {ChooseLangComponent} from './views/more/choose-lang/choose-lang.component';
import {ChoosePlaceComponent} from './views/more/choose-place/choose-place.component';
import {MoreComponent} from './views/more/more.component';
import {NewsComponent} from './views/news/news.component';
import {PasswordChangedComponent} from './views/password-changed/password-changed.component';
import {RecoverComponent} from './views/recover/recover.component';
import {ScanResultComponent} from './views/scan/scan-result/scan-result.component';
import {ScanComponent} from './views/scan/scan.component';
import {SecretCodeValidComponent} from './views/secret-code/secret-code-valid/secret-code-valid.component';
import {SecretCodeComponent} from './views/secret-code/secret-code.component';
import {SignInProComponent} from './views/sign-in-pro/sign-in-pro.component';
import {SignInComponent} from './views/sign-in/sign-in.component';
import {SignUpComponent} from './views/sign-up/sign-up.component';
import {UserPermissionsComponent} from './views/user-permissions/user-permissions.component';
import {ShareClaimComponent} from './views/user/share-claim/share-claim.component';
import {UserComponent} from './views/user/user.component';
import {MoreSecurityComponent} from './views/more/more-security/more-security.component';
import {ChangePasswordComponent} from './views/more/more-security/change-password/change-password.component';
import {ShareClaimModeComponent} from './views/user/share-claim/share-claim-mode/share-claim-mode.component';
import {WelcomeHelloComponent} from './views/welcome/welcome-hello/welcome-hello.component';
import {WelcomeExplainComponent} from './views/welcome/welcome-explain/welcome-explain.component';
import {WelcomeDoneComponent} from './views/welcome/welcome-done/welcome-done.component';
import {ShareClaimDoneComponent} from './views/user/share-claim/share-claim-done/share-claim-done.component';
import {WelcomeBiometricComponent} from './views/welcome/welcome-biometric/welcome-biometric.component';

import {
  MoreSecurityBiometricComponent
} from './views/more/more-security/more-security-biometric/more-security-biometric.component';
import {
  ActivationStepChoiceComponent
} from './views/activation/steps/activation-step-choice/activation-step-choice.component';
import {
  ActivationStepScanMrzComponent
} from './views/activation/steps/activation-step-scan-mrz/activation-step-scan-mrz.component';
import {
  ActivationStepDocNumberComponent
} from './views/activation/steps/activation-step-doc-number/activation-step-doc-number.component';
import {
  ActivationStepDocExpDateComponent
} from './views/activation/steps/activation-step-doc-exp-date/activation-step-doc-exp-date.component';
import {
  ActivationStepDateOfBirthComponent
} from './views/activation/steps/activation-step-date-of-birth/activation-step-date-of-birth.component';
import {
  ActivationStepDrawSignatureComponent
} from './views/activation/steps/activation-step-draw-signature/activation-step-draw-signature.component';
import {
  ActivationStepTakeFacePictureComponent
} from './views/activation/steps/activation-step-take-face-picture/activation-step-take-face-picture.component';
import {
  ActivationStepAskCanComponent
} from './views/activation/steps/activation-step-ask-can/activation-step-ask-can.component';
import {
  ActivationStepAskPinComponent
} from './views/activation/steps/activation-step-ask-pin/activation-step-ask-pin.component';
import {
  ActivationStepNfcScanIdCardComponent
} from './views/activation/steps/activation-step-nfc-scan-id-card/activation-step-nfc-scan-id-card.component';
import {
  ActivationStepConfirmComponent
} from './views/activation/steps/activation-step-confirm/activation-step-confirm.component';
import {
  ActivationStepIntroComponent
} from './views/activation/steps/activation-step-intro/activation-step-intro.component';
import {
  ActivationStepDoneComponent
} from './views/activation/steps/activation-step-done/activation-step-done.component';
import {
  ActivationStepScanMrzNfcComponent
} from './views/activation/steps/activation-step-scan-mrz/activation-step-scan-mrz-nfc/activation-step-scan-mrz-nfc.component';
import {
  ActivationStepScanMrzScanComponent
} from './views/activation/steps/activation-step-scan-mrz/activation-step-scan-mrz-scan/activation-step-scan-mrz-scan.component';
import {
  ActivationStepScanMrzChoiceComponent
} from './views/activation/steps/activation-step-scan-mrz/activation-step-scan-mrz-choice/activation-step-scan-mrz-choice.component';
import {
  ActivationStepAskNniComponent
} from './views/activation/steps/activation-step-ask-nni/activation-step-ask-nni.component';
import {MoreActivityComponent} from './views/more/more-activity/more-activity.component';
import {ActivationStepQrScanComponent} from './views/activation/steps/activation-step-qr-scan/activation-step-qr-scan.component';
import {ActivationStepFormUserComponent} from './views/activation/steps/activation-step-form-user/activation-step-form-user.component';
import {ManageUserComponent} from './views/manage/manage-user/manage-user.component';
import {ServicesComponent} from './views/services/services.component';
import {ServiceIframeComponent} from './views/services/service-iframe/service-iframe.component';
import {SecurityProviderComponent} from './views/security-provider/security-provider.component';
import {DeactivatedGuard} from './shared/services/deactivate-guard.service';
import {ClaimCardComponent} from './views/user/claim-card/claim-card.component';
import {ClaimDataComponent} from './views/user/claim-data/claim-data.component';
import {MoreSecurityOtpExplainComponent} from './views/more/more-security/more-security-otp/more-security-otp-explain/more-security-otp-explain.component';
import {MoreSecurityOtpComponent} from './views/more/more-security/more-security-otp/more-security-otp.component';
import {MoreSecurityChangePinComponent} from './views/more/more-security/more-security-change-pin/more-security-change-pin.component';
import {ScanShareComponent} from './views/scan/scan-share/scan-share.component';
import {
  ActivationStepAskDeviceNameComponent
} from './views/activation/steps/activation-step-ask-device-name/activation-step-ask-device-name.component';
import {PersonnalData} from './views/user/personnal-data/personnal-data.component';
import {PersonnalDatasExplainComponent} from './views/user/personnal-data/personnal-data-explain/personnal-data-explain.component';
import {PersonnalDataTypeComponent} from './views/user/personnal-data/personnal-data-type/personnal-data-type.component';
import {PersonnalDataSubTypeComponent} from './views/user/personnal-data/personnal-data-type/personnal-data-subtype/personnal-data-subtype.component';
import {PersonnalDataAllComponent} from './views/user/personnal-data/personnal-data-all/personnal-data-all.component';
import {ActivationStepFingerprintComponent} from './views/activation/steps/activation-step-fingerprint/activation-step-fingerprint.component';
import {PersonnalDataManageComponent} from './views/user/personnal-data/personnal-data-manage/personnal-data-manage.component';
import {PersonnalDataManageIdComponent} from './views/user/personnal-data/personnal-data-manage/personnal-data-manage-id/personnal-data-manage-id.component';
import {PersonnalDataManageRefusalComponent} from './views/user/personnal-data/personnal-data-manage/personnal-data-manage-refusal/personnal-data-manage-refusal.component';
import {SubscriptionComponent} from './views/subscription/subscription.component';
import {ActivationStepVoucherComponent} from './views/activation/steps/activation-step-voucher/activation-step-voucher.component';
import {UpdateVersionComponent} from './views/update-version/update-version.component';
import {NewsSelectComponent} from './views/news/news-select/news-select.component';
import {ScanShareResultComponent} from './views/scan/scan-share-result/scan-share-result.component';
import {
  ActivationStepDescTakeFacePictureComponent
} from './views/activation/steps/activation-step-desc-take-face-picture/activation-step-desc-take-face-picture.component';

const routes: Routes = [
  {
    path: 'card-generator',
    loadChildren: () => import('./views/card-generator/card-generator.module').then(m => m.CardGeneratorModule)
  },
  {
    path: 'manage',
    loadChildren: () => import('./views/manage/manage.module').then(m => m.ManageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'update-version',
    component: UpdateVersionComponent,
    canActivate: [UserValidGuardService],
    data: {animationState: 'updateVersion', noPin: true, acceptNoNNI: true}
  },
  {
    path: 'device-permissions',
    component: DevicePermissionsComponent,
    canActivate: [UserValidGuardService],
    data: {animationState: 'devicePermissions', acceptNoNNI: true}
  },
  {
    path: 'change-password',
    component: ChangePasswordComponent,
    canActivate: [UserValidGuardService],
    data: {animationState: 'changePassword', requiredPin: [true], onlyOnline: true}
  },
  {
    path: 'user-permissions',
    component: UserPermissionsComponent,
    canActivate: [UserValidGuardService],
    data: {animationState: 'userPermissions', role: [UserPlatformRole.WORKER, UserPlatformRole.ADMIN]}
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [UserUnknownGuardService],
    data: {animationState: 'home'}
  },
  {
    path: 'sign-in',
    component: SignInComponent,
    canActivate: [UserUnknownGuardService],
    data: {animationState: 'signIn'}
  },
  {
    path: 'sign-up',
    component: SignUpComponent,
    canActivate: [UserUnknownGuardService],
    data: {animationState: 'signUp'}
  },
  {
    path: 'activation',
    component: ActivationComponent,
    canActivate: [UserValidGuardService],
    data: {animationState: 'activation', role: [UserPlatformRole.CUSTOMER], acceptNoNNI: true, infoInterval: 15000}
  },
  {
    path: 'activation-code-info',
    component: ActivationCodeInfoComponent,
    canActivate: [UserValidGuardService],
    data: {animationState: 'activationCodeInfo', role: [UserPlatformRole.CUSTOMER], acceptNoNNI: true, infoInterval: 15000}
  },
  {
    path: 'activation-code-map',
    component: ActivationCodeMapComponent,
    canActivate: [UserValidGuardService],
    data: {animationState: 'activationCodeMap', role: [UserPlatformRole.CUSTOMER], acceptNoNNI: true, infoInterval: 15000}
  },
  {
    path: 'activation-code',
    component: ActivationCodeComponent,
    canActivate: [UserValidGuardService],
    data: {animationState: 'activationCode', role: [UserPlatformRole.CUSTOMER], acceptNoNNI: true, infoInterval: 15000}
  },
  {
    path: 'activation-step-intro',
    component: ActivationStepIntroComponent,
    canActivate: [UserValidGuardService],
    data: {animationState: 'activationStepIntro', role: [UserPlatformRole.CUSTOMER, UserPlatformRole.ADMIN], acceptNoNNI: true}
  },
  {
    path: 'activation-step-choice',
    component: ActivationStepChoiceComponent,
    canActivate: [UserValidGuardService],
    data: {animationState: 'activationStepChoice', role: [UserPlatformRole.CUSTOMER, UserPlatformRole.ADMIN], acceptNoNNI: true}
  },
  {
    path: 'activation-step-scan-mrz',
    component: ActivationStepScanMrzComponent,
    canActivate: [UserValidGuardService],
    data: {animationState: 'activationStepScanMrz', role: [UserPlatformRole.CUSTOMER, UserPlatformRole.ADMIN], acceptNoNNI: true}
  },
  {
    path: 'activation-step-scan-mrz-choice',
    component: ActivationStepScanMrzChoiceComponent,
    canActivate: [UserValidGuardService],
    data: {animationState: 'activationStepScanMrzChoice', role: [UserPlatformRole.CUSTOMER, UserPlatformRole.ADMIN], acceptNoNNI: true}
  },
  {
    path: 'activation-step-scan-mrz-scan',
    component: ActivationStepScanMrzScanComponent,
    canActivate: [UserValidGuardService],
    data: {animationState: 'activationStepScanMrzScan', role: [UserPlatformRole.CUSTOMER, UserPlatformRole.ADMIN], acceptNoNNI: true}
  },
  {
    path: 'activation-step-scan-mrz-nfc',
    component: ActivationStepScanMrzNfcComponent,
    canActivate: [UserValidGuardService],
    data: {animationState: 'activationStepScanMrzNfc', role: [UserPlatformRole.CUSTOMER, UserPlatformRole.ADMIN], acceptNoNNI: true}
  },
  {
    path: 'activation-step-doc-number',
    component: ActivationStepDocNumberComponent,
    canActivate: [UserValidGuardService],
    data: {animationState: 'activationStepDocNumber', role: [UserPlatformRole.CUSTOMER, UserPlatformRole.ADMIN], acceptNoNNI: true}
  },
  {
    path: 'activation-step-doc-exp-date',
    component: ActivationStepDocExpDateComponent,
    canActivate: [UserValidGuardService],
    data: {animationState: 'activationStepDocExpDate', role: [UserPlatformRole.CUSTOMER, UserPlatformRole.ADMIN], acceptNoNNI: true}
  },
  {
    path: 'activation-step-date-of-birth',
    component: ActivationStepDateOfBirthComponent,
    canActivate: [UserValidGuardService],
    data: {animationState: 'activationStepDateOfBirth', role: [UserPlatformRole.CUSTOMER, UserPlatformRole.ADMIN], acceptNoNNI: true}
  },
  {
    path: 'activation-step-draw-signature',
    component: ActivationStepDrawSignatureComponent,
    canActivate: [UserValidGuardService],
    data: {animationState: 'activationStepDrawSignature', role: [UserPlatformRole.CUSTOMER, UserPlatformRole.ADMIN], acceptNoNNI: true}
  },
  {
    path: 'activation-step-take-face-picture',
    component: ActivationStepTakeFacePictureComponent,
    canActivate: [UserValidGuardService],
    data: {animationState: 'activationStepTakeFacePicture', role: [UserPlatformRole.CUSTOMER, UserPlatformRole.ADMIN], acceptNoNNI: true}
  },
  {
    path: 'activation-step-ask-can',
    component: ActivationStepAskCanComponent,
    canActivate: [UserValidGuardService],
    data: {animationState: 'activationStepAskCan', role: [UserPlatformRole.CUSTOMER, UserPlatformRole.ADMIN], acceptNoNNI: true}
  },
  {
    path: 'activation-step-ask-pin',
    component: ActivationStepAskPinComponent,
    canActivate: [UserValidGuardService],
    data: {animationState: 'activationStepAskPin', role: [UserPlatformRole.CUSTOMER, UserPlatformRole.ADMIN], acceptNoNNI: true}
  },
  {
    path: 'activation-step-ask-nni',
    component: ActivationStepAskNniComponent,
    canActivate: [UserValidGuardService],
    data: {animationState: 'activationStepAskNni', role: [UserPlatformRole.CUSTOMER, UserPlatformRole.ADMIN], acceptNoNNI: true}
  },
  {
    path: 'activation-step-ask-device-name',
    component: ActivationStepAskDeviceNameComponent,
    canActivate: [UserValidGuardService],
    data: {animationState: 'activationStepAskDeviceName', role: [UserPlatformRole.CUSTOMER, UserPlatformRole.ADMIN], acceptNoNNI: true}
  },
  {
    path: 'activation-step-nfc-scan-id-card',
    component: ActivationStepNfcScanIdCardComponent,
    canActivate: [UserValidGuardService],
    data: {animationState: 'activationStepNfcScanIdCard', role: [UserPlatformRole.CUSTOMER, UserPlatformRole.ADMIN], acceptNoNNI: true}
  },
  {
    path: 'activation-step-fingerprint',
    component: ActivationStepFingerprintComponent,
    canActivate: [],
    data: {animationState: 'activationStepFingerprint', role: [UserPlatformRole.CUSTOMER, UserPlatformRole.ADMIN], acceptNoNNI: true}
  },
  {
    path: 'activation-step-voucher',
    component: ActivationStepVoucherComponent,
    canActivate: [UserValidGuardService],
    data: {animationState: 'activationStepVoucher', role: [UserPlatformRole.CUSTOMER, UserPlatformRole.ADMIN], acceptNoNNI: true}
  },
  {
    path: 'activation-step-confirm',
    component: ActivationStepConfirmComponent,
    canActivate: [UserValidGuardService],
    data: {animationState: 'activationStepConfirm', role: [UserPlatformRole.CUSTOMER, UserPlatformRole.ADMIN], acceptNoNNI: true}
  },
  {
    path: 'activation-step-done',
    component: ActivationStepDoneComponent,
    canActivate: [UserValidGuardService],
    data: {animationState: 'activationStepDone', role: [UserPlatformRole.CUSTOMER, UserPlatformRole.ADMIN], acceptNoNNI: true}
  },
  {
    path: 'activation-step-form-user',
    component: ActivationStepFormUserComponent,
    canActivate: [UserValidGuardService],
    data: {animationState: 'activationStepFormUser', role: [UserPlatformRole.ADMIN, UserPlatformRole.ADMIN]}
  },
  {
    path: 'activation-step-scan-qr',
    component: ActivationStepQrScanComponent,
    canActivate: [UserValidGuardService],
    data: {animationState: 'qrScan', role: [UserPlatformRole.ADMIN]}
  },
  {
    path: 'activation-step-desc-take-face-picture',
    component: ActivationStepDescTakeFacePictureComponent,
    canActivate: [UserValidGuardService],
    data: {animationState: 'activationStepDescTakeFacePicture', role: [UserPlatformRole.CUSTOMER, UserPlatformRole.ADMIN], acceptNoNNI: true}
  },
  {
    path: 'secret-code',
    component: SecretCodeComponent,
    canActivate: [UserValidGuardService],
    data: {animationState: 'secretCode', noPin: true}
  },
  {
    path: 'secret-code-valid',
    component: SecretCodeValidComponent,
    canActivate: [UserValidGuardService],
    data: {animationState: 'secretCodeValid', noPin: 'check'}
  },
  {
    path: 'forgot-pin',
    component: ForgotPinComponent,
    canActivate: [UserValidGuardService],
    data: {animationState: 'forgotPin', noPin: true, onlyOnline: true}
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    canActivate: [UserUnknownGuardService],
    data: {animationState: 'forgotPassword', allowJwt: true, onlyOnline: true}
  },
  {
    path: 'password-changed',
    component: PasswordChangedComponent,
    canActivate: [UserUnknownGuardService],
    data: {animationState: 'passwordChanged', allowJwt: true}
  },
  {
    path: 'recover',
    component: RecoverComponent,
    canActivate: [UserUnknownGuardService],
    data: {animationState: 'recover', allowJwt: true, onlyOnline: true}
  },
  {
    path: 'user',
    component: UserComponent,
    canActivate: [UserValidGuardService],
    data: {animationState: 'user', claimsRequired: true, adminPlace: true}
  },
  {
    path: 'personnal-data',
    component: PersonnalData,
    canActivate: [UserValidGuardService],
    data: {animationState: 'personnalData', selfClaimsRequired: true,  claimsRequired: true, onlyOnline: true, refetchSelfClaims: true}
  },
  {
    path: 'personnal-data-all',
    component: PersonnalDataAllComponent,
    canActivate: [UserValidGuardService],
    data: {animationState: 'personnalDataAll', selfClaimsRequired: true,  claimsRequired: true}
  },
  {
    path: 'personnal-data/:type',
    component: PersonnalDataTypeComponent,
    canActivate: [UserValidGuardService],
    data: {animationState: 'personnalDataType', selfClaimsRequired: true,  claimsRequired: true, onlyOnline: true}

  },
  {
    path: 'personnal-data/:type/:subType',
    component: PersonnalDataSubTypeComponent,
    canActivate: [UserValidGuardService],
    data: {animationState: 'personnalDataSubType', selfClaimsRequired: true,  claimsRequired: true, onlyOnline: true}
  },
  {
    path: 'personnal-data-manage',
    component: PersonnalDataManageComponent,
    canActivate: [UserValidGuardService],
    data: {animationState: 'personnalDataManage', selfClaimsRequired: true,  claimsRequired: true, onlyOnline: true, refetchSelfClaims: true}
  },
  {
    path: 'personnal-data-manage/:id',
    component: PersonnalDataManageIdComponent,
    canActivate: [UserValidGuardService],
    data: {animationState: 'personnalDataManageId', selfClaimsRequired: true,  claimsRequired: true, onlyOnline: true}
  },
  {
    path: 'personnal-data-manage-refusal/:id',
    component: PersonnalDataManageRefusalComponent,
    canActivate: [UserValidGuardService],
    data: {animationState: 'personnalDataManageRefusal', selfClaimsRequired: true,  claimsRequired: true, onlyOnline: true}
  },
  {
    path: 'personnal-data-explain',
    component: PersonnalDatasExplainComponent,
    canActivate: [UserValidGuardService],
    data: {animationState: 'personnalDataExplain', onlyOnline: true}
  },
  {
    path: 'claim-card',
    component: ClaimCardComponent,
    canActivate: [UserValidGuardService],
    data: {animationState: 'claimCard', claimsRequired: true, role: [UserPlatformRole.CUSTOMER]}
  },
  {
    path: 'claim-data',
    component: ClaimDataComponent,
    canActivate: [UserValidGuardService],
    data: {animationState: 'claimData', claimsRequired: true, role: [UserPlatformRole.CUSTOMER]}
  },
  {
    path: 'share-claim',
    component: ShareClaimComponent,
    canActivate: [UserValidGuardService],
    data: {animationState: 'shareClaim', claimsRequired: true, role: [UserPlatformRole.CUSTOMER], requiredPin: [true], subscriptionRequired: true}
  },
  {
    path: 'share-claim-mode',
    component: ShareClaimModeComponent,
    canActivate: [UserValidGuardService],
    data: {animationState: 'shareClaimMode', claimsRequired: true, role: [UserPlatformRole.CUSTOMER], subscriptionRequired: true}
  },
  {
    path: 'share-claim-done',
    component: ShareClaimDoneComponent,
    canActivate: [UserValidGuardService],
    data: {animationState: 'shareClaimDone', claimsRequired: true, role: [UserPlatformRole.CUSTOMER], subscriptionRequired: true}
  },
  {
    path: 'news/:id',
    component: NewsSelectComponent,
    canActivate: [UserValidGuardService],
    data: {animationState: 'newsSelect', onlyOnline: true}
  },
  {
    path: 'services',
    component: ServicesComponent,
    canActivate: [UserValidGuardService],
    data: {animationState: 'services'}
  },
  {
    path: 'service-iframe',
    component: ServiceIframeComponent,
    canActivate: [UserValidGuardService],
    data: {animationState: 'serviceIframe'}
  },
  {
    path: 'sign-in-pro',
    component: SignInProComponent,
    canActivate: [UserValidGuardService],
    data: {animationState: 'signInPro', requiredPin: ['/choose-account'], onlyOnline: true}
  },
  {
    path: 'choose-account',
    component: ChooseAccountComponent,
    canActivate: [UserValidGuardService],
    data: {animationState: 'chooseAccount', requiredPin: ['/sign-in-pro']}
  },
  {
    path: 'choose-place',
    component: ChoosePlaceComponent,
    canActivate: [UserValidGuardService],
    data: {animationState: 'choosePlace', role: [UserPlatformRole.WORKER, UserPlatformRole.ADMIN], places: true}
  },
  {
    path: 'badge',
    component: BadgeComponent,
    canActivate: [UserValidGuardService],
    data: {animationState: 'badge', role: [UserPlatformRole.WORKER, UserPlatformRole.ADMIN], onlyOnline: true}
  },
  {
    path: 'more',
    component: MoreComponent,
    canActivate: [UserValidGuardService],
    data: {animationState: 'more'}
  },
  {
    path: 'more-security',
    component: MoreSecurityComponent,
    canActivate: [UserValidGuardService],
    data: {animationState: 'moreSecurity'}
  },
  {
    path: 'choose-lang',
    component: ChooseLangComponent,
    canActivate: [],
    data: {animationState: 'chooseLang'}
  },
  {
    path: 'news',
    component: NewsComponent,
    canActivate: [UserValidGuardService],
    data: {animationState: 'news'}
  },
  {
    path: 'manage-user',
    component: ManageUserComponent,
    canActivate: [UserValidGuardService],
    data: {animationState: 'manageUser', role: [UserPlatformRole.ADMIN], onlyOnline: true}
  },
  {
    path: 'scan',
    component: ScanComponent,
    canActivate: [UserValidGuardService],
    data: {animationState: 'scan'}
  },
  {
    path: 'scan-share',
    component: ScanShareComponent,
    canActivate: [UserValidGuardService],
    data: {animationState: 'scanShare', onlyOnline: true}
  },
  {
    path: 'scan-result',
    component: ScanResultComponent,
    canActivate: [UserValidGuardService],
    data: {animationState: 'scanResult'}
  },
  {
    path: 'scan-share-result',
    component: ScanShareResultComponent,
    canActivate: [UserValidGuardService],
    data: {animationState: 'scanShareResult', requiredPin: ['/scan-share'], onlyOnline: true}
  },
  {
    path: 'welcome-hello',
    component: WelcomeHelloComponent,
    canActivate: [UserValidGuardService],
    data: {animationState: 'welcomeHello'}
  },
  {
    path: 'welcome-explain',
    component: WelcomeExplainComponent,
    canActivate: [UserValidGuardService],
    data: {animationState: 'welcomeExplain'}
  },
  {
    path: 'welcome-biometric',
    component: WelcomeBiometricComponent,
    canActivate: [UserValidGuardService],
    data: {animationState: 'welcomeBiometric'}
  },
  {
    path: 'choose-activity',
    component: MoreActivityComponent,
    canActivate: [UserValidGuardService],
    data: {animationState: 'moreActivity', onlyOnline: true}
  },
  {
    path: 'more-security-change-pin',
    component: MoreSecurityChangePinComponent,
    canActivate: [UserValidGuardService],
    data: {animationState: 'changePin', role: [UserPlatformRole.CUSTOMER], onlyOnline: true}
  },
  {
    path: 'more-security-biometric',
    component: MoreSecurityBiometricComponent,
    canActivate: [UserValidGuardService],
    data: {animationState: 'moreSecurityBiometric'}
  },
  {
    path: 'welcome-done',
    component: WelcomeDoneComponent,
    canActivate: [UserValidGuardService],
    data: {animationState: 'welcomeDone'}
  },
  {
    path: 'subscription',
    component: SubscriptionComponent,
    canActivate: [UserValidGuardService],
    data: {animationState: 'subscription'}
  },
  {
    path: 'account-valid',
    component: AccountValidComponent,
    canActivate: [UserUnknownGuardService],
    data: {animationState: 'accountValid'}
  },
  {
    path: 'security-provider',
    component: SecurityProviderComponent,
    data: {animationState: 'securityProvider'},
    canDeactivate: [DeactivatedGuard]
  },
  {
    path: 'more-security-otp-explain',
    component: MoreSecurityOtpExplainComponent,
    data: {animationState: 'moreSecurityOtpExplain', onlyOnline: true},
    canActivate: [UserValidGuardService]
  },
  {
    path: 'more-security-otp',
    component: MoreSecurityOtpComponent,
    data: {animationState: 'moreSecurityOtp', onlyOnline: true},
    canActivate: [UserValidGuardService]
  },
  {
    path: '**',
    redirectTo: 'home',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {useHash: true, scrollPositionRestoration: 'enabled'})
  ],
  exports: [
    RouterModule
  ],
  providers: [
    UserUnknownGuardService,
    UserValidGuardService
  ],
  declarations: []
})
export class AppRoutingModule {
}
