import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'enumToArray'
})
export class EnumToArrayPipe implements PipeTransform {
  transform(data: any) {
    const result = [];
    const keys = Object.keys(data);
    const values = Object.values(data);
    for(let i = 0; i < keys.length; i++) {
      result.push({key: keys[i], value: values[i]});
    }
    return result.slice(keys.length / 2);
  }
}
