<div id="layout" class="f_col layout_sm">
  <app-view-title bgImage="assets-countries/header_background.png" class="portrait_only">{{'scanResult.title' | translate}}</app-view-title>
  <div class="scroll_wrapper">
    <div [class.f_grow]="personnalDataShare.length === 0" class="f_grow relative claims_result" [class.claims_result-noCard]="scan.claims?.length === 1">
      <div  id="rotate_mobile" class="mt_20 mb_20" *ngIf="(scan.claims?.length === 1 && scan.claims[0].type !== api.ct.ADULT && scan.claims?.length === 1 && scan.claims[0].type !== api.ct.IDENTITY) || (scan.claims?.length !== 1 && personnalDataShare?.length === 0) && !this.scanBadgeData">
        <span class="mb_10">{{'identity_card.rotate' | translate}}</span>
        <fa-icon icon="mobile-alt"></fa-icon>
      </div>
      <div *ngFor="let claim of scan.claims" class="claims_item">
        <div *ngIf="claim?.type === api.ct.ID_CARD && scan.hasControl(api.ct.ID_CARD)">
          <app-card [imageRecto]="claim?.imageUrlRecto" [imageVerso]="claim?.imageUrlVerso"></app-card>
        </div>
        <div *ngIf="claim?.type === api.ct.DRIVING_LICENSE && scan.hasControl(api.ct.DRIVING_LICENSE)">
          <app-card [imageRecto]="claim?.imageUrlRecto" [imageVerso]="claim?.imageUrlVerso"></app-card>
        </div>
        <div *ngIf="claim?.type === api.ct.HEALTH_CARD && scan.hasControl(api.ct.HEALTH_CARD)">
          <app-card [imageRecto]="claim?.imageUrlRecto" [imageVerso]="claim?.imageUrlVerso"></app-card>
        </div>
        <div *ngIf="claim?.type === api.ct.TRANSPORT_CARD && scan.hasControl(api.ct.TRANSPORT_CARD)">
          <app-card [imageRecto]="claim?.imageUrlRecto" [imageVerso]="claim?.imageUrlVerso"></app-card>
        </div>
        <div class="mt_20" *ngIf="scan.claims?.length === 1 && scan.claims[0]?.type === api.ct.IDENTITY">
          <app-block icon="hashtag" [title]="('global.NNI' | translate) + ': ' + JSON.parse(scan?.claims[0]?.serialized)"></app-block>
        </div>
        <div id="claim_adult" class="f_center f_align_c" 
             *ngIf="(claim?.type === api.ct.ADULT && scan.claims?.length === 1) || (claim?.type === api.ct.ID_CARD && scan.hasControl(api.ct.ADULT))" 
             [class.claim_adult_valid]="checkAge(claim)?.adult">
          {{checkAge(claim)?.countryAge}}<span class="ml_4">+</span>
        </div>
      </div>

      <div class="claims_item mt_20 mb_40" *ngIf="scanBadgeData">
        <app-badge-card [badgeData]="scanBadgeData"></app-badge-card>
      </div>

      <div *ngIf="personnalDataShare.length > 0" class="f_col gap_20 mb_10 mt_20 fullWidth">
        <app-paragraph>{{'shared.data' | translate}}</app-paragraph>
        <div *ngFor="let item of personnalDataShare">
          <app-block [icon]="item?.stateIcon?.icon" [iconColor]="item?.stateIcon?.iconColor" [iconLeft]="false" title="{{item.title | translate}}" [arrayElements]="item?.arrayElement"></app-block>
        </div>
      </div>

    </div>
  </div>
  <app-back-btn position="botLeft" [navTo]="{route: 'scan'}" class="portrait_only"></app-back-btn>
</div>
