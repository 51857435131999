<div id="layout" class="f_col layout_sm" *ngIf="actualClaimSelf">
    <app-view-title bgImage="assets-countries/header_background.png">{{'personnal.verify.DYN.' + actualClaimSelf?.type | translate}}</app-view-title>
    <div class="scroll_wrapper">
        <app-block *ngIf="actualClaimSelf" class="mt_20" title="{{actualClaimSelf?.label  + '.DYN.title'| translate}}" [arrayElements]="actualClaimSelf?.arrayElement"></app-block>
        <app-slide-element *ngIf="actualClaimSelf?.proofDocument" class="mt_10" (btnClicked)="viewProof()">{{(this.actualProof.type === 'application/pdf' ? 'proof.download': 'proof.show') | translate}}</app-slide-element>
        <div class="flex f_col gap_10 mt_30">
          <app-button color="primary" (btnClicked)="approveClaimSelf()" class="fullWidth">{{'global.approve' | translate}}</app-button>
          <app-button color="red" (btnClicked)="nav.to('personnal-data-manage-refusal/' + actualClaimSelf?.id)" class="fullWidth">{{'global.refusal' | translate}}</app-button>
        </div>
    </div>
    <div class="f_align_end">
      <div class="fullWidth flex gap_10 mb_20">
        <app-back-btn (btnClicked)="nav.to('personnal-data-manage')"></app-back-btn>
      </div>
    </div>
  </div>
  