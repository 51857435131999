import {Component, ViewEncapsulation} from '@angular/core';
import {NavigateService} from 'ngx-satoris';
import {ApiService} from '../../shared/services/api.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-password-changed',
  templateUrl: './password-changed.component.html',
  styleUrls: ['./password-changed.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PasswordChangedComponent {
  data: {proAccount: boolean};

  constructor(public nav: NavigateService, 
    public api: ApiService,
    private router: Router) {
    this.data = <any> this.router.getCurrentNavigation().extras.state;

  }
}
