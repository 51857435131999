import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {LangService, LoaderService, NavigateService} from 'ngx-satoris';
import {ActivityService} from 'src/app/shared/services/activity.service';
import {ApiService} from 'src/app/shared/services/api.service';
import {ActivatedRoute} from '@angular/router';
import {SyncService} from 'src/app/shared/services/sync.service';

@Component({
  selector: 'app-more-activity',
  templateUrl: './more-activity.component.html',
  styleUrls: ['./more-activity.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MoreActivityComponent implements OnInit, OnDestroy {
  activities: any;
  filterActivities: any;
  type: 'account' | 'shared';

  constructor(public api: ApiService,
    public activity: ActivityService,
    private route: ActivatedRoute,
    private loader: LoaderService,
    private formBuilder: FormBuilder,
    private sync: SyncService,
    private lang: LangService,
    private nav: NavigateService) {
    this.route.queryParams
      .subscribe(params => {
        this.type = params && (params.type);
      });
  }

  form: FormGroup;

  ngOnInit(): void {
    if(!this.sync.isOnline) {
      this.loader.loading(true, {type: 'info', message: this.lang.transform('no_connection'), btnLabel: 'confirm'}).then(() => {
        this.nav.to('more');
      });
    }
    this.loader.loading(true);
    this.activity.getActivities(this.type).then(()=>{
      this.loader.loading(false);
    });
    this.setForm();
  }

  ngOnDestroy(): void {
    this.activity.resetActivities();
  }

  setForm() {
    this.form = this.formBuilder.group({
      search: ['']});
  }

}


