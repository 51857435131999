import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {LangService, LoaderService, NavigateService} from 'ngx-satoris';
import {ClaimSelfAlt, ClaimSelfDetails, ClaimSelfForAdmin} from 'src/app/shared/models/claim';
import {ApiService} from 'src/app/shared/services/api.service';
import {PersonnalDatasService} from 'src/app/shared/services/personnal-datas.service';

@Component({
  selector: 'app-personnal-data-manage-refusal',
  templateUrl: './personnal-data-manage-refusal.component.html',
  styleUrls: ['./personnal-data-manage-refusal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PersonnalDataManageRefusalComponent implements OnInit {

  form: FormGroup;
  actualClaimSelf: ClaimSelfForAdmin;
  allArray: ClaimSelfDetails[];
  refusalProofMode = false;
  
  constructor(public api: ApiService, 
    public nav: NavigateService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private loader: LoaderService,
    private lang: LangService,
    public personnalDatas: PersonnalDatasService) {
    this.route.params.subscribe(params => {
      this.actualClaimSelf = this.personnalDatas.allCustomerSelfClaims.find(item => item.id === params.id);
      const claimSelfToCheck = Object.keys(this.personnalDatas.countryClaims).map(key => {
        if(key in ClaimSelfAlt) {
          return (this.personnalDatas.countryClaims[key] as any)[this.actualClaimSelf.type]; 
        } else {
          return (this.personnalDatas.countryClaims as any)[+this.actualClaimSelf.type];
        }
      }).filter(item => item !== undefined)[0];
      this.allArray = claimSelfToCheck.metadataValue;
    });
  }

  ngOnInit() {
    this.setForm();
  }

  setForm() {
    this.form = this.formBuilder.group({});
    if(!this.refusalProofMode) {
      this.allArray.forEach(item => {
        this.form.addControl(item.label, this.formBuilder.control({
          value: '',
          disabled: false
        }, []));
      });
    } else {
      this.form.addControl('reasonMessage', this.formBuilder.control({
        value: this.lang.transform('proof.error.default'),
        disabled: false
      }, []));
    }
  }
  

  submitForm() {
    this.loader.loading(true, {type: 'warn', message: this.lang.transform('refusal.ask'), btnLabel: this.lang.transform('global.confirm')}).then((res: boolean) => {
      if(res){
        let refusalReason:  ClaimSelfDetails['state']['rejectReason'];
        if(this.refusalProofMode) {
          refusalReason = {
            reasonMessage: this.form.value.reasonMessage,
            labelRefusal: []
          };
        } else { 
          refusalReason = {
            reasonMessage: '',
            labelRefusal: Object.keys(this.form.value).filter(key => this.form.value[key] === true)
          };
        }
        this.loader.loading(true);
        this.api.judgeSelfClaim(this.actualClaimSelf.id, this.api.userPlaceId, undefined, refusalReason).then(() => {
          this.nav.to('personnal-data-manage');
          setTimeout(() => {
            this.loader.loading(true, {type: 'valid', message: 'refusalClaimSelf.success'});
          }, 500);
        });
      }
    });
  }

  changeStateRefusal() {
    this.refusalProofMode =! this.refusalProofMode;
    this.setForm();
  }
}
