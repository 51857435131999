<div id="layout" class="f_col" [class.layout_sm]="!api.userRole?.isCustomer">
  <app-view-title bgImage="assets-countries/header_background.png" fromRoute="activation-step-desc-take-face-picture" toRoute="choose-lang" [chooseLangBtn]="api.userRole?.isCustomer && true">
    {{'activation.step.descFaceCheck.title' | translate}}
  </app-view-title>
  <div class="scroll_wrapper">
    <app-view-deco-image maxWidth="300px" imageUrl="assets-countries/faceCheck.svg"></app-view-deco-image>
    <app-paragraph class="mb_20" [innerhtml]="('activation.step.descFaceCheck.DYN' + (api.userRole?.isAdmin ? '.admin' : '')) | translate : {country: ('form.DYN.country.' + api.env.country) | translate}"></app-paragraph>
    <div class="f_align_end f_grow ">
      <app-back-btn (btnClicked)="enroll.navigate('previous')"></app-back-btn>
      <div class="fullWidth ml_10">
        <app-button (btnClicked)="enroll.navigate('next')" class="fullWidth">
          {{'global.continue' | translate}}
        </app-button>
      </div>
    </div>
  </div>
</div>
