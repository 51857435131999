import {Component, ViewEncapsulation} from '@angular/core';
import {ApiService} from '../../../../shared/services/api.service';
import {EnrollService} from '../../../../shared/services/enroll.service';
import {InitializeService} from 'src/app/shared/services/initialize.service';
import {LoaderService, NavigateService} from 'ngx-satoris';

@Component({
  selector: 'app-activation-step-done',
  templateUrl: './activation-step-done.component.html',
  styleUrls: ['./activation-step-done.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ActivationStepDoneComponent{
  constructor(public api: ApiService,
    public enroll: EnrollService,
    private nav: NavigateService,
    private init: InitializeService,
    private loader: LoaderService) {
    if(this.api.userInfo.nationalNumber && this.api.userRole.isCustomer) {
      this.api.setPrincipalMode = true;
    }
  }

  ngOnDestroy() {
    this.api.setPrincipalMode = false;
  }

  done() {
    if(this.api.setPrincipalMode){
      const finalise = (isLogout = false) => {
        this.loader.loading(true);
        return this.api.info().then(() => {
          this.loader.loading(false);
          this.api.setPrincipalMode = false;
          this.nav.to('user');
          if(isLogout){
            setTimeout(() => {
              this.loader.loading(true, {type: 'valid', message: 'success.logoutAllDevices'});
            }, 500);
          }
        });
      };
      this.loader.loading(true, {type: 'question', message: 'ask.logoutAllDevices', btnLabel: 'global.disconnect', custom: {closeBtnLabel: 'global.no'}}).then((res: boolean) => {
        if(res) {
          return this.api.logoutAllDevices().then((isLogout: boolean) =>{
            if(isLogout) {
              return finalise(true);
            } else {
              this.loader.loading(true, {type: 'error', message: 'error.logoutAllDevices'}).then(() => finalise());
            }
          });
        } else {
          return finalise();
        }
      });
    } else {
      if(!this.api.userRole.isCustomer) {
        this.nav.to('user');
      } else {
        this.init.triggerSplashScreen('card.activating');
        setTimeout(() => {
          this.api.info().then(() => {
            this.nav.to('user');
          });
        }, 5000);
      }
    }
  }
}
