import {Component, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {LoaderService, NavigateService} from 'ngx-satoris';
import {ScanShareData} from 'src/app/shared/models/claim';
import {ApiService} from 'src/app/shared/services/api.service';

@Component({
  selector: 'app-scan-share-result',
  styleUrls: ['./scan-share-result.component.scss'],
  templateUrl: './scan-share-result.component.html',
  encapsulation: ViewEncapsulation.None
})
export class ScanShareResultComponent {
  data: ScanShareData;
  processing = true;

  constructor(public nav: NavigateService,
              public api: ApiService,
              private router: Router,
              private loader: LoaderService) {
    this.data = <any> this.router.getCurrentNavigation().extras.state;
    if(!this.data || this.data.claims?.length !== this.api.userInfo.server.callbacks[this.data.service].types.length) {
      this.error();
      return;
    } else {
      setTimeout(() => {
        Promise.all(this.data.claims.map(c => this.api.shareClaim(c.id, 60000))).then(readUuids => {
          const shareClaims = readUuids.map((r, i) => ({
            type: this.data.claims[i].type,
            id: this.data.claims[i].id,
            uuid: r
          }));
          return this.api.shareCallback(this.data.service, this.data.requestId, shareClaims).then(() => {
            this.processing = false;
            this.nav.to('user');
            setTimeout(() => {
              this.loader.loading(true, {type: 'valid', message: 'api.postClaim.success'});
            }, 300);
          }).catch(() => {
            this.processing = false;
            this.error();
          });
        }).catch(() => {
          this.processing = false;
          this.error();
        });
      }, 500);
    }
  }

  error() {
    this.nav.to('user');
    setTimeout(() => {
      this.loader.loading(true, {type: 'error', message: 'api.postClaim.error'});
    }, 300);
  }
}
