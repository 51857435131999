import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AccountType, HtmlTemplates, OtpType} from 'src/app/shared/models/user';
import {ApiService} from 'src/app/shared/services/api.service';
import {
  FormService,
  LangService,
  LoaderService,
  NavigateService
} from 'ngx-satoris';
import {getStoredItem, setStoredItem} from 'src/app/shared/utils/storage';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChangePasswordComponent implements OnInit {
  form: FormGroup;
  submitted: boolean;
  showOtp = false;
  otp = '';
  showOTPError: boolean;
  OtpType = OtpType;
  showOTPCorrect: boolean;
  pswdStrength: number;
  currentSubmit: boolean;
  accounts: AccountType[] = getStoredItem('jwts') || [];

  constructor(public forms: FormService,
    public api: ApiService,
    public nav: NavigateService,
    public loader: LoaderService,
    private formBuilder: FormBuilder,
    private lang: LangService) {
  }

  ngOnInit(): void {
    this.setForm();
  }

  setForm() {
    this.form = this.formBuilder.group({
      oldPassword: ['', [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(30)
      ]],
      password: ['', [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(30)
      ]],
      password2: ['',
        Validators.required
      ]
    }, {
      validator: [
        this.forms.mustMatch('oldPassword', 'password', true),
        this.forms.mustMatch('password', 'password2'),
        this.forms.customPatternValidator('password', [/[A-Z]/, /[a-z]/, /[0-9]/, /[~`!@#$%^&*()\-_+={[}\]|:;"'<,>.?/]/], 3)
      ]
    });
  }

  submitForm() {
    this.submitted = true;
    if(this.forms.getFormErrors(this.form).length < 1 && this.submitted) {
      this.verifyOldPassword();
    }
  }

  verifyOldPassword() {
    this.loader.loading(true);
    this.api.consumeLoginWeak(this.api.userRole.isCustomer ? this.api.userInfo.accountName : this.api.userInfo.identifier,
      this.form.get('oldPassword').value, undefined,
      this.api.userPlaceId ? this.api.userPlaceId : undefined, undefined, undefined, undefined, false, HtmlTemplates.CHANGE_PASSWORD)
      .then(() => {
        if(this.api.userRole.isCustomer) {
          this.launchOtp();
        } else {
          this.setPassword();
        }
      })
      .catch((err) => {
        this.submitted = false;
        if(err.includes('user.otpTooSoon')) {
          this.loader.loading(true, {type: 'error', message: 'wait.otpCode'});
        } else {
          this.loader.loading(true, {type: 'error', message: 'change-password.wrong_oldpass'});
        }
      });
  }

  launchOtp() {
    this.loader.loading(false);
    this.showOtp = true;
  }

  checkOTPCode(e: string[]) {
    const codeOk = e.length >= 6;
    this.otp = e.join('');

    if(codeOk && !this.currentSubmit) {
      this.currentSubmit = true;
      this.validateOtp();
      this.loader.loading(true);
    }
  }

  validateOtp() {
    this.showOTPError = false;
    this.showOTPCorrect = false;
    this.api.consumeLoginWeak(this.api.userRole.isCustomer ? this.api.userInfo.accountName : this.api.userInfo.identifier, undefined, this.otp, undefined, undefined, undefined, false, true)
      .then(() => {
        this.showOTPCorrect = true;
        this.setPassword();
      })
      .catch(() => {
        this.showOTPError = true;
        setTimeout(() => {
          this.loader.loading(true, {type: 'error', message: this.lang.transform(this.api.userInfo.otpType === OtpType.EMAIL ? 'otp.email.changePassword.error' : 'otp.sms.changePassword.error')})
            .then(() => {
              this.showOTPError = false;
              this.currentSubmit = false;
            });
        }, 500);
      });
  }

  setPassword() {
    this.api.setPassword(this.form.get('password').value)
      .then(() => {
        this.loader.loading(true, {type: 'valid', message: this.lang.transform(this.api.userRole.isCustomer ? 'change-password-done.desc' : 'change-password-done.pro.desc')})
          .then(() => {
            if(this.api.userRole.isCustomer) {
              this.api.signOut();
            } else {
              const updatedJwts = getStoredItem('jwts').map((storedAccount: AccountType) => storedAccount.email === this.api.account.email ? {...this.api.account, jwt: ''} : storedAccount);
              this.api.signOut(false); 
              this.api.accountJwt = this.api.getCustomerAccount();
              setStoredItem('jwts', updatedJwts);
              this.api.checkJwt().then(() => {
                this.nav.to('user');
                setTimeout(() => {
                  this.loader.loading(true, {type: 'info', message: this.lang.transform('admin.disconnect')});
                }, 500);
              });
            }
          });
      })
      .catch(() => {
        this.submitted = false;
        this.loader.loading(true, {type: 'error', message: 'change-password.newpass_error'});
      });
  }

  checkPswdStrength() {
    this.pswdStrength = this.forms.checkPswdStrength(this.form.get('password').value);
  }

  resetOtp() {
    this.otp = '';
    this.showOtp = false;
    this.form.get('oldPassword').setValue('');
    this.form.get('password').setValue('');
    this.form.get('password2').setValue('');
    this.submitted = false;
  }
}
