<div id="layout" class="f_col layout_dk">
  <img id="custom_bg" ngSrc="assets-countries/home.png" width="200" height="200">
  <h2 class="text_center mt_40">{{'scanShare.title' | translate}}</h2>
  <app-paragraph class="mt_10" textAlign="center">{{api.userInfo.server.callbacks[this.data.service].name}} {{'scanShare.desc' | translate}}</app-paragraph>
  <div *ngIf="claims" class="card_carousel mt_20" (click)="doUnfold()" [class.folded]="!unfold" [class.accepted]="accepted">
    <div class="card_carousel_card_wrapper" [ngStyle]="{'animation-delay': unfold ? '400ms': '0ms'}">
      <div *ngFor="let claim of claims; let i = index" class="card_carousel_card" [class.card_sm]="claim.type === api.ct.ADULT" [ngStyle]="{'transform': !unfold && !accepted ? 'rotate(' + claimsRotations[i] + 'deg)' : !unfold && accepted ? 'rotate(' + claimsRotationsSmall[i] + 'deg)' : 'none'}">
        <app-card *ngIf="claim.imageUrlRecto" [noPointerEvents]="true" size="sm" [imageRecto]="claim.imageUrlRecto" [imageVerso]="claim.imageUrlVerso"></app-card>
        <div *ngIf="claim.type === api.ct.ADULT" id="claim_adult" class="f_center f_align_c" [class.claim_adult_valid]="checkAge().adult">
          {{checkAge().countryAge}}<span class="ml_4">+</span>
        </div>
      </div>
    </div>
    <div *ngIf="claims.length > 1 && !unfold && !accepted" class="f_grow f_col f_end">{{'scanShare.touch' | translate}}</div>
  </div>
  <div class="f_between gap_10 mb_10 mt_40">
    <app-button color="warn" [disabled]="accepted" (btnClicked)="nav.to('user')" class="fullWidth">{{'global.refuse' | translate}}</app-button>
    <app-button color="primary" [disabled]="accepted" (btnClicked)="accept()" class="fullWidth">{{'global.accept' | translate}}</app-button>
  </div>
</div>
