<div id="layout" class="f_col layout_sm">
  <div *ngIf="article" class="scroll_wrapper pt_30">
    <div class="flex f_between f_align_c">
      <h1>{{article.title}}</h1>
      <span>{{I18nLang[article.lang]}}</span>
    </div>
    <h3>{{article.description}}</h3>
    <div class="f_grow">
      <div id="article_wrapper" [innerHTML]="article.link || article.content"></div>
    </div>
  </div>
  <div class="flex mt_20 mb_10">
    <app-back-btn [navTo]="{route: data?.fromRoute}"></app-back-btn>
    <div class="fullWidth ml_10">
      <app-button *ngIf="api.userRole.isAdmin" class="fullWidth" (btnClicked)="nav.to('manage/manage-news-new', undefined, {state: {article: article}})">{{'global.edit' | translate}} <fa-icon class="ml_10" icon="pen-to-square"></fa-icon></app-button>
    </div>
  </div>
</div>
