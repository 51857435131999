<div id="layout" class="f_col layout_sm">
    <app-view-title bgImage="assets-countries/header_background.png">{{'personnalData.title' | translate}}</app-view-title>
    <div class="scroll_wrapper">
    <div class="mt_20">
        <div *ngFor="let item of subType">
            <app-slide-element [stateIcon]="stateVisual(item?.type)?.icon" [colorStateIcon]="stateVisual(item?.type)?.color"  [leftIcon]="item?.icon" (btnClicked)="nav.to('personnal-data/'+ this.type + '/' + item?.type)" class="mb_10">
                {{(item?.label +  '.DYN.title') | translate}}
              </app-slide-element>
        </div>
    </div>
    </div>
    <div class="f_align_end">
      <div class="fullWidth flex gap_10 mb_20">
        <app-back-btn (btnClicked)="nav.to('personnal-data')"></app-back-btn>
      </div>
    </div>
  </div>
  