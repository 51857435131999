import {Component, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {NavigateService} from 'ngx-satoris';
import {ApiService} from 'src/app/shared/services/api.service';

@Component({
  selector: 'app-personnal-data-explain',
  templateUrl: './personnal-data-explain.component.html',
  styleUrls: ['./personnal-data-explain.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PersonnalDatasExplainComponent {

  fromRoute: string;
  constructor(public api: ApiService,
    public nav: NavigateService,
    private router: Router) { 
    this.fromRoute = this.router.getCurrentNavigation().extras?.state?.fromRoute;
  }
}
