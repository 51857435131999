import {Component, ViewEncapsulation} from '@angular/core';
import {ApiService} from '../../shared/services/api.service';
import {LangService, LoaderService, NavigateService} from 'ngx-satoris';
import {SyncService} from '../../shared/services/sync.service';

@Component({
  selector: 'app-pro-toggle',
  templateUrl: './pro-toggle.component.html',
  styleUrls: ['./pro-toggle.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ProToggleComponent {
  constructor(public api: ApiService, 
    public nav: NavigateService, 
    public sync: SyncService,
    private loader: LoaderService,
    private lang: LangService) {
  }

  connectionExplain(){
    this.loader.loading(true, {type: 'warn', message: this.lang.transform('co.unstable'), custom: {icon: 'wifi'}});
  }
}
