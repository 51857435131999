import {Component, ViewEncapsulation} from '@angular/core';
import {ApiService} from '../../../shared/services/api.service';
import {DevicesService, NavigateService} from 'ngx-satoris';
import {BiometricService} from 'src/app/shared/services/biometric.service';

@Component({
  selector: 'app-welcome-explain',
  templateUrl: './welcome-explain.component.html',
  styleUrls: ['./welcome-explain.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WelcomeExplainComponent{

  constructor(public api: ApiService, public nav: NavigateService, public device: DevicesService, public biometric: BiometricService) { }

}
