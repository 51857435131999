<div id="layout" class="f_col" [class.layout_sm]="!api.userRole.isCustomer">
  <app-view-title bgImage="assets-countries/header_background.png" fromRoute="activation-step-choice" toRoute="choose-lang" [chooseLangBtn]="api.userRole.isCustomer && true">
    {{'activation.step.choice.title' | translate}}
  </app-view-title>
  <div class="scroll_wrapper">
    <app-view-deco-image maxWidth="300px" imageUrl="{{'assets-countries/activation.step.choice.img.DYN.' + enroll.getChoiceString() | translate}}.svg"></app-view-deco-image>
    <app-paragraph class="mb_10" [innerhtml]="'activation.step.choice' | translate"></app-paragraph>
    <app-paragraph class="mb_10" [innerhtml]="('activation.step.choice.DYN.' + enroll.getChoiceString()) | translate"></app-paragraph>
    <div class="f_align_end f_grow ">
      <app-back-btn (btnClicked)="enroll.navigate('previous')"></app-back-btn>
      <div class="fullWidth ml_10">
        <div *ngIf="enroll.stepsConfig[enroll.currentConfigIndex].type === 'CHOICE' && !(enroll.currentChoiceIndex >= 0)">
          <div *ngFor="let choice of enroll.getChoice()" class="mt_10">
            <app-button (btnClicked)="enroll.selectChoice(choice)">{{('activation.step.choice.DYN.' + choice) | translate}}</app-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
