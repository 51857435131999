export enum EnrollStep {
  ASK_CAN = 'ASK_CAN',
  ASK_PIN = 'ASK_PIN',
  ASK_NNI = 'ASK_NNI',
  ASK_DEVICE_NAME = 'ASK_DEVICE_NAME',
  NFC_SCAN_ID_CARD = 'NFC_SCAN_ID_CARD',
  QR_SCAN = 'QR_SCAN',
  DRAW_SIGNATURE = 'DRAW_SIGNATURE',
  DOC_NUMBER = 'DOC_NUMBER',
  DATE_OF_BIRTH = 'DATE_OF_BIRTH',
  DOC_EXPIRATION_DATE = 'DOC_EXPIRATION_DATE',
  TAKE_FACE_PICTURE = 'TAKE_FACE_PICTURE',
  CHOICE = 'CHOICE',
  CONFIRM = 'CONFIRM',
  SCAN_MRZ = 'SCAN_MRZ',
  SCAN_MRZ_CHOICE = 'SCAN_MRZ_CHOICE',
  SCAN_MRZ_SCAN = 'SCAN_MRZ_SCAN',
  SCAN_MRZ_NFC = 'SCAN_MRZ_NFC',
  MANAGE_CUSTOMER = 'MANAGE_CUSTOMER',
  FORM_USER = 'FORM_USER',
  FINGERPRINT = 'FINGERPRINT',
  VOUCHER = 'VOUCHER',
  DESC_TAKE_FACE_PICTURE = 'DESC_TAKE_FACE_PICTURE'
}

export enum MRZType {
  TD1 = 'TD1',
  TD3 = 'TD3'
}

export enum FC {
  CAN = 'CAN',
  PIN = 'PIN',
  NNI = 'NNI',
  NFC = 'NFC',
  MRZ = 'MRZ',
  DOC_NUMBER = 'DOC_NUMBER',
  DOC_EXPIRY_DATE = 'DOC_EXPIRY_DATE',
  DATE_OF_BIRTH = 'DATE_OF_BIRTH',
  SIGNATURE = 'SIGNATURE',
  FACE_PICTURE = 'FACE_PICTURE',
  FIRST_NAME = 'FIRST_NAME',
  MIDDLE_NAME = 'MIDDLE_NAME',
  LAST_NAME = 'LAST_NAME',
  BIRTH_PLACE = 'BIRTH_PLACE',
  GENDER = 'GENDER',
  HEIGHT = 'HEIGHT',
  PROFESSION = 'PROFESSION',
  COUNTRY = 'COUNTRY',
  NATIONALITY = 'NATIONALITY',
  PERSONAL_NUMBER = 'PERSONAL_NUMBER',
  ISSUE_PLACE = 'ISSUE_PLACE',
  ISSUE_DATE = 'ISSUE_DATE',
  DISTRICT = 'DISTRICT',
  DIVISION = 'DIVISION',
  SUB_LOCATION = 'SUBLOCATION',
  LOCATION = 'LOCATION',
  OCCUPATION = 'OCCUPATION',
  USER_ID = 'USER_ID',
  DEVICE_XY = 'DEVICE_XY',
  DEVICE_NAME = 'DEVICE_NAME',
  FINGERPRINT = 'FINGERPRINT',
  VOUCHER = 'VOUCHER'
}

interface EnrollFCInterface {
  pattern?: string;
  minLength?: number;
  maxLength?: number;
  min?: number;
  max?: number;
  required?: boolean;
}

export interface MrzNfcDataInterface {
  global_name?: string;
  birth_date?: string;
  country?: string;
  document_number?: string;
  date_of_issue?: string;
  expiry_date?: string;
  issuing_authority?: string;
  place_of_birth?: string;
  issuer?: string;
  image?: string;
}

export interface EnrollForm {
  [id: string]: string | {[id: string]: string}
}

export type StepConfig = {
  type: 'CHOICE',
  steps: EnrollStep[][]
} | {
  type: 'STEP',
  steps: EnrollStep[]
};

export const enrollFC: {[key: string]: EnrollFCInterface} = {
  [FC.FIRST_NAME]: {
    pattern: '[A-Za-zàáâãäåçèéêëìíîïðòóôõöùúûüýÿñÀÁÂÃÄÅÇÈÉÊËÌÍÎÏÐÒÓÔÕÖÙÚÛÜÝŸÑ\'-\\s]+',
    required: true
  },
  [FC.MIDDLE_NAME]: {
    pattern: '[A-Za-zàáâãäåçèéêëìíîïðòóôõöùúûüýÿñÀÁÂÃÄÅÇÈÉÊËÌÍÎÏÐÒÓÔÕÖÙÚÛÜÝŸÑ\'-\\s]+'
  },
  [FC.LAST_NAME]: {
    pattern: '[A-Za-zàáâãäåçèéêëìíîïðòóôõöùúûüýÿñÀÁÂÃÄÅÇÈÉÊËÌÍÎÏÐÒÓÔÕÖÙÚÛÜÝŸÑ\'-\\s]+',
    required: true
  },
  [FC.BIRTH_PLACE]: {
    pattern: '[a-zA-Z-]+',
    required: true
  },
  [FC.GENDER]: {
    required: true
  },
  [FC.HEIGHT]: {
    required: true,
    max: 300
  },
  [FC.PROFESSION]: {
    pattern: '[a-zA-Z \'-\\s()]+',
    required: true
  },
  [FC.COUNTRY]: {
    required: true
  },
  [FC.NATIONALITY]: {
    pattern: '[a-zA-Z-]+',
    required: true
  },
  [FC.PERSONAL_NUMBER]: {
    required: true
  },
  [FC.ISSUE_PLACE]: {
    required: true
  },
  [FC.ISSUE_DATE]: {
    required: true
  },
  [FC.DISTRICT]: {
    required: true
  },
  [FC.DIVISION]: {
    required: true
  },
  [FC.SUB_LOCATION]: {
    required: true
  },
  [FC.LOCATION]: {
    required: true
  },
  [FC.OCCUPATION]: {
    pattern: '[a-zA-Z \'-\\s()]+',
    required: true
  },
  [FC.CAN]: {
    pattern: '',
    minLength: 6,
    maxLength: 6,
    required: true
  },
  [FC.PIN]: {
    pattern: '',
    minLength: 6,
    maxLength: 6,
    required: true
  },
  [FC.NNI]: {
    required: true
  },
  [FC.NFC]: {
    required: true
  },
  [FC.MRZ]: {
    required: true
  },
  [FC.DOC_NUMBER]: {
    required: true
  },
  [FC.DOC_EXPIRY_DATE]: {
    required: true
  },
  [FC.DATE_OF_BIRTH]: {
    required: true
  },
  [FC.SIGNATURE]: {
    required: true
  },
  [FC.FACE_PICTURE]: {
    required: true
  },
  [FC.USER_ID]: {
    required: true
  },
  [FC.DEVICE_XY]: {
    required: true
  },
  [FC.DEVICE_NAME]: {
    required: true
  },
  [FC.FINGERPRINT]: {
    required: true
  },
  [FC.VOUCHER]: {
    minLength: 7,
    pattern: '^DI[0-9]+$',
    required: false
  }
};
