<div id="layout" class="f_col container layout_sm">
  <app-view-title bgImage="assets-countries/header_background.png">{{('claim.DYN.' + params.claimType) | translate}}</app-view-title>
  <div class="scroll_wrapper">
    <div class="f_grow mb_40">
      <ng-container *ngIf="claim">
        <ng-container *ngIf="claim?.image_url || claim?.portrait">
          <div class="f_col f_align_c mt_40 mb_40">
            <div class="user_img">
              <img id="canvasProfileScanNFC" [src]="claim?.image_url || claim?.portrait" alt="">
            </div>
          </div>
        </ng-container>
        <div class="f_grow" [class.mt_40]="!claim?.image_url">
          <ng-container *ngIf="claim?.doc_nr || claim?.document_number || claim?.attestation_number">
            <span class="user_info_label">{{'form.doc_nr' | translate}}</span>
            <app-slide-element [noPointerEvent]="true" leftIcon="hashtag" rightIcon="none" class="mb_10">
              {{claim?.doc_nr || claim?.document_number || claim?.attestation_number}}
            </app-slide-element>
          </ng-container>
          <ng-container *ngIf="claim?.driving_privileges">
            <span class="user_info_label">{{'form.driving_privileges' | translate}}</span>
            <app-slide-element [noPointerEvent]="true" leftIcon="car" rightIcon="none" class="mb_10 truncate">
              <span *ngFor="let item of claim?.driving_privileges"><strong>{{item.vehicle_category_code | titlecase}}</strong>
                <span class="ml_10">{{item.issue_date | date:'dd/MM/yyyy' }}</span>
                <fa-icon class="ml_10 mr_10" icon="arrow-right"></fa-icon>
                <span>{{item.expiry_date | date:'dd/MM/yyyy' }}</span></span>
            </app-slide-element>
          </ng-container>
          <ng-container *ngIf="claim?.first_name || claim?.given_name">
            <span class="user_info_label">{{'form.firstName' | translate}}</span>
            <app-slide-element [noPointerEvent]="true" leftIcon="id-card" rightIcon="none" class="mb_10">
              <ng-container *ngIf="claim?.first_name && claim?.first_name?.constructor.name === 'Array'">
                <span *ngFor="let item of claim?.first_name" class="mr_6">{{item | titlecase}}</span>
              </ng-container>
              <ng-container *ngIf="claim?.first_name && claim?.first_name?.constructor.name === 'String'">
                <span>{{claim?.first_name | titlecase}}</span>
              </ng-container>
              <span *ngIf="claim?.given_name">{{claim?.given_name | titlecase}}</span>
            </app-slide-element>
          </ng-container>
          <ng-container *ngIf="claim?.middle_name">
            <span class="user_info_label" *ngIf="claim?.middle_name.length > 0">{{'form.middleName' | translate}}</span>
            <app-slide-element *ngIf="claim?.middle_name.length > 0" [noPointerEvent]="true" leftIcon="id-card" rightIcon="none" class="mb_10">
              <span *ngFor="let item of claim?.middle_name" class="mr_6">{{item | titlecase}}</span>
            </app-slide-element>
          </ng-container>
          <ng-container *ngIf="claim?.last_name || claim?.family_name">
            <span class="user_info_label">{{'form.lastName' | translate}}</span>
            <app-slide-element [noPointerEvent]="true" leftIcon="id-card" rightIcon="none" class="mb_10">
              <ng-container *ngIf="claim?.last_name && claim?.last_name?.constructor.name === 'Array'">
                <span *ngFor="let item of claim?.last_name" class="mr_6">{{item | titlecase}}</span>
              </ng-container>
              <ng-container *ngIf="claim?.last_name && claim?.last_name?.constructor.name === 'String'">
                <span>{{claim?.last_name | titlecase}}</span>
              </ng-container>
              <span *ngIf="claim?.family_name">{{claim?.family_name | titlecase}}</span>
            </app-slide-element>
          </ng-container>
          <ng-container *ngIf="claim?.date_of_birth">
            <span class="user_info_label">{{'form.birthDate' | translate}}</span>
            <app-slide-element [noPointerEvent]="true" leftIcon="calendar-day" rightIcon="none" class="mb_10">
              {{claim?.date_of_birth | date:'dd/MM/yyyy'}}
            </app-slide-element>
          </ng-container>
          <ng-container *ngIf="claim?.birth_place || claim?.town_of_birth">
            <span class="user_info_label">{{'form.birth_place' | translate}}</span>
            <app-slide-element [noPointerEvent]="true" leftIcon="location-dot" rightIcon="none" class="mb_10">
              {{(claim?.birth_place || claim?.town_of_birth) | titlecase}}
            </app-slide-element>
          </ng-container>
          <ng-container *ngIf="claim?.country_of_birth">
            <span class="user_info_label">{{'form.country_of_birth' | translate}}</span>
            <app-slide-element [noPointerEvent]="true" leftIcon="earth-africa" rightIcon="none" class="mb_10">
              {{claim?.country_of_birth | uppercase}}
            </app-slide-element>
          </ng-container>
          <ng-container *ngIf="claim?.father_first_name || claim?.father_last_name">
            <span class="user_info_label">{{'form.father_name' | translate}}</span>
            <app-slide-element [noPointerEvent]="true" leftIcon="person" rightIcon="none" class="mb_10">
              {{(claim?.father_first_name | titlecase)+ ' ' + (claim?.father_last_name | titlecase)}}
            </app-slide-element>
          </ng-container>
          <ng-container *ngIf="claim?.mother_first_name || claim?.mother_last_name">
            <span class="user_info_label">{{'form.mother_name' | translate}}</span>
            <app-slide-element [noPointerEvent]="true" leftIcon="person-dress" rightIcon="none" class="mb_10">
              {{(claim?.mother_first_name | titlecase) + ' ' + (claim?.mother_last_name | titlecase)}}
            </app-slide-element>
          </ng-container>
          <ng-container *ngIf="claim?.gender || claim?.sex">
            <span class="user_info_label">{{'form.gender' | translate}}</span>
            <app-slide-element [noPointerEvent]="true" leftIcon="venus-mars" rightIcon="none" class="mb_10">
              {{claim?.gender ? (claim.gender | uppercase) : claim.sex === 0 ? 'M' : 'F'}}
            </app-slide-element>
          </ng-container>
          <ng-container *ngIf="claim?.height">
            <span class="user_info_label">{{'form.height' | translate}}</span>
            <app-slide-element [noPointerEvent]="true" leftIcon="arrows-up-down" rightIcon="none" class="mb_10">
              {{claim?.height}}
            </app-slide-element>
          </ng-container>
          <ng-container *ngIf="claim?.profession">
            <span class="user_info_label">{{'form.birthPlace' | translate}}</span>
            <app-slide-element [noPointerEvent]="true" leftIcon="location-dot" rightIcon="none" class="mb_10">
              {{claim?.place_of_birth | titlecase}}
            </app-slide-element>
          </ng-container>
          <ng-container *ngIf="claim?.nationality">
            <span class="user_info_label">{{'form.nationality' | translate}}</span>
            <app-slide-element [noPointerEvent]="true" leftIcon="earth-africa" rightIcon="none" class="mb_10">
              {{claim?.nationality | uppercase}}
            </app-slide-element>
          </ng-container>
          <ng-container *ngIf="claim?.telephone_number">
            <span class="user_info_label">{{'form.phoneNumber' | translate}}</span>
            <app-slide-element [noPointerEvent]="true" leftIcon="id-card" rightIcon="none" class="mb_10">
              <span class="mr_6">{{claim?.telephone_number}}</span>
            </app-slide-element>
          </ng-container>
          <ng-container *ngIf="claim?.profession">
            <span class="user_info_label">{{'form.profession' | translate}}</span>
            <app-slide-element [noPointerEvent]="true" leftIcon="id-card" rightIcon="none" class="mb_10">
              <span class="mr_6">{{claim?.profession | titlecase}}</span>
            </app-slide-element>
          </ng-container>
          <ng-container *ngIf="claim?.residence_adr_1 || claim?.residence_adr_2">
            <span class="user_info_label">{{'form.residence_adr' | translate}}</span>
            <app-slide-element [noPointerEvent]="true" leftIcon="location-dot" rightIcon="none" class="mb_10">
              <p *ngIf="claim?.residence_adr_1">{{claim?.residence_adr_1 | titlecase}}</p>
              <p *ngIf="claim?.residence_adr_2">{{claim?.residence_adr_2 | titlecase}}</p>
            </app-slide-element>
          </ng-container>
          <ng-container *ngIf="claim?.town_of_residence">
            <span class="user_info_label">{{'form.town_of_residence' | translate}}</span>
            <app-slide-element [noPointerEvent]="true" leftIcon="location-dot" rightIcon="none" class="mb_10">
              {{claim?.town_of_residence | titlecase}}
            </app-slide-element>
          </ng-container>
          <ng-container *ngIf="claim?.district">
            <span class="user_info_label">{{'form.district' | translate}}</span>
            <app-slide-element [noPointerEvent]="true" leftIcon="id-card" rightIcon="none" class="mb_10">
              <span class="mr_6">{{claim?.district | titlecase}}</span>
            </app-slide-element>
          </ng-container>
          <ng-container *ngIf="claim?.division">
            <span class="user_info_label">{{'form.division' | translate}}</span>
            <app-slide-element [noPointerEvent]="true" leftIcon="id-card" rightIcon="none" class="mb_10">
              <span class="mr_6">{{claim?.division | titlecase}}</span>
            </app-slide-element>
          </ng-container>
          <ng-container *ngIf="claim?.location">
            <span class="user_info_label">{{'form.location' | translate}}</span>
            <app-slide-element [noPointerEvent]="true" leftIcon="id-card" rightIcon="none" class="mb_10">
              <span class="mr_6">{{claim?.location | titlecase}}</span>
            </app-slide-element>
          </ng-container>
          <ng-container *ngIf="claim?.subLocation">
            <span class="user_info_label">{{'form.subLocation' | translate}}</span>
            <app-slide-element [noPointerEvent]="true" leftIcon="id-card" rightIcon="none" class="mb_10">
              <span class="mr_6">{{claim?.subLocation | titlecase}}</span>
            </app-slide-element>
          </ng-container>
          <ng-container *ngIf="claim?.personal_address">
            <span class="user_info_label">{{'form.address' | translate}}</span>
            <app-slide-element [noPointerEvent]="true" leftIcon="id-card" rightIcon="none" class="mb_10">
              <span class="mr_6">{{claim?.personal_address | titlecase}}</span>
            </app-slide-element>
          </ng-container>
          <ng-container *ngIf="claim?.personal_number">
            <span class="user_info_label">{{'form.personalNr' | translate}}</span>
            <app-slide-element [noPointerEvent]="true" leftIcon="barcode" rightIcon="none" class="mb_10">
              {{claim?.personal_number}}
            </app-slide-element>
          </ng-container>
          <ng-container *ngIf="claim?.expiry_date">
            <span class="user_info_label">{{'form.expiryDate' | translate}}</span>
            <app-slide-element [noPointerEvent]="true" leftIcon="calendar-day" rightIcon="none" class="mb_10">
              {{claim?.expiry_date | date:'dd/MM/yyyy'}}
            </app-slide-element>
          </ng-container>
          <ng-container *ngIf="claim?.issue_date">
            <span class="user_info_label">{{'form.issue_date' | translate}}</span>
            <app-slide-element [noPointerEvent]="true" leftIcon="calendar-day" rightIcon="none" class="mb_10">
              {{claim?.issue_date | date:'dd/MM/yyyy'}}
            </app-slide-element>
          </ng-container>
          <ng-container *ngIf="claim?.title_of_signing_authority">
            <span class="user_info_label">{{'form.title_of_signing_authority' | translate}}</span>
            <app-slide-element [noPointerEvent]="true" leftIcon="gavel" rightIcon="none" class="mb_10">
              {{claim?.title_of_signing_authority | titlecase}}
            </app-slide-element>
          </ng-container>
          <ng-container *ngIf="claim?.issuing_authority || claim?.name_of_signing_authority">
            <span class="user_info_label">{{'form.issuing_authority' | translate}}</span>
            <app-slide-element [noPointerEvent]="true" leftIcon="gavel" rightIcon="none" class="mb_10">
              {{(claim?.issuing_authority || claim?.name_of_signing_authority) | titlecase}}
            </app-slide-element>
          </ng-container>
          <ng-container *ngIf="claim?.issuing_country">
            <span class="user_info_label">{{'form.issuing_country' | translate}}</span>
            <app-slide-element [noPointerEvent]="true" leftIcon="earth-africa" rightIcon="none" class="mb_10">
              {{claim?.issuing_country | titlecase}}
            </app-slide-element>
          </ng-container>
          <ng-container *ngIf="claim?.ssn || claim?.serial_number">
            <span class="user_info_label">{{'form.ssn' | translate}}</span>
            <app-slide-element [noPointerEvent]="true" leftIcon="hashtag" rightIcon="none" class="mb_10">
              {{claim?.ssn || claim?.serial_number}}
            </app-slide-element>
          </ng-container>
          <ng-container *ngIf="claim?.issue_place || claim?.center">
            <span class="user_info_label">{{'form.center' | translate}}</span>
            <app-slide-element [noPointerEvent]="true" leftIcon="building-columns" rightIcon="none" class="mb_10">
              {{(claim?.issue_place || claim?.center) | titlecase}}
            </app-slide-element>
          </ng-container>
          <ng-container *ngIf="claim?.support_document">
            <span class="user_info_label">{{'form.support_document' | translate}}</span>
            <app-slide-element [noPointerEvent]="true" leftIcon="file" rightIcon="none" class="mb_10">
              {{claim?.support_document + (claim?.support_document_num ? ' #' + claim?.support_document_num : '')}}
            </app-slide-element>
          </ng-container>
          <ng-container *ngIf="claim?.support_document_date">
            <span class="user_info_label">{{'form.support_document_date' | translate}}</span>
            <app-slide-element [noPointerEvent]="true" leftIcon="calendar-day" rightIcon="none" class="mb_10">
              {{claim?.support_document_date | date:'dd/MM/yyyy'}}
            </app-slide-element>
          </ng-container>
          <ng-container *ngIf="claim?.mrz?.line1">
            <span class="user_info_label" *ngIf="claim?.mrz?.line1">{{'form.mrz' | translate}}</span>
            <app-slide-element *ngIf="claim?.mrz?.line1" [noPointerEvent]="true" leftIcon="code" rightIcon="none" class="mb_10 OCRB">
              <p *ngIf="claim?.mrz?.line1">{{claim?.mrz?.line1}}</p>
              <p *ngIf="claim?.mrz?.line2">{{claim?.mrz?.line2}}</p>
              <p *ngIf="claim?.mrz?.line3">{{claim?.mrz?.line3}}</p>
            </app-slide-element>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>
  <app-back-btn position="botLeft" (btnClicked)="nav.to('claim-card', undefined, {queryParams: params})"></app-back-btn>
</div>
