import {Component, ViewEncapsulation} from '@angular/core';
import {ApiService} from '../../../shared/services/api.service';
import {DevicesService, LoaderService, NavigateService} from 'ngx-satoris';
import {SyncService} from '../../../shared/services/sync.service';

@Component({
  selector: 'app-more-security',
  templateUrl: './more-security.component.html',
  styleUrls: ['./more-security.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MoreSecurityComponent {
 
  constructor(public api: ApiService, 
    public nav: NavigateService, 
    public device: DevicesService, 
    public sync: SyncService,
    private loader: LoaderService) {}

  disconnectDevices() {
    this.loader.loading(true, {type: 'warn', message: 'ask.disconnectAllDevices', btnLabel: 'global.yes'})
      .then((res: boolean) => {
        if(res) {
          return this.api.logoutAllDevices().then((isLogout: boolean) =>{
            if(isLogout) {
              this.loader.loading(true, {type: 'valid', message: 'success.logoutAllDevices'});
            } else {
              this.loader.loading(true, {type: 'error', message: 'error.logoutAllDevices'});
            }
          });
        }
      })
      .catch(() => {
        this.loader.loading(true, {type: 'error', message: 'error.logout'});
      });
  }
    
    
}
