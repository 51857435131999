import {AfterViewInit, ChangeDetectorRef, Component, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {User} from 'src/app/shared/models/user';
import {ApiService} from 'src/app/shared/services/api.service';
import {
  FormService, LangService,
  LoaderService,
  NavigateService
} from 'ngx-satoris';
import {TimeoutError} from 'rxjs';
import {Place} from 'src/app/shared/models/place';
import {ScanService} from 'src/app/shared/services/scan.service';

@Component({
  selector: 'app-permissions',
  templateUrl: './user-permissions.component.html',
  styleUrls: ['./user-permissions.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserPermissionsComponent implements AfterViewInit {
  form: FormGroup;
  submitted: boolean;
  show: boolean;
  selfPlaceUser: User;
  user: User;
  data: {userId: number, placeId: string, user?: User, routeFrom?: string, routeTo?: string, place?: Place, fromCreation?: boolean};

  constructor(public forms: FormService,
    public nav: NavigateService,
    public router: Router,
    public api: ApiService,
    private lang: LangService,
    private formBuilder: FormBuilder,
    private loader: LoaderService,
    private cdRef:ChangeDetectorRef,
    public scan: ScanService) {
    this.data = <any> this.router.getCurrentNavigation().extras.state;
    this.selfPlaceUser = this.api.currentPlace.users.find(u => u.nationalNumber === this.api.userInfo.nationalNumber);
    if(!this.data?.placeId || this.data?.userId === undefined) nav.goBack();
  }
  ngAfterViewInit() {
    this.setForm().then(()=>{
      this.cdRef.detectChanges();
    });
  }

  goBack() {
    this.data?.routeFrom ? this.nav.to(this.data?.routeFrom, undefined, {state: this.data}) : this.nav.goBack();
  }

  setForm() {
    this.loader.loading(true);
    return this.api.user(this.data.userId, false).then(user => {
      this.loader.loading(false);
      this.user = user;
      const placePerm = user.links?.find(x => x.place_id === this.data.placeId);
      this.form = this.formBuilder.nonNullable.group({
        perm1: [placePerm?.allowCreate ? placePerm.allowCreate : false, []],
        perm2: [placePerm?.allowConsume ? placePerm.allowConsume : false, []],
        perm3: [placePerm?.allowControl ? placePerm.allowControl : false, []],
        perm4: [placePerm?.allowAdmin ? placePerm.allowAdmin : false, []]
      });
      this.show = true;
    }).catch(() => {
      this.loader.loading(true, {type: 'error', message: 'userPermission.timeout', btnLabel: 'global.retry', custom: {closeBtnLabel: 'global.close'}}).then((done: boolean) => {
        if(done){
          this.setForm(); 
        } else {
          this.nav.to('/manage/place-members');
        }
      });
    });
  }

  submitForm() {
    this.submitted = true;
    if(this.forms.getFormErrors(this.form).length < 1) {
      this.submitted = false;
      const perms = {
        allowCreate: this.form.get('perm1')?.value,
        allowConsume: this.form.get('perm2')?.value,
        allowControl: this.form.get('perm3')?.value,
        allowAdmin: this.form.get('perm4')?.value
      };
      if((this.data.userId === this.api.userInfo.id) && !perms.allowAdmin) {
        this.loader.loading(true, {type: 'warn', message: this.lang.transform('admin.noAdminPerm')});
      } else {
        this.loader.loading(true, {type: 'warn', message: this.lang.transform('admin.changePerm'), btnLabel: this.lang.transform('global.confirm')}).then((res: boolean) => {
          if(res) {
            this.loader.loading(true);
            this.api.places(this.data.userId, this.data.placeId, undefined, undefined, perms.allowCreate, perms.allowConsume, perms.allowControl, perms.allowAdmin)
              .then(() => {
                this.loader.loading(false);
                this.loader.loading(true, {type: 'valid', message: this.lang.transform('userPermissions.done')}).then(()=>{
                  this.data?.routeTo && this.nav.to(this.data.routeTo, undefined, {state: {perms, ...this.data}});
                });
              }).catch(err => {
                this.submitted = false;
                if(!(err instanceof TimeoutError)) {
                  this.loader.loading(true, {type: 'error', message: String(err).indexOf(': ') > -1 ? err : this.lang.transform('userPermissions.error')});
                }
              });
          }
        });
      }
    }
  }

  changePerm(e: any, permName: string) {
    this.form.get(permName).setValue(e.checked);
  }
}
