<div id="layout" class="sign_in f_col">
  <app-view-title bgImage="assets-countries/header_background.png">{{'signInPro.title' | translate}}</app-view-title>
  <div class="scroll_wrapper">
    <app-view-deco-image imageUrl="assets-countries/undraw_welcome.svg"></app-view-deco-image>
    <div *ngIf="!askChoosePlace">
      <form id="form" [formGroup]="form" (ngSubmit)="submitForm()">
        <app-input formCtrlName="identifier" [formGrp]="form" icon="hashtag" label="form.identifier"></app-input>
        <app-input type="password" formCtrlName="password" [formGrp]="form" label="form.password" class="mt_24"></app-input>
        <app-errors-list [dark]="true" *ngIf="submitted" [formErrors]="forms.getFormErrors(this.form)"></app-errors-list>
      </form>
      <div class="f_end mt_20">
        <a (click)="nav.to('forgot-password', undefined, {state: {proAccount : true, identifier: this.form.get('identifier')?.value}})">{{'signIn.forgotPassword' | translate}}</a>
      </div>
    </div>
    <ng-container *ngIf="askChoosePlace && api.userPlaces && api.userPlaces.length > 0">
      <app-paragraph class="mb_10">{{'choose.place.signInPro' | translate}}</app-paragraph>
      <app-slide-element  *ngFor="let place of api.userPlaces" (btnClicked)="choosePlace(place)"
                          (rightIcon)="place.id === this.api.userPlaceId ? 'circle-check' : 'circle'"
                          leftIcon="globe"
                          class="mt_4">
                          {{place.longName}}
      </app-slide-element>
  </ng-container>
  </div>

  <div class="f_align_end f_grow mb_30">
    <app-back-btn (click)="backBtn()"></app-back-btn>
    <div class="ml_10 fullWidth">
      <app-button *ngIf="!askChoosePlace" type="submit" [showRemainingDebounceTime]="true" [debounceTimeInMilliseconds]="5000" formId="form" class="fullWidth">{{'login.sign_in' | translate}}</app-button>
    </div>
  </div>
</div>
