import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {NavigateService} from 'ngx-satoris';

@Component({
  selector: 'app-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class QrCodeComponent implements OnInit {

  @Input() show = true;
  @Input() value: string;
  @Input() timer: number;
  animTime: number;

  constructor(public nav: NavigateService) {}

  ngOnInit(): void {
    this.animTime = this.timer;
  }
}
