<div id="layout" class="f_col" [class.layout_sm]="!api.userRole.isCustomer">
  <app-view-title bgImage="assets-countries/header_background.png" fromRoute="activation-step-ask-nni" toRoute="choose-lang" [chooseLangBtn]="api.userRole.isCustomer && true">
    {{'activation.step.ask_nni.title' | translate}}
  </app-view-title>
  <div class="scroll_wrapper">
    <app-view-deco-image maxWidth="300px" imageUrl="assets-countries/undraw_card.svg"></app-view-deco-image>
    <app-paragraph innerhtml="{{'activation.step.ask_nni.desc' | translate}}"></app-paragraph>
    <div class="f_grow mt_20">
      <form id="form" [formGroup]="enroll.form" (ngSubmit)="enroll.submitForm(enroll.getCurrentStep())">
        <app-input type="text" [formGrp]="enroll.form" [formCtrlName]="enroll.fc.NNI" label="form.nni" icon="hashtag"></app-input>
        <app-errors-list [dark]="true" *ngIf="!enroll.form.controls[enroll.fc.NNI].valid && enroll.form.controls[enroll.fc.NNI].touched" [formErrors]="forms.getFormErrors(enroll.form, enroll.fc.NNI)"></app-errors-list>
      </form>
    </div>

    <div class="f_align_end f_grow ">
      <app-back-btn (btnClicked)="enroll.navigate('previous')"></app-back-btn>
      <div class="fullWidth ml_10">
        <app-button [disabled]="!enroll.form.controls[enroll.fc.NNI].valid" (btnClicked)="enroll.navigate('next')" class="fullWidth">
          {{'global.continue' | translate}}
        </app-button>
      </div>
    </div>
  </div>
</div>
