import {Component, ViewEncapsulation} from '@angular/core';
import {ApiService} from '../../shared/services/api.service';
import {LangService, LoaderService, NavigateService} from 'ngx-satoris';
import {Article, ArticleType} from 'src/app/shared/models/article';
import {Results} from '../../shared/models/backend-message';
import {isAfter, isBefore} from 'date-fns';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NewsComponent {
  articles: Article[];
  types = ArticleType;

  constructor(public api: ApiService,
              public lang: LangService,
              public nav: NavigateService,
              private loader: LoaderService) {
  }

  ngOnInit() {
    this.loader.loading(true);
    this.api.searchArticle(new Date()).then((articles: Results<Article[]>) => {
      this.articles = articles.result?.filter(article => this.api.userRole.isWorker ? article.type !== ArticleType.INTERNAL_ADMIN : true).filter(article => this.api.userRole.isCustomer ? article.type !== ArticleType.INTERNAL_PRO && article.type !== ArticleType.INTERNAL_ADMIN : true).filter(article => article.lang === this.lang.readLang)?.sort((a, b) => a.metaId.localeCompare(b.metaId));
      this.loader.loading(false);
    }).catch(() => {
      this.loader.loading(true, {type: 'error', message: 'articles.search_error'});
    });
  }

  isVisible(article: Article) {
    return isBefore(new Date(article.visibilityFrom), new Date()) && isAfter(new Date(article.visibilityTo), new Date());
  }

  articlesByType(type: ArticleType | unknown) {
    return this.articles?.filter(article => article.type === type).sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
  }

  protected readonly ArticleType = ArticleType;
}
