import {Component, ViewEncapsulation} from '@angular/core';
import {Place} from 'src/app/shared/models/place';
import {AccountType} from 'src/app/shared/models/user';
import {ApiService} from '../../../shared/services/api.service';
import {DevicesService, LangService, LoaderService, NavigateService} from 'ngx-satoris';
import {getStoredItem, setStoredItem} from 'src/app/shared/utils/storage';
import {TimeoutError} from 'rxjs';
import {SecureStorageService} from 'src/app/shared/services/secure-storage.service';

@Component({
  selector: 'app-choose-place',
  templateUrl: './choose-place.component.html',
  styleUrls: ['./choose-place.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChoosePlaceComponent{
  places =  this.api.userPlaces;
  accounts: AccountType[] = getStoredItem('jwts') || [];

  constructor(public nav: NavigateService,
    public api: ApiService,
    private lang: LangService,
    private loader: LoaderService,
    private devices: DevicesService,
    private secure: SecureStorageService) {
  }

  changePlace(place: Place) {
    const previousAccount = this.api.accountJwt;
    const previousJwts = getStoredItem('jwts');
    const previousJwt = getStoredItem('jwt');
    const previousUserInfo = this.api.userInfo;
    this.loader.loading(true);
    
    return this.api.exchange(place.id, undefined, undefined, true).then((res: any) => {
      const email = JSON.parse(JSON.stringify(this.api.accountJwt.email));
      const auto_name = JSON.parse(JSON.stringify(`${this.api.role[this.api.userInfo.role]}@${place.longName}`));
      const identifier = JSON.parse(JSON.stringify(this.api.userInfo.identifier));

      this.api.signOut(false);

      const account: AccountType = {
        name: auto_name,
        email: email,
        jwt: res.jwt
      };
      this.api.accountJwt = account;
      const index = this.accounts.findIndex(object => object.email === identifier);
      if(index > -1) {
        this.accounts[index] = account;
      } else {
        this.accounts.push(account);
      }
      setStoredItem('jwts', this.accounts);
      setStoredItem('jwt', account);
      this.api.currentPlace = place;

      return this.api.info(false);
  }).then(() => this.api.place(place.id)).then(resPlace => {
      this.api.currentPlace = resPlace;
      if(this.devices.isDevices('cordova')){
        return this.secure.setEncryptSecureDatas('place', JSON.stringify(resPlace))
          .then(() => this.nav.toPin('choose-place', 'user'));
      } else {
        this.nav.toPin('choose-place', 'user');
      }
    }).catch(err => {
      if(!(err instanceof TimeoutError)){
        const errorMessage =  String(err).indexOf(': ') > -1 
          ? `Error - ${err}` 
          : this.lang.transform('api.error');
        this.loader.loading(true, {type: 'error', message: errorMessage});
      }
      this.api.accountJwt = previousAccount;
      setStoredItem('jwts', previousJwts);
      setStoredItem('jwt', previousJwt);
      this.api.userInfo = previousUserInfo;
    });
  }

  alreadyChoose() {
    this.loader.loading(true, {type: 'warn', message: this.lang.transform('place.alreadyChoose')});
  }
}
