<div id="layout" class="f_col container layout_sm">
    <app-view-title bgImage="assets-countries/header_background.png">{{'user.choose_activity.DYN.'+ type | translate}}</app-view-title>
    <form id="form" [formGroup]="form" class="mb_20 mt_20">
      <app-date-range (onChange)="activity.onDateRangeChanged($event)"></app-date-range>
    </form>
    <div class="scroll_wrapper">
      <div class="mt_20">
        <app-activity-list [userInfo]="this.api.userInfo" [inputArray]="activity?.filterActivities ?? activity.activities" *ngIf="activity?.activities"></app-activity-list>
      </div>
    </div>
</div>
