<div id="layout" class="f_col layout_dk">
  <img id="custom_bg" ngSrc="assets-countries/home.png" width="200" height="200">
  <div class="scroll_wrapper">
    <div class="f_grow f_col f_center">
      <div class="f_center mb_20">
        <h2>{{'secretCode.title' | translate}}</h2>
      </div>
      <app-paragraph textAlign="center">{{'secretCode.desc' | translate}}</app-paragraph>
      <div class="mt_20">
        <app-pin [showError]="showError" (codeOutput)="checkCode($event)"></app-pin>
      </div>
    </div>
    <div class="flex gap_10 mb_10 mt_40">
      <app-back-btn *ngIf="data?.state?.fromRoute && api.userInfo.nationalNumber" (btnClicked)="back()"></app-back-btn>
        <app-button  class="width_100pc" color="secondary" (btnClicked)="code && toValid()" [disabled]="!codeOk">{{'confirm' | translate}}</app-button>
    </div>
  </div>
</div>
