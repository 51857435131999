import {
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {Router} from '@angular/router';
import {ZXingScannerComponent} from '@zxing/ngx-scanner';
import {DevicesService, LoaderService, NavigateService, PermData} from 'ngx-satoris';
import {getStoredItem, setStoredItem} from 'src/app/shared/utils/storage';
import {PrivacyScreen} from '@capacitor-community/privacy-screen';
import {environment} from '../../../environments/environment';
import {ScanService} from '../../shared/services/scan.service';

@Component({
  selector: 'app-scanner',
  templateUrl: './scanner.component.html',
  styleUrls: ['./scanner.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ScannerComponent implements OnInit, OnDestroy {
  @Output() scan: EventEmitter<string> = new EventEmitter<string>();
  @Output() hasStarted: EventEmitter<string> = new EventEmitter<string>();
  @Output() onQrFound: EventEmitter<any> = new EventEmitter<any>();

  data: PermData;
  //Cordova permissions plugin
  permissions: any;

  started: boolean;
  enable = false;
  //Trigger to start zxing components
  hasPermission: boolean;

  availableDevices: MediaDeviceInfo[];
  currentDevice: MediaDeviceInfo = null;
  currentCamIndex = 0;

  qrResultString: string;

  eventPause = this.onCameraPause.bind(this);
  eventResume = this.onCameraResume.bind(this);

  @ViewChild('zscanner', {static: false}) zscanner: ZXingScannerComponent;
  @ViewChild('scanner_wrapper', {static: false}) scanner_wrapper: ElementRef<HTMLVideoElement>;
  @ViewChild('scanner_square', {static: false}) scanner_square: ElementRef<HTMLVideoElement>;

  constructor(public nav: NavigateService,
              private router: Router,
              private devices: DevicesService,
              private scanService: ScanService,
              private loader: LoaderService) {
    this.data = <any> this.router.getCurrentNavigation()?.extras?.state;
    if(this.devices.isDevices('cordova') && environment.production) PrivacyScreen.disable();
  }

  ngOnInit(): void {
    this.scanService.scanDone = false;
    this.start();
    if(this.devices.isDevices('cordova')) {
      document.addEventListener('pause', this.eventPause);
      document.addEventListener('resume', this.eventResume);
    }
  }

  ngOnDestroy() {
    this.quitScanner();
    document.removeEventListener('pause', this.eventPause);
    document.removeEventListener('resume', this.eventResume);
    if(this.devices.isDevices('cordova') && environment.production) PrivacyScreen.enable();
    this.scanService.scanDone = false;
  }

  start() {
    this.loader.loadingElement('scan', true, 'lg');
  }

  quitScanner(){
    this.scanner_wrapper && this.scanner_wrapper.nativeElement.remove();
    this.scanner_square && this.scanner_square.nativeElement.remove();
    this.zscanner && this.zscanner.previewElemRef.nativeElement.remove();
    this.started = false;
    this.zscanner && this.zscanner.scanStop();
    this.zscanner.enable = false;
    this.loader.loadingElement('scan', false, 'lg');
  }

  onCodeResult(resultString: string) {
    this.qrResultString = resultString;
    resultString && this.scan.emit(resultString);
    this.quitScanner();
  }

  onCamerasFound(devices: MediaDeviceInfo[]): void {
    const ios = this.devices.isDevices('cordova-ios');
    this.availableDevices = devices.filter(d => !ios || d.label.toLowerCase() === 'front camera' || d.label.toLowerCase() === 'back camera');
    const defaultCameraIndex = getStoredItem('camera');
    if(!isNaN(defaultCameraIndex)) this.currentDevice = this.availableDevices[this.currentCamIndex];
  }

  autoStarted() {
    this.started = true;
    if(this.availableDevices?.length > 0) {
      if(getStoredItem('camera')) {
        this.currentCamIndex = getStoredItem('camera');
        this.currentDevice = this.availableDevices[this.currentCamIndex];
      }
    }
    setTimeout(() => {
      this.loader.loadingElement('scan', false);
    }, 1000);
  }

  nextCamera() {
    this.loader.loadingElement('scan', true, 'lg');
    this.started = false;
    setTimeout(() => {
      this.currentCamIndex++;
      if(this.currentCamIndex + 1 > this.availableDevices.length) {
        this.currentCamIndex = 0;
      }
      this.currentDevice = this.availableDevices[this.currentCamIndex];
      setStoredItem('camera', this.currentCamIndex);
      this.started = true;
      this.loader.loadingElement('scan', false, 'lg');
    }, 501);
  }

  onHasPermission(has: boolean) {
    this.hasPermission = has;
    // if(has !== false) this.nav.toPerm(this.data?.fromRoute || 'user', this.data?.toRoute || 'user', 'CAMERA', {state: this.data});
  }

  camerasNotFoundHandler() {
    this.nav.to('user');
    this.loader.loading(true, {type: 'error', message: 'err.camera.notfound'});
  }

  scanError(err: any) {
    console.error(`ScanError: ${err}`);
    if(err.name === 'NotReadableError'){
      this.zscanner.enable = false;
      setTimeout(() => {
        this.zscanner.enable = true;
      }, 100);
    }
  }

  onCameraPause(){
    this.zscanner.enable = false;
  }

  onCameraResume(){
    this.zscanner.enable = true;
  }

  onScanComplete(result: any) {
    let byteArray: Uint8Array;
    if(result.resultMetadata){
      this.zscanner.scanStop();
      byteArray = result.resultMetadata.entries().next().value[1][0];

      setTimeout(() => {
        const str = this.binArrayToJson(byteArray);
        this.onQrFound.emit(str);
      });
    }
  }

  binArrayToJson(binArray: any) {
    let str = '';
    for(let i = 0; i < binArray.length; i++) {
      str += String.fromCharCode(parseInt(binArray[i]));
    }
    return JSON.stringify(str);
  }
}

