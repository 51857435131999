<div id="layout" class="f_col layout_sm">
  <app-view-title bgImage="assets-countries/header_background.png">{{'services.title' | translate}}</app-view-title>
  <div class="scroll_wrapper">
    <div class="f_grow mt_20" *ngIf="api.env?.services?.length > 0">
      <app-slide-element *ngFor="let item of api.env.services"
                         [disabled]="!sync.isOnline || !item.active"
                         [leftIcon]="item.icon"
                         [colorRight]="!sync.isOnline ? '#f51d2a': ''"
                         [rightIcon]="!sync.isOnline ? 'wifi' : 'arrow-right'"
                         (btnClicked)="!item.active ? disabledServices() : sync.isOnline  ? nav.to('service-iframe', undefined, {queryParams: {service: stringify(item)}}) : sync.loaderOffline()"
                         class="mb_10">
        {{item.title | translate}}
      </app-slide-element>
    </div>
  </div>
</div>
