import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {NavigateService, LoaderService} from 'ngx-satoris';
import {ApiService} from 'src/app/shared/services/api.service';
import {OtpType} from 'src/app/shared/models/user';

@Component({
  selector: 'app-more-security-otp-explain',
  templateUrl: './more-security-otp-explain.component.html',
  styleUrls: ['./more-security-otp-explain.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MoreSecurityOtpExplainComponent implements OnInit {
  constructor(public nav: NavigateService,
    public loader: LoaderService,
    public api: ApiService) {}

  actualOtp : OtpType;
  OtpType = OtpType;
  
  ngOnInit(): void {
    this.actualOtp = this.api.userInfo.otpType;
  }

  alreadyOtp() {
    this.loader.loading(true, {type: 'warn', message: 'already.otp.DYN.'+ this.actualOtp});
  }
}
