import {Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';
import {truncateString} from 'src/app/shared/utils/string';

@Component({
  selector: 'app-modal-article',
  templateUrl: './modal-article.component.html',
  styleUrls: ['./modal-article.component.scss']
})
export class ModalArticleComponent {

  truncateString = truncateString;
  @Input() title: string;
  @Input() description: string;
  @Input() image: string;
  @Input() href: string;
  @Input() isOnline: boolean;
  @Output() onClick = new EventEmitter<void>();
  @HostBinding('class') @Input('class') classList = '';


  handleClick() {
    if(this.href && this.isOnline) {
      window.open(this.href, '_blank');
    } else {
      this.onClick.emit();
    }
  }
}
