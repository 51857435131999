<div id="navbar" [class.backToButton]="backToButton">
  <div class="back"></div>
  <div class="bar">
    <ng-container *ngIf="!backToButton">
      <fa-icon icon="wallet" [class.active]="isActive('user')" (click)="checkNav('user')"></fa-icon>
      <fa-icon icon="bell-concierge" [class.active]="isActive('services')" (click)="checkNav('services')"></fa-icon>
      <div class="nav_scan" (click)="checkNav('scan')">
          <fa-icon icon="qrcode"></fa-icon>
      </div>
      <fa-icon icon="compass" [class.active]="isActive('compass')" (click)="checkNav('news')"></fa-icon>
      <fa-icon icon="ellipsis" [class.active]="isActive('more')" (click)="checkNav('more')"></fa-icon>
    </ng-container>

    <app-button *ngIf="backToButton" size="xsm" (btnClicked)="backButton()">{{'global.close'| translate}}</app-button>
  </div>
</div>
