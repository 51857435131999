export const setStoredItem = (key: string, value: any) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    console.log(e);
  }
};

export const getStoredItem = (key: string): any => {
  try {
    if(!localStorage.getItem(key) || localStorage.getItem(key) === 'undefined') return;
    return JSON.parse(localStorage.getItem(key) || 'null');
  } catch (e) {
    throw new Error(e);
  }
};

export const removeStoredItem = (key: string) => {
  localStorage.removeItem(key);
};

export const clearStoredItem = () => {
  const lang = localStorage.getItem('lang');
  localStorage.clear();
  if(lang) {
    localStorage.setItem('lang', lang);
  }
};