import {Component, NgZone, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {ApiService} from '../../../../shared/services/api.service';
import {EnrollService} from '../../../../shared/services/enroll.service';
import {Subscription} from 'rxjs';
import {ButtonComponent, DevicesService, FormService, LangService, LoaderService, NavigateService} from 'ngx-satoris';
import {NfcService} from '../../../../shared/services/nfc.service';

@Component({
  selector: 'app-activation-step-fingerprint',
  templateUrl: './activation-step-fingerprint.component.html',
  styleUrls: ['./activation-step-fingerprint.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ActivationStepFingerprintComponent implements OnInit, OnDestroy {
  @ViewChild('buttonStartScan') buttonStartScan: ButtonComponent;

  fingerOptions: {name: string, value: number}[] = [];
  private fpCaptured: Subscription;
  private fpCaptureError: Subscription;

  constructor(public nav: NavigateService,
              public nfc: NfcService,
              public api: ApiService,
              public enroll: EnrollService,
              public forms: FormService,
              private zone: NgZone,
              private lang: LangService,
              private loader: LoaderService,
              private device: DevicesService) {
    if(!this.device.isDevices('cordova')) {
      this.loader.loading(true, {type: 'error', message: 'err.fingerprint.noCordova'});
      this.enroll.navigate('previous');
      return;
    }
    this.start();
    const fingersOrder = ['unknown', 'rightThumb', 'rightIndex', 'rightMiddle', 'rightRing', 'rightLittle', 'leftThumb', 'leftIndex', 'leftMiddle', 'leftRing', 'leftLittle'];
    fingersOrder.map((finger, index) => {
      this.fingerOptions.push({name: this.lang.transform('activation.step.fingerprint.fingerOption.DYN.' + finger), value: index});
    });
  }

  ngOnInit(): void {
    //If we're back, skip saved fingerprint image, but keep the finger
    if(this.enroll.form?.controls[this.enroll.fc.FINGERPRINT]?.value?.finger){
      this.enroll.form?.controls[this.enroll.fc.FINGERPRINT]?.setValue(this.enroll.form?.controls[this.enroll.fc.FINGERPRINT]?.value.finger);
    }
  }

  private start() {
    try {
      this.nfc.initFingerprint();
      this.fpCaptured = this.nfc.fingerprintCaptured.subscribe((image: string) => this.fingerprintCaptured(image));
      this.fpCaptureError = this.nfc.fingerprintCaptureError.subscribe((err: any) => this.onFingerprintCapturedError(err));
    } catch (err) {
      this.loader.loading(true, {type: 'error', message: this.lang.transform('fingerprint.init.init_failed')})
        .then(() => this.nav.to(this.api.userRole?.isCustomer ? 'activation' : 'manage-user'));
    }
  }

  scanFingerprint() {
    this.nfc.startCapture().then(() => {
      this.buttonStartScan.startTimer();
    }).catch((msg: any) => {
      if(msg.includes('no device present yet')){
        this.loader.loading(true, {type: 'error', message: 'err.fingerprint.noDevicePresentYet'});
      } else if(msg.includes('SDK is not initialized')){
        this.loader.loading(true, {type: 'error', message: 'err.fingerprint.sdkNotInitialized'});
      } else {
        this.loader.loading(true, {type: 'error', message: msg});
      }
    });
  }

  fingerprintCaptured(image: string) {
    this.zone.run(() => {
      this.fpCaptured?.unsubscribe();
      const finger = this.enroll.form.controls[this.enroll.fc.FINGERPRINT].value;
      this.enroll.form.controls[this.enroll.fc.FINGERPRINT].setValue({finger: finger, image: image});
      this.enroll.navigate('next');
    });
  }

  onFingerprintCapturedError(err: any) {
    this.zone.run(() => {
      if(err.includes('Capture timeout')) {
        this.loader.loading(true, {type: 'error', message: 'err.fingerprint.captureTimeout'});
      } else if(err.includes('SDK is not initialized')){
        this.loader.loading(true, {type: 'error', message: 'err.fingerprint.sdkNotInitialized'});
      } else {
        this.loader.loading(true, {type: 'error', message: `error.capture: ${err}`});
      }
    });
  }

  ngOnDestroy() {
    this.fpCaptured?.unsubscribe();
    this.fpCaptureError?.unsubscribe();

    if(this.device.isDevices('cordova')) {
      this.nfc.abortCapture();
      this.nfc.teardownFingerprint();
    }
  }
}
