<div id="layout" class="f_col layout_sm">
  <app-view-title bgImage="assets-countries/header_background.png">{{'personnalData.title' | translate}}</app-view-title>
  <div class="scroll_wrapper">
    <div class="mt_20" *ngIf="api.userRole?.isCustomer">
      <app-slide-element *ngIf="!isObjectEmpty(personnalDatas.selfClaims)" [leftIcon]="'database'" (btnClicked)="nav.to('personnal-data-all')" class="mb_10">
        {{'personnalData.all'| translate}}
      </app-slide-element>
      <app-slide-element [leftIcon]="'question'" (btnClicked)="nav.to('personnal-data-explain')" class="mb_10">
        {{'ask.help'| translate}}
      </app-slide-element>

      <app-divider class="mt_20 mb_20 width_100pc" color="white"></app-divider>

      <div *ngFor="let type of ClaimsTypes">
        <app-slide-element  [stateIcon]="stateByClaimType(type)?.icon" [leftIcon]="personnalDatas.countryClaims[type]?.icon"  [colorStateIcon]="stateByClaimType(type)?.color" (btnClicked)="nav.to('personnal-data/' + type + personnalDatas.countryClaims[type]?.skipTo, undefined)" class="mb_10">
          {{personnalDatas.countryClaims[type]?.label | translate}}
        </app-slide-element>
      </div>
    </div>
  </div>
  <div class="f_align_end">
    <div class="fullWidth flex gap_10 mb_20">
      <app-back-btn (btnClicked)="back()"></app-back-btn>
    </div>
  </div>
</div>
