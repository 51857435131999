<div id="layout" class="f_col container" [class.layout_sm]="api.userPlaceId && api.currentPlace">
  <app-view-title bgImage="assets-countries/header_background.png">{{'setPlaceAdmin.form.title' | translate}}</app-view-title>
  <div class="scroll_wrapper">
    <div class="f_grow mt_20">
      <ng-container *ngIf="places && places.length > 0">
        <app-slide-element [disabled]="place.id === api.userPlaceId"  *ngFor="let place of places" (btnClicked)="place.id === api.userPlaceId ? alreadyChoose() :  changePlace(place)"
                            (rightIcon)="place.id === this.api.userPlaceId ? 'circle-check' : 'circle'"
                            leftIcon="globe"
                            class="mt_10">
                            {{place.longName}}
        </app-slide-element>
      </ng-container>
      <div class="mt_20 text_center" *ngIf="places && places.length === 0">
        {{ 'global.no_data_available' | translate }}
      </div>
    </div>
    <app-back-btn (click)="nav.to('more')"></app-back-btn>
  </div>
</div>
