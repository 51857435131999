import {Component, ViewEncapsulation} from '@angular/core';
import {ApiService} from '../../../../shared/services/api.service';
import {EnrollService} from '../../../../shared/services/enroll.service';
import {FormService} from 'ngx-satoris';

@Component({
  selector: 'app-activation-step-ask-device-name',
  templateUrl: './activation-step-ask-device-name.component.html',
  styleUrls: ['./activation-step-ask-device-name.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ActivationStepAskDeviceNameComponent {
  constructor(public api: ApiService,
              public forms: FormService,
              public enroll: EnrollService) {
    if(this.api.userInfo.nationalNumber && this.api.userRole.isCustomer) {
      this.api.setPrincipalMode = true;
    }
  }
}
