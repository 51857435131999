<div id="layout" class="f_col layout_sm">
    <app-view-title bgImage="assets-countries/header_background.png" fromRoute="welcome-done" toRoute="choose-lang" [popUp]="true" (popUpEmit)="popupHelper()">{{subType + '.DYN.title' | translate}}</app-view-title>
    <div class="scroll_wrapper">
      <div class="flex f_col gap_10 mt_20" *ngIf="actualClaimSelf?.state?.value">
        <app-block
        [actionIcon]="actionIcon()"
        *ngIf="actualClaimSelf?.state?.value !== ClaimSelfState.NONE"
        [title]="actualClaimSelf?.state?.value === ClaimSelfState.CHANGING ? ((actualClaimSelf?.state?.changingRefusal ?'personnalData.changing.refused' : 'persoData.changing') | translate)  : actualClaimSelf?.state?.value === ClaimSelfState.PENDING ? ('persoData.pending' | translate) :
                 (actualClaimSelf?.state?.value === ClaimSelfState.APPROVED ? ('persoData.approved' | translate) :
                 ('persoData.rejected' | translate))"

        [icon]="personnalDatas.stateVisual(actualClaimSelf?.state)?.icon"

        [iconColor]="personnalDatas.stateVisual(actualClaimSelf?.state)?.color"
        [desc]="descBlock"
      >
      </app-block>
        <app-divider></app-divider>
      </div>
      <form *ngIf="form" id="form" [formGroup]="form" (ngSubmit)="submitForm()" class="flex f_col gap_20 mt_30">
        <div *ngFor="let meta of metaDatas.metadataValue">
          <app-input [type]="meta.subType"
                     formCtrlName="{{meta.label}}"
                     [selectOptions]="meta.selectOptions ? meta.selectOptions : null"
                     [formGrp]="form"
                     [label]="'form.DYN.' + meta.label">
          </app-input>
        </div>
        <div *ngIf="metaDatas?.isProof" class="flex f_col gap_5">
          <h3 class="mt_0">{{'proof' | translate}} :</h3>
          <app-input class="proofPdf" type="file" leftIcon="file-alt" rightIcon="file-import" [formGrp]="form" [label]="'upload.proof' | translate" formCtrlName="proofPdf" acceptType=".pdf,.jpg,.jpeg,.png" (change)="onFileChange($event)" (reset)="onResetFile()" (visualFile)="visualFile($event)" [customBase64File]="this.actualClaimSelf?.proofDocument"></app-input>
        </div>

        <app-errors-list [dark]="true" *ngIf="submitted" [formErrors]="forms.getFormErrors(this.form)"></app-errors-list>
      </form>
    </div>
    <div class="f_align_end">
    <div class="fullWidth flex gap_10 mb_20">
        <app-back-btn (btnClicked)="backButton()"></app-back-btn>
        <app-button type="submit" formId="form" class="fullWidth">{{'global.confirm' | translate}}</app-button>
      </div>
    </div>
  </div>
