<div id="layout" class="f_col container layout_sm">
    <app-view-title bgImage="assets-countries/header_background.png">{{(device.isDevices('cordova-ios') ? 'more.security.faceID' : 'more.security.fingerprint') | translate}}</app-view-title>
    <div class="scroll_wrapper">
      <app-view-deco-image *ngIf="device.isDevices('cordova-android')" imageUrl="assets-countries/fingerprint.svg"></app-view-deco-image>
      <app-view-deco-image *ngIf="device.isDevices('cordova-ios')" imageUrl="assets-countries/faceid.svg"></app-view-deco-image>
        <app-paragraph *ngIf="device.isDevices('cordova-android')" class="mb_10">{{(!biometric.biometric.isGranted && biometric.biometric.isGranted !== null ? 'fingerprint.notGranted.desc' : biometric.biometric.isRegistered ? 'fingerprint.set.desc' : 'fingerprint.unset.desc') | translate}}
        </app-paragraph>
        <app-paragraph *ngIf="device.isDevices('cordova-ios')" class="mb_10">{{(!biometric.biometric.isGranted && biometric.biometric.isGranted !== null ? 'faceID.notGranted.desc' : biometric.biometric.isRegistered ? 'faceID.set.desc' : 'faceID.unset.desc') | translate}}
        </app-paragraph>
      <div class="f_grow">
        <app-button *ngIf="device.isDevices('cordova-android')" class="mt_10" (btnClicked)="handleBiometricClicked()">
          {{(!biometric.biometric.isGranted && biometric.biometric.isGranted !== null ? 'perm.settings.always' : biometric.biometric.isRegistered ? 'fingerprints.button.unset' : 'fingerprint.button.set') | translate }}
        </app-button>
        <app-button *ngIf="device.isDevices('cordova-ios')" class="mt_10" (btnClicked)="handleBiometricClicked()">
          {{(!biometric.biometric.isGranted && biometric.biometric.isGranted !== null ? 'perm.settings.always' : biometric.biometric.isRegistered ? 'faceID.button.unset' : 'faceID.button.set') | translate }}
        </app-button>
      </div>
    </div>
    <app-back-btn position="botLeft" [navTo]="{route: 'more-security'}"></app-back-btn>
  </div>

