<div id="layout" class="f_col container layout_sm">
  <app-view-title bgImage="assets-countries/header_background.png">{{'manage.user.title' | translate}}</app-view-title>
  <div class="scroll_wrapper">
    <div class="f_grow mt_20" *ngIf="api.userRole?.isAdmin || api.userRole?.isSuperAdmin">
      <app-slide-element leftIcon="id-card" (btnClicked)="nav.to('activation-step-intro')" class="mb_10">
        {{'manage.user.activation' | translate}}
      </app-slide-element>
      <app-slide-element leftIcon="briefcase" (btnClicked)="nav.to('/manage/set-pro-account')" class="mb_10">
        {{'manage.user.set_pro_account' | translate}}
      </app-slide-element>
      <app-slide-element leftIcon="database" (btnClicked)="nav.to('personnal-data-manage')" class="mb_10">
        {{'manage.user.personnal-data' | translate}}
      </app-slide-element>
    </div>
  </div>
  <app-back-btn position="botLeft" [navTo]="{route: 'user'}"></app-back-btn>
</div>
