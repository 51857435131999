<div id="layout" class="f_col splash_screen" *ngIf="initialize.isSplashScreen" [@inOutAnimation]>
  <img id="splash_bg" ngSrc="assets-countries/home.png" width="200" height="200">
  <div class="f_col f_center f_grow f_align_c">
    <div id="home_logo">
      <img src="assets-countries/logo.svg" width="240" alt="">
      <img id="home_logo_shadow" src="assets-countries/logo_shadow.svg" alt="">
    </div>
  </div>
  <div class="loader_open mt_50">
    <app-paragraph textAlign="center" [innerhtml]="initialize.textSplashScreen | translate"></app-paragraph>
    <div class="loading">
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
    </div>
  </div>
</div>
