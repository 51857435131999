import {Component, ViewEncapsulation} from '@angular/core';
import {ApiService} from 'src/app/shared/services/api.service';
import {NavigateService} from 'ngx-satoris';
import {removeStoredItem} from 'src/app/shared/utils/storage';
import {BiometricService} from 'src/app/shared/services/biometric.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-secret-code',
  templateUrl: './secret-code.component.html',
  styleUrls: ['./secret-code.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SecretCodeComponent{
  codeOk: boolean;
  showError: boolean;
  code: string;
  data: { state: { pin: string, fromRoute : string, resetPin: boolean, finaliseResetPin: boolean } };

  constructor(public nav: NavigateService,
    public api: ApiService,
    private router: Router,
    private biometric: BiometricService) {
    this.data = <any> this.router.getCurrentNavigation()?.extras;
    if(this.api.userInfo?.pinHash && !this.data?.state?.pin) {
      this.nav.toPin('user', 'user');
    } else {
      removeStoredItem('biometric');
      this.biometric.biometric = {
        faceID: false,
        touchID: false,
        isRegistered: false,
        isGranted: null,
        enrolled: this.biometric.biometric.enrolled
      };
    }
  }

  checkCode(e: string[]) {
    this.showError = false;
    this.codeOk = e.length >= 6;
    this.code = e.join('');
  }

  toValid() {
    this.api.userInfo.pinHash = true;
    this.nav.toPin('secret-code', 'user', true, this.code, {queryParams: {pin: this.data?.state?.pin}, state: {finaliseResetPin: this.data?.state?.resetPin}}, undefined, false);
  }

  back(){
    this.nav.to(this.api.resetPin ? 'more-security-change-pin' : this.data?.state?.fromRoute);
  }
}
