import {Component, ViewEncapsulation} from '@angular/core';
import {NavigateService} from 'ngx-satoris';
import {ApiService} from '../../../../shared/services/api.service';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-activation-code-info',
  templateUrl: './activation-code-info.component.html',
  styleUrls: ['./activation-code-info.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ActivationCodeInfoComponent {
  environment = environment;

  constructor(public nav: NavigateService, public api: ApiService) {}
}
