<div id="layout" class="f_col" [class.layout_sm]="!api.userRole.isCustomer">
  <app-view-title bgImage="assets-countries/header_background.png" fromRoute="activation-step-doc-exp-date" toRoute="choose-lang" [chooseLangBtn]="api.userRole.isCustomer && true">
    {{'activation.step.doc_exp_date.title' | translate}}
  </app-view-title>
  <div class="scroll_wrapper">
    <app-view-deco-image class="mb_10" maxHeight="unset" maxWidth="300px" imageUrl="assets-countries/undraw_expiry_calendar.svg"></app-view-deco-image>
    <app-paragraph innerhtml="{{'activation.step.doc_exp_date.desc' | translate}}"></app-paragraph>
    <div class="f_grow mt_20">
      <form id="form" [formGroup]="enroll.form" (ngSubmit)="enroll.submitForm(enroll.getCurrentStep())">
        <app-input type="date" [minDate]="minDate" [maxDate]="maxDate" [formGrp]="enroll.form" [formCtrlName]="enroll.fc.DOC_EXPIRY_DATE" label="form.docExpiryDate" icon="calendar"></app-input>
        <app-errors-list [dark]="true" *ngIf="!enroll.form.controls[enroll.fc.DOC_EXPIRY_DATE].valid && enroll.form.controls[enroll.fc.DOC_EXPIRY_DATE].touched" [formErrors]="forms.getFormErrors(enroll.form, enroll.fc.DOC_EXPIRY_DATE)"></app-errors-list>
      </form>
    </div>

    <div class="f_align_end f_grow ">
      <app-back-btn (btnClicked)="enroll.navigate('previous')"></app-back-btn>
      <div class="fullWidth ml_10">
        <app-button [disabled]="!enroll.form.controls[enroll.fc.DOC_EXPIRY_DATE].valid" (btnClicked)="enroll.navigate('next')" class="fullWidth">
          {{'global.continue' | translate}}
        </app-button>
      </div>
    </div>
  </div>
</div>
