<div id="layout" class="f_col" [class.layout_sm]="api.userInfo">
  <app-view-title bgImage="assets-countries/header_background.png" fromRoute="forgot-password" toRoute="choose-lang" [chooseLangBtn]="!api.userInfo">{{'forgotPassword.title' | translate}}</app-view-title>
  <app-view-deco-image imageUrl="assets-countries/undraw_forgot-password.svg"></app-view-deco-image>
  <div class="scroll_wrapper">
    <form class="mt_20" id="form" [formGroup]="form" (ngSubmit)="submitForm()">
      <app-input *ngIf="signType === OtpType.EMAIL" [type]="data?.proAccount ? 'text' : 'email'" [formCtrlName]="data?.proAccount ? 'identifier': 'email'" [formGrp]="form" [label]="data?.proAccount ? 'form.identifier' : 'form.email'"></app-input>
      <app-input *ngIf="signType === OtpType.PHONE" type="tel" [formCtrlName]="'phone' + api.env.country" [formGrp]="form" label="form.sms" prefix="225"></app-input>
      <app-errors-list [dark]="true" *ngIf="submitted" [formErrors]="forms.getFormErrors(this.form)"></app-errors-list>
    </form>
  </div>
  <div class="flex mb_30 mt_40">
    <app-back-btn (click)="navBack()"></app-back-btn>
    <app-button type="submit" formId="form" class="ml_10 fullWidth">{{'confirm' | translate}}</app-button>
  </div>
</div>
