<div id="layout" class="f_col layout_dk">
  <img id="custom_bg" ngSrc="assets-countries/home.png" width="200" height="200">
  <div class="scroll_wrapper">
    <div class="f_grow f_col f_center">
      <div class="f_center mt_40">
        <h2>{{'secretCodeValid.title' | translate}}</h2>
      </div>
      <p *ngIf="data?.state?.showLabel" class="text_center info_text_md">{{'pin.redirectDYN.' + getLabel() | translate}}</p>
      <p class="f_center">{{'secretCodeValid.msg' | translate}}</p>
      <div>
        <app-pin [backupButton]="api.retrySecretCodeBtn" [disabled]="api.currentlyUnlockingPin" [showCorrect]="showCorrect" [showError]="showError" (codeOutput)="checkCode($event)" (backupSubmit)="submit()"></app-pin>
        <div *ngIf="!this.data?.state?.setPin" class="f_end mt_20" >
          <a (click)="nav.to('forgot-pin', undefined, data)">{{'forgotPin.title' | translate}}</a>
        </div>
      </div>
    </div>
  </div>
  <div class="mb_30" *ngIf="data?.state?.fromRoute || data?.queryParams?.fromRoute">
    <app-back-btn (btnClicked)="back()"></app-back-btn>
  </div>
</div>
