import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FormService, LangService, LoaderService, NavigateService} from 'ngx-satoris';
import {ApiService} from 'src/app/shared/services/api.service';
import {ScanService} from 'src/app/shared/services/scan.service';
import {EnrollService, enrollStepPaths} from 'src/app/shared/services/enroll.service';
import {UserPlatformRole} from 'src/app/shared/models/user';

@Component({
  selector: 'activation-step-voucher',
  templateUrl: './activation-step-voucher.component.html',
  styleUrls: ['./activation-step-voucher.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ActivationStepVoucherComponent implements OnInit{
  constructor(public nav: NavigateService,
    public api: ApiService,
    public enroll: EnrollService,
    public forms: FormService,
    private scan: ScanService,
    private loader: LoaderService,
    private lang: LangService) {
  }

  ngOnInit() {
    const isSubActive = this.api.userInfo.isForcedSub && new Date(this.api.userInfo.isForcedSub).getTime() > Date.now();
    const nextStepInConfig = this.enroll.stepsConfig[this.enroll.currentConfigIndex].steps[this.enroll.currentStepIndex + 1];
    const prevStep = Object.keys(enrollStepPaths).find(key => enrollStepPaths[key] === this.nav.previousUrl.substring(1));
    const isGoingBack = nextStepInConfig === prevStep;
    if(isSubActive && (nextStepInConfig === prevStep)) {
      this.enroll.navigate('previous');
    }
    if(this.api.userInfo.role === UserPlatformRole.CUSTOMER) {
      if(isSubActive) this.enroll.navigate(isGoingBack ? 'previous' : 'next');
    } else {
      this.api.user(+this.scan.userScanData.data.sig).then(user => {
        if(user.isForcedSub && new Date(user.isForcedSub).getTime() > Date.now()) this.enroll.navigate(isGoingBack ? 'previous' : 'next');
      }).catch(() => {
        this.enroll.navigate('previous');
        setTimeout(() => {
          this.loader.loading(true, {type: 'error', message: this.lang.transform('qr.userNotRead')});
        }, 100);
      });
    }
  }

  progress() {
    let doPromise: Promise<any>;
    if(this.api.userInfo.role === UserPlatformRole.CUSTOMER) {
      doPromise = this.api.paySub(this.enroll.form.controls[this.enroll.fc.VOUCHER].value);
    } else {
      doPromise = this.api.payAdminSub(+this.scan.userScanData.data.sig, this.enroll.form.controls[this.enroll.fc.VOUCHER].value);
    }
    doPromise.then(() => {
      if(this.api.userInfo.role === UserPlatformRole.CUSTOMER) this.api.userInfo.isForcedSub = new Date(Date.now() + 366 * 24 * 60 * 60 * 1000).toISOString();
      this.enroll.navigate('next');
    }).catch(() => {
      this.loader.loading(true, {type: 'error', message: this.lang.transform('qr.voucherNotOk')});
    });
  }
}
