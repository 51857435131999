import {Component, ElementRef, HostListener, NgZone, OnInit, ViewChild} from '@angular/core';
import {Router, RouterOutlet} from '@angular/router';
import {App, URLOpenListenerEvent} from '@capacitor/app';
import {routeTransitionAnimations} from './shared/route-transition-animations';
import {ApiService} from './shared/services/api.service';
import {DevicesService, LangService} from 'ngx-satoris';
import {InitializeService} from './shared/services/initialize.service';
import {environment} from '../environments/environment';
import {getStoredItem} from './shared/utils/storage';
import {ScreenOrientation, OrientationType} from '@capawesome/capacitor-screen-orientation';
import {UserPlatformRole} from './shared/models/user';

declare const window: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routeTransitionAnimations]
})
export class AppComponent implements OnInit {
  resizeWait: boolean;

  @ViewChild('outlet', {static: true}) outlet: ElementRef<any>;

  constructor(public api: ApiService,
              public router: Router,
              public initialize: InitializeService,
              private lang: LangService,
              private devices: DevicesService,
              private zone: NgZone) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = environment.supportSource;
    document.body.appendChild(script);

    document.title = environment.name;
    this.resizeViewPortHeight();
    // Listen for orientation changes
    window.addEventListener('orientationchange', () => {
      this.resizeViewPortHeight();
    }, false);

    this.lang.defaultLang = environment.supportedLanguages[0];
    this.lang.setLang(this.api.readLang);

    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        // Example url: https://myoneciplus.oneci.ci/profile
        const slug = event.url.split(environment.urlDiscriminant).pop();
        if(slug) {
          this.router.navigateByUrl(slug);
        }
      });
    });

    const helpHolder = window.initialQueryString.find((q: string[]) => q[0] === 'help');
    if(helpHolder) {
      setTimeout(() => {
        try {
          window.toggleGlpi(decodeURIComponent(helpHolder[1]));
        } catch {}
      }, 2000);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    if(!this.resizeWait) {
      this.resizeWait = true;
      setTimeout(() => {
        this.resizeViewPortHeight();
        this.resizeWait = false;
      }, 100);
    }
  }

  ngOnInit(): void {
    this.devices.checkDevices();
    this.devices.isDevices('cordova') && ScreenOrientation.lock({type: OrientationType.PORTRAIT}).catch((err: any) => console.error('ScreenOrientation lock failed', err));
    try {
      window.updateGlpi(this.lang.langType(this.api.readLang).toLowerCase(), undefined, this.lang.transform('faqExternalUrl.DYN.role.' + UserPlatformRole.CUSTOMER));
    } catch { /* empty */ }
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet &&
      outlet.activatedRouteData &&
      outlet.activatedRouteData.animationState;
  }

  resizeViewPortHeight() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', vh + 'px');
  }

  onSpecialRoute() {
    return this.router.url.includes('forgot-pin') ||
      this.router.url.includes('update-version') ||
      this.router.url.includes('secret-code') ||
      this.router.url.includes('/scan-share') ||
      this.router.url.includes('/sign-in') ||
      this.router.url.includes('/share-claim?')  ||
      this.router.url.includes('sign-in-pro') ||
      this.router.url.includes('badge') ||
      this.router.url.includes('activation-step-voucher') ||
      this.router.url.includes('welcome');
  }

  checkWelcomeDone() {
    return Boolean(getStoredItem('welcomeDone')) === true;
  }
}
