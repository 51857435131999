import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {DevicesService, LangService, LoaderService, NavigateService} from 'ngx-satoris';
import {ApiService} from 'src/app/shared/services/api.service';
import {BiometricService} from 'src/app/shared/services/biometric.service';

declare const window: any;

@Component({
  selector: 'app-welcome-biometric',
  templateUrl: './welcome-biometric.component.html',
  styleUrls: ['./welcome-biometric.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WelcomeBiometricComponent implements OnInit {
  constructor(public api: ApiService,
    public device: DevicesService,
    public nav: NavigateService,
    public biometric: BiometricService,
    private loader: LoaderService,
    private lang: LangService) {}

  ngOnInit() {
    if(!this.device.isDevices('cordova')) {
      this.nav.to('welcome-done');
    }
  }

  biometricCheck() {
    if(!this.biometric.biometric.enrolled){
      return this.loader.loading(true, {type: 'warn', message: 'notEnrolledBiometric'});
    }
    if(this.biometric?.biometric?.isGranted === false) {
      this.loader.loading(true, {type: 'warn', message: this.lang.transform('faceID.biometric.notGranted'), btnLabel: this.lang.transform('perm.settings.always')}).then((result: boolean) => {
        if(result) {
          window?.cordova?.plugins?.diagnostic?.switchToSettings();
        }
      });
    } else {
      this.nav.toPin('welcome-biometric', 'welcome-done', true, undefined, {state: {setBiometric: true}});
    }
  }

  skip(){
    this.nav.to('welcome-done');
  }
}


