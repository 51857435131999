<mat-dialog-content class="dialog_content">
  <form [formGroup]="form" id="form">
    <div class="input_wrapper mt_20">
      <select #place id="place" [class.hasValue]="place.value" (change)="changePlace($event)" formControlName="place">
        <option value="" disabled></option>
        <option *ngFor="let p of places" [value]="p.id">{{p.longName}} {{hasPlace(p) ? '*' : undefined}}</option>
      </select>
      <label for="place">{{'form.place' | translate}}</label>
      <fa-icon icon="location-dot"></fa-icon>
    </div>

    <app-errors-list [dark]="true" *ngIf="submitted" [formErrors]="forms.getFormErrors(this.form)"></app-errors-list>

    <div class="f_between mt_20 dialog_bot_btns">
      <app-button size="sm" color="secondary" (click)="closeDialog()">{{'global.close' | translate}}</app-button>
      <app-button size="sm" type="submit" formId="form" (click)="submitForm()">{{'global.confirm' | translate}}</app-button>
    </div>
  </form>
</mat-dialog-content>
