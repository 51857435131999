import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {DialogFormInput, LoaderService, NavigateService, base64ToFile, downloadFile} from 'ngx-satoris';
import {ClaimSelfForAdmin} from 'src/app/shared/models/claim';
import {ApiService} from 'src/app/shared/services/api.service';
import {PersonnalDatasService} from 'src/app/shared/services/personnal-datas.service';

@Component({
  selector: 'app-personnal-data-manage-id',
  templateUrl: './personnal-data-manage-id.component.html',
  styleUrls: ['./personnal-data-manage-id.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PersonnalDataManageIdComponent implements OnInit {

  actualClaimSelf: ClaimSelfForAdmin;
  actualProof: File;
  currentId: string;

  constructor(public api: ApiService,
    private route: ActivatedRoute,
    public nav: NavigateService,
    private loader: LoaderService,
    private personnalDatas: PersonnalDatasService) {
    this.route.params.subscribe(params => {
      this.currentId = params.id;
    });
  }

  ngOnInit(): void {
    this.loader.loading(true);
    this.personnalDatas.selfClaimsAdmin(this.currentId).then(() => {
      this.loader.loading(false);
      this.actualClaimSelf = this.personnalDatas.allCustomerSelfClaims[0];
      this.actualProof = base64ToFile(this.actualClaimSelf?.proofDocument);
    });
  }


  approveClaimSelf() {
    const dialogInput = [{
      formCtrlName: 'expirationDate',
      type: 'date',
      label: 'activation.step.confirm.fc.DYN.DOC_EXPIRY_DATE',
      date: {
        minDate: new Date().toISOString().split('T')[0],
        maxDate: new Date(new Date().setFullYear(new Date().getFullYear() + 100)).toISOString().split('T')[0]
      }
    }] as DialogFormInput[];
    this.loader.loading(true, {type: 'valid', message: 'ask.approveClaimSelf', dialogInput: dialogInput}).then(res => {
      if(res) {
        this.loader.loading(true);
        return this.api.judgeSelfClaim(this.actualClaimSelf.id, this.api.userPlaceId, undefined, undefined, new Date(res as unknown as Date)).then(() => {
          this.loader.loading(true, {type: 'valid', message: 'approveClaimSelf.success'}).then(() => {
            this.nav.to('personnal-data-manage');
          });
        });
      }
    });
  }

  viewProof() {
    if(this.actualProof.type === 'application/pdf') {
      downloadFile(this.actualProof);
    } else {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        const result = event.target.result;
        this.loader.loading(true, {
          hideHeader: true,
          fullPage: true,
          custom: {
            innerHtml: `<div class="popup-visualInput">
              <img class="popup-image" src="${result}" alt="popup image" />
            </div>`
          }
        });
      };
      reader.readAsDataURL(this.actualProof);
    }
  }
}
