<div id="layout" class="f_col layout_sm">
    <app-view-title bgImage="assets-countries/header_background.png" fromRoute="welcome-done" toRoute="choose-lang">{{'all.personnalDatas' | translate}}</app-view-title>
    <div class="scroll_wrapper">
      <div *ngIf="isNoSelf" class="f_col gap_20 mb_10">
        <app-paragraph class="mt_20" align="center" textAlign="center">{{'no.personnalData' | translate}}</app-paragraph>
      </div>
      <div *ngIf="personnalDatas.selfClaims" class="f_col gap_20 mb_10 mt_20">
        <div *ngFor="let item of  this.personnalDatas?.selfClaimsMerged">
          <app-block [actionIcon]="actionIcon()" [icon]="item?.stateIcon?.icon" [iconColor]="item?.stateIcon?.iconColor" [iconLeft]="false" title="{{item.title | translate}}" [arrayElements]="item?.arrayElement"></app-block>
        </div>        
      </div>
      <div class="f_align_end f_grow ">
        <div class="ml_10 flex gap_10 mr_20">
          <app-back-btn (btnClicked)="nav.to('personnal-data')"></app-back-btn>
        </div>
        <app-button *ngIf="!disabledShare" class="fullWidth"
                  (btnClicked)="nav.toPin('personnal-data-all','share-claim',true,undefined,{queryParams: params})">
        {{'global.share' | translate}}
      </app-button>
      </div>
    </div>
  </div>
  