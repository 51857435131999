<div id="proToggle">
  <span class="f_align_c" *ngIf="api.userRole?.isWorker">Pro</span>
  <span class="f_align_c" *ngIf="api.userRole?.isAdmin">Admin</span>
  <span class="f_align_c" *ngIf="api.userRole?.isSuperAdmin">Super</span>
  <span class="f_align_c" *ngIf="nav.demoMode">Demo</span>
  <button *ngIf="!sync.isOnline" class="connectionBtnExplain" (click)="connectionExplain()">
    <app-paragraph class="">{{'lost.connection' | translate}}</app-paragraph>
    <fa-icon style="color: red" icon="wifi"></fa-icon>
  </button>
</div>
