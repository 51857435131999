import {Component, ViewEncapsulation} from '@angular/core';
import {DevicesService, LangService} from 'ngx-satoris';
import {animate, style, transition, trigger} from '@angular/animations';
import {InitializeService} from '../../shared/services/initialize.service';
import {ApiService} from '../../shared/services/api.service';

@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('inOutAnimation',
      [
        transition(':enter',
          [
            style({opacity: 0}),
            animate('300ms ease-in',
              style({opacity: 1}))
          ]),
        transition(':leave',
          [
            style({opacity: 1}),
            animate('300ms ease-in',
              style({opacity: 0}))
          ])
      ])
  ]
})
export class SplashScreenComponent {
  window = window;

  constructor(public initialize: InitializeService,
              public devices: DevicesService,
              public api: ApiService,
              public lang: LangService){}
}
