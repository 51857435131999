import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {ApiService} from '../../shared/services/api.service';
import {
  FormService, LangService,
  LoaderService,
  NavigateService
} from 'ngx-satoris';
import {environment} from '../../../environments/environment';
import {OtpType} from 'src/app/shared/models/user';
import {parseError} from 'src/app/shared/utils/string';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SignUpComponent implements OnInit {

  form: FormGroup;
  submitted: boolean;
  pswdStrength: number;
  signType: OtpType = OtpType.EMAIL;

  constructor(public forms: FormService,
      public nav: NavigateService,
      public router: Router,
      public api: ApiService,
      private lang: LangService,
      private formBuilder: FormBuilder,
      private loader: LoaderService) {
  }

  ngOnInit(): void {
    this.setForm();
  }

  setForm() {
    this.form = this.formBuilder.group({
      email: ['', [
        Validators.required,
        Validators.email
      ]],
      phone: [''],
      password: [this.form?.get('password')?.value ? this.form.get('password').value : '', [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(30)
      ]],
      password2: [this.form?.get('password2')?.value ? this.form.get('password2').value : '',
        Validators.required
      ]
    }, {
      validator: [
        this.forms.mustMatch('password', 'password2'),
        this.forms.customPatternValidator('password', [/[A-Z]/, /[a-z]/, /[0-9]/, /[~`!@#$%^&*()\-_+={[}\]|:;"'<,>.?/]/], 3)
      ]
    });
  }

  submitSignUp() {
    this.submitted = true;
    if(this.forms.getFormErrors(this.form).length < 1) {
      this.loader.loading(true);
      this.api.register(this.form.get(this.signType === OtpType.EMAIL ? 'email' : 'phone').value, this.form.get('password').value).then(() => {
        this.loader.loading(false);
        if(this.nav.demoMode) {
          this.nav.to('sign-in');
        } else {
          this.nav.to('account-valid', undefined, {state: {signType: this.signType, accountToActivate: this.form.get(this.signType === OtpType.EMAIL ? 'email' : 'phone').value}});
        }
      }, err => this.loader.loading(true, {type: 'error', message: this.lang.transform(parseError(err, 'err.err').tradError)}));
    }
  }

  checkPswdStrength() {
    this.pswdStrength = this.forms.checkPswdStrength(this.form.get('password').value);
  }

  changeOtpType() {
    this.signType = this.signType === OtpType.EMAIL ? OtpType.PHONE : OtpType.EMAIL;
    setTimeout(() => {
      if(this.signType === OtpType.EMAIL) {
        this.form.get('email').setValidators([Validators.required, Validators.email]);
        this.form.get('email').updateValueAndValidity();
        this.form.get('phone').clearValidators();
        this.form.get('phone').updateValueAndValidity();
      } else {
        this.form.get('phone').setValidators([Validators.required, Validators.pattern(environment.phonePattern)]);
        this.form.get('phone').updateValueAndValidity();
        this.form.get('email').clearValidators();
        this.form.get('email').updateValueAndValidity();
      }
    });
  }

  protected readonly OtpType = OtpType;
}
