import {Component, NgZone, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {DevicesService, LangService, LoaderService, NavigateService, PermData} from 'ngx-satoris';
import {getStoredItem, removeStoredItem} from 'src/app/shared/utils/storage';
import {ApiService} from '../../shared/services/api.service';
import {PermissionsService} from '../../shared/services/permissions.service';

declare const window: any;

@Component({
  selector: 'app-device-permissions',
  templateUrl: './device-permissions.component.html',
  styleUrls: ['./device-permissions.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DevicePermissionsComponent implements OnInit, OnDestroy {
  data: PermData;
  imgURL: string;
  anotherImgURL: string;
  hasAccept: string;
  bluetoothOk = false;
  locationOk = false;
  initCheck: boolean;
  eventResume = this.onResume.bind(this);
  btImgHidden = false;
  lcImgHidden = true;

  constructor(public router: Router,
              public nav: NavigateService,
              public api: ApiService,
              private zone: NgZone,
              public permissions: PermissionsService,
              public device: DevicesService,
              private loader: LoaderService,
              private lang: LangService) {
    this.data = this.router.getCurrentNavigation().extras?.state as PermData;
    if(getStoredItem('dataPerm')) this.data = getStoredItem('dataPerm');
    if(!this.data) this.nav.to('user');

    switch(this.data.perm) {
    case 'CAMERA':
      this.imgURL = `assets-countries/permission_camera.svg`;
      break;
    case 'BLUETOOTH':
      this.imgURL = `assets-countries/permission_bluetooth.svg`;
      this.anotherImgURL = `assets-countries/permission_location.svg`;
      if(this.permissions.stateOfPermissions.bluetooth.hasPermission && this.permissions.stateOfPermissions.localisation.hasPermission  && this.permissions.stateOfPermissions.localisation.state && this.permissions.stateOfPermissions.bluetooth.state === 'powered_on' && this.device.isDevices('cordova-android')) {
        this.quitApp(this.data.toRoute);
      } else if(this.permissions.stateOfPermissions.bluetooth.hasPermission && this.permissions.stateOfPermissions.bluetooth.state === 'powered_on' && this.device.isDevices('cordova-ios')) {
        this.quitApp(this.data.toRoute);
      }
      break;
    case 'NFC':
      this.imgURL = `assets-countries/permission_nfc.svg`;
      break;
    case 'ACCESS_FINE_LOCATION':
      this.imgURL = `assets-countries/permission_location.svg'`;
      break;
    }
    this.checkPermissions();
  }

  ngOnInit() {
    document.addEventListener('resume', this.eventResume);
    if(this.data?.perm === 'BLUETOOTH') {
      this.btHandler();
      if(this.device.isDevices('cordova-android')) {
        this.locationHandler();
      }
    }
  }

  ngOnDestroy() {
    this.permissions.removeHandlerPermissions();
    document.removeEventListener('resume', this.eventResume);
  }

  checkPermissions() {
    if(this.data.perm === 'CAMERA') {
      this.checkCameraPermission();
    } else if(this.data.perm === 'BLUETOOTH') {
      this.checkBluetooth();
      if(this.bluetoothOk && this.device.isDevices('cordova-android')) {
        this.checkLocation();
      }
      if(this.bluetoothOk && this.device.isDevices('cordova-ios')) {
        this.quitApp(this.data.toRoute);
      }
      if(this.bluetoothOk && this.locationOk) {
        this.quitApp(this.data.toRoute);
      }
      if(+this.permissions.deviceInformation?.version < 11 && this.device.isDevices ('cordova-android') && this.locationOk) {
        window.cordova?.plugins?.diagnostic?.setBluetoothState(this.quitApp(this.data.toRoute), console.error, true);
      }
    } else if(this.data.perm === 'NFC') {
      console.warn('NFC not implemented yet');
    } else if(this.data.perm === 'ACCESS_FINE_LOCATION') {
      this.checkLocation();
    }
    if(!this.initCheck) {
      this.initCheck = true;
    }
  }

  requestCameraPermission() {
    this.permissions.requestCameraPermission().then((status: string) => {
      this.zone.run(() => {
        status.toUpperCase() ===  window.cordova.plugins.diagnostic?.permissionStatus.GRANTED ? this.quitApp(this.data?.toRoute) : this.hasAccept = status.toUpperCase();
      });
    });
  }

  checkCameraPermission() {
    this.permissions.checkCameraPermission().then(() => {
      if(this.permissions.stateOfPermissions.camera.state ===  window.cordova.plugins.diagnostic?.permissionStatus.GRANTED) {
        this.quitApp(this.data.toRoute);
      } else if(this.permissions.stateOfPermissions.camera.state ===  window.cordova.plugins.diagnostic?.permissionStatus.DENIED_ALWAYS) {
        this.initCheck && window.cordova.plugins.diagnostic?.switchToSettings();
      } else {
        this.initCheck && this.permissions.requestCameraPermission().then((status: string) => {
          status ===  window.cordova.plugins.diagnostic?.permissionStatus.GRANTED ? this.quitApp(this.data.toRoute) : this.permissions.stateOfPermissions.camera.state = status;
        });
      }
    });
  }

  quitApp(route: string) {
    document.removeEventListener('resume', this.eventResume);
    this.zone.run(() => {
      this.nav.to(route, undefined, {state: this.data, queryParams: this.data.extras?.queryParams});
      removeStoredItem('dataPerm');
    });
  }

  onResume() {
    if(this.data.perm === 'CAMERA'){
      window.cordova?.plugins?.diagnostic?.isCameraAvailable((cam: any) => {
        cam && this.quitApp(this.data.toRoute);
      }, (err: any) => {
        console.error(err);
      }, false);
    }
    if(this.data.perm === 'BLUETOOTH'){
      if(this.device.isDevices('cordova-android')) {
        this.checkBluetooth(true);
        this.checkLocation(true);
        if(this.bluetoothOk && this.locationOk) {
          this.quitApp(this.data.toRoute);
        }
        this.permissions.checkIso().then(() => {
          if(this.permissions.quickCheck()){
            this.quitApp(this.data.toRoute);
          }
        });
      }
    }
  }

  checkLocation(checkOnly = false) {
    if(this.data.perm === 'BLUETOOTH'){
      this.btImgHidden = true;
      this.lcImgHidden = false;
    }
    if(!checkOnly){
      if(!this.permissions.stateOfPermissions.localisation.hasPermission) {
        return this.initCheck && this.permissions.requestLocationAuthorization();
      }
      if(!this.permissions.stateOfPermissions.localisation.state) {
        return this.initCheck && this.loader.loading(true, {type: 'warn', message: this.lang.transform('localisation.disabled')});
      }
    }
    if(this.permissions.stateOfPermissions.localisation.state && this.permissions.stateOfPermissions.localisation.hasPermission){
      this.locationOk = true;
    }
  }

  checkBluetooth(checkOnly = false) {
    if(!checkOnly){
      if(!this.permissions.stateOfPermissions.bluetooth.hasPermission) {
        if(this.device.isDevices('cordova-ios')) {
          return this.initCheck && window?.cordova?.plugins?.diagnostic?.switchToSettings();
        } else if(this.device.isDevices('cordova-android')) {
          if(+this.permissions.deviceInformation?.version <= 11){
            this.permissions.setBluetoothON().then(() => this.quitApp(this.data.toRoute));
            return;
          } else {
            this.initCheck && this.permissions.requestBTPermission();
            return;
          }
        }
      }
      if(this.permissions.stateOfPermissions.bluetooth.state !== window?.cordova?.plugins?.diagnostic?.bluetoothState.POWERED_ON){
        return this.initCheck && this.loader.loading(true, {type: 'warn', message: this.lang.transform('bluetooth.disabled')});
      }
    }
    if((this.permissions.stateOfPermissions.bluetooth.state === window?.cordova?.plugins?.diagnostic?.bluetoothState.POWERED_ON || this.permissions.stateOfPermissions.bluetooth.state === window?.cordova?.plugins?.diagnostic?.permissionStatus.GRANTED_WHEN_IN_USE) && this.permissions.stateOfPermissions.bluetooth.hasPermission) {
      this.bluetoothOk = true;
    }
  }

  returnTextPermissions() {
    if(this.data.perm === 'CAMERA'){
      if(this.permissions.stateOfPermissions.camera.state !== window?.cordova?.plugins?.diagnostic?.permissionStatus.GRANTED) {
        return this.lang.transform('camera.desc.noPermission');
      }
    } else {
      if(!this.bluetoothOk) {
        if(!this.permissions.stateOfPermissions.bluetooth.hasPermission) {
          return this.lang.transform('bluetooth.desc.noPermissions');
        } else if(this.permissions.stateOfPermissions.bluetooth.state !== window?.cordova?.plugins?.diagnostic?.bluetoothState.POWERED_ON) {
          return this.lang.transform('bluetooth.desc.disabled');
        }
      } else if(this.bluetoothOk && !this.locationOk) {
        if(!this.permissions.stateOfPermissions.localisation.hasPermission) {
          return this.lang.transform('localisation.desc.noPermissions');
        } else if(!this.permissions.stateOfPermissions.localisation.state) {
          return this.lang.transform('localisation.desc.disabled');
        }
      }
    }
  }
  btHandler(register = true) {
    window.cordova.plugins.diagnostic.registerBluetoothStateChangeHandler(register ? (bluetooth: string)=>{
      if(bluetooth !== 'unknown' && bluetooth !== null && bluetooth !== undefined){
        this.permissions.stateOfPermissions.bluetooth.state = bluetooth;
      }
      this.checkBluetooth(true);
      if(this.device.isDevices('cordova-android')) {
        this.checkLocation(true);
      }
      if((this.device.isDevices('cordova-ios') && this.bluetoothOk) || this.bluetoothOk && this.locationOk) {
        this.quitApp(this.data.toRoute);
      }
    } : false);
  }

  locationHandler(register = true) {
    window?.cordova?.plugins?.diagnostic?.registerLocationStateChangeHandler(register ? (location: string)=>{
      if(location !== 'unknown' && location !== null && location !== undefined){
        this.permissions.stateOfPermissions.localisation.state = location === 'location_off' ? false : true;
      }
      this.checkBluetooth(true);
      this.checkLocation(true);
      if(this.bluetoothOk && this.locationOk) {
        this.quitApp(this.data.toRoute);
      }
    } : false);
  }
}
