<div id="layout" class="f_col" [class.layout_sm]="!api.userRole.isCustomer">
  <app-view-title bgImage="assets-countries/header_background.png" fromRoute="activation-step-nfc-scan-id-card" toRoute="choose-lang" [chooseLangBtn]="api.userRole?.isCustomer && true">
    {{'activation.step.nfc_scan_id_card.title' | translate}}
  </app-view-title>
  <div class="scroll_wrapper">
    <div class="f_grow" >
      <div class="nfc-anim-container">
        <div class="nfc-anim">
          <img src="assets-countries/specimen.png" class="nfc-specimen" alt="Image de la carte">
          <img src="assets-countries/phone_nfc.svg" class="nfc-phone" alt="Image du téléphone">
        </div>
      </div>
        <app-paragraph class="nfc-paragraph-anim mb_10"> {{'nfc-explain' | translate}} </app-paragraph>
        <app-progress-bar [gif]="false" type='nfc' [progress]="progress" [speed]="speed" (onDone)="onComplete()"></app-progress-bar>
    </div>
      <div class="f_align_end f_grow mt_10 backbtn">
      <app-back-btn (btnClicked)="!initialize.isScanning && enroll.navigate('previous')"></app-back-btn>
    </div>
  </div>
</div>
