import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {FormService, NavigateService, LoaderService, LangService} from 'ngx-satoris';
import {CountryName, countries, countriesSO} from 'src/app/shared/models/countries';
import {GenderType} from 'src/app/shared/models/user';
import {ApiService} from 'src/app/shared/services/api.service';
import {EnrollService} from 'src/app/shared/services/enroll.service';
import {ScanService} from 'src/app/shared/services/scan.service';
import {generateTd1Mrz} from 'src/app/shared/utils/td1';

@Component({
  selector: 'app-activation-step-form-user',
  templateUrl: './activation-step-form-user.component.html',
  styleUrls: ['./activation-step-form-user.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ActivationStepFormUserComponent implements OnInit, OnDestroy {

  type: string;
  countryname = CountryName;
  submitted: boolean;
  gender = GenderType;
  genderSO: {value: any, name: any}[] = [];
  countries = countries;
  countriesSO = countriesSO;
  nationalitiesSO: {value: any, name: any}[] = [];
  currentMRZ: {line1: string, line2: string, line3: string};

  constructor(public forms: FormService,
              public nav: NavigateService,
              public router: Router,
              public scan: ScanService,
              public api: ApiService,
              private loader: LoaderService,
              private lang: LangService,
              public enroll: EnrollService) {
    if(!this.scan.userScanData || !this.scan.userScanData?.data?.sig) {
      this.loader.loading(true, {type: 'error', message: this.lang.transform('api.createClaim.missingData')}).then(() => {
        this.nav.to('user-activate');
      });
    }
    this.type = this.router?.getCurrentNavigation()?.extras?.state?.type;
  }

  ngOnInit() {
    this.gender.map(gender => this.genderSO.push({value: gender.value, name: 'form.gender.DYN.' + gender.name}));
    this.countries.filter(country => !country.name.includes('_ADMIN')).map(country => this.nationalitiesSO.push({value: country.name, name: 'form.nationality.DYN.' + country.name}));
  }

  ngOnDestroy() {
    this.enroll.submittedForm = false;
  }

  split(text: string) {
    return text.split('');
  }

  inputAdd(id: string) {
    const txt = document.getElementById(id) as HTMLInputElement;
    txt.value = txt.value + ', ';
    txt.focus();
  }

  checkInputAdd(txt: string): boolean {
    return txt && txt && !txt.trim().endsWith(',');
  }

  goBack() {
    this.enroll.navigate('previous');
  }

  checkMRZ() {
    if(this.enroll.areAllControlsValid()) {
      this.currentMRZ = generateTd1Mrz({
        docType: 'ID',
        country: this.enroll.form.controls[this.enroll.fc.COUNTRY].value,
        docNumber: this.enroll.form.controls[this.enroll.fc.DOC_NUMBER].value,
        birthdate: new Date(this.enroll.form.controls[this.enroll.fc.DATE_OF_BIRTH].value),
        sex: this.enroll.form.controls[this.enroll.fc.GENDER].value,
        expiry: new Date(this.enroll.form.controls[this.enroll.fc.DOC_EXPIRY_DATE].value),
        nationality: this.enroll.form.controls[this.enroll.fc.NATIONALITY].value,
        optionalElement: this.enroll.form.controls[this.enroll.fc.COUNTRY].value,
        familyNames: this.enroll.form.controls[this.enroll.fc.LAST_NAME].value.split(',').map((element: string) => element.trim()),
        givenNames: this.enroll.form.controls[this.enroll.fc.MIDDLE_NAME].value.length > 0 ?
          [this.enroll.form.controls[this.enroll.fc.FIRST_NAME].value].concat(this.enroll.form.controls[this.enroll.fc.MIDDLE_NAME].value.split(',').map((element: string) => element.trim())) :
          [this.enroll.form.controls[this.enroll.fc.FIRST_NAME].value]
      });
      this.enroll.form.controls[this.enroll.fc.MRZ].setValue(this.currentMRZ);
    }
  }

  continueForm() {
    this.checkMRZ();
    this.enroll.navigate('next');
  }
}
