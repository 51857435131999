import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Claim, ClaimShareParams, ClaimType, CountryClaim} from '../../../shared/models/claim';
import {ApiService} from '../../../shared/services/api.service';
import {DevicesService, LoaderService, NavigateService} from 'ngx-satoris';
import {environment} from '../../../../environments/environment';
import {ScreenOrientation, OrientationType} from '@capawesome/capacitor-screen-orientation';
import {InitializeService} from 'src/app/shared/services/initialize.service';

@Component({
  selector: 'app-claim-card',
  templateUrl: './claim-card.component.html',
  styleUrls: ['./claim-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ClaimCardComponent implements OnInit, OnDestroy {
  claim: Claim;
  params: ClaimShareParams;

  constructor(public api: ApiService,
              public device: DevicesService,
              public nav: NavigateService,
              public router: Router,
              private route: ActivatedRoute,
              private loader: LoaderService,
              public init: InitializeService) {
    this.route.queryParams.subscribe((params: any) => {
      if(params) {
        this.params = {
          claimType: JSON.parse(params.claimType),
          isoMode: environment.countryClaims.find((x: CountryClaim) => x.claimType === JSON.parse(params.claimType)).isoMode,
          selectedClaims: params.selectedClaims,
          fromRoute: 'claim-card'
        };
        this.claim = this.api.claims[this.params.claimType];
      }
    });
  }

  ngOnInit() {
    this.device.isDevices('cordova') && ScreenOrientation.unlock().catch((err: any) => console.error('ScreenOrientation unlock failed', err));
  }

  share() {
    const toShareClaims: ClaimType[] = JSON.parse(this.params.selectedClaims);
    if(toShareClaims.length > 1) {
      this.loader.loading(true, {
        hideHeader: true,
        titleNoHeader: 'allShare.title',
        message: 'share.choose',
        btnLabel: 'global.share',
        dialogInput: [{
          type: 'select',
          selectOptions: [{
            name: 'share.all',
            value: '-'
          }].concat(...toShareClaims.map(c => ({
            name: 'userShare.DYN.' + c,
            value: '' + c
          }))),
          formCtrlName: 'shares'
        }]
      }).then((shareMode: any) => {
        if(shareMode && shareMode.shares !== '') {
          if(shareMode.shares !== '-') {
            this.doShare([+shareMode.shares]);
          } else {
            this.doShare(toShareClaims);
          }
        }
      });
    } else {
      this.doShare(toShareClaims);
    }
  }

  ngOnDestroy() {
    this.device.isDevices('cordova') && ScreenOrientation.lock({type: OrientationType.PORTRAIT}).catch((err: any) => console.error('ScreenOrientation lock failed', err));
  }

  private doShare(toShareClaims: ClaimType[]) {
    if(toShareClaims.indexOf(this.params.claimType) === -1) this.params.isoMode = false;
    this.nav.toPin('claim-card', (this.params.isoMode ? 'share-claim-mode' : 'share-claim'), true, undefined, {queryParams: {
      ...this.params,
      selectedClaims: JSON.stringify(toShareClaims)
    }}, 0);
  }

  protected readonly JSON = JSON;
}
