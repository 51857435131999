import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {AccountType, User, UserPlatformRole} from '../../../shared/models/user';
import {ApiService} from '../../../shared/services/api.service';
import {LangService, LoaderService, NavigateService} from 'ngx-satoris';
import {SyncService} from '../../../shared/services/sync.service';
import {getStoredItem, setStoredItem} from 'src/app/shared/utils/storage';


@Component({
  selector: 'app-choose-account',
  templateUrl: './choose-account.component.html',
  styleUrls: ['./choose-account.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChooseAccountComponent  implements OnInit{
  timer: number = 600 * 60;
  edit: boolean;
  selectAccount: AccountType;
  allJwtAcounts = getStoredItem('jwts');

  public get UserPlaformRole() {
    return UserPlatformRole;
  }

  constructor(public nav: NavigateService,
    public api: ApiService,
    private lang: LangService,
    private loader: LoaderService,
    public sync: SyncService) {}
  ngOnInit() {
    this.selectAccount= this.api.accountJwt;
  }

  select(account: AccountType) {
    this.api.accountTransition = false;
    if(account.email !== this.api.accountJwt.email && !this.edit) {
      this.selectAccount = account;
      const body = this.selectAccount.jwt ? JSON.parse(atob(this.selectAccount.jwt.split('.')[1])) : '';
      if(!body) {
        return this.loader.loading(true, {type: 'warn', message: 'admin.reconnect'}).then(() => {
          this.nav.to('sign-in-pro', undefined, {state: {
            accountReconnect: this.selectAccount
          }});
        });
      }
      const currentTime = Math.floor(Date.now() / 1000);
      const isSelectAccountValid = body.exp > currentTime;
      if(isSelectAccountValid || this.api.getUserInfoOfJwt(this.selectAccount).role === UserPlatformRole.CUSTOMER) {
        this.api.signOut(false);
        this.api.accountJwt = this.selectAccount;
        this.api.checkJwt().then(() => {
          this.nav.toPin(undefined, 'user');
        });
      } else {
        this.loader.loading(true, {type: 'warn', message: this.lang.transform('accountPro.expired'), btnLabel: this.lang.transform('global.confirm')}).then((result: boolean) => {
          if(result) {
            this.nav.to('sign-in-pro', undefined, {state: {
              accountReconnect: this.selectAccount
            }});
          } else {
            this.selectAccount = this.api.accountJwt;
          }
        });
      }
    }
  }

  remove(i: number) {
    const accountToRemove = getStoredItem('jwts')[i];
    const userInfoAccountToRemove: any = this.api.getUserInfoOfJwt(accountToRemove);
    const isCurrentAccount = accountToRemove.email === this.api.accountJwt.email;
    const accountUserInfoCustomer: any = Object.values(getStoredItem('digid.cache.infos')).find((account: User) => account.role === UserPlatformRole.CUSTOMER);
    const accountJwtCustomer: any = getStoredItem('jwts').find((jwt: AccountType) => jwt.email === accountUserInfoCustomer.identifier || jwt.email === accountUserInfoCustomer.accountName);

    if(isCurrentAccount){
      this.loader.loading(true, {type: 'warn', message: this.lang.transform(`ask.${userInfoAccountToRemove.role === UserPlatformRole.WORKER ? 'workerSelf' : 'adminSelf'}.remove`), btnLabel: this.lang.transform('global.confirm')}).then((result: boolean) => {
        if(result) {
          this.api.signOut(false);
          this.loader.loading(true);
          this.api.accountJwt = accountJwtCustomer;
          this.api.allJwtAcounts.splice(i, 1);
          setStoredItem('jwts',  this.api.allJwtAcounts);
          this.api.checkJwt().then(() => {
            this.nav.to('user');
            setTimeout(() => {
              this.loader.loading(true, {type: 'info', message: this.lang.transform('change.fromAdmin')});
            }, 500);
          });
        }
      });
    } else {
      this.loader.loading(true, {type: 'warn', message: this.lang.transform(`ask.${userInfoAccountToRemove.role === UserPlatformRole.WORKER ? 'worker' : 'admin'}.remove`), btnLabel: this.lang.transform('global.confirm')}).then((result: boolean) => {
        if(result) {
          this.loader.loading(true);
          this.api.checkJwt().then(() => {
            this.loader.loading(false);
            this.api.allJwtAcounts.splice(i, 1);
            setStoredItem('jwts',  this.api.allJwtAcounts);
            this.nav.toPin(undefined, 'user');
            setTimeout(() => {
              this.loader.loading(true, {type: 'info', message: this.lang.transform('account.removed')});
            }, 500);
          });
        }
      });
    }
  }


  toggleEdit() {
    this.edit = !this.edit;
  }

  colorElement(accountJwt: AccountType){
    const infoAccountJwt = getStoredItem('digid.cache.infos')[accountJwt.email];
    if(!this.sync.isOnline && accountJwt.name !== this.api.accountJwt.name && +infoAccountJwt.role !== UserPlatformRole.CUSTOMER && !this.edit){
      if(!this.api.isJwtValid(accountJwt)){
        return 'red';
      }
    }  else {
      return undefined;
    }
  }

  rightIcon(accountJwt: AccountType) {
    const infoAccountJwt = getStoredItem('digid.cache.infos')[accountJwt.email];
    if(!this.sync.isOnline && +infoAccountJwt.role !== UserPlatformRole.CUSTOMER && accountJwt.name !== this.api.accountJwt.name && !this.edit){
      if(!this.api.isJwtValid(accountJwt)){
        return 'wifi';
      } else {
        return this.selectAccount?.email === accountJwt.email && !this.edit ? 'circle-check' : this.edit ? +infoAccountJwt.role !== UserPlatformRole.CUSTOMER ? 'circle-xmark' : '': 'circle';
      }
    } else {
      return this.selectAccount?.email === accountJwt.email && !this.edit ? 'circle-check' : this.edit ? +infoAccountJwt.role !== UserPlatformRole.CUSTOMER ? 'circle-xmark' : '': 'circle';
    }
  }

  disabled(accountJwt: AccountType) {
    const infoAccountJwt = getStoredItem('digid.cache.infos')[accountJwt.email];
    if(!this.sync.isOnline && accountJwt.name !== this.api.accountJwt.name && +infoAccountJwt.role !== UserPlatformRole.CUSTOMER  && !this.edit){
      if(!this.api.isJwtValid(accountJwt)){
        return true;
      } else {
        return this.edit ? +infoAccountJwt.role === 0 : false;
      }
    } else {
      return this.edit ? +infoAccountJwt.role === 0 : false;
    }
  }

  editMode(accountJwt: AccountType, index: number) {
    const infoAccountJwt = getStoredItem('digid.cache.infos')[accountJwt.email];
    const isSameAccount = accountJwt.email === this.api.accountJwt.email;
    if(isSameAccount) {
      return infoAccountJwt.role === UserPlatformRole.CUSTOMER ? undefined : this.edit ?  this.remove(index) : this.select(accountJwt);
    } else {
      if(!this.sync.isOnline && !this.api.isJwtValid(accountJwt) && accountJwt.name !== this.api.accountJwt.name && +infoAccountJwt.role !== UserPlatformRole.CUSTOMER  && !this.edit) {
        this.loader.loading(true, {type: 'warn', message: this.lang.transform('selectAdmin.offline.expired')});
      } else {
        return this.edit && +infoAccountJwt.role !== UserPlatformRole.CUSTOMER ? this.remove(index) : this.select(accountJwt);
      }
    }
  }

  disconnect() {
    this.loader.loading(true, {type: 'warn', message: this.lang.transform('ask.disconnect.allAccounts'), btnLabel: this.lang.transform('global.confirm')}).then((result: boolean) => {
      if(result) {
        this.api.signOut();
        setTimeout(() => {
          this.loader.loading(true, {type: 'valid', message: this.lang.transform('allAccounts.disconnected')});
        }, 500);
      }
    });
  }
}

