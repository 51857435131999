<div id="layout" class="f_col">
  <app-view-title bgImage="assets-countries/header_background.png" fromRoute="activation-code-info" toRoute="choose-lang" [chooseLangBtn]="true">{{'activation.code.info.title' | translate}} {{environment.center}}</app-view-title>
  <div class="scroll_wrapper">
    <app-view-deco-image imageUrl="assets-countries/undraw_place.svg"></app-view-deco-image>
    <app-paragraph>{{'activation.code.info.desc' | translate}}</app-paragraph>
  </div>
  <div class="f_col mb_30 mt_40">
    <app-button (btnClicked)="nav.toPin('activation-code-info', 'activation-code')" class="fullWidth" icon="qrcode">{{'activation.code.scan' | translate}}</app-button>
    <div class="flex mt_10">
      <app-back-btn [navTo]="{route: 'activation'}"></app-back-btn>
      <div class="fullWidth ml_10">
        <!-- <app-button color="secondary" (btnClicked)="nav.toPerm('activation-code-info', 'activation-code-map', 'ACCESS_FINE_LOCATION')" class="fullWidth" icon="location-dot"> {{'nearest.activation.point' | translate}}</app-button> -->
        <app-button color="secondary" (btnClicked)="nav.to('activation-code-map')" class="fullWidth" icon="location-dot"> {{'nearest.activation.point' | translate}}</app-button>
      </div>
    </div>
  </div>
</div>
