import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ApiService} from '../../../shared/services/api.service';
import {LangService, LoaderService, NavigateService} from 'ngx-satoris';
import {ScanService} from '../../../shared/services/scan.service';
import {SecureStorageService} from '../../../shared/services/secure-storage.service';

@Component({
  selector: 'app-activation-code',
  templateUrl: './activation-code.component.html',
  styleUrls: ['./activation-code.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ActivationCodeComponent implements OnInit {

  qrValue: string;
  showQR: boolean;
  interval: NodeJS.Timeout;
  time = 30;

  constructor(public nav: NavigateService,
    public api: ApiService,
    private secure: SecureStorageService,
    private loader: LoaderService,
    private lang: LangService,
    private scan: ScanService) {}

  ngOnInit(): void {
    this.start();
  }

  ngOnDestroy() {
    clearInterval(this.interval);
    this.qrValue = '';
    this.time = 30;
  }

  start() {
    this.loader.loadingElement('qr', true, 'lg');
    this.qrValue = '';
    if(!this.api.pinUnlocked) {
      this.nav.to('secret-code-valid');
    }
    clearInterval(this.interval);
    this.api.badge().then((res: string) => {
      this.secure.retrieveSecretKey().then(() => {
        const badge = this.secure.fullJwk ? (res += ('.' + btoa(JSON.stringify({device_x: this.secure.fullJwk.x, device_y: this.secure.fullJwk.y})))) : res;
        this.loader.loadingElement('qr', false);
        this.qrValue = this.scan.setQrCode(badge);
        this.showQR = true;
        this.interval = setInterval(() => {
          this.time--;
          if(this.time <= 0) {
            this.qrValue = '';
            this.showQR = false;
            clearInterval(this.interval);
            if(this.nav.demoMode) {
              this.time = 30;
              this.start();
            } else {
              this.api.pinUnlocked = false;
              this.nav.toPin('', 'activation-code');
            }
          }
        }, 1000);
      });
    }).catch(err => {
      this.loader.loading(true, {type: 'error', message: String(err).indexOf(': ') > -1 ? err : this.lang.transform('activation_code.badge_error')});
    });
  }

  goBack(){
    this.loader.loading(true);
    this.api.info().then(res => {
      this.loader.loading(false);
      if(res.nationalNumber){
        this.nav.to('user');
      } else {
        this.nav.to('activation-code-info');
      }
    }).catch(()=>{
      this.loader.loading(false);
      this.nav.to('activation-code-info');
    });
  }
}
