<div id="layout" class="home f_col">
  <app-btn-choose-lang fromRoute="home" toRoute="choose-lang" [absolute]="true"></app-btn-choose-lang>
  <img id="home_bg" ngSrc="assets-countries/home.png" width="200" height="200">
  <div class="f_col f_center f_grow f_align_c">
    <div id="home_logo">
      <img src="assets-countries/logo.svg" width="240" alt="">
      <img id="home_logo_shadow" src="assets-countries/logo_shadow.svg" alt="">
    </div>
  </div>
  <div class="f_col mb_30">
    <app-button (btnClicked)="nav.to('sign-up')">{{'createAccount' | translate}}</app-button>
    <div class="mt_10 fullWidth">
      <app-button class="fullWidth" (btnClicked)="nav.to('sign-in', undefined, {state: {signType: OtpType.EMAIL}})" color="secondary">{{'alreadyAccount' | translate}}</app-button>
    </div>
    <div class="mt_10 fullWidth">
      <app-button color="secondary" icon="headset" (btnClicked)="api.openSupport()" class="fullWidth">{{'global.help_center' | translate}}</app-button>
    </div>
  </div>
</div>
