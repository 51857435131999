import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {EnumToArrayPipe} from './enumToArray.pipe';

@NgModule({
  imports: [
    HttpClientModule,
    FormsModule
  ],
  exports: [
    EnumToArrayPipe
  ],
  declarations: [
    EnumToArrayPipe
  ]
})
export class SharedModule {
}
