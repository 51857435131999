import {Injectable, Injector, NgZone} from '@angular/core';
import {Network} from '@capacitor/network';
import {LangService, LoaderService} from 'ngx-satoris';
import {BehaviorSubject, Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {getStoredItem, setStoredItem} from '../utils/storage';
import {UserValidGuardService} from './user-valid-guard.service';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SyncService {

  isOnlineSubject = new BehaviorSubject<boolean>(false);

  get isOnline$() {
    return this.isOnlineSubject?.asObservable();
  }
  
  get isOnline(): boolean {
    return this.isOnlineSubject?.value;
  }

  isDataSkimRefetch = true;
  private onlineChange: Subject<void> = new Subject<void>();
  private offlineChange: Subject<void> = new Subject<void>();

  constructor(private loader: LoaderService,
    private lang: LangService,
    private zone: NgZone,
    private injector: Injector,
    private router: Router) {
    this.onlineChange.pipe(debounceTime(1000)).subscribe(() => {
      this.doSomethingWhenOnline();
    });
    this.offlineChange.pipe(debounceTime(1000)).subscribe(() => {
      this.doSomethingWhenOffline();
    });
  }

  init(): Promise<void> {
    const manualOffline = getStoredItem('manualOffline');
    return Network.getStatus().then(status => {
      if(manualOffline !== undefined) {
        this.isOnlineSubject.next(false);
      } else {
        this.isOnlineSubject.next(status.connected);
      }
      Network.addListener('networkStatusChange', status => {
        this.changeOnline(status.connected);
      });
    });
  }

  changeOnline(isOnline: boolean) {
    this.zone.run(() => {
      if(this.isOnlineSubject.value !== isOnline) {
        this.isOnlineSubject.next(isOnline);
        if(isOnline) {
          this.onlineChange.next();
        } else {
          this.isDataSkimRefetch = false;
          this.offlineChange.next();
        }
      }
    });
  }

  doSomethingWhenOnline() {
    const guard = this.injector.get(UserValidGuardService);
    const snapshot = this.router.routerState.snapshot.root;
    const childSnapshot = snapshot.firstChild;
    guard.checkAll(childSnapshot);
    this.loader.loading(true);
    guard.fetchDataDone.subscribe((isDone) => {
      if(isDone) {
        this.isDataSkimRefetch = true;
      }
    });
  }

  doSomethingWhenOffline() {
    const updateClaimsDates = getStoredItem('updateClaims') || {};

    if(updateClaimsDates.lastSkim) {
      delete updateClaimsDates.lastSkim;
      setStoredItem('updateClaims', updateClaimsDates);
    }

    const guard = this.injector.get(UserValidGuardService);
    const snapshot = this.router.routerState.snapshot.root;
    const childSnapshot = snapshot.firstChild;
    guard.checkAll(childSnapshot);
  }

  loaderOffline() {
    const isOnlineManual = getStoredItem('manualOffline');
    this.zone.run(() => this.loader.loading(true, {type: 'warn', message: this.lang.transform(isOnlineManual ? 'no.connection.server' : 'no.connection'), custom: {icon: 'wifi'}}));
  }
}
