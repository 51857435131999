import {Injectable} from '@angular/core';
import {FormError, FormService, NavigateService} from 'ngx-satoris';
import {FormBuilder, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {enrollFC, EnrollForm, EnrollStep, FC, MRZType, StepConfig} from '../models/enroll';
import {environment} from '../../../environments/environment';
import {NfcService} from './nfc.service';
import {ApiService} from './api.service';
import {UserPlatformRole} from '../models/user';

declare const SatorisOcr: any;

export const enrollStepPaths: {[key: string]: string} = {
  [EnrollStep.CHOICE]: 'activation-step-choice',
  [EnrollStep.SCAN_MRZ]: 'activation-step-scan-mrz',
  [EnrollStep.SCAN_MRZ_CHOICE]: 'activation-step-scan-mrz-choice',
  [EnrollStep.SCAN_MRZ_SCAN]: 'activation-step-scan-mrz-scan',
  [EnrollStep.SCAN_MRZ_NFC]: 'activation-step-scan-mrz-nfc',
  [EnrollStep.DOC_NUMBER]: 'activation-step-doc-number',
  [EnrollStep.DOC_EXPIRATION_DATE]: 'activation-step-doc-exp-date',
  [EnrollStep.DATE_OF_BIRTH]: 'activation-step-date-of-birth',
  [EnrollStep.DRAW_SIGNATURE]: 'activation-step-draw-signature',
  [EnrollStep.TAKE_FACE_PICTURE]: 'activation-step-take-face-picture',
  [EnrollStep.ASK_CAN]: 'activation-step-ask-can',
  [EnrollStep.ASK_PIN]: 'activation-step-ask-pin',
  [EnrollStep.NFC_SCAN_ID_CARD]: 'activation-step-nfc-scan-id-card',
  [EnrollStep.CONFIRM]: 'activation-step-confirm',
  [EnrollStep.ASK_NNI]: 'activation-step-ask-nni',
  [EnrollStep.QR_SCAN]: 'activation-step-scan-qr',
  [EnrollStep.MANAGE_CUSTOMER]: 'activation-step-manage-customer',
  [EnrollStep.FORM_USER]: 'activation-step-form-user',
  [EnrollStep.ASK_DEVICE_NAME]: 'activation-step-ask-device-name',
  [EnrollStep.FINGERPRINT]: 'activation-step-fingerprint',
  [EnrollStep.VOUCHER]: 'activation-step-voucher',
  [EnrollStep.DESC_TAKE_FACE_PICTURE]: 'activation-step-desc-take-face-picture'
};

@Injectable({
  providedIn: 'root'
})
export class EnrollService {
  stepsConfig: StepConfig[] = [];
  enrollForm: EnrollForm;
  currentConfigIndex = 0;
  currentStepIndex = 0;
  currentChoiceIndex: number | undefined = undefined;
  step = EnrollStep;
  form: FormGroup;
  fc = FC;
  uploadDrawSignature = false;
  MRZTypes = MRZType;
  MRZType: MRZType = undefined;
  submittedForm = false;

  constructor(private api: ApiService,
              private nav: NavigateService,
              private formBuilder: FormBuilder,
              private forms: FormService,
              private nfc: NfcService) {}

  setForm() {
    this.form = this.formBuilder.group({});
    this.getEnrollFormList().forEach((fc: FC) => {
      this.form.addControl(fc, this.formBuilder.control('', this.getEnrollFCValidators(fc)));
    });
  }

  setCustomValidators(formControl: string, validators: ValidatorFn[] | ValidatorFn, replace = false) {
    if(replace) {
      this.form.get(formControl).setValidators(validators);
    } else {
      this.form.get(formControl).addValidators(validators);
    }
    this.form.get(formControl).updateValueAndValidity();
  }

  setConfig() {
    switch(this.api.userInfo.role) {
    case UserPlatformRole.SUPER_ADMIN:
      this.stepsConfig = environment.stepsConfigSuperAdmin;
      this.enrollForm = environment.enrollFormAdmin;
      break;
    case UserPlatformRole.ADMIN:
      this.stepsConfig = environment.stepsConfigAdmin;
      this.enrollForm = environment.enrollFormAdmin;
      break;
    default:
      this.stepsConfig = environment.stepsConfig;
      this.enrollForm = environment.enrollForm;
      break;
    }
  }

  submitForm(_step: EnrollStep) {
    this.submittedForm = true;
    if(this.forms.getFormErrors(this.form).length < 1) {
      this.navigate('next');
    }
  }

  navigate(direction: 'next' | 'previous', numberNavigate = 0) {
    if(this.stepsConfig[this.currentConfigIndex].type === 'STEP') {
      if(direction === 'next') {
        if(this.currentStepIndex < this.stepsConfig[this.currentConfigIndex].steps.length - 1) { //check if is not the last step
          this.currentStepIndex++;
        } else if(this.stepsConfig[this.currentConfigIndex + 1]) { //if is the last step, check if there is a next configItem
          this.currentConfigIndex++;
          this.currentStepIndex = 0;
        } else {
          this.nav.to('activation-step-done');
          return;
        }
      } else {
        if(this.currentStepIndex > 0) { //check if is not the first step
          this.currentStepIndex--;
        } else if(this.stepsConfig[this.currentConfigIndex - 1]) { //if is the first step, check if there is a previous configItem
          this.currentConfigIndex--;
          if(this.stepsConfig[this.currentConfigIndex].type === 'STEP') {
            this.currentStepIndex = this.stepsConfig[this.currentConfigIndex].steps.length - 1;
          }
          if(this.stepsConfig[this.currentConfigIndex].type === 'CHOICE') {
            this.currentStepIndex = this.stepsConfig[this.currentConfigIndex].steps[this.currentChoiceIndex].length - 1;
          }
        } else {
          this.nav.to('activation-step-intro');
          return;
        }
      }
    } else if(this.stepsConfig[this.currentConfigIndex].type === 'CHOICE') {
      if(direction === 'next') {
        if(this.currentStepIndex < this.stepsConfig[this.currentConfigIndex].steps[this.currentChoiceIndex].length - 1) { //check if is not the last step
          this.currentStepIndex++;
        } else if(this.stepsConfig[this.currentConfigIndex + 1]) { //if is the last step of the choice, check if there is a next configItem
          this.currentConfigIndex++;
          this.currentStepIndex = 0;
        } else {
          this.nav.to('activation-step-done');
          return;
        }
      } else {
        if(this.currentStepIndex > 0) { //check if is not the first step
          this.currentStepIndex--;
        } else if(this.stepsConfig[this.currentConfigIndex - 1]) { //if is the first step, check if there is a previous choice
          if(this.stepsConfig[this.currentConfigIndex - 1].type === 'STEP' && this.currentChoiceIndex >= 0) {
            this.currentStepIndex = 0;
            this.currentChoiceIndex = undefined; //ADDED
          } else if(this.stepsConfig[this.currentConfigIndex - 1].type === 'STEP') {
            this.currentConfigIndex--;
            this.currentChoiceIndex = undefined; //ADDED
            this.currentStepIndex = this.stepsConfig[this.currentConfigIndex].steps.length - 1;
          }
          if(this.stepsConfig[this.currentConfigIndex - 1]?.type === 'CHOICE') {
            this.currentStepIndex = this.stepsConfig[this.currentConfigIndex].steps[this.currentChoiceIndex].length - 1;
          }
        } else if(this.currentChoiceIndex >= 0) { //if is the first step of the choice, check if there is a previous choice
          this.currentChoiceIndex = undefined;
          this.currentStepIndex = 0;
        } else {
          if(this.stepsConfig[this.currentConfigIndex].type === 'CHOICE') {
            this.currentStepIndex = this.stepsConfig[this.currentConfigIndex - 1].steps.length -1;
            if(this.currentConfigIndex > 0) {
              this.currentConfigIndex --;
            }
          } else {
            this.nav.to('activation-step-intro');
            return;
          }
        }
      }
    }
    if(numberNavigate > 0) {
      for(let i = 0; i < numberNavigate; i++) {
        this.navigate(direction);
      }
    }
    this.switchToStep();
  }

  switchToStep() {
    let interval: NodeJS.Timeout;

    switch(this.getCurrentStep()) {
    case EnrollStep.CHOICE:
      this.nav.to(enrollStepPaths[EnrollStep.CHOICE]);
      break;
    case EnrollStep.SCAN_MRZ:
      environment.ocrType = parseInt(SatorisOcr.OCRType.BLUR, 10);
      interval = setInterval(() => {
        if(SatorisOcr) {
          this.nav.to(enrollStepPaths[EnrollStep.SCAN_MRZ]);
          clearInterval(interval);
        }
      }, 100);
      break;
    case EnrollStep.NFC_SCAN_ID_CARD:
      this.nfc.isNFCEnabled().then(() => {
        this.nav.to('activation-step-nfc-scan-id-card');
      }).catch(() => {
        this.navigate('previous');
        this.nfc.isNFCEnabled(true);
      });
      break;
    default:
      this.nav.to(enrollStepPaths[this.getCurrentStep()]);
    }
  }

  getChoice(): EnrollStep[] {
    const choices = this.stepsConfig[this.currentConfigIndex].steps as EnrollStep[][];
    const firstChoices: EnrollStep[] = [];
    choices.forEach((choice: EnrollStep[]) => {
      firstChoices.push(choice[0]);
    });
    return firstChoices;
  }

  getChoiceString(): string {
    return this.getChoice().map((choice: EnrollStep) => EnrollStep[choice]).join('.').toLowerCase();
  }

  selectChoice(choice: EnrollStep) {
    this.currentChoiceIndex = this.getChoice().indexOf(choice);
    this.currentStepIndex = 0;
    this.switchToStep();
  }

  getCurrentStep(): EnrollStep {
    let currentSteps: EnrollStep[] = [];
    const currentConfig = this.stepsConfig[this.currentConfigIndex];
    if(currentConfig.type === 'STEP') {
      currentSteps = currentConfig.steps;
    }
    if(this.currentChoiceIndex !== undefined && currentConfig.type === 'CHOICE') {
      currentSteps = currentConfig.steps[this.currentChoiceIndex];
    }
    return currentSteps.length > 0 ? currentSteps[this.currentStepIndex] : EnrollStep.CHOICE;
  }

  showNextButton(): boolean {
    return (this.stepsConfig[this.currentConfigIndex].type === 'STEP' || this.currentChoiceIndex >= 0) && this.getCurrentStep() !== this.step.CONFIRM;
  }

  getEnrollFormList(): string[] {
    const fcs: string[] = [];
    Object.keys(this.enrollForm).forEach((key: string) => {
      fcs.push(key);
    });
    return fcs;
  }

  getEnrollFCValidators(fc: FC): ValidatorFn[] {
    const validators: ValidatorFn[] = [];
    if(enrollFC[fc].required) {
      validators.push(Validators.required);
    }
    if(enrollFC[fc].minLength) {
      validators.push(Validators.minLength(enrollFC[fc].minLength));
    }
    if(enrollFC[fc].maxLength) {
      validators.push(Validators.maxLength(enrollFC[fc].maxLength));
    }
    if(enrollFC[fc].pattern) {
      validators.push(Validators.pattern(enrollFC[fc].pattern));
    }
    if(enrollFC[fc].min) {
      validators.push(Validators.min(enrollFC[fc].min));
    }
    if(enrollFC[fc].max) {
      validators.push(Validators.max(enrollFC[fc].max));
    }
    return validators;
  }

  areAllControlsValid(): boolean {
    if(!this.api.userRole?.isAdmin) return true;
    let allValid = true;
    for(const controlName of environment.controlsToCheckAdmin) {
      if(!this.form?.controls[controlName]?.valid) {
        allValid = false;
        break;
      }
    }
    return allValid;
  }

  areAnyControlsInvalidAndTouched(): boolean {
    if(!this.api.userRole?.isAdmin) return false;
    return environment.controlsToCheckAdmin.some(ctrl => {
      const control = this.form.controls[ctrl];
      return control && !control.valid && control.touched;
    });
  }

  getFilteredFormErrors(): FormError[] {
    if(!this.api.userRole?.isAdmin) return [];
    const errors: FormError[] = this.forms.getFormErrors(this.form);
    const filteredErrors: FormError[] = [];
    for(const error of errors) {
      if(environment.controlsToCheckAdmin.includes(error.key as FC)) {
        filteredErrors.push(error);
      }
    }
    return filteredErrors;
  }
}
