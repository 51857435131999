import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Place} from '../../../shared/models/place';
import {User} from '../../../shared/models/user';
import {ApiService} from '../../../shared/services/api.service';
import {
  FormService,
  LangService,
  LoaderService
} from 'ngx-satoris';

@Component({
  selector: 'app-dialog-choose-place',
  templateUrl: './dialog-choose-place.component.html',
  styleUrls: ['./dialog-choose-place.component.scss']
})
export class DialogChoosePlaceComponent implements OnInit {

  places: Place[];
  form: FormGroup;
  submitted: boolean;

  constructor(public dialogRef: MatDialogRef<DialogChoosePlaceComponent>,
    public forms: FormService,
    @Inject(MAT_DIALOG_DATA) public user: User,
    private api: ApiService,
    private lang: LangService,
    private formBuilder: FormBuilder,
    private loader: LoaderService) { }

  ngOnInit(): void {
    this.setForm();
    this.getPlaces();
  }

  setForm() {
    this.form = this.formBuilder.group({
      place: ['', [
        Validators.required
      ]]
    });
  }

  submitForm() {
    this.submitted = true;
    if(this.forms.getFormErrors(this.form).length < 1) {
      const res: Place = this.places.find(x => x.id === this.form.get('place').value);
      this.closeDialog(res);
    }
  }

  getPlaces() {
    //this.loader.loading(true);
    if(this.api.userRole?.isSuperAdmin) {
      this.api.searchPlace().then(res => {
        this.places = res.result;
        this.loader.loading(false);
      }).catch(err => this.loader.loading(true, {type: 'error', message: String(err).indexOf(': ') > -1 ? err : this.lang.transform('api.searchPlace.error')}));
    } else {
      this.api.listPlaces(this.user.links.map(l => l.place_id)).then(res => {
        this.places = res.filter(x => x.id !== '');
        //this.loader.loading(false);
      }).catch(err => {
        this.loader.loading(true, {type: 'error', message: String(err).indexOf(': ') > -1 ? err : this.lang.transform('api.listPlaces.error')});
      });
    }
  }

  changePlace(e: any) {
    this.form.controls.place.setValue(e.target.value, {
      onlySelf: true
    });
  }

  hasPlace(place: Place): boolean {
    return this.user?.links.some(x => x.place_id === place.id);
  }

  closeDialog(place?: Place) {
    this.dialogRef.close(place);
  }
}
