import {Component, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DevicesService, LoaderService, NavigateService} from 'ngx-satoris';
import {ClaimShareParams} from 'src/app/shared/models/claim';
import {ApiService} from 'src/app/shared/services/api.service';
import {InitializeService} from 'src/app/shared/services/initialize.service';
import {isObjectEmpty} from 'src/app/shared/utils/verification';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SubscriptionComponent {
  data : {
    fromRoute: string
  };
  params: ClaimShareParams;
  isPromo = false;

  constructor(private router: Router, 
              public api: ApiService,
              public nav: NavigateService,
              public init: InitializeService,
              public devices: DevicesService,
              private route : ActivatedRoute,
              private loader: LoaderService) {
    this.data = <any> this.router.getCurrentNavigation().extras.state;
    this.route.queryParams.subscribe((params: any) => {
      if(!isObjectEmpty(params)) {
        this.params = {
          claimType: JSON.parse(params.claimType),
          isoMode: JSON.parse(params.isoMode),
          selectedClaims: params.selectedClaims,
          fromRoute: 'claim-card'
        };
      }
    });
  }

  activateSubscription() {
    this.init.purchase(this.init.products[0]).then((isPurchase: boolean) => {
      if(isPurchase) {
        this.nav.to('user');
      }
    });
  }

  restoreSubscription() {
    this.loader.loading(true);
    this.init.restore().then(() => {
      this.loader.loading(false);
      this.nav.to('user');
    });
  }

  follow(link: string) {
    window.open(link, '_blank');
  }
}
