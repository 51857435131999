import {Component, NgZone, OnDestroy, ViewEncapsulation} from '@angular/core';
import {ApiService} from '../../../../shared/services/api.service';
import {EnrollService} from '../../../../shared/services/enroll.service';
import {Subscription, TimeoutError} from 'rxjs';
import {DevicesService, LangService, LoaderService, NavigateService} from 'ngx-satoris';
import {NfcService} from '../../../../shared/services/nfc.service';
import {ScanService} from '../../../../shared/services/scan.service';
import {SecureStorageService} from 'src/app/shared/services/secure-storage.service';
import {environment} from '../../../../../environments/environment';
import {InitializeService} from 'src/app/shared/services/initialize.service';
import {parseError} from 'src/app/shared/utils/string';
import {PrivacyScreen} from '@capacitor-community/privacy-screen';

@Component({
  selector: 'app-activation-step-nfc-scan-id-card',
  templateUrl: './activation-step-nfc-scan-id-card.component.html',
  styleUrls: ['./activation-step-nfc-scan-id-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ActivationStepNfcScanIdCardComponent implements OnDestroy {

  subject: string[] = [];
  progress = 0;
  speed = 1;

  private sub: Subscription;

  constructor(public nav: NavigateService,
    public nfc: NfcService,
    public api: ApiService,
    public enroll: EnrollService,
    private zone: NgZone,
    private lang: LangService,
    private loader: LoaderService,
    private secure: SecureStorageService,
    private scan: ScanService,
    private device: DevicesService,
    public initialize: InitializeService) {
    if(this.device.isDevices('cordova') && environment.production) PrivacyScreen.disable();
    this.nfc.claimIdCard = undefined;
    if(this.api.userInfo.usesLoginOtp || !this.api.userInfo.nationalNumber) {
      this.start(this.api.userRole.isAdmin);
    } else {
      this.loader.loading(true, {type: 'info', message: 'nfc.demo', isWaiting: true, waitTimer: 5000}).then(() => {
        this.enroll.navigate('next');
      });
    }

    if(this.api.userInfo.nationalNumber && this.api.userRole.isCustomer) {
      this.api.setPrincipalMode = true;
    }
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
    this.initialize.isScanning = false;
    if(this.device.isDevices('cordova')) {
      this.nfc.stop();
    }
    if(this.device.isDevices('cordova') && environment.production) PrivacyScreen.enable();
    this.api.setPrincipalMode = false;
  }

  start(admin?: boolean) {
    try {
      this.nfc.init();
      this.nfc.scan().then(() => {
        this.sub = this.nfc.cardFound$.subscribe(() => {
          this.scanCard(admin);
        });
      });
    } catch (err) {
      this.loader.loading(true, {
        type: 'error',
        message: this.lang.transform('nfc.read.nfc_notfound')
      }).then(() => this.nav.to(this.api.userRole.isCustomer ? 'activation' : 'manage-user'));
    }
  }

  scanCard(_admin?: boolean) {
    this.sub.unsubscribe();
    this.zone.run(() => {
      this.progress = 50;
    });
    this.initialize.isScanning = true;
    this.nfc.getCertificate(true, true, this.enroll.form.controls[this.enroll.fc.CAN].value).then(() => {
      this.nfc.readIcao(false, this.enroll.form.controls[this.enroll.fc.CAN].value).then(() => {
        this.progress = 80;
        this.checkIcao();
        if(!_admin) {
          // customer with PIN
          if(this.enroll.form.controls[this.enroll.fc.PIN]?.value && this.enroll.form.controls[this.enroll.fc.CAN]?.value) {
            this.submit(this.enroll.form.controls[this.enroll.fc.PIN].value);
          } else { // customer without PIN, facecheck on next
            this.progress = 100;
            this.speed = 0.1;
            this.enroll.navigate('next');
          } 
        // admin with fingerprint
        } else if(this.enroll.form?.controls[this.enroll.fc.FINGERPRINT]?.value.image && this.nfc.icao.personalDetails?.personalNumber){
          this.api.testFinger(+this.scan.userScanData.data.sig, this.enroll.form.controls[this.enroll.fc.FINGERPRINT].value.image, this.nfc.icao.personalDetails?.personalNumber, +this.enroll.form.controls[this.enroll.fc.FINGERPRINT].value.finger).then(() => {
            this.api.activateSc(+this.scan.userScanData.data.sig, this.enroll.form.controls[this.enroll.fc.DEVICE_XY].value.x, this.enroll.form.controls[this.enroll.fc.DEVICE_XY].value.y, this.enroll.form.controls[this.enroll.fc.DEVICE_NAME].value || 'myDevice', this.nfc.icao.sodRaw,
              this.nfc.icao.mrz.raw, this.nfc.icao.face.raw, this.nfc.icao.personalDetails.raw, this.nfc.icao.documentDetails.raw, this.enroll.form.controls[this.enroll.fc.SIGNATURE].value, this.nfc.icao.personalDetails.personalNumber).then(() => {
              this.progress = 100;
              this.speed = 0.1;
            }).catch(err => {
              const error = parseError(err);
              console.error(error);
              this.zone.run(()=>{
                this.onCompleteError('adminActivate', error.tradError);
              });
            });
          }).catch(err => {
            console.error(err);
            this.zone.run(()=>{
              this.loader.loading(true, {type: 'error', message: err?.includes('client.extended.noFingerAuth') ? 'client.extended.noFingerAuth' : err})
                .then(() => this.enroll.navigate('previous'));
            });
          });
        } else { // admin without fingerprint
          this.api.activateSc(+this.scan.userScanData.data.sig, this.enroll.form.controls[this.enroll.fc.DEVICE_XY].value.x, this.enroll.form.controls[this.enroll.fc.DEVICE_XY].value.y, this.enroll.form.controls[this.enroll.fc.DEVICE_NAME].value || 'myDevice', this.nfc.icao.sodRaw,
            this.nfc.icao.mrz.raw, this.nfc.icao.face.raw, this.nfc.icao.personalDetails.raw, this.nfc.icao.documentDetails.raw, this.enroll.form.controls[this.enroll.fc.SIGNATURE].value, this.nfc.icao.personalDetails.personalNumber).then(() => {
            this.progress = 100;
            this.speed = 0.1;
          }).catch(err => {
            const error = parseError(err);
            this.zone.run(()=>{
              this.onCompleteError('adminActivate', error.tradError);
            });
          });
        }
      }).catch(() => {
        this.zone.run(() => {
          this.progress = 0;
          this.sub?.unsubscribe();
          this.nfc.stop();
          this.loader.loading(true, {type: 'error', message: this.lang.transform('nfc.error.icao')}).then(() => {
            this.initialize.isScanning = false;
            if(this.api.userRole.isAdmin) {
              this.start(true);
            } else {
              this.start();
            }
          });
        });
      });
    }).catch(() => {
      this.zone.run(() => {
        this.onCompleteError();
      });
    });
  }

  submit(pin: string) {
    const pem = this.nfc.pkiCert.toString('base64');
    this.api.rearmSc(this.api.userInfo.accountName, pem, [pem]).then(params => {
      this.sign(pin, params.sigStatusChallenge, params.salt);
    }).catch((err) => {
      this.progress = 0;
      const error= parseError(err);
      this.loader.loading(true, {
        type: 'error',
        message: error.tradError
      }).then(()=>this.enroll.navigate('previous', 1));
    });
  }

  sign(pin: string, sigStatusChallenge: string, salt: string) {
    this.nfc.sign(true, pin, sigStatusChallenge, this.enroll.form.controls[this.enroll.fc.CAN].value).then(pkcs12 => {
      this.zone.run(() => {
        this.secure.retrieveSecretKey().then(() => {
          this.api.confirmSc(this.api.userInfo.id, salt, pkcs12, this.secure.fullJwk.x, this.secure.fullJwk.y, this.enroll.form.controls[this.enroll.fc.DEVICE_NAME].value || 'myDevice', this.nfc.icao.sodRaw,
            this.nfc.icao.mrz.raw, this.nfc.icao.face.raw, this.nfc.icao.personalDetails.raw, this.nfc.icao.documentDetails.raw, this.enroll.form.controls[this.enroll.fc.SIGNATURE].value).then(() => {
            this.progress = 100;
            this.speed = 0.1;
          }).catch((err: any) => {
            const error = parseError(err);
            if(err instanceof TimeoutError) {
              return this.enroll.navigate('previous');
            }
            if(error.baseError === 'client.extended.customerExists') {
              return this.onCompleteError('resetProcess', error.tradError);
            }
            if(this.nfc.icao.pin !== 0) {
              this.nfc.icao.pin -= 1;
            }
            this.zone.run(()=>{
              if(this.nfc.icao?.pin === 0) {
                this.zone.run(()=>{
                  this.onCompleteError('noMorePin');
                });
              } else {
                this.zone.run(() => {
                  this.onCompleteError('pin', error.tradError);
                });
              }
            });
          });
        });
      });
    }).catch(() => {
      this.zone.run(()=>{
        if(this.nfc.icao?.pin === 0) {
          this.onCompleteError('noMorePin');
        } else {
          this.onCompleteError('pin');
        }
      });
    });
  }

  onComplete() {
    this.enroll.navigate('next');
  }

  onCompleteError(error?: 'pin' | 'noMorePin' | 'adminActivate' | 'resetProcess', serverError?: string) {
    this.nfc.stop();
    this.initialize.isScanning = false;
    this.progress = 0;
    this.zone.run(() => {
      let msgError: string;
      let redirect: string;
      switch(error) {
      case 'pin':
        this.enroll.form.controls[this.enroll.fc.PIN].setValue(undefined);
        msgError = 'nfc.read.confirmSc.error';
        redirect = 'nfc-read-pin';
        break;
      case 'noMorePin':
        msgError = 'nfc.read.noMorePinTries';
        redirect = 'activation';
        break;
      case 'adminActivate':
        msgError = 'nfc.read.adminActivate';
        redirect = 'manage-user';
        break;
      case 'resetProcess':
        redirect = 'activation';
        break;
      default :
        this.enroll.form.controls[this.enroll.fc.CAN].setValue(undefined);
        msgError = 'nfc.read.self.canError';
        redirect = 'nfc-read-can';
      }
      this.loader.loading(true, {
        type: 'error',
        message: serverError || this.lang.transform(msgError)
      }).then(()=>{
        if(redirect === 'nfc-read-pin') {
          this.enroll.navigate('previous');
        } else if(redirect === 'nfc-read-can') {
          this.enroll.navigate('previous', 2);
        } else {
          this.nav.to(redirect);
        }
      });
    });
  }

  checkIcao() {
    if(!this.nfc.claimIdCard) {
      this.nfc.pkiCert?.subject.typesAndValues.forEach((item: any) => {
        this.subject.push(item.value.valueBlock.value);
      });
      this.nfc.claimIdCard = undefined;
      this.nfc.claimIdCard = {
        date_of_birth: this.nfc.icao.mrz.dateOfBirth,
        expiry_date: this.nfc.icao.mrz.dateOfExpiry,
        first_name: [this.subject[4]],
        middle_name: [],
        last_name: [this.subject[5]],
        gender: this.nfc.icao.mrz.gender,
        image_url: this.nfc.icao.face.data,
        mrz: {line1: '', line2: '', line3: ''},
        nationality: this.nfc.icao.mrz.nationality,
        place_of_birth: this.nfc.icao.personalDetails.birthPlace,
        can: +this.enroll.form.controls[this.enroll.fc.CAN].value,
        signature: this.nfc.signature,
        height: 0,
        profession: this.nfc.icao.personalDetails.profession,
        doc_nr: this.nfc.icao.mrz.documentNumber,
        personal_address: this.nfc.icao.personalDetails.personalAddress,
        telephone_number: '+' + this.nfc.icao.personalDetails.telephoneNumber.substring(0, 3) + ' ' + this.nfc.icao.personalDetails.telephoneNumber.substring(4, this.nfc.icao.personalDetails.telephoneNumber.length).replace(/(.{3})/g, '$1-'),
        personal_number: Number(this.nfc.icao.personalDetails.personalNumber)
      };
    }
  }
}
