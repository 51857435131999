import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {IconProp, NavigateService} from 'ngx-satoris';
import {ClaimSelfAlt, ClaimType, ClaimSelfDetails, ClaimsSelf} from 'src/app/shared/models/claim';
import {ApiService} from 'src/app/shared/services/api.service';
import {PersonnalDatasService} from 'src/app/shared/services/personnal-datas.service';

@Component({
  selector: 'app-personnal-data-type',
  templateUrl: './personnal-data-type.component.html',
  styleUrls: ['./personnal-data-type.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PersonnalDataTypeComponent implements OnInit{

  type: ClaimSelfAlt & ClaimType;
  subType: {icon : IconProp, label: string}[];

  constructor(public api: ApiService,
    public nav: NavigateService,
    private route: ActivatedRoute,
    private personnalDatas: PersonnalDatasService) {
    this.route.params.subscribe(params => {
      this.type = params.type as ClaimType & ClaimSelfAlt;
    });
  }

  ngOnInit() {
    const check = this.personnalDatas.countryClaims[this.type];
    this.subType = Object.keys(check)
      .filter(key => {
        const item = check?.[key as keyof typeof check];
        return item;
      })
      .map(key => {
        if(key in ClaimType) {
          const item = check?.[key as keyof typeof check] as ClaimSelfDetails;
          const metaData = item?.metadataValue?.[0];
          if(metaData) {
            return {icon: metaData?.icon || undefined, label: metaData?.label || '', type: key};
          }
        }
      }).filter(Boolean);
  }

  stateVisual(item: ClaimType & ClaimSelfAlt): { icon: IconProp, color: string } { 
    const actualSubClaimSelf = (this.personnalDatas.selfClaims[this.type] as ClaimsSelf[keyof ClaimsSelf])?.[item];
    const claimState = (actualSubClaimSelf as ClaimSelfDetails)?.state;
    return this.personnalDatas.stateVisual(claimState);
  }
}
