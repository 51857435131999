import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {LangService, LoaderService, NavigateService} from 'ngx-satoris';
import {ApiService} from '../../shared/services/api.service';
import {EnrollService} from '../../shared/services/enroll.service';
import {ActivatedRoute} from '@angular/router';
import {OtpType} from '../../shared/models/user';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit {
  window = window;
  params: any = {};

  constructor(public nav: NavigateService,
    public api: ApiService,
    public enroll: EnrollService,
    private route: ActivatedRoute,
    private lang: LangService,
    private loader: LoaderService) {}

  ngOnInit() {
    this.params.first = this.route.snapshot.queryParams.first;
    if(this.params.first) {
      this.nav.to('home');
      setTimeout(() => {
        this.loader.loading(true, {type: 'valid', message: this.lang.transform('just.activatedAccount')});
      }, 500);
    }
  }

  protected readonly OtpType = OtpType;
}
