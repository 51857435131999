import {Claim} from '../models/claim';

declare const window: any;
declare const CBOR: any;

export const checkValidityOfIssuerAuth = (claim: Claim, remainingMills: number): boolean => {
  if(!window.CBOR) return true;
  try {
    if(claim?.issuerAuth) {
      const checkAuth = CBOR.decode(claim.issuerAuth);
      if(checkAuth && checkAuth.value && checkAuth.value[2]) {
        const firstLevelDecoded = CBOR.decode(checkAuth.value[2]);
        if(firstLevelDecoded && firstLevelDecoded.value) {
          const secondLevelDecoded = CBOR.decode(firstLevelDecoded.value);
          if(secondLevelDecoded && secondLevelDecoded.validityInfo && secondLevelDecoded.validityInfo.validUntil) {
            const validUntilDate = secondLevelDecoded.validityInfo.validUntil;
            return validUntilDate > Date.now() + remainingMills;
          }
        }
      }
    } else {
      return false;
    }
  } catch (err) {
    console.error('An error occurred while checking the validity of the issuerAuth:', err);
    return false;
  }
  return true;
};

export const isObjectEmpty = (obj: Record<string, any>): boolean  => Object.keys(obj).length === 0;