import {Component, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Claim, ClaimCedeaoCard, ClaimIdCard, ClaimResidentCard, ScanShareData} from '../../../shared/models/claim';
import {ApiService} from '../../../shared/services/api.service';
import {LoaderService, NavigateService} from 'ngx-satoris';
import {countries, Country} from '../../../shared/models/countries';
import {differenceInYears} from 'date-fns';

@Component({
  selector: 'app-scan-share',
  templateUrl: './scan-share.component.html',
  styleUrls: ['./scan-share.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ScanShareComponent {
  data: ScanShareData;
  claims: Claim[] = [];
  claimsRotations: number[] = [];
  claimsRotationsSmall: number[] = [];
  unfold = false;
  accepted = false;

  constructor(public nav: NavigateService,
              public api: ApiService,
              private route: ActivatedRoute,
              private loader: LoaderService) {
    this.route.queryParams.subscribe((params: any) => {
      if(params) this.data = params;
      if(this.data?.service) {
        this.claims = Object.values(this.api.claims).filter(c => this.api.userInfo.server.callbacks[this.data.service].types.indexOf(c.type) > -1);
        //Line below is TO TEST different claims
        //this.claims = [this.api.claims[this.api.ct.ID_CARD], this.api.claims[this.api.ct.RESIDENT_CARD], this.api.claims[this.api.ct.ADULT], this.api.claims[this.api.ct.DRIVING_LICENSE]];
        if(!this.claims) {
          this.error();
        } else {
          for(let i = 0; i < this.claims.length; i++) {
            const minMax = this.claims.length > 1 ? 20 : 5;
            this.claimsRotations.push(this.randomInt((-minMax), minMax));
            this.claimsRotationsSmall.push(this.randomInt(-2, 2));
          }
        }
      }
    });
  }

  checkAge(): {adult: boolean, countryAge: number} {
    const id: ClaimIdCard | ClaimResidentCard | ClaimCedeaoCard = this.api.claims[this.api.ct.ID_CARD] ?? this.api.claims[this.api.ct.RESIDENT_CARD] ?? this.api.claims[this.api.ct.CEDEAO_CARD];
    const country: Country = countries.find(x => x.name === id.nationality);
    return {adult: differenceInYears(new Date(), new Date(id.date_of_birth)) > country.age, countryAge: country.age};
  }

  doUnfold() {
    this.unfold = true;
  }

  accept() {
    this.unfold = false;
    this.accepted = true;
    this.nav.toPin('scan-share', 'scan-share-result', true, undefined, {state: {...this.data, claims: this.claims}}, 0);
  }

  error() {
    this.nav.to('user');
    setTimeout(() => {
      this.loader.loading(true, {type: 'error', message: 'api.postClaim.error'});
    }, 300);
  }

  randomInt(min: number, max: number) { // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  }
}
