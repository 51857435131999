<div id="layout" class="f_col">
  <app-view-title bgImage="assets-countries/header_background.png" fromRoute="account-valid" extras="{{JSON.stringify(data)}}"   
  toRoute="choose-lang" [chooseLangBtn]="true">{{'accountValid.title' | translate}}</app-view-title>
  <div class="scroll_wrapper">
    <app-view-deco-image imageUrl="assets-countries/image_done.svg"></app-view-deco-image>
    <div class="f_col gap_10 flex">
      <app-paragraph  class="mt_10" textAlign="center">{{'accountValid.desc' | translate}}</app-paragraph>
      <div class="f_col f_align_c f_center f_grow mt_40 gap_10">
        <app-paragraph class="fw_bold" textAlign="center" *ngIf="data.signType === OtpType.EMAIL">{{'accountValid.email.desc' | translate}}</app-paragraph>
        <app-paragraph class="fw_bold" textAlign="center" *ngIf="data.signType === OtpType.PHONE">{{'accountValid.phone.desc' | translate}}</app-paragraph>
      </div>
    </div>
  </div>
  <div class="mb_30 mt_40 flex gap_5">
    <app-back-btn  icon="home" (btnClicked)="nav.to('sign-in')"></app-back-btn>
    <app-button #buttonResendOtp disableTime="60" color="primary" class="fullWidth" (btnClicked)="resendLink()">{{(data.signType === OtpType.EMAIL ? 'otp.resend.email' : 'otp.resend.phone') | translate}}</app-button>
  </div>
</div>
