import {I18nLang} from 'ngx-satoris';
import {ModelInterface} from './entity';

export enum ArticleType {
    INTERNAL_PRO = 0,
    INTERNAL_ADMIN = 1,
    GOVERNMENT_NEWS = 2,
    LEGAL_UPDATE = 3,
    REPORTS_AND_ANALYSIS = 4,
    INTERVIEWS = 5,
    LOCAL_NEWS = 6,
    INTERNATIONAL_NEWS = 7,
    OPINIONS_AND_EDITORIALS = 8,
    SPECIAL_REPORTS = 9,
    GUIDES_AND_TUTORIALS = 10,
    PRACTICAL_TIPS = 11,
    FAQ = 12,
    GOVERNMENT_CAMPAIGNS = 13,
    ENVIRONMENT_AND_SUSTAINABILITY = 14,
    CULTURAL_EVENTS = 15,
    CULTURAL_HERITAGE = 16,
    SPORTS_NEWS = 17,
    POP_CULTURE = 18
}

export interface Article extends ModelInterface<number> {
    title: string;
    image: string;
    description: string;
    type: ArticleType;
    visibilityFrom: string;
    visibilityTo: string;
    date: string;
    location: string;
    link: string;
    lang: I18nLang;
    content: string;
    metaId: string;
    creator_id: number;
}
