<div id="layout" class="f_col layout_sm">
    <app-view-title bgImage="assets-countries/header_background.png" [popUp]="true" (popUpEmit)="popupHelper()">{{'manage.personnal.pending' | translate}}</app-view-title>
    <div class="scroll_wrapper">
        <div *ngIf="personnalDatas.allCustomerSelfClaims.length > 0" class="mt_20 flex f_col gap_10" (scroll)="onScroll($event)">
            <div *ngFor="let claim of personnalDatas.allCustomerSelfClaims">
                <app-desc-card [leftIconColor]="checkAssignedAt(claim?.id, claim?.assignedAt, true)" [smSizeL]="true" leftIcon="{{claim?.icon}}" descL="{{(claim?.type + '.DYN.title') | translate}}" descR1="{{'#'+(truncateString(claim?.id, 4).toUpperCase())}}" descR2="{{calculateTimeAgo(claim?.assignedAt)}}" (btnClicked)="checkAssignedAt(claim?.id, claim?.assignedAt)"></app-desc-card>
            </div>
        </div>
        <app-paragraph textAlign="center" *ngIf="personnalDatas.allCustomerSelfClaims.length === personnalDatas.scrollNumberOpenSelfClaims && !isReload" class="user-maxLength mt_10">{{'uptodate' | translate}} 🎉</app-paragraph>
        <div *ngIf="personnalDatas.allCustomerSelfClaims.length === 0" class="mt_20 flex f_col gap_10">
          <app-paragraph textAlign="center">{{'noAllClaimSelf' | translate}}</app-paragraph>
      </div>
        <div class="loader_bottom mt_10"></div>
    </div>
    <div class="f_align_end">
      <div class="fullWidth flex gap_10 mb_20">
        <app-back-btn (btnClicked)="nav.to('manage-user')"></app-back-btn>
        <app-button autoStart="true" disableTime="10" color="primary" class="fullWidth" (btnClicked)="refetchClaimAdmin()">{{'global.refresh' | translate}}</app-button>
      </div>
    </div>
  </div>
  