import {Component, ViewEncapsulation} from '@angular/core';
import {ApiService} from '../../../../shared/services/api.service';
import {EnrollService} from '../../../../shared/services/enroll.service';

@Component({
  selector: 'app-activation-step-scan-mrz',
  templateUrl: './activation-step-scan-mrz.component.html',
  styleUrls: ['./activation-step-scan-mrz.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ActivationStepScanMrzComponent {

  constructor(public api: ApiService, public enroll: EnrollService) {}

}
