import {Component, ViewEncapsulation} from '@angular/core';
import {ApiService} from '../../shared/services/api.service';
import {LangService, LoaderService, NavigateService, PinData} from 'ngx-satoris';
import {Router} from '@angular/router';

@Component({
  selector: 'app-forgot-pin',
  templateUrl: './forgot-pin.component.html',
  styleUrls: ['./forgot-pin.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ForgotPinComponent {
  data: {state: PinData, queryParams: any};

  constructor(public api: ApiService, 
    public nav: NavigateService, 
    private router: Router,
    private loader: LoaderService,
    private lang: LangService) {
    this.data = <any> this.router.getCurrentNavigation()?.extras;
  }

  resetPin() {
    this.loader.loading(true, {type: 'warn', message: this.lang.transform('ask.signout.resetPin'), btnLabel: this.lang.transform('confirm')}).then((done) =>{
      if(done) {
        return this.api.resetPinLogout().then((isLogout: boolean) => {
          if(isLogout) {
            this.loader.loading(true, {type: 'valid', message: 'success.resetPinLogout'});
            this.api.signOut(true);
          } else {
            this.loader.loading(true, {type: 'error', message: 'error.resetPinLogout'});
          }
        });
      }
    });
  }
}
