import {Component, ViewEncapsulation} from '@angular/core';
import {ApiService} from '../../../../shared/services/api.service';
import {EnrollService} from '../../../../shared/services/enroll.service';
import {FormService} from 'ngx-satoris';
import {subYears, format} from 'date-fns';

@Component({
  selector: 'app-activation-step-date-of-birth',
  templateUrl: './activation-step-date-of-birth.component.html',
  styleUrls: ['./activation-step-date-of-birth.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ActivationStepDateOfBirthComponent {
  maxDate = format(new Date(), 'yyyy-MM-dd');
  minDate = format(subYears(new Date(), 150), 'yyyy-MM-dd');

  constructor(public api: ApiService, 
    public enroll: EnrollService, 
    public forms: FormService) {
    if(this.api.userInfo.nationalNumber && this.api.userRole.isCustomer) {
      this.api.setPrincipalMode = true;
    }
  }

  ngOnDestroy() {
    this.api.setPrincipalMode = false;
  }
}
