<div id="layout" class="f_col layout_sm">
  <app-view-title bgImage="assets-countries/header_background.png" class="portrait_only">{{('claim.DYN.' + params.claimType) | translate}}</app-view-title>
  <div class="scroll_wrapper">
    <div class="f_grow f_col f_center">
      <div id="rotate_mobile" class="mt_20 mb_10">
        <span class="mb_10">{{'identity_card.rotate' | translate}}</span>
        <fa-icon icon="mobile-alt"></fa-icon>
      </div>
      <div id="idCard" class="f_grow f_center f_align_c" (click)="!init.isPro && nav.to('subscription', undefined, {state: {fromRoute: 'claim-card'},queryParams: params})">
        <app-card [style.pointer-events]="init.isPro ? 'auto' : 'none'" *ngIf="this.claim" [imageRecto]="this.claim?.imageUrlRecto" [imageVerso]="this.claim?.imageUrlVerso"></app-card>
      </div>
    </div>
    <div class="mb_10 f_col portrait_only">
      <app-button [disabledStyle]="!init.isPro" (btnClicked)="init.isPro ? share() : nav.to('subscription', undefined, {state: {fromRoute: 'claim-card'},queryParams: params})">
        {{'global.share' | translate}}
      </app-button>
      <div class="flex mt_20">
        <app-back-btn [navTo]="{route: 'user'}"></app-back-btn>
        <div class="ml_10 fullWidth">
          <app-button color="secondary" (btnClicked)="nav.to('claim-data', undefined, {queryParams: params})">{{'global.more' | translate}}</app-button>
        </div>
      </div>
    </div>
  </div>
</div>
