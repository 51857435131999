import {Component, ViewEncapsulation} from '@angular/core';
import {ApiService} from '../../../shared/services/api.service';
import {NavigateService} from 'ngx-satoris';

@Component({
  selector: 'app-manage-news',
  templateUrl: './manage-news.component.html',
  styleUrls: ['./manage-news.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ManageNewsComponent {
  constructor(public api: ApiService, public nav: NavigateService) {
  }

}
