import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {LangService, LoaderService, NavigateService} from 'ngx-satoris';
import {Router} from '@angular/router';
import {ApiService} from 'src/app/shared/services/api.service';
import {ScanService} from 'src/app/shared/services/scan.service';
import {EnrollService} from 'src/app/shared/services/enroll.service';

@Component({
  selector: 'activation-step-qr-scan',
  templateUrl: './activation-step-qr-scan.component.html',
  styleUrls: ['./activation-step-qr-scan.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ActivationStepQrScanComponent implements OnInit{

  type: string;
  constructor(public nav: NavigateService,
    public api: ApiService,
    private scan: ScanService,
    private loader: LoaderService,
    private router: Router,
    private lang: LangService,
    public enroll: EnrollService) {
    this.type = <any> this.router?.getCurrentNavigation()?.extras?.state?.type;
    this.api.savedForm = undefined;
  }

  ngOnInit() {
    this.loader.loadingElement('scan', true, 'lg');
  }

  hasStarted() {
    this.loader.loadingElement('scan', false);
  }

  scanResult(val: string) {
    const checkQrCode = this.scan.checkQrCode(val);
    if(!checkQrCode) {
      this.nav.to('user');
      setTimeout(() => {
        this.loader.loading(true, {type: 'error', message: 'qr.invalid'});
      });
    } else {
      return this.scan.setUserScanValue(checkQrCode).then(res => {
        this.scan.userScanData = res;
        if(res?.xy) {
          this.enroll.form.controls[this.enroll.fc.DEVICE_XY].setValue({x: res.xy.device_x, y: res.xy.device_y});
        }
        if(res.data.sig && !res.data.nat) {
          this.enroll.form.controls[this.enroll.fc.USER_ID].setValue(res.data.sig);
          this.enroll.navigate('next');
        }
      }).catch(() => {
        this.enroll.navigate('previous');
        setTimeout(() => {
          this.loader.loading(true, {type: 'error', message: this.lang.transform('qr.invalid')});
        }, 100);
      });
    }
  }
}
