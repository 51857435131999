<div id="layout" class="f_col"  [class.layout_sm]="!api.userRole.isCustomer">
  <app-view-title bgImage="assets-countries/header_background.png" fromRoute="activation-step-scan-mrz-scan" toRoute="choose-lang"
                  [chooseLangBtn]="api.userRole.isCustomer && true">
    {{'activation.step.scan_mrz_scan.title' | translate}}
  </app-view-title>
  <div id="cameraPreview"></div>
  <div class="scroll_wrapper">
    <app-paragraph style="margin-top: {{optionsCamera.height + 60}}px" class="mb_30" innerhtml="{{'activation.step.scan_mrz_scan.desc' | translate}}"></app-paragraph>
    <div class="f_end f_grow f_col">
      <div class="flex f_between">
        <app-back-btn (btnClicked)="enroll.navigate('previous')"></app-back-btn>
        <div class="fullWidth ml_10">
          <app-button [color]="isFlash ? 'warn' : 'primary'" (btnClicked)="toggleFlash()" icon="lightbulb"
                      class="fullWidth">
            {{(isFlash ? 'camera.turnFlashOff' : 'camera.turnFlashOn') | translate}}
          </app-button>
        </div>
      </div>
    </div>
  </div>
</div>
