import {Component, Input} from '@angular/core';
import {ClaimType, ScanBadgeData} from '../../shared/models/claim';
import {format} from 'date-fns';
import {SyncService} from '../../shared/services/sync.service';

@Component({
  selector: 'app-badge-card',
  templateUrl: './badge-card.component.html'
})
export class BadgeCardComponent {
  @Input() badgeData: ScanBadgeData;

  constructor(public sync: SyncService) {}

  dateNow(): string {
    return format(new Date(), 'dd/MM/yyyy - HH:mm');
  }

  protected readonly ClaimType = ClaimType;
}
