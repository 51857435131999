import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {IconProp, LangService, LoaderService, NavigateService} from 'ngx-satoris';
import {ClaimSelfAlt, ClaimSelfDetails, ClaimSelfState, ClaimType, ClaimsSelf} from 'src/app/shared/models/claim';
import {ApiService} from 'src/app/shared/services/api.service';
import {PersonnalDatasService} from 'src/app/shared/services/personnal-datas.service';
import {isObjectEmpty} from 'src/app/shared/utils/verification';

@Component({
  selector: 'app-personnal-data',
  templateUrl: './personnal-data.component.html',
  styleUrls: ['./personnal-data.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PersonnalData implements OnInit{

  ClaimsTypes: (string | ClaimType | ClaimSelfAlt)[] = [];
  isObjectEmpty = isObjectEmpty;

  constructor(public api: ApiService,
    public nav: NavigateService,
    public loader: LoaderService,
    public lang: LangService,
    public personnalDatas: PersonnalDatasService) {
  }

  ngOnInit() {
    this.ClaimsTypes = Object.entries(this.personnalDatas.countryClaims)
      .filter(([_, value]) => value !== undefined)
      .map(([key]) => key);
  }

  back() {
    this.nav.to('user');
  }


  stateByClaimType(type: ClaimType & ClaimSelfAlt): { icon: IconProp, color: string } {
    const claimSelf: ClaimsSelf & ClaimSelfDetails = this.personnalDatas?.selfClaims?.[type];
    let highestPriorityState: any = null;
    if(claimSelf) {
      const hasClaimType = Object.keys(claimSelf).some((key: any) => ClaimType[key] !== undefined);
      if(hasClaimType) {
        for(const subClaim of Object.values(claimSelf)) {
          highestPriorityState = this.getHighestPriorityState(highestPriorityState, subClaim?.state?.value);
        }
      } else {
        highestPriorityState = this.getHighestPriorityState(highestPriorityState, claimSelf?.state?.value);
      }
    }
    return this.personnalDatas.stateVisual(highestPriorityState);
  }

  getHighestPriorityState(currentHighest: ClaimSelfState | null, newClaimState: ClaimSelfState | null): {value : ClaimSelfState | null} {
    if(newClaimState === ClaimSelfState.CHANGING || currentHighest === ClaimSelfState.CHANGING) {
      return {value: ClaimSelfState.CHANGING};
    }
    if(newClaimState === ClaimSelfState.REJECTED || currentHighest === ClaimSelfState.REJECTED) {
      return {value: ClaimSelfState.REJECTED};
    }
    if(newClaimState === ClaimSelfState.PENDING || currentHighest === ClaimSelfState.PENDING) {
      return {value: ClaimSelfState.PENDING};
    }
    if(newClaimState === ClaimSelfState.APPROVED || currentHighest === ClaimSelfState.APPROVED) {
      return {value: ClaimSelfState.APPROVED};
    }
    return {value: null};
  }
}
