<div class="modal_news {{classList}}"
     (click)="clicked.emit()"
     [style.background]="bgImage ? 'url(' + bgImage + ') center center no-repeat, linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0))' : 'url(./assets/images/article_blank.png), linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0))'">
  <div class="news_container">
    <h3 class="news_title">{{title}}
      <fa-icon *ngIf="hasLink" class="ml_4" icon="arrow-up-right-from-square"></fa-icon>
    </h3>
    <h4 class="news_subtitle">{{subtitle}}</h4>
  </div>
  <span class="news_date" *ngIf="date">{{date}}</span>
</div>
