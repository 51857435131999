import {Component, ViewEncapsulation} from '@angular/core';
import {ApiService} from '../../../../shared/services/api.service';
import {EnrollService} from '../../../../shared/services/enroll.service';
import {FormService} from 'ngx-satoris';

@Component({
  selector: 'app-activation-step-ask-can',
  templateUrl: './activation-step-ask-can.component.html',
  styleUrls: ['./activation-step-ask-can.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ActivationStepAskCanComponent {

  constructor(public api: ApiService,
    public enroll: EnrollService,
     public forms: FormService) {
    if(this.api.userInfo.nationalNumber && this.api.userRole.isCustomer) {
      this.api.setPrincipalMode = true;
    }
  }

  ngOnDestroy() {
    this.api.setPrincipalMode = false;
  }

}
