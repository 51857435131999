import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Place} from '../../shared/models/place';
import {AccountType} from '../../shared/models/user';
import {ApiService} from '../../shared/services/api.service';
import {
  DevicesService,
  FormService, LangService,
  LoaderService,
  NavigateService
} from 'ngx-satoris';
import {getStoredItem, setStoredItem} from 'src/app/shared/utils/storage';
import {Router} from '@angular/router';
import {parseError} from 'src/app/shared/utils/string';
import {TimeoutError} from 'rxjs';
import {SecureStorageService} from 'src/app/shared/services/secure-storage.service';

@Component({
  selector: 'app-sign-in-pro',
  templateUrl: './sign-in-pro.component.html',
  styleUrls: ['./sign-in-pro.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SignInProComponent implements OnInit {
  form: FormGroup;
  submitted: boolean;
  askChoosePlace = false;
  accounts: AccountType[] = getStoredItem('jwts') || [];
  initUserNNI: string;
  data: { state?: {pin?: string, accountReconnect : { email: string}, identifier?: string}};

  constructor(public forms: FormService,
    public nav: NavigateService,
    public api: ApiService,
    private lang: LangService,
    private formBuilder: FormBuilder,
    private router: Router,
    private loader: LoaderService,
    private secure: SecureStorageService,
    private devices: DevicesService) {
    this.data = <any> this.router.getCurrentNavigation()?.extras;
  }

  ngOnInit(): void {
    this.setForm();
  }

  setForm() {
    this.form = this.formBuilder.group({
      identifier: [this.data?.state?.accountReconnect?.email ? this.data.state.accountReconnect.email : this.data.state?.identifier || '', [
        Validators.required,
        Validators.pattern(/^[a-z0-9]{10}$/)
      ]],
      password: ['', [
        Validators.required
      ]]
    }, {
      validator: this.forms.customPatternValidator('password', [/[A-Z]/, /[a-z]/, /[0-9]/, /[~`!@#$%^&*()\-_+={[}\]|:;"'<,>.?/]/], 3)
    });
  }

  submitForm() {
    this.submitted = true;
    if(this.forms.getFormErrors(this.form).length < 1 && this.submitted && this.api.userInfo.nationalNumber) {
      const allAccounts = getStoredItem('jwts') || [];
      let shouldProceed = true;
      if(allAccounts.length > 0) {
        allAccounts.forEach((element: AccountType) => {
          if(element.email === this.form.get('identifier').value) {
            if(element?.jwt) {
              const currentTime = Math.floor(Date.now() / 1000);
              const isExpired = JSON.parse(atob(element.jwt.split('.')[1])).exp < currentTime + 1;
              if(!isExpired){
                shouldProceed = false;
                this.loader.loading(true, {type: 'error', message: this.lang.transform('user.alreadyAdded')});
              }
            }
          }
        });
      }
      if(shouldProceed) {
        this.api.userPlaceId = undefined;
        this.loginWeak();
      }
    }
  }

  remove(i: number) {
    this.accounts.splice(i, 1);
    setStoredItem('jwts', this.accounts);
  }

  loginWeak(placeId?: string): void {
    this.login(undefined, placeId, undefined);
  }

  login(name?: string, placeId?: string, place?: Place) {
    this.initUserNNI = JSON.parse(JSON.stringify(this.api.userInfo.nationalNumber));
    this.loader.loading(true);
    const previousUserInfo = this.api.userInfo;
    return this.api.consumeLoginWeak(this.form.get('identifier').value, this.form.get('password').value, undefined,
      placeId ? placeId : undefined, undefined, name).then(() => {
      this.loader.loading(false);
      const accountJwt = this.api.accountJwt;
      this.api.signOut(false);
      const index = this.accounts.findIndex(object => object.email === this.form.get('identifier').value);
      this.api.accountJwt = accountJwt;
      if(index > -1) {
        this.accounts[index] = accountJwt;
      } else {
        this.accounts.push(accountJwt);
      }
      setStoredItem('jwts', this.accounts);
      this.api.currentPlace = place;
      return this.finishLogin(previousUserInfo);
    }).catch(err => {
      if(!(err instanceof TimeoutError)) {
        const error = parseError(err);
        this.loader.loading(true, {type: 'error', message: error.tradError});
      } else {
        this.loader.loading(false);
      }
    });
  }

  private finishLogin(previousUserInfo: any): any {
    this.loader.loading(true);
    return this.api.info(false, false, false).then(() => {
      this.loader.loading(false);
      if(this.api.userInfo.nationalNumber !== this.initUserNNI) {
        this.api.signOut(true);
        this.loader.loading(true, {type: 'error', message: this.lang.transform('login.invalid')});
      } else {
        if((this.api.userInfo.links.length > 0) && !this.api.userPlaceId && !this.api.currentPlace) {
          if(this.api.userInfo.links.length > 1) {
            this.loader.loading(true);
            return this.api.listPlaces(this.api.userInfo?.links?.map(l => l.place_id)).then(res => {
              this.loader.loading(false);
              this.api.userPlaces = res.filter(x => x.id !== '');
              this.askChoosePlace = true;
            }).catch(() => {
              this.loader.loading(false);
              this.returnToCustomerAccount(previousUserInfo);
            });
          } else {
            this.loader.loading(false);
            const auto_name = `${this.api.role[this.api.userInfo.role]}@${this.api.userInfo.links[0].place_id}`;
            this.login(auto_name, this.api.userInfo.links[0].place_id);
          }
        } else {
          this.pinRedirect(undefined, previousUserInfo);
        }
      }
    }).catch(err => {
      const errorMessage = !(err instanceof TimeoutError)
        ? String(err).indexOf(': ') > -1 ? err : this.lang.transform('api.info.error')
        : this.lang.transform('call.timeout');
      this.loader.loading(true, {type: 'error', message: errorMessage});
      this.returnToCustomerAccount(previousUserInfo);
      this.api.pinUnlocked = true;
    });
  }

  private pinRedirect(choosePlaceID = '', previousUserInfo?: any) {
    const placeID = choosePlaceID || this.api.userInfo.links[0].place_id;
    if(!this.api.userInfo?.pinHash) {
      this.loader.loading(true);
      return this.api.setPin(this.data.state.pin, undefined).then(() => {
        this.api.userInfo.pinHash = true;
        this.loader.loading(false);
        return this.api.place(placeID, false);
      }).then(resPlace => {
        this.api.currentPlace = resPlace;
        if(this.devices.isDevices('cordova')) {
          return this.secure.setEncryptSecureDatas('place', JSON.stringify(resPlace)).then(() => {
            this.loader.loading(true, {type: 'question', message: 'ask.signinpro.reset.password', btnLabel: 'global.yes', custom: {closeBtnLabel: 'global.no', disableClose: true}}).then((done) => {
              done ? this.nav.toPin('sign-in-pro', 'change-password', true, undefined, undefined) : this.nav.toPin('', 'user');
            });
          });
        } else {
          this.loader.loading(true, {type: 'question', message: 'ask.signinpro.reset.password', btnLabel: 'global.yes', custom: {closeBtnLabel: 'global.no', disableClose: true}}).then((done) => {
            done ? this.nav.toPin('sign-in-pro', 'change-password', true, undefined, undefined) : this.nav.toPin('', 'user');
          });
        }
      }).catch(err => {
        if(!(err instanceof TimeoutError)) {
          const errorMessage = !(err instanceof TimeoutError)
            ? String(err).indexOf(': ') > -1 ? err : this.lang.transform('api.setPin.error')
            : this.lang.transform('timeout.error');
          this.loader.loading(true, {type: 'error', message: errorMessage});
        }
        this.returnToCustomerAccount(previousUserInfo);
      });
    } else {
      this.loader.loading(true);
      return this.api.place(placeID, false).then(resPlace => {
        this.api.currentPlace = resPlace;
        if(this.devices.isDevices('cordova')) {
          return this.secure.setEncryptSecureDatas('place', JSON.stringify(resPlace)).then(() => {
            this.nav.toPin('', 'user');
            this.loader.loading(false);
          });
        } else {
          this.nav.toPin('', 'user');
          this.loader.loading(false);
        }
      }).catch(err => {
        const errorMessage = !(err instanceof TimeoutError)
          ? String(err).indexOf(': ') > -1 ? err : this.lang.transform('choose_place.exchange_error')
          : this.lang.transform('call.timeout');
        this.loader.loading(true, {type: 'error', message: errorMessage});
        this.returnToCustomerAccount(previousUserInfo);
        this.api.pinUnlocked = true;
      });
    }
  }

  returnToCustomerAccount(previousUserInfo?: any){
    const customerAccount = this.api.getCustomerAccount();
    const storedAccount = getStoredItem('jwts');
    const updatedJwts = storedAccount.filter((account: any) => account.email === customerAccount.email);
    this.api.signOut(false);
    this.api.accountJwt = customerAccount;
    setStoredItem('jwts', updatedJwts);
    this.api.userInfo = previousUserInfo || getStoredItem('digid.cache.infos')[customerAccount.email];
    this.api.setUserRole(previousUserInfo || getStoredItem('digid.cache.infos')[customerAccount.email]);
  }

  choosePlace(place: Place) {
    this.loader.loading(true);
    const previousAccount = this.api.accountJwt;
    const previousJwts = getStoredItem('jwts');
    const previousJwt = getStoredItem('jwt');
    const previousUserInfo = this.api.userInfo;

    return this.api.exchange(place.id, undefined, undefined, true).then((res: any) => {
      const email = JSON.parse(JSON.stringify(this.api.accountJwt.email));
      const auto_name = JSON.parse(JSON.stringify(`${this.api.role[this.api.userInfo.role]}@${place.longName}`));
      const identifier = JSON.parse(JSON.stringify(this.api.userInfo.identifier));

      this.api.signOut(false);

      const account: AccountType = {
        name: auto_name,
        email: email,
        jwt: res.jwt
      };

      this.api.accountJwt = account;

      const index = this.accounts.findIndex(object => object.email === identifier);

      if(index > -1) {
        this.accounts[index] = account;
      } else {
        this.accounts.push(account);
      }

      setStoredItem('jwts', this.accounts);
      setStoredItem('jwt', account);

      return this.api.info(false);
    }).then(() => this.api.place(place.id)).then(resPlace => {
      this.loader.loading(false);
      this.api.currentPlace = resPlace;
      if(this.devices.isDevices('cordova')) {
        return this.secure.setEncryptSecureDatas('place', JSON.stringify(resPlace))
          .then(() => this.pinRedirect(place.id, previousUserInfo));
      } else {
        this.pinRedirect(place.id, previousUserInfo);
      }
    }).catch(err => {
      this.loader.loading(false);
      if(!(err instanceof TimeoutError)) {
        const errorMessage = String(err).indexOf(': ') > -1 ? `Error - ${err}` : this.lang.transform('api.error');
        this.loader.loading(true, {type: 'error', message: errorMessage});
      }
      this.api.accountJwt = previousAccount;
      setStoredItem('jwts', previousJwts);
      setStoredItem('jwt', previousJwt);
      this.api.userInfo = previousUserInfo;
    });
  }

  backBtn(){
    if(this.askChoosePlace){
      this.returnToCustomerAccount();
      this.askChoosePlace = false;
      this.submitted = false;
      this.api.pinUnlocked = true;
      this.setForm();
    } else {
      this.nav.to('more');
    }
  }
}
