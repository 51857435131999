<div id="layout" class="f_col">
  <app-view-title bgImage="assets-countries/header_background.png" fromRoute="activation" toRoute="choose-lang" [chooseLangBtn]="true">{{'activation.title' | translate}}</app-view-title>
  <div class="scroll_wrapper">
    <app-view-deco-image maxWidth="300px" imageUrl="assets-countries/undraw_runner.svg"></app-view-deco-image>
    <app-paragraph>{{'activation.desc' | translate}}</app-paragraph>
  </div>
  <div class="f_col mb_30 mt_40">
    <app-button (btnClicked)="nav.to('activation-code-info')" class="fullWidth">{{'activation.code_info' | translate}} {{environment.center}}</app-button>
    <div class="mt_10 fullWidth"> <!--*ngIf="devices.isDevices('cordova')"-->
      <app-button [disabledStyle]="!hasNfc" (btnClicked)="accountSelfActivation()" class="fullWidth">{{'activate.self' | translate}}</app-button>
    </div>
    <div class="mt_10 fullWidth">
      <app-button color="secondary" icon="headset" (btnClicked)="api.openSupport()" class="fullWidth">{{'global.help_center' | translate}}</app-button>
    </div>
    <div class="mt_10 fullWidth">
      <app-button color="warn" (btnClicked)="askDisconnect()" class="fullWidth">{{'header.disconnect' | translate}}</app-button>
    </div>
  </div>
</div>
