import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {ApiService} from '../../shared/services/api.service';
import {
  FormService, LangService,
  LoaderService,
  NavigateService
} from 'ngx-satoris';
import {getStoredItem} from '../../shared/utils/storage';
import {OtpType} from 'src/app/shared/models/user';

declare const window: Window & {initialQueryString: string[][]};

@Component({
  selector: 'app-recover',
  templateUrl: './recover.component.html',
  styleUrls: ['./recover.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RecoverComponent implements OnInit {

  form: FormGroup;
  submitted: boolean;
  pswdStrength: number;
  accountName: string;
  data: {proAccount: boolean, signType?: OtpType, email?: string, phone?: string};
  recoverMode: boolean;

  constructor(public forms: FormService,
    public nav: NavigateService,
    public router: Router,
    public api: ApiService,
    private formBuilder: FormBuilder,
    private lang: LangService,
    private loader: LoaderService) {
    const recoverHolder = window.initialQueryString.find((q: string[]) => q[0] === 'recover');
    this.data = <any>this.router.getCurrentNavigation().extras.state;
    if(recoverHolder) {
      this.accountName = decodeURIComponent(recoverHolder[1]);
      this.recoverMode = true;
    } else if(getStoredItem('recover')) {
      this.accountName = getStoredItem('recover');
    }
  }

  ngOnInit(): void {
    this.setForm();
  }

  setForm() {
    this.form = this.formBuilder.group({
      password: ['', [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(20)
      ]],
      password2: ['',
        Validators.required
      ],
      code: ['',
        Validators.required
      ]
    }, {
      validator: this.forms.mustMatch('password', 'password2')
    });
  }

  submitForm() {
    this.submitted = true;
    if(this.forms.getFormErrors(this.form).length < 1) {
      this.loader.loading(true);
      this.api.resetPassword(this.accountName, this.form.get('code').value, this.form.get('password').value).then(() => {
        this.loader.loading(false);
        this.nav.to('password-changed', undefined, {state: {proAccount: this.data?.proAccount}});
      }, (err: any) => this.loader.loading(true, {type: 'error', message: String(err).indexOf(': ') > -1 ? err : this.lang.transform('recover.resetPassword.error')}));
    }
  }

  checkPswdStrength() {
    const password = this.form.get('password').value;
    let score = 0;
    const letters: any = {};

    for(let i = 0; i < password.length; i++) {
      letters[password[i]] = (letters[password[i]] || 0) + 1;
      score += 5.0 / letters[password[i]];
    }

    const variations: any = {
      digits: /\d/.test(password),
      lower: /[a-z]/.test(password),
      upper: /[A-Z]/.test(password),
      nonWords: /\W/.test(password)
    };

    let variationCount = 0;
    for(const check in variations) {
      variationCount += (variations[check]) ? 1 : 0;
    }
    score += (variationCount - 1) * 10;
    this.pswdStrength = Math.trunc(score) <= 100 ? Math.trunc(score) : 100;
  }
}
