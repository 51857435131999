<div id="layout" class="f_col" [class.layout_sm]="!api.userRole.isCustomer">
  <app-view-title bgImage="assets-countries/header_background.png" fromRoute="activation-step-date-of-birth" toRoute="choose-lang" [chooseLangBtn]="api.userRole.isCustomer && true">
    {{'activation.step.date_of_birth.title' | translate}}
  </app-view-title>
  <div class="scroll_wrapper">
    <app-view-deco-image class="mb_10" maxHeight="unset" maxWidth="300px" imageUrl="assets-countries/undraw_birthday_cake.svg"></app-view-deco-image>
    <app-paragraph innerhtml="{{'activation.step.date_of_birth.desc' | translate}}"></app-paragraph>
    <div class="f_grow mt_20">
      <form id="form" [formGroup]="enroll.form" (ngSubmit)="enroll.submitForm(enroll.getCurrentStep())">
        <app-input type="date" [minDate]="minDate" [maxDate]="maxDate" [formGrp]="enroll.form" [formCtrlName]="enroll.fc.DATE_OF_BIRTH" label="form.dateOfBirth" icon="calendar"></app-input>
        <app-errors-list [dark]="true" *ngIf="!enroll.form.controls[enroll.fc.DATE_OF_BIRTH].valid && enroll.form.controls[enroll.fc.DATE_OF_BIRTH].touched" [formErrors]="forms.getFormErrors(enroll.form, enroll.fc.DATE_OF_BIRTH)"></app-errors-list>
      </form>
    </div>

    <div class="f_align_end f_grow ">
      <app-back-btn (btnClicked)="enroll.navigate('previous')"></app-back-btn>
      <div class="fullWidth ml_10">
        <app-button [disabled]="!enroll.form.controls[enroll.fc.DATE_OF_BIRTH].valid" (btnClicked)="enroll.navigate('next')" class="fullWidth">
          {{'global.continue' | translate}}
        </app-button>
      </div>
    </div>
  </div>
</div>
