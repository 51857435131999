<div class="activityElement mt_4" [class.activityElementAlt]="item.type === 10"
     *ngFor="let item of inputArray; let i = index">
  <div class="f_between fullWidth ellipsis">
    <div class="ellipsis" [ngSwitch]="item.type">
      <span *ngSwitchCase="0"><fa-icon icon="user-plus" size="lg"></fa-icon></span>
      <span *ngSwitchCase="1"><fa-icon icon="user-check" size="lg"></fa-icon></span>
      <span *ngSwitchCase="3"><fa-icon icon="key" size="lg"></fa-icon></span>
      <span *ngSwitchCase="4"><fa-icon icon="check" size="lg"></fa-icon></span>
      <span *ngSwitchCase="6"><fa-icon icon="circle-plus" size="lg"></fa-icon></span>
      <span *ngSwitchCase="7"><fa-icon icon="circle-minus" size="lg"></fa-icon></span>
      <span *ngSwitchCase="8"><fa-icon icon="circle-down" size="lg"></fa-icon></span>
      <span *ngSwitchCase="9"><fa-icon icon="share" size="lg"></fa-icon></span>
      <span *ngSwitchCase="10"><fa-icon icon="share-nodes" size="lg"></fa-icon></span>
      <span *ngSwitchCase="11"><fa-icon icon="ban" size="lg"></fa-icon></span>
      <span *ngSwitchCase="12"><fa-icon icon="user-lock" size="lg"></fa-icon></span>
      <span *ngIf="contexts[i].type !== undefined" class="ml_10">{{('activity.claim.type.DYN' + contexts[i].type) | translate}}</span>
      <span *ngIf="contexts[i].type === undefined" class="ml_10">{{('activity.type.DYN' + item.type) | translate }}</span>
    </div>
    <div *ngIf="((item.actor_id !== userInfo.id) && (item.target_id !== userInfo.id))" class="activityActor">
      #{{item.actor_id}}
    </div>
      <!-- TODO: Change arrow width with flex_grow/shrink to fill the space insted of fixed -->
    <div *ngIf="((item.target_id !== null) && (item.target_id !== userInfo.id))" class="activityTarget">
      #{{item.target_id}}
    </div>
    <div *ngIf="((item.target_id !== null) && (item.target_id == userInfo.id))" class="activityTarget">
      #{{item.actor_id}}
    </div>
  </div>
  <!-- TODO: Maybe change styles a bit to better separte place from date  -->
  <div class="activityInfo">
    <span *ngIf="(item.place_id !== null) && (item.place_id !== userPlaceId)">
      <fa-icon>{{item.place_id}}</fa-icon>&nbsp;-&nbsp;
    </span>
    <span>
      {{item.updatedOn | date:'medium'}}
    </span>
  </div>
</div>
<div *ngIf="inputArray && inputArray.length < 1" class="f_center f_grow fullWidth">{{'global.no_data_available' | translate}}</div>
