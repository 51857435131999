import {ClaimType} from './claim';
import {CountryName} from './countries';
import {ModelInterface} from './entity';
import {UserPlace} from './place';
import {I18nLang} from 'ngx-satoris';

export enum UserPlatformRole {
  CUSTOMER = 0,
  WORKER = 1,
  ADMIN = 2,
  SUPER_ADMIN = 3
}
export enum OtpType {
  EMAIL = 0,
  PHONE = 1
}

export const UserRoles = [
  {name: 'customer', value: 0},
  {name: 'worker', value: 1},
  {name: 'admin', value: 2},
  {name: 'super_admin', value: 3}
];

export const enum UserPermission {
  ALLOW_CREATE = 'allowCreate',
  ALLOW_CONSUME = 'allowConsume',
  ALLOW_CONTROL = 'allowControl',
  ALLOW_ADMIN = 'allowAdmin'
}

export const GenderType = [
  {name: 'male', value: 'M'},
  {name: 'female', value: 'F'}
];

export interface RegisterResponse {
  sigStatusChallenge: string;
  id: number;
  salt: string;
}

export interface User extends ModelInterface<number> {
  userId: string;
  role: UserPlatformRole;
  lang: I18nLang;
  lastLogin: string;
  lastUseApproximation: string;
  loginCount: number;
  accountName: string;
  passwordHash: string;
  salt: string;
  resetKey: string;
  iatNotBefore: string;
  lockedAt: string;
  name: string;
  confirmedAt: string;
  lastConnectIp: string;
  nationalNumber: string;
  notificationsOptOuts: string;
  platformEndpointArn: string;
  imageUrl: string;
  preferences: string;
  certKey: string;
  certCert: string;
  certVersion: number;
  pinHash: boolean;
  pinHashUnlockedAt: string;
  pinHashTryUnlockAt: string;
  identifier: string;
  scCaRootHash: string;
  scCommonName: string;
  scSigningCert: string;
  scNni: string;
  sigStatusChallenge: string;
  sigStatusChallengeAt: string;
  otpEmailUntil: string;
  otpPhone: string;
  otpAccount: string;
  otpType: OtpType;
  deviceName: string;
  isEmailAccount: boolean;
  usesLoginOtp: boolean;
  isSystem: boolean;
  uniqueId: string;
  deviceValidated: boolean;
  isForcedSub: string;
  links?: UserPlace[];
  customerView?: User;
  server?: ServerInfo;
  firstTime?: boolean;
  workerView?: boolean;
  placeAdminView: boolean;
  deviceHash?: string;
}

export interface IsoSchemaContent {
  doc: string,
  namespaces: {namespace: string, fields: (string | string[])[], derivedFields: {[id: string]: string}}[]
}

export interface IsoSchema {
  [id: number]: IsoSchemaContent;
}

export interface ServerInfo {
  demoMode: boolean;
  emailConfig: {
      address: string,
      email: string,
      phone: string,
      imageLogo: string
  };
  jwtCertificate: string;
  ecdsaKey: {
    x: string,
    y: string
  };
  regDays: number;
  country: CountryName;
  allowPortrait: boolean;
  usesVoucher: boolean;
  usesOtp: boolean;
  usesLoginOtp: boolean;
  selfClaims: ClaimType[];
  isoSchemas: IsoSchema;
  isoSchemasVersionned: {[id: number]: IsoSchema};
  isoSchemasVersion: number;
  regScMillis: number;
  callbacks: {[id: string]: {
    name: string,
    types: ClaimType[]
  }};
}

export interface AccountType {
  email: string;
  jwt: string;
  name: string;
}

export enum DelegationType {
  PARENT = 0,
  LEGAL_DELEGATION = 1
}

export interface UserDelegation extends ModelInterface<number> {
  delegationType: DelegationType;
  expiresAt: string;
  delegater_id: number;
  receiver_id: number;
  expired_by: number;
  infoDelegater?: {
    nationalNumber: string;
    accountName: string;
  };
  infoReceiver?: {
    nationalNumber: string;
    accountName: string;
  };
}

export const enum HtmlTemplates {
  RESET = 'reset',
  LOGIN_ONCE = 'login_once',
  CONFIRM = 'confirm',
  NEW_ACCOUNT = 'new_account',
  NO_ENROLL = 'no_enroll',
  OTP = 'otp',
  CHANGE_PASSWORD = 'change_password'
}
