import {Component, OnInit} from '@angular/core';
import {
  DevicesService,
  LangService,
  LoaderService,
  NavigateService
} from 'ngx-satoris';
import {ApiService} from '../../../../shared/services/api.service';
import {Router} from '@angular/router';
import {getStoredItem, removeStoredItem} from 'src/app/shared/utils/storage';

declare const window: any;

@Component({
  selector: 'app-more-security-fingerprint',
  templateUrl: './more-security-fingerprint.component.html',
  styleUrls: ['./more-security-fingerprint.component.scss']
})
export class MoreSecurityFingerprintComponent implements OnInit {
  data: any;
  fp: any;
  fpAvailble = false;
  fpRegistered = false;
  fpSuccess = false;

  constructor(public nav: NavigateService,
    public loader: LoaderService,
    public api: ApiService,
    private router: Router,
    private lang: LangService,
    private deviceService: DevicesService) {
    this.data = <any> this.router.getCurrentNavigation().extras.state;
    this.fpSuccess = this.data?.fpSuccess;
    if(this.deviceService.isDevices('cordova')){
      this.fp = window.Fingerprint;
      this.fpRegistered = getStoredItem('fingerprint');

      if(this.fp) this.fp.isAvailable((result: any) => {
        if(result === 'finger' || result === 'biometric' || result === 'face') this.fpAvailble = true;
      }, (err: any) => {
        this.loader.loading(true, {type: 'error', message: `FPxFUB01: ${err.message}`});
      });
      else this.loader.loading(true, {type: 'error', message: 'FPxIA02'});
    }
  }

  ngOnInit(): void {
    if(this.fpSuccess !== undefined){
      this.fpSuccess && this.loader.loading(true, {type: 'info', message: this.lang.transform('fingerprints.register.success')}).then(() =>{
        this.nav.to('user');
      });
      !this.fpSuccess && this.loader.loading(true, {type: 'error', message: this.lang.transform('fingerprints.register.error')});
    }
  }

  unsetFP() {
    removeStoredItem('fingerprint');
    this.fpRegistered = false;
    this.loader.loading(true, {type: 'info', message: this.lang.transform('fingerprints.unregister.success')}); //Translate
    //TODO: Make real delete of secret here, but for now library doesn't provide this functionality
  }
}
