<div id="layout" class="f_col" [class.layout_sm]="!api.userRole.isCustomer">
  <app-view-title bgImage="assets-countries/header_background.png" fromRoute="activation-step-intro" toRoute="choose-lang" [chooseLangBtn]="api.userRole.isCustomer && true">
    {{(api.setPrincipalMode ? 'activation.step.intro.resetAccount.title' : 'activation.step.intro.title') | translate}}
  </app-view-title>
  <div class="scroll_wrapper">
    <app-view-deco-image maxWidth="300px" imageUrl="assets-countries/undraw_exploring.svg"></app-view-deco-image>
    <app-paragraph class="mb_20" [innerhtml]="('activation.step.intro.DYN' + (api.userRole.isAdmin ? '.admin' : '')) | translate : {country: ('form.DYN.country.' + api.env.country) | translate}"></app-paragraph>
    <app-paragraph *ngIf="api.userRole.isAdmin" class="mb_40" [innerhtml]="'user.activation.text' | translate"></app-paragraph>
    <div class="f_align_end f_grow ">
      <app-back-btn (btnClicked)="back()"></app-back-btn>
      <div class="fullWidth ml_10">
        <app-button (btnClicked)="enroll.switchToStep()" class="fullWidth">
          {{'global.continue' | translate}}
        </app-button>
      </div>
    </div>
  </div>
</div>
