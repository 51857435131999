import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {LoaderService, NavigateService} from 'ngx-satoris';
import {ApiService} from '../../shared/services/api.service';
import {SignaturePadComponent} from '@almothafar/angular-signature-pad';

@Component({
  selector: 'app-signature-pad',
  templateUrl: './signature-pad.component.html',
  styleUrls: ['./signature-pad.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SignatureComponent implements OnInit {
  @ViewChild('signature') public signaturePad: SignaturePadComponent;

  @Input() canvasBg = 'transparent';
  @Input() canvasPenClr = '#000000';
  @Input() fromDataURL: string;
  @Input() uploadOption = false;
  @Input() uploadImageFile = false;
  @Output() isEmpty: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() dataUrl: EventEmitter<string> = new EventEmitter<string>();
  @Output() upload: EventEmitter<boolean> = new EventEmitter<boolean>();
  canvasW: number;
  canvasH: number;

  constructor(public nav: NavigateService, 
    public api: ApiService, 
    private loader: LoaderService) {
    this.loader.loading(true);
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.setCanvasSize();
    }, 500);
  }

  setCanvasSize() {
    const wrapper = document.querySelector('#draw_wrapper');
    this.canvasW = (wrapper?.clientWidth > 0) ? wrapper.clientWidth : 329;
    this.canvasH = (wrapper?.clientHeight > 0) ? wrapper.clientHeight : 265;
    

    if(this.fromDataURL) {
      setTimeout(() => {
        this.signaturePad.fromDataURL(this.fromDataURL);
        this.loader.loading(false);
      }, 500);
    } else {
      this.isEmpty.emit(true);
      this.dataUrl.emit(undefined);
      this.loader.loading(false);
    }
  }

  clear() {
    this.canvasW = undefined;
    this.uploadImageFile = false;
    this.fromDataURL = undefined;
    setTimeout(() => {
      this.setCanvasSize();
    });
  }

  drawEnd(e: any) {
    this.isEmpty.emit(e.signaturePad.isEmpty());
    this.dataUrl.emit(e.signaturePad.toDataURL());
  }

  uploadImage(event: any) {
    //! 0,828125 ratio between width and height (329/265)
    this.clear();
    this.uploadImageFile = true;
    const file = event.target.files[0];
    const reader = new FileReader();
  
    reader.onload = () => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = 329;
        canvas.height = 265;
  
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
  
        const resizedImageDataURL = canvas.toDataURL(file.type);
  
        this.dataUrl.emit(resizedImageDataURL);
        this.upload.emit(true);
        setTimeout(() => {
          this.signaturePad.fromDataURL(this.fromDataURL);
          this.loader.loading(false);
        }, 500);
      };
      img.src = reader.result as string;
      event.target.value = '';
    };
  
    reader.readAsDataURL(file);
  }
  
  
}
