<div id="layout" class="f_col">
  <app-view-title bgImage="assets-countries/header_background.png" fromRoute="welcome-done" toRoute="choose-lang" [chooseLangBtn]="true">{{'welcomeDone.title' | translate}}</app-view-title>
  <div class="scroll_wrapper">
    <app-view-deco-image imageUrl="assets-countries/img_love.svg"></app-view-deco-image>
    <div class="f_col gap_20 ">
      <app-paragraph>{{'welcomeDone.desc1' | translate: {name: api.env.name} }}</app-paragraph>
      <app-paragraph>{{'welcomeDone.desc2' | translate: {name: api.env.name} }}</app-paragraph>
    </div>
    <div class="f_align_end f_grow ">
      <div class="fullWidth ml_10 flex gap_10">
        <app-back-btn (btnClicked)="goBack()"></app-back-btn>
        <app-button (btnClicked)="goUser()"
                    class="fullWidth">
          {{'global.close' | translate}}
        </app-button>
      </div>
    </div>
  </div>
</div>
