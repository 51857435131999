import {EventEmitter, Injectable} from '@angular/core';
import * as asn1js from 'asn1js';
import * as pkijs from 'pkijs';
import * as pvtsutils from 'pvtsutils';
import {ClaimIdCard, Icao} from 'src/app/shared/models/claim';
import {Subject} from 'rxjs';

declare const MCardNFC: any;
declare const window: any;

@Injectable({
  providedIn: 'root'
})
export class NfcService {
  pkiCert: pkijs.Certificate;
  icao: Icao;
  signature: string;
  isValidating = false;
  isFPDeviceFound = false;
  private _isCaptureStarted = false;
  public get isCaptureStarted() {
    return this._isCaptureStarted;
  }
  listening = false;
  cardFound = false;
  cardFound$ = new EventEmitter();
  private _fingerprintCaptured = new Subject();
  private _fingerprintCaptureError = new Subject();
  public get fingerprintCaptured() {
    return this._fingerprintCaptured.asObservable();
  }
  public get fingerprintCaptureError() {
    return this._fingerprintCaptureError.asObservable();
  }
  claimIdCard: ClaimIdCard;

  onNFCFound = () => {
    this.cardFound = true;
    this.cardFound$.emit();
  };
  onCaptureDeviceSet = (event: any) => {
    const {target} = event;
    if(target) {
      console.log(`initSuprema target: ${target}`);
      this.isFPDeviceFound = true;
    }
  };
  onCaptureProcessCompleted = (event: any) => {
    this._isCaptureStarted = false;
    const fingerprintDetails = JSON.parse(event.detail);
    // png wsq template fingerState
    if(fingerprintDetails?.fingerState && fingerprintDetails?.wsq){
      this._fingerprintCaptured.next(this.arrayBufferToB64String(fingerprintDetails.wsq));
    } else {
      //TODO: Check what we got here
      console.error(`onCaptureProcessCompleted else: ${JSON.stringify(event, ['detail', 'message'])}`);
    }
  };
  onCaptureError = (event: any) => {
    this._isCaptureStarted = false;
    this._fingerprintCaptureError.next(event.detail);
  };

  init() {
    MCardNFC.init(pvtsutils, asn1js, pkijs);
  }

  initFingerprint() {
    document.addEventListener('onCaptureDeviceSet', this.onCaptureDeviceSet);
    document.addEventListener('onCaptureProcessCompleted', this.onCaptureProcessCompleted);
    document.addEventListener('onCaptureError', this.onCaptureError);
  }

  teardownFingerprint() {
    document.removeEventListener('onCaptureDeviceSet', this.onCaptureDeviceSet);
    document.removeEventListener('onCaptureProcessCompleted', this.onCaptureProcessCompleted);
    document.removeEventListener('onCaptureError', this.onCaptureError);
  }

  detectNfc() {
    return new Promise((resolve) => {
      MCardNFC.hasNFC(() => resolve(true), () => resolve(false));
    });
  }

  arrayBufferToB64String(buffer: ArrayBuffer) {
    let binary = '';
    const bytes = (new Uint8Array(buffer));
    const len = bytes.byteLength;
    for(let i = 0; i < len; i++) {
      binary += String.fromCharCode( bytes[i] );
    }
    return window.btoa(binary);
  }

  startCapture() {
    return new Promise((resolve, reject) => {
      MCardNFC.startCapture(() => {
        this._isCaptureStarted = true;
        resolve(undefined);
      }, (msg: any) => {
        this._isCaptureStarted = false;
        reject(msg);
      });
    });
  }

  abortCapture() {
    if(this._isCaptureStarted) {
      MCardNFC.abortCapture();
      this._isCaptureStarted = false;
    }
  }

  scan(): Promise<any> {
    return new Promise((resolve, reject) => {
      MCardNFC.startWatch(() => {
        document.addEventListener('onNFCFound', this.onNFCFound);
        this.listening = true;
        resolve(undefined);
      }, reject);
    });
  }

  isNFCEnabled(redirect = false): Promise<any> {
    return new Promise((resolve, reject) => {
      MCardNFC.isNFCEnabled(redirect, resolve, reject);
    });
  }

  stop() {
    MCardNFC.stopWatch();
    document.removeEventListener('onNFCFound', this.onNFCFound);
    this.listening = false;
  }

  getCertificate(reAuth: boolean, onAuthCert = true, can: any): Promise<pkijs.Certificate> {
    return new Promise((resolve, reject) => {
      MCardNFC.getCertificateObject(reAuth ? `${can}` : undefined, onAuthCert, (result: pkijs.Certificate) => {
        this.pkiCert = result;
        resolve(result);
      }, reject);
    });
  }

  sign(reAuth: boolean, pin: string, challenge: string, canValue: string, onAuthCert = true): Promise<string> {
    return new Promise((resolve, reject) => {
      MCardNFC.signObject(reAuth ? `${canValue}` : undefined, `${pin}`, onAuthCert, this.pkiCert, challenge, resolve, reject);
    });
  }

  readIcao(reAuth: boolean, canValue: string): Promise<Icao> {
    return new Promise((resolve, reject) => {
      MCardNFC.readIcaoObject(reAuth ? canValue : undefined, (result: any) => {
        this.icao = result;
        this.icao.face.raw = this.cleanupIcao(this.icao.face.raw);
        this.icao.personalDetails.raw = this.cleanupIcao(this.icao.personalDetails.raw);
        this.icao.documentDetails.raw = this.cleanupIcao(this.icao.documentDetails.raw);
        this.icao.mrz.raw = this.cleanupIcao(this.icao.mrz.raw);
        this.icao.sodRaw = this.cleanupIcao(this.icao.sodRaw);
        resolve(result);
      }, reject);
    });
  }

  cleanupIcao(icao: string): string{
    return decodeURIComponent(icao);
  }
}
