import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ApiService} from 'src/app/shared/services/api.service';
import {Router} from '@angular/router';
import {
  I18nLang,
  LangService,
  LoaderService,
  NavigateService
} from 'ngx-satoris';
import * as langs from 'ngx-satoris';
import {getStoredItem} from 'src/app/shared/utils/storage';
import {SyncService} from 'src/app/shared/services/sync.service';

declare const window: any;

@Component({
  selector: 'app-choose-lang',
  templateUrl: './choose-lang.component.html',
  styleUrls: ['./choose-lang.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChooseLangComponent implements OnInit {
  systemLangs: I18nLang[] = (langs as any).I18nLang;
  languages: {value: any, label: any}[] = [];
  data : {fromRoute: string, extras?: any};

  constructor(public api: ApiService,
    public nav: NavigateService,
    private  router: Router,
    private lang: LangService,
    private loader: LoaderService,
    private sync: SyncService) {
    this.data = <any> this.router.getCurrentNavigation().extras.state;
    if(this.data?.extras) {
      this.data.extras = JSON.parse(this.data?.extras);
    }
  }

  ngOnInit(): void {
    const supportedLanguages = this.api.env.supportedLanguages;
    this.languages = Object.keys(this.systemLangs)
      .filter(v => isNaN(Number(v)))
      .map(l => ({
        label: 'form.lang.DYN.' + l,
        value: this.systemLangs[l as keyof typeof this.systemLangs]
      }));
    this.languages = this.languages.filter(l => supportedLanguages.includes(l.value));
  }

  changeLang(l: I18nLang) {
    try {
      window.updateGlpi(this.lang.langType(l).toLowerCase());
    } catch { /* empty */ }
    if(this.api.userInfo) this.api.userInfo.lang = l;
    this.lang.setLang(l);
    if(this.sync.isOnline && this.api.userInfo) {
      this.loader.loading(true);
      this.api.setPreferences().finally(() =>  {
        this.loader.loading(false);
        if(this.data.fromRoute) this.nav.to(this.data.fromRoute);
      });
    }
  }

  warningChange() {
    this.loader.loading(true, {type: 'warn', message: 'change.lang.already.set'});
  }

  checkWelcomeDone() {
    return Boolean(getStoredItem('welcomeDone')) === true;
  }
}
