<div id="layout" class="f_col layout_dk">
  <img id="custom_bg" ngSrc="assets-countries/home.png" width="200" height="200">
  <div class="scroll_wrapper">
    <div class="f_grow mt_10 loader_shareClaim f_col f_center">
      <h2 class="text_center" *ngIf="qrValue && isQrReady">{{(isClaimSelf ? 'isClaimSelf.share' : 'userShare.DYN.' + (uniqueShareClaim ? uniqueShareClaim : params?.claimType)) | translate}}</h2>  
      <!-- <app-share-claim [iso18013_5]="isoMode" [selectedClaims]="selectedClaims" [timer]="timer" (back)="nav.to(data?.fromRoute || 'user')"></app-share-claim> -->
      <div>
        <app-qr-code *ngIf="!isTransfer && isQrReady" [value]="qrValue" [timer]="time"></app-qr-code>
        <app-progress-bar *ngIf="isTransfer"
          [progress]="chunkProgress"
          (onDone)="transferDone()"
          type="bluetooth"
          [speed]="chunkProgressSpeed"
          iconClass="mt_20 mb_40"
          logoOuterClr="var(--clr-lt)"
          logoInnerClr="var(--clr-primary)"
          innerBarClr="var(--clr-lt)"
          barClr="var(--clr-gray-dk)"
          progressTextClr="var(--clr-lt)">
        </app-progress-bar>
      </div>
    </div>
    <div class="f_col mb_10 mt_40">
      <app-button color="secondary" (btnClicked)="onClose()" class="fullWidth">{{'global.close' | translate}}</app-button>
    </div>
  </div>
</div>
