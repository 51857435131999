import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {NavigateService, LoaderService, FormService, LangService} from 'ngx-satoris';
import {ApiService} from 'src/app/shared/services/api.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {OtpType} from 'src/app/shared/models/user';

@Component({
  selector: 'app-more-security-otp',
  templateUrl: './more-security-otp.component.html',
  styleUrls: ['./more-security-otp.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MoreSecurityOtpComponent implements OnInit {

  data: {otp: 'sms' | 'email';};
  form: FormGroup;
  OtpType = OtpType;
  submitted: boolean;
  showOTPError: boolean;
  showOtp = false;
  showOTPCorrect: boolean;
  oldPreferences = this.api.userInfo.otpType;
  currentSubmit: any;
  otp = '';
  phone = '';

  constructor(public nav: NavigateService,
    public forms: FormService,
    public loader: LoaderService,
    public api: ApiService,
    private formBuilder: FormBuilder,
    private router: Router,
    private lang: LangService) {
    this.data = <any> this.router.getCurrentNavigation().extras.state;
  }
  ngOnInit(): void {
    this.data.otp === 'sms' && this.setFormOtpSms();
  }
  setFormOtpSms() {
    this.form = this.formBuilder.group({
      phone: ['', [
        Validators.required,
        Validators.pattern(/\b0[0-9]{9}\b/g)
      ]]
    });
  }

  submitForm() {
    this.submitted = true;
    if(this.data.otp === 'sms') {
      if(this.forms.getFormErrors(this.form).length < 1) {
        this.loader.loading(true);
        this.phone = this.form.get('phone').value;
        this.api.setPreferences(this.phone, undefined, OtpType.PHONE).then(() => {
          this.api.email2FA().then(() => {
            this.api.setPreferences(undefined, undefined, this.oldPreferences).then(() => {
              this.showOtp = true;
              this.loader.loading(false);
            });
          }).catch(() => {
            this.api.setPreferences(undefined, undefined, this.oldPreferences);
            console.error('error');
            this.loader.loading(false);
          });
        }).catch(() => {
          console.error('error');
          this.loader.loading(false);
        });
      }
    } else {
      this.api.setPreferences(undefined, undefined, OtpType.EMAIL).then(() => {
        this.loader.loading(true, {type: 'valid', message: this.lang.transform('otp.email.success')}).then(() => {
          this.api.info().then(() => {
            this.nav.to('more-security');
          });
        });
      });
    }
  }

  checkOTPCode(e: string[]) {
    const codeOk = e.length >= 6;
    this.otp = e.join('');

    if(codeOk && !this.currentSubmit) {
      this.currentSubmit = true;
      this.validatwOtp();
      this.loader.loading(true);
    }
  }


  validatwOtp() {
    this.showOTPError = false;
    this.showOTPCorrect = false;
    this.api.consumeLoginWeak(this.api.userInfo.accountName, undefined, this.otp, undefined, undefined, undefined, false, true).then(() => {
      this.showOTPCorrect = true;
      setTimeout(() => {
        this.api.setPreferences(this.form.get('phone').value, undefined, OtpType.PHONE).then(() => {
          this.loader.loading(true, {type: 'valid', message: this.lang.transform('otp.sms.success')}).then(() => {
            this.api.info().then(() => {
              this.nav.to('more-security');
            });
          });
        });
      }, 500);
    }).catch(() => {
      this.showOTPError = true;
      setTimeout(() => {
        this.loader.loading(true, {type: 'error', message: this.lang.transform('otp.sms.error')}).then(() => {
          this.showOTPError = false;
          this.currentSubmit = false;
        });
      }, 500);
    }) ;

  }

  resetOtp() {
    this.phone = '';
    this.otp = '';
    this.data.otp === 'sms' && this.setFormOtpSms();
    this.showOtp=false;
    this.form.get('phone').setValue('');
    this.submitted = false;
  }
}
