import {Injectable} from '@angular/core';
import {DevicesService} from 'ngx-satoris';

declare const window: any;
const diagnostic = window?.cordova?.plugins?.diagnostic;
declare const Promise: PromiseConstructor & {
  allConcurrent: <T>(n: number) => ((promiseProxies: (() => Promise<T>)[]) => Promise<T[]>);
};
@Injectable({
  providedIn: 'root'
})
export class PermissionsService {
  stateOfPermissions =  {
    bluetooth: {
      hasPermission: false,
      stateExact: '',
      state: ''
    },
    localisation: {
      hasPermission: false,
      permissionExact: '',
      alwaysDenied: false,
      state: false
    },
    camera: {
      hasPermission: true,
      state: ''
    },
    init: true
  };
  deviceInformation:{OS: string, version: string};
  bluetoothOk = false;
  locationOk = false;
  cameraOk = false;

  constructor(private devices: DevicesService) {}

  /* CAMERA */

  checkCameraPermission(): Promise<boolean>{
    return new Promise((resolve) => {
      window.cordova.plugins.diagnostic?.getCameraAuthorizationStatus((status: string) => {
        this.stateOfPermissions.camera.state = status;
        this.stateOfPermissions.camera.hasPermission = (status.toUpperCase() === 'GRANTED'
                                                        || status.toUpperCase() === 'AUTHORIZED'
                                                        || status.toUpperCase() === 'AUTHORIZED_WHEN_IN_USE') ? true : false;
        resolve(this.stateOfPermissions.camera.hasPermission);
      }, (err: any) => console.error('check camera permission error', err), false);
    });
  }

  checkCameraStatus(): Promise<string>{
    return new Promise((resolve) => {
      window.cordova.plugins.diagnostic?.requestCameraAuthorization((status: string) => {
        this.stateOfPermissions.camera.state = status.toUpperCase();
        resolve(status);
      }, (err: any) => console.error('check camera status error', err), false);
    });
  }

  requestCameraPermission(): Promise<string>{
    return new Promise((resolve) =>  window.cordova.plugins.diagnostic?.requestCameraAuthorization((status: string) => {
      resolve(status);
    }, (err: any) => {
      console.error('problem with camera permissions', err);
    }, false));
  }

  /* BLUETOOTH */

  getBTState(): Promise<string>{
    return new Promise((resolve, reject) => {
      try {
        window?.cordova?.plugins?.diagnostic?.getBluetoothState((status: string) => {
          this.stateOfPermissions.bluetooth.state = status;
          resolve(status);
        });
      } catch {
        reject(undefined);
      }
    });
  }

  checkBLEHasPermission(): Promise<string>{
    return new Promise((resolve, reject) => {
      try {
        window?.cordova?.plugins?.diagnostic?.getBluetoothAuthorizationStatus((result: string) => {
          this.stateOfPermissions.bluetooth.stateExact = result.toUpperCase();
          this.stateOfPermissions.bluetooth.hasPermission = (result.toUpperCase() === 'GRANTED'
                                                          || result.toUpperCase() === 'AUTHORIZED'
                                                          || result.toUpperCase() === 'AUTHORIZED_WHEN_IN_USE') ? true : false;
          resolve(result);
        });
      } catch {
        reject(undefined);
      }
    });
  }

  requestBTPermission() {
    if((!this.stateOfPermissions.init && this.stateOfPermissions.bluetooth.stateExact === 'NOT_REQUESTED')
    || this.stateOfPermissions.bluetooth.stateExact === 'DENIED_ALWAYS'){
      diagnostic.switchToSettings();
    } else {
      diagnostic.requestBluetoothAuthorization((status: {'BLUETOOTH_CONNECT': string, 'BLUETOOTH_ADVERTISE': string, 'BLUETOOTH_SCAN': string}) => {
        this.stateOfPermissions.bluetooth.stateExact = status.BLUETOOTH_CONNECT.toUpperCase();
        this.stateOfPermissions.bluetooth.hasPermission = Object.values(status).every(value => value === 'GRANTED') ? true : false;
      }, console.error, []);
    }
  }

  setBluetoothON(): Promise<boolean>{
    return new Promise((resolve, reject) => {
      try {
        diagnostic.setBluetoothState(resolve, reject, true);
      } catch {
        reject(undefined);
      }
    });
  }

  /* LOCATION */

  getLocationState(): Promise<string>{
    return new Promise((resolve, reject) => {
      try {
        diagnostic.getLocationMode((result: string) => {
          this.stateOfPermissions.localisation.state = result === 'location_off' ? false : true;
          resolve(result);
        });
      } catch {
        reject(undefined);
      }
    });
  }

  checkLocationStatus(): Promise<boolean>{
    return new Promise((resolve, reject) => {
      try {
        window.cordova.plugins.diagnostic.getLocationAuthorizationStatus((status: string) => {
          this.stateOfPermissions.localisation.permissionExact = status.toUpperCase();
          this.stateOfPermissions.localisation.hasPermission = (status.toUpperCase() === 'GRANTED'
                                                          || status.toUpperCase() === 'AUTHORIZED'
                                                          || status.toUpperCase() === 'AUTHORIZED_WHEN_IN_USE') ? true : false;
          resolve(this.stateOfPermissions.localisation.hasPermission);
        }, function(error: string){
          reject(error);
          console.error('The following error occurred: '+error);
        });
      } catch {
        reject(undefined);
      }
    });

  }

  requestLocationAuthorization(){
    if(this.stateOfPermissions.localisation.permissionExact === diagnostic.permissionStatus.DENIED_ALWAYS) {
      diagnostic.switchToSettings();
    } else {
      diagnostic.requestLocationAuthorization((status:any) =>{
        this.stateOfPermissions.localisation.permissionExact = status.toUpperCase();
        this.stateOfPermissions.localisation.hasPermission = (status.toUpperCase() === 'GRANTED'
                                                        || status.toUpperCase() === 'AUTHORIZED'
                                                        || status.toUpperCase() === 'AUTHORIZED_WHEN_IN_USE') ? true : false;
      }, (error: any) => {
        console.error(error);
        this.stateOfPermissions.localisation.hasPermission = false;
      }, diagnostic?.locationAuthorizationMode.ALWAYS
      , diagnostic?.locationAccuracyAuthorization.FULL);
    }
  }

  checkIso (){
    if(this.devices.isDevices('cordova-android')){
      return Promise.allConcurrent(1)([
        () => this.getBTState(),
        () => this.checkBLEHasPermission(),
        () => this.getLocationState(),
        () => this.checkLocationStatus()
      ]);
    } else {
      return Promise.allConcurrent(1)([() => this.checkBLEHasPermission(),
        () => this.getBTState()]);
    }
  }

  quickCheck(): boolean{
    if((this.stateOfPermissions.bluetooth.state === 'powered_on' && this.stateOfPermissions.bluetooth.hasPermission) 
    && (this.stateOfPermissions.localisation.state && this.stateOfPermissions.localisation.hasPermission)){
      return true;
    } else {
      return false;
    }
  }

  /* HANDLERS */

  btHandler() {
    window?.cordova?.plugins?.diagnostic?.registerBluetoothStateChangeHandler((bluetooth: string)=>{
      if(bluetooth !== 'unknown' && bluetooth !== null && bluetooth !== undefined){
        this.stateOfPermissions.bluetooth.state = bluetooth;
      }
    });
  }

  locationHandler() {
    window?.cordova?.plugins?.diagnostic?.registerLocationStateChangeHandler((location: string)=>{
      if(location !== 'unknown' && location !== null && location !== undefined){
        this.stateOfPermissions.localisation.state = location === 'location_off' ? false : true;
      }
    });
  }

  initPermissions() {
    if(!this.deviceInformation){
      window.cordova.plugins.diagnostic.getDeviceOSVersion((OS: any) => {
        const sanitizedOS = OS.version.split('.');
        if(this.devices.isDevices('cordova-android')){
          this.deviceInformation = {
            OS: 'android',
            version: sanitizedOS[0]
          };
        } else {
          this.deviceInformation = {
            OS: 'ios',
            version: sanitizedOS[0]
          };
        }
      });
    }
  }

  removeHandlerPermissions() {
    window?.cordova?.plugins?.diagnostic?.registerBluetoothStateChangeHandler(false);
    window?.cordova?.plugins?.diagnostic?.registerLocationStateChangeHandler(false);
  }
}
