import {Component, Input, ViewEncapsulation} from '@angular/core';
import {User} from 'src/app/shared/models/user';
import {PlatformEvent} from '../../shared/models/information';

@Component({
  selector: 'app-activity-list',
  templateUrl: './activity-list.component.html',
  styleUrls: ['./activity-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ActivityListComponent {
  contexts: any[] = [];

  @Input() inputArray: PlatformEvent[];
  @Input() userInfo: User;
  @Input() userPlaceId = '';

  ngOnInit() {
    this.contexts = this.inputArray.map(event => JSON.parse(event.context));
  }
}
