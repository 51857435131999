<div id="layout" class="f_col layout_sm">
    <img id="subscription_bg" ngSrc="assets-countries/home.png" width="200" height="200"/>
    <div class="scroll_wrapper pt_20 mt_100">
      <div class="flex gap_10 f_align_c">
        <app-paragraph class="oneci-plus">{{'global.abo' | translate}}</app-paragraph>
        <app-paragraph>{{api.env.name}}</app-paragraph>
      </div>
      <app-paragraph class="oneci-promo mt_10">{{(isPromo ? 'sub.promo' : 'sub.buy') | translate}}</app-paragraph>
      <div class="flex f_col mt_30 mb_30">
        <app-paragraph *ngIf="isPromo" class="price price-promo">{{'price.promo' | translate : {pricePromo: init.products[0]?.pricing?.price} }}</app-paragraph>
        <app-paragraph [class.price-real-promo]="isPromo" class="price price-real">{{'price.real' | translate : {price: init.products[0]?.pricing?.price} }}</app-paragraph>
      </div>
      <div class="flex f_col gap_10">
        <div class="flex gap_10">
          <fa-icon icon="check"></fa-icon>
          <app-paragraph>{{'advantage1' | translate}}</app-paragraph>
        </div>
        <div class="flex gap_10">
          <fa-icon icon="check"></fa-icon>
          <app-paragraph>{{'advantage2' | translate}}</app-paragraph>
        </div>
        <div class="flex gap_10">
          <fa-icon icon="check"></fa-icon>
          <app-paragraph>{{'advantage3' | translate}}</app-paragraph>
        </div>
      </div>
      <app-button class="mb_10 mt_20 f_grow" (btnClicked)="activateSubscription()"> {{'global.subscribe' | translate}}</app-button>
      <app-divider color="black" [text]="'global.or' | translate"></app-divider>
      <app-button class="mb_20 mt_10 f_grow" color="secondary" (btnClicked)="restoreSubscription()">{{'global.restore' | translate}}</app-button>
      <app-paragraph textAlign="center" class="subscribeFooter mb_10">{{'subscribe.footer' | translate : {price: init.products[0]?.pricing?.price} }}</app-paragraph>
      <app-paragraph textAlign="center" class="subscribeFooter link mb_5" (click)="follow(devices.isDevices('cordova-ios') ? 'https://www.apple.com/legal/internet-services/itunes/dev/stdeula/' : api.env.eulaLink)">{{'global.eula' | translate}}</app-paragraph>
      <app-paragraph textAlign="center" class="subscribeFooter link" (click)="follow(api.env.privacyLink)">{{'global.privacy' | translate}}</app-paragraph>
    </div>
  </div>
