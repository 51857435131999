import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {User} from '../../../shared/models/user';
import {ApiService} from '../../../shared/services/api.service';
import {
  FormService,
  LangService,
  LoaderService
} from 'ngx-satoris';

@Component({
  selector: 'app-dialog-permissions',
  templateUrl: './dialog-permissions.component.html',
  styleUrls: ['./dialog-permissions.component.scss']
})
export class DialogPermissionsComponent implements OnInit {

  form: FormGroup;
  submitted: boolean;
  show: boolean;
  user: User;

  constructor(public dialogRef: MatDialogRef<DialogPermissionsComponent>,
    public forms: FormService,
    @Inject(MAT_DIALOG_DATA) public data: { userId: number, placeId: string, uniqueId: string },
    private formBuilder: FormBuilder,
    private api: ApiService,
    private lang: LangService,
    private loader: LoaderService) { }

  ngOnInit(): void {
    this.setForm();
  }

  setForm() {
    this.api.user(this.data.userId).then(user => {
      this.user = user;
      const placePerm = user.links?.find(x => x.place_id === this.data.placeId);
      this.form = this.formBuilder.group({
        perm1: [placePerm?.allowCreate ? placePerm.allowCreate : false, []],
        perm2: [placePerm?.allowConsume ? placePerm.allowConsume : false, []],
        perm3: [placePerm?.allowControl ? placePerm.allowControl : false, []],
        perm4: [placePerm?.allowAdmin ? placePerm.allowAdmin : false, []]
      });
      this.show = true;
    });
  }

  submitForm() {
    this.submitted = true;
    if(this.forms.getFormErrors(this.form).length < 1) {
      this.submitted = false;
      this.loader.loadingElement('permdialog', true, 'sm');
      this.api.places(this.data.userId, this.data.placeId, undefined, undefined, this.form.get('perm1')?.value, this.form.get('perm2')?.value,
        this.form.get('perm3')?.value, this.form.get('perm4')?.value).then(() => {
        this.loader.loadingElement('permdialog', false);
        this.closeDialog(true);
      }).catch(err => {
        this.submitted = false;
        this.loader.loading(true, {type: 'error', message: String(err).indexOf(': ') > -1 ? err : this.lang.transform('api.places.error')});
      });
    }
  }

  changePerm(e: any, permName: string) {
    this.form.get(permName).setValue(e.checked);
  }

  closeDialog(done?: boolean) {
    this.dialogRef.close(done);
  }
}
