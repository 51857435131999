<div id="layout" class="f_col layout_sm">
  <app-view-title bgImage="assets-countries/header_background.png">{{'changePin.title' | translate}}</app-view-title>
  <div class="scroll_wrapper">
    <app-view-deco-image imageUrl="assets-countries/security.svg"></app-view-deco-image>
    <div class="f_grow">
      <app-paragraph>{{'changePin.desc' | translate}}</app-paragraph>
    </div>
    <div class="f_align_end f_grow mt_30">
      <app-back-btn [navTo]="{route: 'more-security'}"></app-back-btn>
      <div class="ml_10 fullWidth">
        <app-button (btnClicked)="nav.toPin('more-security-change-pin', 'secret-code', true, undefined, {queryParams: {keepPin: true}, state:{resetPin: true}})" class="fullWidth">{{'global.continue' | translate}}</app-button>
      </div>
    </div>
  </div>
</div>
