import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {WebcamImage, WebcamInitError, WebcamUtil} from 'ngx-webcam';
import {Observable, Subject} from 'rxjs';
import {DevicesService, LoaderService, NavigateService} from 'ngx-satoris';
import {EnrollService} from 'src/app/shared/services/enroll.service';
import {PrivacyScreen} from '@capacitor-community/privacy-screen';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-webcam',
  templateUrl: './webcam.component.html',
  styleUrls: ['./webcam.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WebcamComponent implements OnInit, OnDestroy {

  @Input() backUrl: string;
  @Input() imageQuality = 1;
  @Input() fullHeight = false;
  @Output() output: EventEmitter<any> = new EventEmitter<any>();

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }

  // toggle webcam on/off
  public showWebcam = false;
  public multipleWebcamsAvailable = true;
  public mediaDevices: MediaDeviceInfo[];
  public deviceId: string;
  public videoOptions: MediaTrackConstraints = {
    // width: {ideal: 1024},
    // height: {ideal: 576}
  };
  public errors: WebcamInitError[] = [];

  // latest snapshot
  public webcamImage: WebcamImage = undefined;
  public videoInitialized: boolean;
  public deviceReady = false;

  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();

  private resumeDeviceId: string;

  eventPause = this.onCameraPause.bind(this);
  eventResume = this.onCameraResume.bind(this);

  constructor(public nav: NavigateService,
    public loader: LoaderService,
    private enroll: EnrollService, 
    private devices: DevicesService) {
    if(this.devices.isDevices('cordova') && environment.production) PrivacyScreen.disable();
  }

  public ngOnInit(): void {
    this.loader.loading(true);
    WebcamUtil.getAvailableVideoInputs()
      .then((mediaDevices: MediaDeviceInfo[]) => {
        this.mediaDevices = mediaDevices;
        this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
        this.showWebcam = true;
      });

    if(this.devices.isDevices('cordova')) {
      document.addEventListener('pause', this.eventPause);
      document.addEventListener('resume', this.eventResume);
    }
  }

  public ngOnDestroy() {
    if(this.devices.isDevices('cordova') && environment.production) PrivacyScreen.enable();
    if(this.devices.isDevices('cordova')) {
      document.removeEventListener('pause', this.eventPause);
      document.removeEventListener('resume', this.eventResume);
    }  
  }

  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public toggleWebcam(state: boolean): void {
    this.showWebcam = state || !this.showWebcam;
    if(!this.showWebcam) {
      this.turnOffCamera();
    }
  }

  public turnOffCamera(): void {
    this.deviceId = null;
    this.showWebcam = false;
    this.nextWebcam.complete();
  }

  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }

  public showNextWebcam(directionOrDeviceId: boolean | string): void {
    // true => move forward through devices
    // false => move backwards through devices
    // string => move to device with given deviceId
    this.deviceReady = false;
    this.nextWebcam.next(directionOrDeviceId);
  }

  public handleImage(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
    this.output.emit(this.webcamImage.imageAsDataUrl);
  }

  public cameraWasSwitched(deviceId: string): void {
    this.deviceId = deviceId;
    setTimeout(() => {
      this.deviceReady = true;
      this.loader.loading(false);
    }, 35);
  }

  onCameraPause(){
    this.deviceReady = false;
    this.resumeDeviceId = this.deviceId;
    this.deviceId = null;
    this.nextWebcam.next(null);
  }

  onCameraResume(){
    this.deviceId = this.resumeDeviceId;
    this.nextWebcam.next(this.deviceId);
    this.deviceReady = true;
  }

  backUrlRedirect(backUrl: string) {
    if(backUrl === 'previous' ) {
      this.enroll.navigate('previous');
    } else if(backUrl === 'nfc-read-draw-signature'){
      this.nav.to(this.backUrl, undefined, {state: {type: 'manual'}});
    } else {
      this.nav.to(this.backUrl);
    }
  }
}
