import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ApiService} from '../../../shared/services/api.service';
import {DevicesService, LangService, LoaderService, NavigateService} from 'ngx-satoris';
import {BiometricService} from 'src/app/shared/services/biometric.service';
import {setStoredItem} from 'src/app/shared/utils/storage';

@Component({
  selector: 'app-welcome-done',
  templateUrl: './welcome-done.component.html',
  styleUrls: ['./welcome-done.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WelcomeDoneComponent implements OnInit{

  constructor(public api: ApiService,
    public nav: NavigateService,
    public device: DevicesService,
    public biometric: BiometricService,
    private loader: LoaderService,
    private lang: LangService) { }


  ngOnInit(): void {
    if(this.biometric.currentlyValidated) {
      this.loader.loading(true, {type: 'valid', message: this.lang.transform(this.device.isDevices('cordova-ios') ? 'biometric.faceID.setSuccess' : 'fingerprints.register.success')});
      this.biometric.currentlyValidated = false;
    }
  }

  goUser() {
    this.nav.to('user');
    setStoredItem('welcomeDone', true);
  }

  goBack() {
    this.nav.to(this.biometric.biometric.isRegistered || !this.device.isDevices('cordova') || this.nav.demoMode ? 'welcome-explain' : 'welcome-biometric');
  }
}
