import {Component, ViewEncapsulation} from '@angular/core';
import {Claims, ClaimShareParams} from '../../../shared/models/claim';
import {ActivatedRoute} from '@angular/router';
import {ApiService} from '../../../shared/services/api.service';
import {DevicesService, NavigateService} from 'ngx-satoris';

@Component({
  selector: 'app-claim-data',
  templateUrl: './claim-data.component.html',
  styleUrls: ['./claim-data.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ClaimDataComponent {

  claim: Claims[keyof Claims];
  params: ClaimShareParams;
  constructor(public api: ApiService,
              public device: DevicesService,
              public nav: NavigateService,
              private route: ActivatedRoute) {
    this.route.queryParams
      .subscribe((params: any) => {
        if(params) {
          this.params = {
            claimType: JSON.parse(params.claimType),
            isoMode: JSON.parse(params.isoMode),
            selectedClaims: params.selectedClaims,
            fromRoute: 'claim-card'
          };
          this.claim = this.api.claims[this.params.claimType];
        }
      });
  }

  protected readonly Array = Array;
}
