<mat-dialog-content class="dialog_content loader_permdialog">
  <form *ngIf="show" [formGroup]="form">
    <div class="mt_10">
      <app-checkbox-element [formGrp]="form" [formCtrlName]="'perm1'" [text]="'permission.perm1'" (onChange)="changePerm($event, 'perm1')"></app-checkbox-element>
    </div>
    <div class="mt_10">
      <app-checkbox-element [formGrp]="form" [formCtrlName]="'perm2'" [text]="'permission.perm2'" (onChange)="changePerm($event, 'perm2')"></app-checkbox-element>
    </div>
    <div class="mt_10">
      <app-checkbox-element [formGrp]="form" [formCtrlName]="'perm3'" [text]="'permission.perm3'" (onChange)="changePerm($event, 'perm3')"></app-checkbox-element>
    </div>
    <div class="mt_10">
      <app-checkbox-element [formGrp]="form" [formCtrlName]="'perm4'" [text]="'permission.perm4'" (onChange)="changePerm($event, 'perm4')"></app-checkbox-element>
    </div>

    <app-errors-list [dark]="true" *ngIf="submitted" [formErrors]="forms.getFormErrors(this.form)"></app-errors-list>

    <div class="f_between mt_20 dialog_bot_btns">
      <app-button size="sm" color="secondary" (click)="closeDialog()">{{'global.close' | translate}}</app-button>
      <app-button size="sm" type="submit" (click)="submitForm()">{{'global.confirm' | translate}}</app-button>
    </div>
  </form>
</mat-dialog-content>
