<div id="layout" class="f_col" [class.layout_sm]="!api.userRole.isCustomer">
  <app-view-title bgImage="assets-countries/header_background.png" fromRoute="activation-step-draw-signature" toRoute="choose-lang" [chooseLangBtn]="api.userRole.isCustomer && true">
    {{'activation.step.draw_signature.title' | translate}}
  </app-view-title>
  <div class="scroll_wrapper">
    <app-paragraph class="mt_10" textAlign="center">{{(api.userRole.isCustomer ? 'activation.step.draw_signature' : 'activation.step.draw_signature.admin') | translate}}</app-paragraph>
    <div class="f_grow mt_20">
      <app-signature-pad [fromDataURL]="enroll.form.controls[this.enroll.fc.SIGNATURE].value" (dataUrl)="setSignature($event)" [uploadOption]="api.userRole.isAdmin ? true : false" (upload)="enroll.uploadDrawSignature = true" [uploadImageFile]="enroll.uploadDrawSignature"></app-signature-pad>
    </div>
    <div class="f_align_end f_grow ">
      <app-back-btn (btnClicked)="enroll.navigate('previous')"></app-back-btn>
      <div class="fullWidth ml_10">
        <app-button [disabled]="!enroll.form.controls[enroll.fc.SIGNATURE].valid" (btnClicked)="enroll.navigate('next')" class="fullWidth">
          {{'global.continue' | translate}}
        </app-button>
      </div>
    </div>
  </div>
</div>
