import {Component, ViewEncapsulation} from '@angular/core';
import {ScanService} from '../../shared/services/scan.service';

@Component({
  selector: 'app-scan',
  templateUrl: './scan.component.html',
  styleUrls: ['./scan.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ScanComponent {

  scanDone: boolean;

  constructor(public scan: ScanService) {
    this.scan.scanDone = false;
    this.scan.claimScanData = undefined;
    this.scan.claims = undefined;
    this.scan.placeControlClaims = undefined;
  }
}
