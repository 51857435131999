import {AfterViewInit, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {FormService, LangService, LoaderService, NavigateService, fileToBase64} from 'ngx-satoris';
import {ClaimSelfDetails, ClaimSelfAlt, ClaimSelfState, ClaimSelfSubType, ClaimType, ClaimSelfForAdmin} from 'src/app/shared/models/claim';
import {ApiService} from 'src/app/shared/services/api.service';
import {ActivatedRoute} from '@angular/router';
import {parseError} from 'src/app/shared/utils/string';
import {removeStoredItem} from 'src/app/shared/utils/storage';
import {PersonnalDatasService} from 'src/app/shared/services/personnal-datas.service';

@Component({
  selector: 'app-personnal-data-subtype',
  templateUrl: './personnal-data-subtype.component.html',
  styleUrls: ['./personnal-data-subtype.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PersonnalDataSubTypeComponent implements OnInit, AfterViewInit {


  form: FormGroup;
  submitted: boolean;
  type: ClaimType & ClaimSelfAlt;
  subType: ClaimSelfSubType;
  actualClaimSelf: ClaimSelfForAdmin;
  ClaimSelfState =  ClaimSelfState;
  descBlock: string;
  selectedFile: File;
  metaDatas: ClaimSelfDetails;

  constructor(public nav: NavigateService,
    public api: ApiService,
    public forms: FormService,
    private formBuilder: FormBuilder,
    private loader: LoaderService,
    private route: ActivatedRoute,
    private lang: LangService,
    private personnalDatas: PersonnalDatasService) {

    this.route.params.subscribe(params => {
      this.type = params.type as ClaimType & ClaimSelfAlt;
      this.subType = params.subType;
      const selfFilter = this.personnalDatas.selfClaims[this.type];
      this.metaDatas = (this.personnalDatas.countryClaims[this.type as keyof typeof this.personnalDatas.countryClaims] as Record<ClaimSelfSubType, ClaimSelfDetails>)[this.subType] || (this.personnalDatas.countryClaims[this.type as keyof typeof this.personnalDatas.countryClaims] as Record<ClaimSelfSubType, ClaimSelfDetails>) as ClaimSelfDetails;

      this.actualClaimSelf = selfFilter?.[this.subType] || selfFilter;

      const refusal = this.actualClaimSelf?.state?.rejectReason;

      if(refusal){
        if(this.actualClaimSelf?.state?.value === ClaimSelfState.REJECTED || this.actualClaimSelf?.state?.value === ClaimSelfState.CHANGING) {
          if(refusal?.labelRefusal?.length > 0) {
            this.descBlock = `${refusal?.reasonMessage && (refusal?.reasonMessage + '<br> <br>') }${this.lang.transform('personnalData.rejected.labelRefusal', {labelRefusal: `<b>${refusal.labelRefusal.map((element: string) => this.lang.transform('form.DYN.' + element)).join(', ')}</b>`})}`;
          } else {
            this.descBlock = `${this.lang.transform('proofError.byAdmin')}${refusal?.reasonMessage ? ('<br> <br>' +  this.lang.transform('proofError.byAdmin.desc', {reasonMessage: refusal?.reasonMessage})) : ''}`;
          }
        }
      }
    });
  }


  ngOnInit() {
    this.setForm();
  }


  injectError() {
    if(this.actualClaimSelf?.state?.rejectReason?.labelRefusal){
      this.actualClaimSelf.state.rejectReason.labelRefusal.forEach((element: string) => {
        document.getElementById(element)?.classList.add('input_error');
      });
    }
    if(this.actualClaimSelf?.state?.rejectReason?.reasonMessage){
      document.getElementById('proofPdf')?.classList.add('input_error');
    }
  }
  ngAfterViewInit()  {
    this.injectError();
  }


  setForm() {
    this.form = this.formBuilder.group({});
    let proofRequired = false;
    this.metaDatas.metadataValue.forEach((element: ClaimSelfDetails) => {
      const fieldValidators = element?.validators;
      if(this.metaDatas?.isProof) {
        proofRequired = true;
      }
      let fieldValue = '';
      if(this.actualClaimSelf) {
        if(typeof this.actualClaimSelf.value === 'object') {
          fieldValue = this.actualClaimSelf?.value ?  this.actualClaimSelf?.value[element.label] || '' : '';
        } else {
          fieldValue = this.actualClaimSelf.value || '';
        }
      }
      this.form.addControl(element.label,
        this.formBuilder.control({
          value: fieldValue,
          disabled: false
        },
        fieldValidators));
    });
    if(proofRequired) {
      if(this.actualClaimSelf?.proofDocument) {
        this.form.addControl('proofPdf', this.formBuilder.control(null));
      } else {
        this.form.addControl('proofPdf', this.formBuilder.control(null, Validators.required));
      }
    }

  }


  submitForm() {
    this.submitted = true;
    if(this.forms.getFormErrors(this.form).length < 1 && this.form.valid) {
      this.submitted = false;
      const {proofPdf, ...formValue} = this.form.value;
      let processProof = Promise.resolve(null);
      if(proofPdf) {
        processProof = fileToBase64(this.selectedFile);
      }
      const sendData = () => {
        this.loader.loading(true);
        const updateClaimsDates = JSON.parse(localStorage.getItem('updateClaims')) || {};
        removeStoredItem('selfClaimsCustomer');
        updateClaimsDates.lastSkim = '';
        localStorage.setItem('updateClaims', JSON.stringify(updateClaimsDates));
        processProof
          .then((proofPdfBase64) => this.api.createSelfClaim(JSON.stringify({...formValue}), +this.subType, (this.actualClaimSelf?.proofDocument && !proofPdf) ? this.actualClaimSelf?.proofDocument : proofPdfBase64)).then(() => {
            this.loader.loading(true, {type: 'valid', message: this.actualClaimSelf?.value ? `selfClaim.DYN.${this.metaDatas.metadataValue[0].label}.modify` : `selfClaim.DYN.${this.metaDatas.metadataValue[0].label}.success`}).then(() => this.nav.to('personnal-data'));
          })
          .catch(err => {
            const error = parseError(err);
            this.loader.loading(true, {type: 'error', message: error.tradError});
          });
      };


      if(this.actualClaimSelf?.value) {
        const refusalLabel = this.actualClaimSelf?.state?.rejectReason?.labelRefusal;
        const reasonMessage = this.actualClaimSelf?.state?.rejectReason?.reasonMessage;
        const isCorrected = refusalLabel?.length > 0 ? refusalLabel?.every((element: string) => JSON.stringify(this.actualClaimSelf?.value[element]) !== JSON.stringify(formValue[element])) : proofPdf ? this.actualClaimSelf?.proofDocument !== proofPdf : false;
        if(!isCorrected && (refusalLabel?.length > 0 || reasonMessage)) {
          return this.loader.loading(true, {type: 'warn', message: 'modifyPersonnal.refusal.void'}).then(() => this.injectError());
        }
        if(JSON.stringify(this.actualClaimSelf?.value) === JSON.stringify(formValue) && !this.selectedFile){
          return this.loader.loading(true, {type: 'warn', message: 'modifyPersonnal.void'});
        }
        this.loader.loading(true, {type: 'warn', message: (JSON.stringify(this.actualClaimSelf?.value) === JSON.stringify(formValue) && !reasonMessage) ? 'modifyPersonnal.noChange' : 'modifyPersonnal.DYN.' + this.actualClaimSelf.state.value + '.ask', btnLabel: 'global.confirm'}).then((result: boolean) => {
          if(result){
            sendData();
          }
        } );
      } else {
        sendData();
      }

    }
  }


  onFileChange(event: InputEvent) {
    const inputElement = event.target as HTMLInputElement;
    if(inputElement.files && inputElement.files.length > 0) {
      this.selectedFile = inputElement.files[0];
    }
  }

  onResetFile() {
    this.selectedFile = null;
  }

  popupHelper() {
    const helperMessage = this.lang.transform(`personnalHelperMessage.DYN.${this.metaDatas.metadataValue[0].label}`);
    let proofDocument = 'proofExample';
    if(this.metaDatas?.isProof) {
      proofDocument += `.${this.metaDatas.metadataValue[0].label}`;
    }
    const htmlContent = `
      <div>
        <span>${helperMessage}</span>
        ${this.metaDatas?.isProof ? `<img class="custom-image" src="assets-countries/${proofDocument}.webp" alt="Example">` : ''}
      </div>
    `;
    this.loader.loading(true, {type: 'question', custom: {innerHtml: htmlContent}});
  }


  backButton(){
    this.type in ClaimSelfAlt ? this.nav.to(`personnal-data/${this.type}`) : this.nav.to('personnal-data');
  }

  visualFile(file: File) {
    this.loader.loading(true, {
      hideHeader: true,
      fullPage: true,
      custom: {
        innerHtml: `<div class="popup-visualInput">
        <img id="popup-image" src="${file}" alt="popup image" />
    </div>`
      }
    });
  }

  actionIcon() {
    return () => this.nav.to('personnal-data-explain', undefined, {state: {fromRoute: 'personnal-data/' + this.type + '/' + this.subType}});
  }
}
