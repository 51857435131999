<div id="layout" class="sign_in f_col layout_sm">
  <app-view-title *ngIf="!bluetoothOk" bgImage="assets-countries/header_background.png">{{'devicePermissionsDYN.'+ data.perm +'.title' | translate}}</app-view-title>
  <app-view-title *ngIf="bluetoothOk && !locationOk" bgImage="assets-countries/header_background.png">{{'localisation.title' | translate}}</app-view-title>
  <div class="scroll_wrapper">
    <div class="f_grow">
      <ng-container *ngIf="this.data.perm === 'BLUETOOTH'">
        <app-view-deco-image [hidden]="btImgHidden" [imageUrl]="imgURL"></app-view-deco-image>
        <app-view-deco-image [hidden]="lcImgHidden" [imageUrl]="anotherImgURL"></app-view-deco-image>
      </ng-container>
      <ng-container *ngIf="this.data.perm !== 'BLUETOOTH'">
        <app-view-deco-image [imageUrl]="imgURL"></app-view-deco-image>
      </ng-container>
      <app-paragraph class="mb_40">{{returnTextPermissions()}}</app-paragraph>
    </div>
      <app-button *ngIf="this.data.perm === 'CAMERA'" type="submit" formId="form" class="fullWidth" (click)="checkPermissions()">
        {{hasAccept === 'denied_once' ? ('perm.settings.once'  | translate) : hasAccept === 'denied_always' ?  ('perm.settings.always'  | translate) : ('perm.settings.once'  | translate)}}
      </app-button>
      <app-button *ngIf="this.data.perm === 'BLUETOOTH'" type="submit" formId="form" class="fullWidth" (click)="checkPermissions()">
        {{hasAccept === 'denied_once' ? ('perm.settings.once'  | translate) : hasAccept === 'denied_always' ?  ('perm.settings.always'  | translate) : ('perm.settings.once'  | translate)}}
      </app-button>
  </div>
</div>
