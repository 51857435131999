import {Injectable} from '@angular/core';
import {CapacitorSecurityProvider, SecurityProviderStatus} from '@capacitor-community/security-provider';
import * as pkijs from 'pkijs';
import {DevicesService} from 'ngx-satoris';

declare const window: any;
declare const bluetoothle: any;
declare const Promise: PromiseConstructor & {
  allConcurrent: <T>(n: number) => ((promiseProxies: (() => Promise<T>)[]) => Promise<T[]>);
};

export const OBJECT_STORES: ('claims')[] = ['claims'];
type ArrayElement<ArrayType extends readonly unknown[]> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

@Injectable({
  providedIn: 'root'
})
export class SecureStorageService {
  private crypto = pkijs.getCrypto(true);
  private deviceReady = false;
  private secureStorage: any;
  private idbstorage: IDBDatabase;
  private generateKeyPromise: Promise<CryptoKey>;

  fullJwk: JsonWebKey & {hash?: string};
  privateDeviceKey: CryptoKey;
  securityStatus = SecurityProviderStatus.NotImplemented;

  constructor(private devices: DevicesService) {
    CapacitorSecurityProvider.installIfNeeded().then((result: any) => {
      this.securityStatus = result.status;
    });
  }

  private generateNewSecretKey(): Promise<CryptoKey> {
    if(this.generateKeyPromise) return this.generateKeyPromise;
    if(this.devices.isDevices('cordova')) {
      this.generateKeyPromise = Promise.all([
        this.crypto.generateKey({
          name: 'AES-GCM',
          length: 256
        }, true, ['encrypt', 'decrypt']).then(key => Promise.all([Promise.resolve(key), this.crypto.exportKey('raw', key as CryptoKey)])),
        this.crypto.generateKey({
          name: 'ECDSA',
          namedCurve: 'P-256'
        }, true, ['sign']).then(key => Promise.all([
          Promise.resolve(key),
          this.crypto.exportKey('jwk', key.privateKey),
          this.crypto.exportKey('jwk', key.publicKey)
        ]))
      ]).then(([[symmKey, bytesSym], [asymmKey, jwkPriDevice, jwkPubDevice]]) => {
        this.fullJwk = {
          ...jwkPubDevice,
          ...jwkPriDevice
        };
        this.privateDeviceKey = asymmKey.privateKey;
        return this.crypto.digest('SHA-256', bluetoothle.stringToBytes(this.fullJwk.x + this.fullJwk.y)).then(hash => {
          this.fullJwk.hash = this.hexEncode(hash);

          this.secureStorage.set((key: any) => console.log('key : ' + key), (error: any) => console.error('error : '+ error), 'secretKey', bluetoothle.bytesToString(bytesSym), this.fullJwk.x + this.fullJwk.y);
          this.secureStorage.set((key: any) => console.log('key : ' + key), (error: any) => console.error('error : '+ error), 'privateDeviceKey', JSON.stringify(jwkPriDevice), this.fullJwk.x + this.fullJwk.y);
          this.secureStorage.set((key: any) => console.log('key : ' + key), (error: any) => console.error('error : '+ error), 'publicDeviceKey', JSON.stringify(jwkPubDevice), this.fullJwk.x + this.fullJwk.y);
          return symmKey;
        });
      });
      return this.generateKeyPromise;
    }
    return Promise.reject('Only supported on cordova');
  }

  hexEncode(input: string | ArrayBuffer) {
    let hex, result = '';
    const isStr = typeof input === 'string';
    let str = isStr ? input : new Uint8Array(input);
    for(let i = 0; i < str.length; i++) {
      hex = (isStr ? (str as string).charCodeAt(i) : str[i]).toString(16);
      result += ('0' + hex).slice(-2);
    }
    return result;
  }

  clearStore() {
    if(this.devices.isDevices('cordova')){
      return Promise.all(OBJECT_STORES.map(store => new Promise((resolve, reject) => {
        if(!this.idbstorage) reject;
        const trans = this.idbstorage.transaction(store, 'readwrite');
        const objStore = trans.objectStore(store);
        const request = objStore.clear();
        request.onsuccess = resolve;
        request.onerror = reject;
      })));
    }
    return Promise.reject('Only supported on cordova');
  }

  retrieveMasterKey(): Promise<CryptoKey> {
    if(!this.fullJwk) return this.retrieveSecretKey().then(() => this.retrieveMasterKey());
    return new Promise(resolve => {
      this.secureStorage.get(resolve, (error: any) => {
        console.warn('error : ', error);
        resolve(undefined);
      }, 'secretKey');
    }).then(stringSecretKey => this.crypto.importKey('raw', bluetoothle.stringToBytes(stringSecretKey), {
      name: 'AES-GCM'
    }, false, ['encrypt', 'decrypt'])).catch(() => this.generateNewSecretKey().then(newKey => newKey));
  }

  retrieveSecretKey(): Promise<any> {
    if(this.fullJwk && this.privateDeviceKey) return Promise.resolve();
    if(this.devices.isDevices('cordova')) {
      return Promise.allConcurrent(1)([
        () => new Promise(resolve => {
          this.secureStorage.get(resolve, (error: any) => {
            console.warn('error : ', error);
            resolve(undefined);
          }, 'privateDeviceKey');
        }),
        () => new Promise(resolve => {
          this.secureStorage.get(resolve, (error: any) => {
            console.warn('error : ', error);
            resolve(undefined);
          }, 'publicDeviceKey');
        })
      ]).then(([jwkPriDevice, jwkPubDevice]: [string, string]): Promise<any> => {
        if(!jwkPriDevice || !jwkPubDevice) {
          return Promise.all([
            this.generateNewSecretKey(),
            this.clearStore()
          ]);
        }
        this.fullJwk = {
          ...JSON.parse(jwkPubDevice),
          ...JSON.parse(jwkPriDevice)
        };
        return Promise.all([
          this.crypto.importKey('jwk', this.fullJwk, {
            name: 'ECDSA',
            namedCurve: 'P-256'
          }, false, ['sign']).then(key => {
            this.privateDeviceKey = key;
          }),
          this.crypto.digest('SHA-256', bluetoothle.stringToBytes(this.fullJwk.x + this.fullJwk.y)).then(hash => {
            this.fullJwk.hash = this.hexEncode(hash);
          })
        ]);
      });
    }
    return Promise.reject('Only supported on cordova');
  }

  encryptDatas(name: ArrayElement<typeof OBJECT_STORES>, id: string, dataToEncrypt: string, dataToStore: string) {
    if(this.devices.isDevices('cordova')) {
      return this.retrieveMasterKey().then(symmetricKey => this.crypto.encrypt({
        name: 'AES-GCM',
        iv: new Int8Array([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]),
        tagLength: 128
      }, symmetricKey, bluetoothle.stringToBytes(dataToEncrypt)).then(encryptedBytes => new Promise((resolve, reject) => {
        const trans = this.idbstorage.transaction(name, 'readwrite');
        const objStore = trans.objectStore(name);
        const request = objStore.put({id: id, valueEncrypted: encryptedBytes, value: dataToStore});
        request.onsuccess = resolve;
        request.onerror = reject;
      })));
    }
    return Promise.reject('Only supported on cordova');
  }

  setEncryptSecureDatas(name: 'pin' | 'longJWt' | 'place', dataToEncrypt: string) {
    if(this.devices.isDevices('cordova')) {
      return this.retrieveSecretKey().then(() => new Promise(resolve => {
        this.secureStorage.set(resolve, (error: any) => {
          console.log('error : ', error);
          resolve(undefined);
        }, name, dataToEncrypt);
      }));
    }
    return Promise.reject('Only supported on cordova');
  }

  getEncryptSecureDatas(name: 'pin' | 'longJWt' | 'place'): Promise<string> {
    if(this.devices.isDevices('cordova')) {
      return new Promise(resolve => {
        this.secureStorage.get(resolve, () => {
          resolve(undefined);
        }, name);
      });
    }
    return Promise.reject('Only supported on cordova');
  }

  deleteEncryptSecureDatas(name: 'pin' | 'longJWt' | 'place'){
    if(this.devices.isDevices('cordova')) {
      return new Promise(resolve => {
        this.secureStorage.remove(resolve, (error: any) => {
          console.error(error);
          resolve(undefined);
        }, name);
      });
    }
    return Promise.reject('Only supported on cordova');
  }

  getEncryptedDatas(name: ArrayElement<typeof OBJECT_STORES>): Promise<{ id: string, dataToEncrypt: string, dataToStore: string }[]> {
    if(this.devices.isDevices('cordova')) {
      return this.retrieveMasterKey().then(symmetricKey => new Promise((resolve, reject) => {
        const objStore = this.idbstorage.transaction(name, 'readonly').objectStore(name);
        const query = objStore.openCursor();
        const fetched: { id: string, dataToEncrypt: string, dataToStore: string }[] = [];
        const decryptionPromises: Promise<void>[] = [];

        query.onsuccess = (e: any) => {
          const cursor = e.target.result;
          if(cursor) {
            const cursorValue = cursor.value;
            const decryptionPromise = this.crypto.decrypt({
              name: 'AES-GCM',
              iv: new Int8Array([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]),
              tagLength: 128
            }, symmetricKey, cursorValue.valueEncrypted).then(bytes => {
              fetched.push({id: cursorValue.id, dataToEncrypt: this.arrayBufferToString(bytes), dataToStore: cursorValue.value});
            }).catch(reject);

            decryptionPromises.push(decryptionPromise);
            cursor.continue();
          } else {
            Promise.all(decryptionPromises).then(() => {
              resolve(fetched);
            }).catch(reject);
          }
        };
        query.onerror = reject;
      }));
    }
    return Promise.reject('Only supported on cordova');
  }

  getStoreAttestations(xy?: string): Promise<string> {
    if(this.devices.isDevices('cordova')) {
      return new Promise((resolve, reject) => {
        if(this.devices.isDevices('cordova-ios') && xy){
          this.secureStorage.attestation((result: string) => resolve(result), (err: any) => reject(err), xy);
        } else if(this.devices.isDevices('cordova-android')) {
          this.secureStorage.attestation((result: string) => resolve(result), (err: any) => reject(err));
        } else return Promise.reject('Error during attestation');
      });
    }
    return Promise.reject('Only supported on cordova');
  }

  retrieveSecretKeyAndCerts(): Promise<[string, string[]]> {
    return this.retrieveSecretKey().then(() => this.getStoreAttestations().then((attestations: string) => {
      //TODO: Check on iOS
      const certs = attestations.match(/-----BEGIN CERTIFICATE-----[\s\S]+?-----END CERTIFICATE-----/g);
      let xyCert, certChain;

      if(certs) {
        xyCert = certs[0];
        certChain = certs.slice(1);
        return [xyCert, certChain];
      } else {
        console.error('No certificates found.');
        return [undefined, undefined];
        //TODO: Change to this return if we need to refuse using devices without attestations
        // return Promise.reject('error.no_attestation');
      }
    }));
  }

  arrayBufferToString( buffer: any ) {
    let binary = '';
    const bytes = (new Uint8Array(buffer));
    const len = bytes.byteLength;
    for(let i = 0; i < len; i++) {
      binary += String.fromCharCode( bytes[i] );
    }
    return binary;
  }

  initSecureStorage() {
    return new Promise(resolve => {
      if(this.devices.isDevices('cordova')) {
        if(this.deviceReady) {
          resolve(true);
        } else {
          document.addEventListener('deviceready', () => {
            this.deviceReady = true;
            const dbreq = window.indexedDB.open('lu.satoris.digitalid', 1);
            dbreq.onsuccess = (e: any) => {
              this.idbstorage = e.target.result;
              if(this.secureStorage) {
                resolve(true);
              }
            };
            dbreq.onupgradeneeded = (e: any) => {
              const db = e.target.result;
              if(!db.objectStoreNames.contains('claims')) {
                db.createObjectStore('claims', {keyPath: 'id'});
              }
            };
            if(!this.secureStorage) {
              if(this.devices.isDevices('cordova-ios')) {
                this.secureStorage = new window.cordova.plugins.SecureStorage(() => {
                  if(this.idbstorage) {
                    resolve(true);
                  }
                }, console.error, 'lu.satoris.digitalid');
              } else { // cordova-android
                this.secureStorage = window.SecureKeystoreStorage;
                if(this.idbstorage) {
                  resolve(true);
                }
              }
            }
          });
        }
      } else {
        resolve(true);
      }
    });
  }
}
