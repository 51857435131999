<div id="layout" class="f_col layout_sm">
  <app-view-title bgImage="assets-countries/header_background.png">{{'more.security' | translate}}</app-view-title>
  <div class="scroll_wrapper">
    <div class="f_grow mt_20">
      <app-slide-element *ngIf="device.isDevices('cordova') && !nav.demoMode" leftIcon="{{device.isDevices('cordova-android') ? 'fingerprint' : 'face-smile'}}" (btnClicked)="nav.to('more-security-biometric')" class="mb_10">
        {{(device.isDevices('cordova-android') ? 'more.security.fingerprint' : 'more.security.faceid') | translate}}
      </app-slide-element>
      <app-slide-element *ngIf="api.userRole?.isCustomer" [disabled]="!sync.isOnline" [colorRight]="!sync.isOnline ? '#f51d2a' : undefined" [rightIcon]="!sync.isOnline ? 'wifi' : 'arrow-right'" leftIcon="message" (btnClicked)="sync.isOnline ? nav.to('more-security-otp-explain') : sync.loaderOffline()" class="mb_10">
        {{('more.security.otp') | translate}}
      </app-slide-element>
      <app-slide-element [disabled]="!sync.isOnline" leftIcon="location-dot" [colorRight]="!sync.isOnline ? '#f51d2a' : undefined" [rightIcon]="!sync.isOnline ? 'wifi' : 'arrow-right'" leftIcon="key" (btnClicked)="sync.isOnline ? nav.toPin('more-security', 'change-password', true, undefined, undefined) : sync.loaderOffline()" class="mb_10">
        {{'more.change_password' | translate}}
      </app-slide-element>
      <app-slide-element *ngIf="api.userRole?.isCustomer" [disabled]="!sync.isOnline" leftIcon="location-dot" [colorRight]="!sync.isOnline ? '#f51d2a' : undefined" [rightIcon]="!sync.isOnline ? 'wifi' : 'arrow-right'" leftIcon="key" (btnClicked)="sync.isOnline ? nav.to('more-security-change-pin') : sync.loaderOffline()" class="mb_10">
        {{'more.change_pin' | translate}}
      </app-slide-element>
     <app-divider *ngIf="api.userRole.isCustomer && sync.isOnline" class="mt_20 mb_20 width_100pc" color="white"></app-divider>
     <app-button *ngIf="api.userRole.isCustomer && sync.isOnline" customBackgroundColor="var(--clr-btn-red)"  (click)="sync.isOnline ? disconnectDevices() : sync.loaderOffline()" type="button" class="fullWidth">{{'disconnect.allOtherDevices' | translate}}</app-button>
    </div>
  </div>
  <app-back-btn position="botLeft" [navTo]="{route: 'more'}"></app-back-btn>
</div>
