<div id="layout" class="sign_up f_col">
  <app-view-title bgImage="assets-countries/header_background.png" fromRoute="sign-up" toRoute="choose-lang" [chooseLangBtn]="true">{{'signUp.title' | translate}}</app-view-title>
  <div class="scroll_wrapper">
    <app-view-deco-image imageUrl="assets-countries/undraw_exploring.svg"></app-view-deco-image>
    <app-paragraph  textAlign="center" class="mt_10 mb_10" innerhtml="{{'signup.desc' | translate}}" ></app-paragraph>
    <app-slide-element  [leftIcon]="signType === OtpType.EMAIL ? 'phone' : 'message'" (btnClicked)="changeOtpType()" class="mb_10">
      {{(signType === OtpType.EMAIL ? 'signup.phone' : 'signup.email') | translate}}
    </app-slide-element>
    <app-paragraph class="mb_20"  align="center" textAlign="center" [innerHtml]="(signType=== OtpType.EMAIL ? 'signup.state.email' : 'signup.state.phone') | translate"></app-paragraph>
    <form id="form" [formGroup]="form" (ngSubmit)="submitSignUp()">
      <app-input *ngIf="signType === OtpType.EMAIL" type="email" formCtrlName="email" [formGrp]="form" label="form.email"></app-input>
      <app-input *ngIf="signType === OtpType.PHONE" type="tel" formCtrlName="phone" [formGrp]="form" label="form.sms" prefix="225"></app-input>
      <app-input type="password" formCtrlName="password" (output)="checkPswdStrength()"
                 [formGrp]="form" label="form.password" class="mt_24"></app-input>
      <div class="pswd_strength mt_4">
        <span class="pswd_strength_bar" *ngIf="pswdStrength >= 20"></span>
        <span class="pswd_strength_bar" *ngIf="pswdStrength >= 40"></span>
        <span class="pswd_strength_bar" *ngIf="pswdStrength >= 70"></span>
        <span class="pswd_strength_bar" *ngIf="pswdStrength >= 90"></span>
      </div>
      <app-input type="password" formCtrlName="password2"
                 [formGrp]="form" label="form.password2" class="mt_24 mb_20"></app-input>
      <app-errors-list [dark]="true" *ngIf="submitted"
                       [formErrors]="forms.getFormErrors(this.form)"></app-errors-list>
    </form>
  </div>
  <div class="f_align_end f_grow mt_10 mb_30">
    <app-back-btn [navTo]="{route: 'home'}"></app-back-btn>
    <div class="ml_10 fullWidth">
      <app-button type="submit" formId="form" class="fullWidth">{{'confirm' | translate}}</app-button>
    </div>
  </div>
</div>
