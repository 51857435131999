<div id="layout" class="f_col" [class.layout_sm]="api.userInfo && api.userInfo.nationalNumber">
  <app-view-title bgImage="assets-countries/header_background.png" fromRoute="password-changed" toRoute="choose-lang" [chooseLangBtn]="true">{{'passwordChanged.title' | translate}}</app-view-title>
  <div class="scroll_wrapper">
    <app-view-deco-image imageUrl="assets-countries/image_done.svg"></app-view-deco-image>
    <app-paragraph class="mb_40 text_center">{{(data?.proAccount ? 'passwordProChanged.desc' :  'passwordChanged.desc') | translate }}</app-paragraph>
    <div class="f_align_end f_grow ">
      <div class="fullWidth ml_10">
        <app-button  (btnClicked)="nav.to(data?.proAccount ? 'sign-in-pro' : 'sign-in')"
                    class="fullWidth">
          {{(data?.proAccount ? 'connectPro.back': 'connect.back') | translate}}
        </app-button>
      </div>
    </div>
  </div>
</div>
