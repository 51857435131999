const NUMERIC_REGEXP = /[0-9]/g;
const NUMERIC_VALUE_BASE = 10;
const ALPHABET_REGEXP = /[a-z]/g;
const ALPHABET_START = 'a'.charCodeAt(0);
const ALPHABET_VALUE_START = 10;
const WEIGHTS = [7, 3, 1];
const FILLER = '<';
const MAX_LINE_LENGTH = 30;

function padNumber(num: number, toLength: number) {
  const str = String(num);
  if(str.length > toLength) return str.substring(str.length - toLength);
  return new Array(toLength - str.length).fill('0').join('') + str;
}
function padString(str: string, toLength: number) {
  str = str.toUpperCase();
  if(str.length > toLength) return str.substring(0, toLength).replace(/ /g, FILLER);
  return str.replace(/ /g, FILLER) + new Array(toLength - str.length).fill(FILLER).join('');
}
function fixNames(names: string[]) {
  return names.join(FILLER).replace(/[ -']/g, '')
    .replace(/é/g, 'e').replace(/è/g, 'e').replace(/à/g, 'a').replace(/ù/g, 'u').replace(/ç/g, 'c')
    .replace(/ï/g, 'i').replace(/î/g, 'i').replace(/ê/g, 'e').replace(/ô/g, 'o')
    .replace(/É/g, 'e').replace(/È/g, 'e').replace(/À/g, 'a').replace(/Ù/g, 'u').replace(/Ç/g, 'c')
    .replace(/Ï/g, 'i').replace(/Î/g, 'i').replace(/Ê/g, 'e').replace(/Ô/g, 'o').toUpperCase();
}

function calculateCheckDigit(str: string) {
  const arr = str.split('');
  const valArr = arr.map((character: string, index: number) => {
    const char = character.toLowerCase();
    const weight = WEIGHTS[index % 3];
    let value = 0;
    if(char.match(ALPHABET_REGEXP) !== null) {
      value = char.charCodeAt(0) - ALPHABET_START + ALPHABET_VALUE_START;
    } else if(char.match(NUMERIC_REGEXP) !== null) {
      value = parseInt(char, NUMERIC_VALUE_BASE);
    }
    return value * weight;
  });
  return ((valArr.reduce((acc, value) => acc + value, 0) % NUMERIC_VALUE_BASE) + NUMERIC_VALUE_BASE) % NUMERIC_VALUE_BASE;
}

function displayDate(date: Date) {
  return padNumber(date.getUTCFullYear(), 2) + padNumber(date.getUTCMonth() + 1, 2) + padNumber(date.getUTCDate(), 2);
}

export function generateTd1Mrz({
  docType,
  country,
  docNumber,
  birthdate,
  sex,
  expiry,
  nationality,
  optionalElement,
  familyNames,
  givenNames
}: {
    docType: 'ID',
    country: string,
    docNumber: string,
    birthdate: Date,
    sex: 'M' | 'F' | '<',
    expiry: Date,
    nationality: string,
    optionalElement: string, // National number!
    familyNames: string[],
    givenNames: string[]
}) {
  const bel = country.toUpperCase() === 'BEL';
  const line1 = padString(docType + padString(country, 3) + padString(docNumber, 9) + (bel ?
    (FILLER + padString(docNumber.substring(9), 3) + calculateCheckDigit(padString(docNumber.substring(0, 9) + FILLER + docNumber.substring(9), 13))) :
    calculateCheckDigit(padString(docNumber, 9))), MAX_LINE_LENGTH);
  const line2 = displayDate(birthdate) + calculateCheckDigit(displayDate(birthdate)) + sex + displayDate(expiry) + calculateCheckDigit(displayDate(expiry)) + padString(nationality, 3) + padString(optionalElement, 11);
  return {
    line1: line1,
    line2: line2 + calculateCheckDigit(line1.substring(5, 30) + line2.substring(0, 7) + line2.substring(8, 15) + line2.substring(18, 29)),
    line3: padString(fixNames(familyNames) + FILLER + FILLER + fixNames(givenNames), MAX_LINE_LENGTH)
  };
}
