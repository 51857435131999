<div id="layout" class="f_col layout_sm">
    <app-view-title bgImage="assets-countries/header_background.png" fromRoute="welcome-done" toRoute="choose-lang">{{'ask.help' | translate}}</app-view-title>
    <div class="scroll_wrapper">
      <app-view-deco-image imageUrl="assets-countries/undraw_myDatas.svg"></app-view-deco-image>
      <div class="f_col gap_20 mb_10">
        <app-paragraph [innerHTML]="'personnalAdd.explain' | translate"></app-paragraph>
      </div>
      <div class="flex f_col">
        <app-paragraph textAlign="center">{{'explainHelpPersonnal' | translate}}</app-paragraph>
        <div class="mt_20 flex gap_20 f_center f_col">
          <div class="flex gap_5 f_col f_align_c">
            <fa-icon icon="circle-check" [style.color]="'green'"></fa-icon>
            <span class="ml_4">{{'explain.personnal.verify' | translate}}</span>
          </div>
          <app-divider></app-divider>
          <div class="flex gap_5 f_col f_align_c">
            <fa-icon icon="clock" [style.color]="'orange'"></fa-icon>
            <span class="ml_4">{{'explain.personnal.waiting' | translate}}</span>
          </div>
          <app-divider></app-divider>
          <div class="flex gap_5 f_col f_align_c">
            <fa-icon icon="circle-xmark" [style.color]="'var(--clr-btn-red)'"></fa-icon>
            <span class="ml_4">{{'explain.personnal.noVerify' | translate}}</span>
          </div>
          <app-divider></app-divider>
          <div class="flex gap_5 f_col f_align_c mb_10">
              <div class="flex gap_20">
              <fa-icon icon="rotate" [style.color]="'orange'"></fa-icon>
              <fa-icon icon="rotate" [style.color]="'var(--clr-btn-red)'"></fa-icon>
            </div>
              <span class="ml_4">{{'explain.personnal.change' | translate}}</span>
          </div>
        </div>
      </div>
      <div class="f_align_end f_grow ">
        <div class="fullWidth ml_10 flex gap_10">
          <app-back-btn (btnClicked)="nav.to(fromRoute || 'personnal-data')"></app-back-btn>
        </div>

      </div>
    </div>
  </div>

