import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders} from '@angular/common/http';
import {Observable, from} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {ApiService} from './api.service';
import {getStoredItem} from '../utils/storage';


const noInterceptor = [
  'https://maps.googleapis.com/maps',
  'ping'
];

@Injectable()
export class JwtInterceptorService implements HttpInterceptor {

  constructor(private api: ApiService) {
  }
  
  private getHttpOptions(): object {

    const token = this.api.accountJwt?.jwt;
    
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json'
      })
    };

    if(token) {
      options.headers = options.headers.append('Authorization', 'Bearer ' + token);
    }
    return options;
  }
  
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.api.accountJwt?.jwt || getStoredItem('jwt');
    
    const nextRequest = () => {
      const authReq = request.clone(this.getHttpOptions());
      return next.handle(authReq);
    };
    if(!token || noInterceptor.some(url => request.url.includes(url))) {
      return next.handle(request);
    }  else if(this.api.isCheckingJwt){
      return nextRequest();
    } else {
      return from(this.api.checkJwt(true)).pipe(switchMap(() => nextRequest()));
    }
  }
}
