<div id="layout" class="f_col" [class.layout_sm]="api.userInfo && api.userInfo.nationalNumber && checkWelcomeDone()">
  <app-view-title bgImage="assets-countries/header_background.png">{{'choose-lang.title' | translate}}</app-view-title>
  <div class="scroll_wrapper">
    <app-paragraph class="mt_20">{{'choose-lang.desc' | translate}}</app-paragraph>
    <div class="f_grow mt_20">
      <app-slide-element  *ngFor="let language of languages" (btnClicked)="this.api?.userInfo?.lang !== language?.value ? changeLang(language.value) : warningChange()"
                          leftIcon="language"
                          class="mt_10"
                          [disabled]="this.api?.userInfo?.lang === language?.value">
                          {{language.label | translate}}
      </app-slide-element>
    </div>
  </div>
  <div class="mb_10">
    <app-back-btn  (btnClicked)="data?.extras ? nav.to(data?.fromRoute, undefined, {state: data?.extras}) : nav.to(data?.fromRoute)"></app-back-btn>
  </div>
</div>
