<div id="layout" class="f_col layout_sm">
    <app-view-title bgImage="assets-countries/header_background.png">{{'claimSelf.RefusalTitle' | translate}}</app-view-title>
    <app-checkbox-element class="mt_20" [text]="'proof.error.ask'" (onChange)="changeStateRefusal()" customColorCheck="var(--clr-btn-red)">{{'proof.error.ask' | translate}}</app-checkbox-element>
    <div class="scroll_wrapper">
      <div>
        <app-paragraph *ngIf="!refusalProofMode" class="mt_20">{{'field.maybeError' | translate}}</app-paragraph>
        <form class="flex f_col gap_10 mt_20" id="form" *ngIf="form" [formGroup]="form" (ngSubmit)="submitForm()">
          <div *ngIf="!refusalProofMode">
            <div *ngFor="let claim of allArray" class="mt_10">
              <app-checkbox-element [formGrp]="form" [formCtrlName]="claim.label" [text]="'form.DYN.' + claim.label" customColorCheck="var(--clr-btn-red)"></app-checkbox-element>
            </div>
          </div>
          <div *ngIf="refusalProofMode" class="flex f_col gap_20">
            <app-paragraph>{{'proofRefusal.desc' | translate}}</app-paragraph>
            <app-input [maxLength]="200" textareaRows="8"  [formGrp]="form" type="textarea" formCtrlName="reasonMessage"></app-input>
          </div>
        </form>
      </div>
    </div>
    <div class="f_align_end">
      <div class="fullWidth flex gap_10 mb_20">
        <app-back-btn (btnClicked)="nav.to('personnal-data-manage/' + actualClaimSelf.id)"></app-back-btn>
        <app-button [disabled]="!form.valid" type="submit" formId="form" class="fullWidth">{{'global.confirm' | translate}}</app-button>
      </div>
    </div>
  </div>
  