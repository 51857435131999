<div id="layout" class="f_col" [class.layout_sm]="!api.userRole.isCustomer">
  <app-view-title bgImage="assets-countries/header_background.png" fromRoute="activation-step-confirm" toRoute="choose-lang" [chooseLangBtn]="api.userRole.isCustomer && true">
    {{'activation.step.confirm.title' | translate}}
  </app-view-title>
  <div class="scroll_wrapper">
    <app-view-deco-image maxWidth="300px" imageUrl="assets-countries/completed.svg"></app-view-deco-image>
    <div *ngFor="let fc of enroll.getEnrollFormList(); let i = index" class="flex f_between f_align_c fullWidth mt_10">
      <app-slide-element [noPointerEvent]="true" [rightIcon]="enroll.form.controls[fc].valid ? 'check' : 'times'" class="fullWidth">
        {{(i + 1) + '. '+ (('activation.step.confirm.fc.DYN.'+ fc) | translate )}}
      </app-slide-element>
    </div>
    <div class="f_align_end f_grow mt_20">
      <app-back-btn (btnClicked)="enroll.navigate('previous')"></app-back-btn>
      <div class="fullWidth ml_10">
        <app-button [disabled]="inConfirm" (btnClicked)="confirm()" class="fullWidth">
          {{'global.confirm' | translate}}
        </app-button>
      </div>
    </div>
  </div>
</div>
