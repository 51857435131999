/**
 * truncate string if more than parameter
 * @param {string} string
 * @param {number} n
 * @param {boolean} reverse
 * @param {boolean} middle
 * @returns {string}
 */
export const truncateString = (string: string, n: number, reverse = false, middle = false): string => {
  if(string) {
    if(middle) {
      if(string.length > 2*n) {
        return `${string.substring(0, n)}...${string.substring(string.length - n)}`;
      } else {
        return string;
      }
    } else {
      if(!reverse) {
        return string.length > n ? string.substring(0, n)  : string;
      } else {
        return string.length > n ? string.substring(string.length  - n, string.length)  : string;
      }
    }
  } else {
    return '';
  }
};

/**
 * Parse error received by the api
 * @param {any} err
 * @param {string} backupMessage
 * @returns {{ baseError: string, tradError: string }}
 */
export const parseError = (err: any, backupMessage = ''): { baseError: string, tradError: string } => {
  try {
    if(err.includes('{"message":')) {
      try {
        console.error(JSON.parse(err)?.message);
        return {baseError: JSON.parse(err)?.message, tradError: 'error.basic'};
      } finally {}
    }
    const baseError = err.toString().split(': ').pop().trim();
    const tradError = baseError && `${baseError}.DYN.server`;
    return {baseError, tradError: tradError || String(backupMessage + '.DYN.server').trim()};
  } catch (e) {
    const backupServerError = String(backupMessage + '.DYN.server').trim();
    return {baseError: backupServerError, tradError: backupServerError};
  }
};
