<div id="layout" class="f_col" [class.layout_sm]="!api.userRole?.isCustomer">
  <app-view-title bgImage="assets-countries/header_background.png" fromRoute="activation-step-fingerprint" toRoute="choose-lang" [chooseLangBtn]="api.userRole?.isCustomer && true">
    {{'activation.step.fingerprint.title' | translate}}
  </app-view-title>
  <div class="scroll_wrapper">
      <app-paragraph class="mt_20">{{'fingerprint.read.self.form.desc' | translate}}</app-paragraph>
      <div class="f_grow mt_20" [class.mb_20]="!api.userRole?.isCustomer">
        <form id="form" [formGroup]="enroll.form" (ngSubmit)="enroll.submitForm(enroll.getCurrentStep())">
          <app-input type="select" [selectOptions]="fingerOptions" [formGrp]="enroll.form" [formCtrlName]="enroll.fc.FINGERPRINT" label="form.finger" icon="hashtag"></app-input>
          <app-errors-list [dark]="true" *ngIf="!enroll.form.controls[enroll.fc.FINGERPRINT].valid && enroll.form.controls[enroll.fc.FINGERPRINT].touched" [formErrors]="this.forms.getFormErrors(enroll.form, enroll.fc.FINGERPRINT)"></app-errors-list>
        </form>
      </div>
      <app-paragraph class="mt_20" *ngIf="this.nfc.isCaptureStarted">{{'fingerprint.read.self.form.desc2' | translate}}</app-paragraph>
  
      <div class="f_align_end f_grow ">
        <div class="f_align_end f_grow mt_10 backbtn">
          <app-back-btn (btnClicked)="!this.nfc.isCaptureStarted && enroll.navigate('previous')"></app-back-btn>
        </div>
        <div class="fullWidth ml_10">
          <app-button #buttonStartScan [disabled]="this.nfc.isCaptureStarted || !enroll.form.controls[enroll.fc.FINGERPRINT].valid" disableTime="60" (btnClicked)="scanFingerprint()" class="fullWidth">
            {{'global.continue' | translate}}
          </app-button>
        </div>
      </div>
  </div>
</div>
