import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {PlatformEvent} from '../models/information';
import {LangService, LoaderService} from 'ngx-satoris';
import {FormGroup} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ActivityService {

  constructor(private api: ApiService, private loader: LoaderService, private lang: LangService) { }

  activities: PlatformEvent[];
  filterActivities: PlatformEvent[];

  getActivities(type: 'account' | 'shared') {
    const excludeTypes = type === 'account' ? [6, 7, 8, 9, 10] : [0, 1, 3, 4, 11, 12];
    return Promise.all([
      this.api.events(false),
      this.api.events(true)
    ]).then(([res1, res2]: [any, any]) => {
      const alllActivities = res1.result.concat(res2.result);
      this.activities = alllActivities.filter((activity: PlatformEvent) => !excludeTypes.includes(activity?.type));
      Promise.resolve();
    }).catch(err => {
      this.loader.loading(true, {type: 'error', message: String(err).indexOf(': ') > -1 ? err : this.lang.transform('activity.getActivities.error')});
      Promise.reject(err);
    });
  }


  onDateRangeChanged(eventData: { dateFrom: string, dateTo: string }) {
    const isDateBetween = (dateFrom: string, dateTo: string) => (item: PlatformEvent) => {
      const df = Date.parse(dateFrom);
      const dt = Date.parse(dateTo);
      const idate = Date.parse(item.updatedOn);

      return idate > df && idate < dt;
    };

    if(eventData.dateFrom === '' && eventData.dateTo === '') {
      this.filterActivities = undefined;
    } else {
      const fdate = eventData.dateFrom === '' ? new Date(+0).toISOString() : eventData.dateFrom;
      const tdate = eventData.dateTo === '' ? new Date().toISOString() : eventData.dateTo;
      this.filterActivities = this.activities.filter(isDateBetween(fdate, tdate));
    }
  }

  submit(form: FormGroup) {
    if(form.get('search').value) {
      const value = form.get('search').value.toLowerCase().trim();
      this.filterActivities = this.activities.filter(x => x.place_id?.toLowerCase().includes(value) || x.target_id?.toString().includes(value) || x.id?.toString().includes(value));
    } else {
      this.filterActivities = undefined;
    }
  }

  resetActivities() {
    this.filterActivities = undefined;
    this.activities = undefined;
  }

}
