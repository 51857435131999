import {Component, ViewEncapsulation} from '@angular/core';
import {ApiService} from '../../shared/services/api.service';
import {DevicesService, LangService, LoaderService, NavigateService} from 'ngx-satoris';
import {setStoredItem} from 'src/app/shared/utils/storage';
import {PermissionsService} from '../../shared/services/permissions.service';
import {environment} from 'src/environments/environment';
import {NfcService} from 'src/app/shared/services/nfc.service';

@Component({
  selector: 'app-activation',
  templateUrl: './activation.component.html',
  styleUrls: ['./activation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ActivationComponent {
  environment = environment;
  hasNfc = false;
  constructor(public nav: NavigateService,
    public api: ApiService,
    public devices: DevicesService,
    private permissions: PermissionsService,
    public nfc: NfcService,
    private loader: LoaderService,
    private lang: LangService) {
    setStoredItem('welcomeDone', false);
    this.nfc.detectNfc().then((res: boolean) => {
      this.hasNfc = res;
    });
  }

  accountSelfActivation() {
    if(!this.hasNfc) {
      return this.loader.loading(true, {type: 'warn', message: 'device.noNfc'});
    }
    if(this.devices.isDevices('cordova')){
      this.permissions.checkCameraPermission().then(() => {
        const isCameraPermissionGranted = this.permissions.stateOfPermissions.camera.hasPermission;
        if(isCameraPermissionGranted){
          this.nav.to('activation-step-intro');
        } else {
          this.nav.toPerm('activation', 'activation-step-intro', 'CAMERA');
        }
  
      });
    } else {
      this.nav.toPerm('activation', 'activation-step-intro', 'CAMERA');
    }
  }

  askDisconnect() {
    this.loader.loading(true, {type: 'warn', message: this.lang.transform('ask.signout'), btnLabel: this.lang.transform('confirm')}).then((done) =>{
      if(done) {
        this.api.signOut(true);
      }
    });
  }
}