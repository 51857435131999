<div class="card"
     (click)="toggleTurnAround()"
     [class.noPointerEvents]="noPointerEvents"
     [class.turnAround]="turnAround"
     [class.card_sm]="size === 'sm'">
  <div class="card_sideA" [class.card_sideA_noPro]="!init.isPro">
    <div class="lock">
      <fa-icon *ngIf="!init.isPro" icon="lock"></fa-icon>
    </div>
    <div *ngIf="!init.isPro" class="card_noPro"></div>
    <img [src]="imageRecto" alt="recto">
  </div>
  <div class="card_sideB" [class.card_sideB_noPro]="!init.isPro">
    <div class="lock">
      <fa-icon *ngIf="!init.isPro" icon="lock"></fa-icon>
    </div>
    <div *ngIf="!init.isPro" class="card_noPro"></div>
    <img [src]="imageVerso" alt="verso">
  </div>
</div>
