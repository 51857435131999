<div id="layout" class="f_col layout_sm">
  <app-view-title [bgImage]="api.env.theme.header_background">{{'manageNewsArticles.title' | translate}}</app-view-title>
  <div class="scroll_wrapper">
    <ng-container *ngFor="let type of ArticleType | enumToArray">
      <ng-container *ngIf="articlesByType(type.value)?.length > 0">
        <h2>{{('article.type.' + type.key) | translate}}</h2>
        <div class="f_grow articles_list">
          <app-modal-features *ngFor="let item of articlesByType(type.value)"
                              [title]="item.title"
                              [subtitle]="item.description"
                              [hasLink]="!!item.link"
                              [bgImage]="item.image"
                              (click)="nav.to('news/' + item.metaId, undefined, {state: {article: item, fromRoute: 'manage/manage-news-articles'}})"></app-modal-features>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <app-back-btn position="botLeft" [navTo]="{route: 'manage/manage-news'}"></app-back-btn>
</div>
