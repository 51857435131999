export function checkImage(url: string): Promise<boolean> {
  return new Promise<boolean>((resolve) => {
    if(!url) {
      resolve(false);
    }

    const img = new Image();
    img.onload = () => {
      // L'image s'est chargée avec succès, résoudre avec true
      resolve(true);
    };
    img.onerror = () => {
      // L'image n'a pas pu être chargée, résoudre avec false
      resolve(false);
    };

    img.src = url;
  });
}
