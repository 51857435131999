export interface Country {
  name: CountryName;
  age: number;
}

export enum CountryName {
  BEL = 'BEL',
  CIV = 'CIV',
  GMB = 'GMB',
  LUX = 'LUX',
  ZWE = 'ZWE',
  KEN = 'KEN',
  UTO = 'UTO',
  CIV_ADMIN = 'CIV_ADMIN',
  GMB_ADMIN = 'GMB_ADMIN',
  LUX_ADMIN = 'LUX_ADMIN',
  ZWE_ADMIN = 'ZWE_ADMIN',
  KEN_ADMIN = 'KEN_ADMIN',
  UTO_ADMIN = 'UTO_ADMIN'
}

export const countries: Country[] = [{
  name: CountryName.BEL,
  age: 18
}, {
  name: CountryName.CIV,
  age: 18
}, {
  name: CountryName.LUX,
  age: 18
}, {
  name: CountryName.ZWE,
  age: 18
}, {
  name: CountryName.GMB,
  age: 18
}, {
  name: CountryName.UTO,
  age: 18
}, {
  name: CountryName.KEN,
  age: 18
}, {
  name: CountryName.CIV_ADMIN,
  age: 21
}, {
  name: CountryName.LUX_ADMIN,
  age: 18
}, {
  name: CountryName.ZWE_ADMIN,
  age: 18
}, {
  name: CountryName.GMB_ADMIN,
  age: 18
}, {
  name: CountryName.KEN_ADMIN,
  age: 18
}, {
  name: CountryName.UTO_ADMIN,
  age: 18
}];

export const countriesSO = countries.filter(country => !country.name.includes('_ADMIN')).map(country => ({
  value: country.name,
  name: 'form.DYN.country.' + country.name
}));
