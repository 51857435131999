<div class="scannedBadge">
          <span *ngIf="!sync.isOnline" class="scannedBadge_network">
            <fa-icon icon="faWifi"></fa-icon>
          </span>
  <div class="scannedBadge_img">
    <img *ngIf="badgeData.img" [src]="badgeData.img" alt="">
    <span *ngIf="!badgeData.img">{{badgeData.pl[0]}}</span>
  </div>
  <div class="scannedBadge_title mt_20">
    <span class="scannedBadge_name">{{badgeData.n || ('scannedBadge.no_name' | translate)}}</span><br>
    <span class="mr_6">{{'scannedBadge.member_of' | translate}}</span>
    <span class="scannedBadge_place">{{badgeData.pl || ('scannedBadge.no_place' | translate)}}</span>
  </div>
  <div *ngIf="badgeData.pco.length > 0 || badgeData.p.length > 0" class="scannedBadge_perms mt_20">
    <ng-container *ngIf="badgeData.pco.length > 0">
      <span class="scannedBadge_perms_title">{{'scannedBadge.reading' | translate}}</span>
      <div class="scannedBadge_perms_claims mt_10 mb_10">
        <div class="scannedBadge_perms_snack" *ngFor="let pco of badgeData.pco">
          <span>{{('scannedBadge.claim.'+ClaimType[pco]) | translate}}</span>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="badgeData.p.length > 0">
      <span class="scannedBadge_perms_title">{{'scannedBadge.permissions' | translate}}</span>
      <ng-container *ngFor="let p of badgeData.p; let i = index">
        <p *ngIf="p > 0" [innerHTML]="('scannedBadge.permissions.'+i) | translate"></p>
      </ng-container>
    </ng-container>
  </div>
  <span class="scannedBadge_footer">{{dateNow()}} - #{{badgeData.userId}}/{{badgeData.pi}}</span>
</div>
