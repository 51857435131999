import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable, Subject, TimeoutError} from 'rxjs';
import {User, UserPermission} from '../models/user';
import {ApiService} from './api.service';
import {Claim, ClaimType} from '../models/claim';
import {differenceInSeconds, parseISO, differenceInMilliseconds, differenceInDays} from 'date-fns';
import {DevicesService, LangService, LoaderService, NavigateService} from 'ngx-satoris';
import {SecureStorageService} from './secure-storage.service';
import {InitializeService} from './initialize.service';
import {NfcService} from './nfc.service';
import {SyncService} from './sync.service';
import {checkValidityOfIssuerAuth, isObjectEmpty} from '../utils/verification';
import {getStoredItem, setStoredItem} from '../utils/storage';
import {PersonnalDatasService} from './personnal-datas.service';
import {environment} from 'src/environments/environment';


declare const Promise: PromiseConstructor & {
  allConcurrent: <T>(n: number) => ((promiseProxies: (() => Promise<T>)[]) => Promise<T[]>);
};

@Injectable()
export class UserValidGuardService implements CanActivate {

  fetchDataDone: Subject<boolean> = new Subject<boolean>();
  isBackgroundClaiming = false;

  constructor(public api: ApiService,
    private loader: LoaderService,
    private lang: LangService,
    private router: Router,
    private devices: DevicesService,
    private secure: SecureStorageService,
    private nav: NavigateService,
    private initialize: InitializeService,
    private sync: SyncService,
    private init: InitializeService,
    private nfc: NfcService,
    private personnalDatas: PersonnalDatasService) {
  }

  canActivate(r: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if(!('return_url' in sessionStorage)) {
      const uri = state.url.replace(/;.*$/, '').replace(/^\//, '');
      sessionStorage.setItem('return_url', JSON.stringify(uri.split('/').map(decodeURIComponent)));
    }
    return this.api.checkJwt(true).then(() => this.checkAll(r)).catch((err: any)=>{
      if(!(err instanceof TimeoutError)) {
        this.api.signOut();
        return false;
      }
      this.initialize.removeSplashScreen();
    });
  }

  checkAll(r: ActivatedRouteSnapshot): boolean | Promise<boolean> {
    const runChecks = () => {
      const promises = [
        this.checkRole.bind(this)(r),
        this.checkPerm(r),
        this.checkClaims(r),
        this.checkNoPin(r),
        this.checkRequiredPin(r),
        this.checkOnline(r),
        this.checkInfoInterval(r),
        this.checkSubscription(r),
        this.checkSelfClaims(r),
        this.checkAdminPlace(r)
      ];
      return Promise.all(promises).then(checks => {
        const allChecksPassed = checks.every(check => check === true);
        this.loader.loading(false);
        if(allChecksPassed) {
          const manualoffline = getStoredItem('manualOffline');
          if(manualoffline) {
            setTimeout(() => {
              this.api.pingServer();
            }, 5000);
          }
          this.initialize.removeSplashScreen();
          this.backgroundClaims();
          if(this.initialize.firstLaunch) {
            this.initialize.firstLaunch = false;
            if(this.api.accountTransition) {
              this.api.accountTransition = false;
              this.nav.toPin(undefined, 'user');
              setTimeout(() => {
                this.loader.loading(true, {type: 'info', message: this.lang.transform('exp.pro.session')});
              }, 500);
            } else {
              this.nav.to('user');
            }
            return allChecksPassed;
          } else {
            return allChecksPassed;
          }
        } else {
          return false;
        }
      }).catch(error => {
        console.error(error);
        this.loader.loading(false);
        this.initialize.removeSplashScreen();
        return false;
      });
    };

    if(r?.data?.places !== undefined && !this.api.userPlaces && !this.api.userRole?.isCustomer) {
      return this.checkPlace().then((res) => {
        if(res) {
          return runChecks();
        } else {
          return false;
        }
      });
    } else {
      return runChecks();
    }
  }

  storeClaims (claim: Claim) {
    const {imageUrlRecto, imageUrlVerso, ...claimWithoutImage} = claim;
    return this.secure.encryptDatas('claims', claim.id, JSON.stringify(claimWithoutImage), JSON.stringify({
      imageUrlRecto: imageUrlRecto || this.api.claims[claim.type]?.imageUrlRecto,
      imageUrlVerso: imageUrlVerso || this.api.claims[claim.type]?.imageUrlVerso
    })).catch(console.error);
  }

  /**
   * Validates required claims for a user and fetches/stores them if necessary.
   *
   * @param {ActivatedRouteSnapshot} r - The current route snapshot.
   * @returns {Promise<boolean>} - A Promise that resolves to true once all claims are validated.
   */
  checkClaims(r: ActivatedRouteSnapshot) {
    if(r.data.claimsRequired !== undefined
      && this.api.userRole?.isCustomer
      && this.api.userInfo.nationalNumber
      && this.api.pinUnlocked
      && !this.api.claims[ClaimType.ID_CARD]) {
      const storeClaims = (claim: Claim) => {
        const {imageUrlRecto, imageUrlVerso, ...claimWithoutImage} = claim;
        return this.secure.encryptDatas('claims', claim.id, JSON.stringify(claimWithoutImage), JSON.stringify({
          imageUrlRecto: imageUrlRecto || this.api.claims[claim.type]?.imageUrlRecto,
          imageUrlVerso: imageUrlVerso || this.api.claims[claim.type]?.imageUrlVerso
        })).catch(console.error);
      };
      this.loader.loading(true);
      const processClaims = () => this.api.getClaimsProcess().then((res: Claim[]) => {
        const claimPromises = res.map((claim: Claim) => () => {
          const cl = this.api.getClaimBody(claim.type, [claim]);
          if(this.devices.isDevices('cordova')) {
            const isIssuerAuthValid = checkValidityOfIssuerAuth(cl, 10 * 60000);
            if(this.api.isClaimsCached) {
              if(cl.issuerAuth && !isIssuerAuthValid && cl.fetchable && this.sync.isOnline){
                return this.api.fetchClaim(claim.id, this.secure.fullJwk.x, this.secure.fullJwk.y, true).then(copyClaim => {
                  cl.issuerAuth = copyClaim?.issuerAuth || cl?.issuerAuth;
                  this.api.claims[claim.type] = cl;
                  return storeClaims(cl).then(() => true);
                }).catch(console.error);
              } else {
                this.api.claims[claim.type] = cl;
                return storeClaims(cl).then(() => true);
              }
            } else {
              if(claim.fetchable) {
                if(this.sync.isOnline) {
                  if(this.secure.fullJwk?.hash === this.api.userInfo?.deviceHash || !this.api.userInfo.usesLoginOtp){
                    return this.api.fetchClaim(claim.id, this.secure.fullJwk.x, this.secure.fullJwk.y).then(copyClaim => {
                      cl.issuerAuth = copyClaim?.issuerAuth || cl?.issuerAuth;
                      this.api.claims[claim.type] = cl;
                      return storeClaims(cl).then(() => true);
                    }).catch(console.error);
                  } else {
                    this.api.claims[claim.type] = cl;
                    return storeClaims(cl).then(() => true);
                  }
                } else {
                  this.api.claims[claim.type] = cl;
                  return Promise.resolve(true);
                }
              } else {
                this.api.claims[claim.type] = cl;
                return storeClaims(cl).then(() => true);
              }
            }
          } else {
            this.api.claims[claim.type] = cl;
            return Promise.resolve(true);
          }
        });
        return Promise.allConcurrent(1)(claimPromises).then(() => {
          localStorage.setItem('updateClaims', JSON.stringify({lastFullUpdate: new Date().toISOString(), lastSkim: new Date().toISOString()}));
        });
      }).then(() => true).finally(() => this.loader.loading(false));
      if(this.devices.isDevices('cordova')) {
        return this.secure.retrieveSecretKey().then(() => processClaims().catch(() => {
          this.api.currentlyUnlockingPin= false;
          return Promise.reject(false);
        }));
      } else {
        return processClaims().then(() => true).catch(() => false);
      }
    }
    return Promise.resolve(true);
  }

  backgroundClaims(issuerAuthInvalid?: Claim[]): Promise<boolean> {
    const updateClaimsDates = JSON.parse(localStorage.getItem('updateClaims')) || {};
    const lastFullUpdateDate = updateClaimsDates.lastFullUpdate ? parseISO(updateClaimsDates.lastFullUpdate) : null;
    const lastSkimDate = updateClaimsDates.lastSkim ? parseISO(updateClaimsDates.lastSkim) : null;

    const currentDate = new Date();

    const invalidFull = !lastFullUpdateDate || differenceInDays(currentDate, lastFullUpdateDate) > 0;
    const invalidSkim = !lastSkimDate ||  differenceInMilliseconds(currentDate, lastSkimDate) > 600000;
    const allClaims = Object.values(this.api.claims);

    let claimsIssuerAuthInvalid = allClaims.filter((claim: Claim) => {
      const isIssuerAuthValid = checkValidityOfIssuerAuth(claim, 10 * 60000);
      return claim.issuerAuth && !isIssuerAuthValid && this.devices.isDevices('cordova') && claim.fetchable;
    });

    const uniqueClaims = issuerAuthInvalid ? issuerAuthInvalid.filter(item => !claimsIssuerAuthInvalid.includes(item)) : [];
    claimsIssuerAuthInvalid = claimsIssuerAuthInvalid.concat(uniqueClaims);

    if(this.isBackgroundClaiming || (!invalidFull && !invalidSkim) && claimsIssuerAuthInvalid.length === 0){
      this.isBackgroundClaiming = false;
      return Promise.resolve(true);
    }

    const updateClaimsFromApi = (invalidFull: any, claimsIssuerAuthInvalid: any) => this.api.updateClaimsFromApi(invalidFull ? 'invalidFull' : 'invalidSkim', claimsIssuerAuthInvalid as any).then((updatedClaims: Claim[]) => {
      const claimPromises: (() => Promise<unknown>)[] = [];
      for(const updatedClaim of updatedClaims) {
        const currentClaim = this.api.claims[updatedClaim.type];
        const cl = this.api.getClaimBody(updatedClaim.type, [updatedClaim]);
        if(cl) {
          cl.imageUrlRecto = updatedClaim.imageUrlRecto || currentClaim?.imageUrlRecto;
          cl.imageUrlVerso = updatedClaim.imageUrlVerso || currentClaim?.imageUrlVerso;
          cl.issuerAuth = updatedClaim?.issuerAuth || currentClaim?.issuerAuth;
          this.api.claims[updatedClaim.type] = cl;
        }
        claimPromises.push(() => {
          if(this.devices.isDevices('cordova')) {
            return this.storeClaims(updatedClaim);
          } else {
            return Promise.resolve();
          }
        });
      }
      if(claimPromises.length > 0) {
        return Promise.allConcurrent(1)(claimPromises).then(() => {
          this.fetchDataDone.next(true);
          this.isBackgroundClaiming = false;
          return true;
        });
      } else {
        return true;
      }
    }).catch(() => false);

    this.isBackgroundClaiming = true;

    if(this.devices.isDevices('cordova') && this.sync.isOnline && Object.keys(this.api.claims).length > 0) {
      return this.secure.retrieveSecretKey().then(() => updateClaimsFromApi(invalidFull, claimsIssuerAuthInvalid));
    } else if(this.sync.isOnline && Object.keys(this.api.claims).length > 0) {
      return updateClaimsFromApi(invalidFull, claimsIssuerAuthInvalid);
    } else {
      this.isBackgroundClaiming = false;
      return Promise.resolve(true);
    }
  }

  checkNoPin(r: ActivatedRouteSnapshot) {
    if(r.data.noPin !== undefined) {
      if(r.data.noPin === 'check' && !this.api.userInfo?.pinHash) {
        this.router.navigate(['/secret-code']);
        return false;
      }
      return true;
    } else {
      if(this.api.userInfo?.pinHash) {
        if(this.api.pinUnlocked || this.api.resetPin) {
          if(!this.api.userInfo?.nationalNumber && this.api.userRole?.isCustomer) {
            if(r.data.acceptNoNNI && !this.nfc.isValidating) {
              return true;
            } else {
              this.router.navigate(['/activation']);
              return false;
            }
          }
          return true;
        } else {
          this.router.navigate(['/secret-code-valid']);
          return false;
        }
      } else {
        if(this.api.resetPin) {
          return true;
        } else {
          this.router.navigate(['/secret-code']);
          return false;
        }
      }
    }
  }

  checkRole(r: ActivatedRouteSnapshot) {
    if(r.data.role !== undefined) {
      if(this.api.userRole.isSuperAdmin) {
        return true;
      } else {
        return r.data.role.includes(this.api.userInfo.role);
      }
    }
    return true;
  }

  checkPerm(r: ActivatedRouteSnapshot) {
    if(r.data.permission !== undefined) {
      if(r.data.permission.constructor === Array) {
        return r.data.permission.some((v: UserPermission) => this.api.hasPermission(v));
      } else {
        return this.api.hasPermission(r.data.permission?.toString());
      }
    }
    return true;
  }

  checkRequiredPin(r: ActivatedRouteSnapshot) {
    if(r.data.requiredPin !== undefined) {
      if(this.nav.demoMode) {
        return true;
      } else {
        const exception = typeof r.data.requiredPin[0] === 'boolean' ? r.data.requiredPin[0]
          : (this.router.url.includes(r.data.requiredPin[0]) || this.router.url.includes('/secret-code-valid'));
        return exception || differenceInSeconds(new Date(), new Date(this.api.userInfo?.pinHashUnlockedAt)) < environment.debounce_pin;
      }
    }
    return true;
  }

  checkOnline(r: ActivatedRouteSnapshot) {
    if(r.data.onlyOnline) {
      if(!this.sync.isOnline) {
        this.nav.to('user');
        setTimeout(() => {
          this.loader.loading(true, {type: 'warn', message: this.lang.transform('route.online')});
        }, 500);
      }
      return this.sync.isOnline;
    }
    return true;
  }

  checkInfoInterval(r: ActivatedRouteSnapshot) {
    clearInterval(this.api.infoInterval);
    if(r.data.infoInterval !== undefined) {
      this.api.infoInterval = setInterval(() => {
        this.api.info(false).then((res: User) => {
          if(res.nationalNumber) {
            clearInterval(this.api.infoInterval);
            this.init.triggerSplashScreen('card.activating');
            setTimeout(() => {
              this.nav.to('user');
            }, 7000);
          }
        });
      }, r.data.infoInterval);
    }
    return true;
  }

  checkPlace(): Promise<boolean> {
    return this.api.listPlaces(this.api.userInfo?.links?.map(l => l.place_id)).then(res => {
      this.api.userPlaces = res.filter(x => x.id !== '');
      return true;
    }).catch(err => {
      this.loader.loading(true, {type: 'error', message: String(err).indexOf(': ') > -1 ? `304 error? ${err}` : this.lang.transform('choose_place.listPlaces_error')});
      return false;
    });
  }

  checkSubscription(r: ActivatedRouteSnapshot) {
    if(r.data.subscriptionRequired) {
      if(this.init.isPro || this.init.products.length === 0 || !environment.production) {
        return true;
      } else {
        this.nav.to('subscription');
      }
    }
    return true;
  }

  checkSelfClaims(r: ActivatedRouteSnapshot): Promise<boolean> {
    if(!r.data.selfClaimsRequired || !this.api.userInfo.nationalNumber || !this.api.pinUnlocked) {
      return Promise.resolve(true);
    }
    if(this.api.userRole?.isAdmin) {
      if(r.data.refetchSelfClaims) {
        this.loader.loading(true);
        return this.personnalDatas.selfClaimsAdmin().then(() => {
          this.loader.loading(false);
          return true;
        }).catch(() => {
          this.loader.loading(false);
          return true;
        });
      } else {
        return Promise.resolve(true);
      }
    } else {
      const dateClaimSelf = getStoredItem('selfClaimsCustomer') ? JSON.parse(getStoredItem('selfClaimsCustomer')) : null;
      if((!dateClaimSelf || differenceInSeconds(new Date(), new Date(dateClaimSelf)) > 60 || isObjectEmpty(this.personnalDatas.selfClaims)) && r.data.refetchSelfClaims) {
        setStoredItem('selfClaimsCustomer', JSON.stringify(new Date()));

        const claimSelfValidated = Object.keys(this.api.claims)
          .filter(claimKey => this.personnalDatas.isClaimTypeClaimSelf(+claimKey))
          .map(claimKey => this.api.claims[+claimKey as keyof typeof this.api.claims]) as any;

        this.loader.loading(true);
        if(this.personnalDatas.selfClaims || this.personnalDatas.selfClaimsMerged) {
          const updateClaimsDates = JSON.parse(localStorage.getItem('updateClaims')) || {};
          updateClaimsDates.lastSkim = '';
          localStorage.setItem('updateClaims', JSON.stringify(updateClaimsDates));
        }

        return this.backgroundClaims().then(() => this.personnalDatas.selfClaimsCustomer(claimSelfValidated).then(() => {
          this.loader.loading(false);
          return true;
        })).catch(() => {
          this.loader.loading(false);
          return true;
        });
      }
    }
    return Promise.resolve(true);
  }

  checkAdminPlace(r: ActivatedRouteSnapshot) {
    if(!this.api.currentPlace && r.data.adminPlace && !this.api.userRole?.isCustomer) {
      if(this.api.userPlaceId){
        if(this.sync.isOnline){
          this.loader.loading(true);
          return this.api.place(this.api.userPlaceId).then(res => {
            this.loader.loading(false);
            this.api.currentPlace = res;
            if(this.devices.isDevices('cordova')){
              this.secure.setEncryptSecureDatas('place', JSON.stringify(res));
            }
            return true;
          }).catch(err => {
            this.loader.loading(false);
            if(this.devices.isDevices('cordova')) {
              this.secure.getEncryptSecureDatas('place').then(place => {
                if(place) {
                  this.api.currentPlace = JSON.parse(place);
                  return true;
                } else {
                  this.api.currentlyUnlockingPin = false;
                  this.api.retrySecretCodeBtn = true;
                  if(!(err instanceof TimeoutError)) {
                    this.loader.loading(true, {type: 'error', message: this.lang.transform('api.place.error')});
                  }
                  return false;
                }
              });
            } else {
              this.api.currentlyUnlockingPin = false;
              this.api.retrySecretCodeBtn = true;
              if(!(err instanceof TimeoutError)) {
                this.loader.loading(true, {type: 'error', message: this.lang.transform('api.place.error')});
              }
              return false;
            }
          });
        } else {
          this.secure.getEncryptSecureDatas('place').then(place => {
            if(place) {
              this.api.currentPlace = JSON.parse(place);
              return true;
            } else {
              this.api.currentlyUnlockingPin = false;
              this.api.retrySecretCodeBtn = true;
              return false;
            }
          });
        }
      } else {
        const customerAccount = this.api.getCustomerAccount();
        const storedAccount = getStoredItem('jwts');
        const updatedJwts = storedAccount.filter((account: any) => account.email === customerAccount.email);
        this.api.signOut(false);
        this.api.accountJwt = customerAccount;
        setStoredItem('jwts', updatedJwts);
        this.api.userInfo =  getStoredItem('digid.cache.infos')[customerAccount.email];
        this.api.setUserRole(getStoredItem('digid.cache.infos')[customerAccount.email]);
      }
    }
    return true;
  }
}
