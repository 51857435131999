import {Injectable} from '@angular/core';
import {Claim, ClaimSelf, ClaimSelfAlt, ClaimSelfDetails, ClaimSelfForAdmin, ClaimSelfState, ClaimType, ClaimTypeClaimSelf, ClaimsSelf} from '../models/claim';
import {environment} from 'src/environments/environment';
import {IconProp} from 'ngx-satoris';
import {ApiService} from './api.service';

@Injectable({
  providedIn: 'root'
})
export class PersonnalDatasService {
  personnalDatas: any;
  dataToArray: any;

  countryClaims = Object.fromEntries(Object.entries(environment.selfClaims).filter(([_, value]) => value !== undefined));
  selfClaims = {} as ClaimsSelf[keyof ClaimsSelf];
  allCustomerSelfClaims = [] as ClaimSelfForAdmin[];
  scrollNumberOpenSelfClaims = 30;
  selfClaimsMerged: any = {} as ClaimsSelf[keyof ClaimsSelf];
  
  constructor(private api: ApiService) {}
  
  /**
   * Get a state and return the icon and the color of the state
   * @param state 
   * @returns 
  */
  stateVisual(state: ClaimSelfDetails['state']): { icon: IconProp, color: string } { 

    if(state?.value === ClaimSelfState.CHANGING) {
      return {
        icon: 'rotate',
        color: state?.changingRefusal ? 'var(--clr-btn-red)' :  'orange'
      };
    } else if(state?.value === ClaimSelfState.REJECTED) {
      return {
        icon: 'circle-xmark',
        color: 'var(--clr-btn-red)'
      };
    } else if(state?.value === ClaimSelfState.PENDING) {
      return {
        icon: 'clock',
        color: 'orange'
      };
    } else if(state?.value === ClaimSelfState.APPROVED) {
      return {
        icon: 'circle-check',
        color: 'green'
      };
    } else {
      return {
        icon: undefined,
        color: ''
      };
    }
  }

  /**
   * Get a claim and return the state of the claim
   * @param claim 
   * @returns 
   */
  checkValiditySelfClaims = (claim: ClaimSelf & Claim): ClaimSelfDetails['state'] => {
    const isSelfClaimsChanging = this.api.claims?.[claim.type] && !claim?.approved_by_user__id;
    if(claim?.unique){
      return {
        value: ClaimSelfState.APPROVED,
        user_id: null,
        rejectReason: null
      };
    }
    if(isSelfClaimsChanging) {
      return {
        value: ClaimSelfState.CHANGING,
        changingRefusal: claim?.rejected_by_user__id ? true :  false,
        user_id: claim.rejected_by_user__id,
        rejectReason: claim?.rejectReason ? JSON.parse(claim?.rejectReason) : ''
      };
    } else if(claim.rejected_by_user__id) {
      return {
        value: ClaimSelfState.REJECTED,
        user_id: claim.rejected_by_user__id,
        rejectReason: claim?.rejectReason ? JSON.parse(claim?.rejectReason) : ''
      };
    } else if(claim.approved_by_user__id || claim?.signature && !isSelfClaimsChanging) {
      return {
        value: ClaimSelfState.APPROVED,
        user_id: claim.approved_by_user__id,
        rejectReason: null
      };
    } else {
      return {
        value: ClaimSelfState.PENDING,
        user_id: null,
        rejectReason: null
      };
    }
  };

  /**
   * Allow to transform a claim to a self claim
   * @param claims Claims to transform
   * @param needParent If we need to add the parent (ClaimAlt)
   * @param returnArray If we need to return an array
   * @param mergeArray If we need to merge the array
   * @param needIcon If we need to add the icon
   */
  transformClaimToSelfClaim(claims: Claim[] & ClaimSelf[], needParent = false, returnArray = false, mergeArray = false, needIcon = true): any {
    let dataToReturn: any= returnArray ? [] : {};
    claims.forEach((claim: Claim & ClaimSelf) => {
      const state = this.checkValiditySelfClaims(claim);
      const deserializedData = claim.serialized ?  JSON.parse(claim?.serialized) : '';
      let parentAlt: ClaimSelfAlt = null;
      let additionalDatas: ClaimSelfDetails = null;
      Object.keys(this.countryClaims).forEach(subType => {
        if(subType in ClaimSelfAlt) {
          if(claim.type in this.countryClaims[subType as ClaimSelfAlt]) {
            parentAlt = subType as ClaimSelfAlt;
            additionalDatas = (this.countryClaims as any)[subType][claim.type];
          } else {
            additionalDatas = (this.countryClaims as any)[claim.type];
          }
        }
      });

      const commonProperties: ClaimSelfDetails = {
        title: parentAlt ? this.countryClaims[parentAlt].label : this.countryClaims[+claim?.type]?.label,
        label: additionalDatas?.metadataValue[0].label,
        type: claim?.type,
        assignedAt: claim?.assignedAt,
        proofDocument: claim?.proof,
        icon: additionalDatas?.icon ? additionalDatas?.icon : additionalDatas.metadataValue[0].icon,
        stateIcon: needIcon &&  {
          icon: this.stateVisual(state).icon,
          iconColor: this.stateVisual(state).color
        },
        metadataValue: additionalDatas?.metadataValue,
        created_at: claim?.createdAt,
        value: deserializedData,
        arrayElement: [{
          subElements: additionalDatas?.metadataValue.map(metaData => ({
            title: 'form.DYN.' + metaData.label,
            value: claim?.serialized ? JSON.parse(claim.serialized)[metaData.label] : '',
            icon: metaData.icon,
            stateIcon: needIcon && parentAlt && mergeArray ? {
              icon: this.stateVisual(state).icon,
              iconColor: this.stateVisual(state).color
            } : undefined
          }))
        }],
        id: claim?.id,
        state
      };
      if(needParent && parentAlt) {
        dataToReturn[parentAlt] = {...dataToReturn[parentAlt], [claim.type]: commonProperties};
      } else {
        dataToReturn[claim.type] = commonProperties;
      }
    });

    if(returnArray) {
      dataToReturn = Object.values(dataToReturn);
    }
    if(mergeArray) {
      const uniqueClaims: any = {};
      Object.values(dataToReturn).forEach((claim: ClaimSelfDetails) => {
        if(uniqueClaims[claim.title]) {
          uniqueClaims[claim.title].arrayElement[0].subElements.push(...claim.arrayElement[0].subElements);
        } else {
          uniqueClaims[claim.title] = claim;
        }
      });
      dataToReturn = Object.values(uniqueClaims).filter((claim: ClaimSelfDetails) => claim !== undefined && claim !== null);
    }
    return dataToReturn;
  }

  /**
   * Get the self claims of the customer and merge them with the self claims validated by the admin
   * @param selfClaims Claim self get from the server
   * @param claimSelfValidated Claim self validated by the admin
   * @returns 
   */
  selfClaimsCustomer(claimSelfValidated: ClaimSelf[]){
    return this.api.mySelfClaims()
      .then((selfClaims: ClaimSelf[]) => {
        const seen = new Set();
        const filteredRes = selfClaims
          .sort((a, b) => new Date(b.updatedOn).getTime() - new Date(a.updatedOn).getTime())
          .filter(claim => {
            const identifier = `${claim.user__id}-${claim.type}`;
            if(!seen.has(identifier)) {
              seen.add(identifier);
              return true;
            }
            return false;
          });
        const filteredResApproved = filteredRes.map(item => ({...item}));
        claimSelfValidated.forEach((claim: any) => {
          if(!filteredRes.find(c => c.type === claim.type)) {
            claim.unique = true;
            filteredRes.push(claim);
          }
        });
        for(let i = 0; i < filteredRes.length; i++) {
          const claim = filteredRes[i];
          const state = this.checkValiditySelfClaims(claim);
          const foundClaim = claimSelfValidated.find((c: any) => c.type === claim.type);
          if(foundClaim) {
            filteredResApproved[i] = foundClaim;
            if(state.changingRefusal) {
              filteredResApproved[i].rejected_by_user__id = state.user_id;
            }
            if(state.value === ClaimSelfState.APPROVED) {
              filteredRes[i] = foundClaim;
              filteredRes[i].approved_by_user__id = state.user_id;
            }
          }
        }
      
        this.selfClaims = this.transformClaimToSelfClaim(filteredRes, true);
        this.selfClaimsMerged = this.transformClaimToSelfClaim(filteredResApproved, undefined, true, true);
        return true;
      });
  }

  /**
   * Get self claims for the admin
   * @param id optional id of the claim for fetch more details about it
   * @returns 
   */
  selfClaimsAdmin(id?: string): Promise<boolean> {
    return this.api.openSelfClaims(undefined, this.scrollNumberOpenSelfClaims - this.scrollNumberOpenSelfClaims, this.scrollNumberOpenSelfClaims).then(res => {
      if(this.scrollNumberOpenSelfClaims !== res.count) {
        this.scrollNumberOpenSelfClaims = res.count as number;
      }
      if(res.result.length === 0) {
        this.allCustomerSelfClaims= [];
        return true;
      }
      if(!id) {
        this.allCustomerSelfClaims = this.transformClaimToSelfClaim(res.result, undefined, true);
      } else {
        return this.api.getSelfClaim(id).then(claim => {
          this.allCustomerSelfClaims = this.transformClaimToSelfClaim([claim], undefined, true);
          return true;
        });
      }
      return true;
    });
  }

  isClaimTypeClaimSelf(value: any): value is ClaimTypeClaimSelf {
    return value === ClaimType.ADDITIONAL_INFO_ADDRESS || 
           value === ClaimType.ADDITIONAL_INFO_CONTACT_EMAIL || 
           value === ClaimType.ADDITIONAL_INFO_CONTACT_PHONE_NR;
  }
}

