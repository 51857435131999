<div id="layout" class="f_col container layout_sm">
  <app-view-title bgImage="assets-countries/header_background.png">{{'choose_account.title' | translate}}</app-view-title>
  <div class="scroll_wrapper">
    <div class="f_grow mt_20">
      <app-checkbox-element [icons]="['pen-to-square', 'arrow-left']"
                            [text]="(edit ? 'chooseAccount.exit_edit' : 'chooseAccount.edit')"
                            (onChange)="toggleEdit()">
      </app-checkbox-element>
      <app-slide-element
                          *ngFor="let account of allJwtAcounts; let i = index"
                          [leftIcon]="!account?.jwt ? 'bolt' : 'user'"
                          [color]="!account?.jwt ? 'red' : ''"
                          [disabled]="disabled(account)"
                          [rightIcon]="rightIcon(account)"
                          (btnClicked)="editMode(account, i)"
                          [colorRight]="colorElement(account)"
                          class="mt_10">
                          {{account.name}}
      </app-slide-element>
      <app-button [disabledStyle]="!sync.isOnline" [colorIcon]="sync.isOnline ? '': 'red'" size="sm" *ngIf="edit" [icon]="sync.isOnline ? 'user-plus' : 'wifi'" (btnClicked)="sync.isOnline  ? nav.toPin('more', 'sign-in-pro', true, undefined, {queryParams: {keepPin: true}}) : sync.loaderOffline()" class="mt_30">
        {{'chooseAccount.new_account' | translate}}
      </app-button>
      <app-button color="red" size="sm" *ngIf="edit" (btnClicked)="disconnect()" class="mt_10">
        {{'chooseAccount.disconnectAll' | translate}}
      </app-button>
    </div>
  </div>
  <app-back-btn position="botLeft" [navTo]="{route: 'more'}"></app-back-btn>
</div>
