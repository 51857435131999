<div id="layout" class="f_col container">
  <app-view-title fromRoute="recover-password" toRoute="choose-lang" [chooseLangBtn]="!api.userInfo" bgImage="assets-countries/header_background.png">{{'recover.title' | translate}} </app-view-title>
  <div class="scroll_wrapper">
    <app-view-deco-image imageUrl="assets-countries/undraw_forgot-password.svg"></app-view-deco-image>
    <app-paragraph textAlign="center">{{'recover.desc' | translate}}</app-paragraph>
    <div class="f_center f_grow">
      <form id="form" [formGroup]="form" (ngSubmit)="submitForm()">
        <app-input type="password"
                  class="mt_30"
                  formCtrlName="password"
                  [formGrp]="form"
                  label="form.password"
                  maxlength="20"
                  (output)="checkPswdStrength()">
        </app-input>
        <div class="pswd_strength mt_4">
          <span class="pswd_strength_bar" *ngIf="pswdStrength >= 20"></span>
          <span class="pswd_strength_bar" *ngIf="pswdStrength >= 40"></span>
          <span class="pswd_strength_bar" *ngIf="pswdStrength >= 70"></span>
          <span class="pswd_strength_bar" *ngIf="pswdStrength >= 90"></span>
        </div>
        <app-input type="password"
                  formCtrlName="password2"
                  [formGrp]="form"
                  label="form.password2"
                  class="mt_24">
        </app-input>
        <app-input type="password"
                  icon="key"
                  formCtrlName="code"
                  [formGrp]="form"
                  label="form.code"
                  class="mt_24">
        </app-input>
        <app-errors-list [dark]="true" *ngIf="submitted" [formErrors]="forms.getFormErrors(this.form)"></app-errors-list>
      </form>
    </div>
    <div class="f_align_end f_grow mb_30">
      <app-back-btn  (btnClicked)="nav.to('forgot-password', undefined, {state: {proAccount: data.proAccount ? true : false }})"></app-back-btn>
      <div class="ml_10 fullWidth">
        <app-button type="submit" formId="form" class="fullWidth">{{'confirm' | translate}}</app-button>
      </div>
    </div>
  </div>
</div>
