<div id="draw_wrapper">
  <signature-pad #signature *ngIf="canvasW && canvasH" (drawEnd)="drawEnd(signature)"
                 [options]="{canvasHeight: canvasH, canvasWidth: canvasW, backgroundColor: canvasBg, penColor: canvasPenClr, dotSize: 2.4, minWidth: 2.6, maxWidth: 3}"></signature-pad>
</div>
<div class="f_end mt_20 gap_10 f_align_c mb_20">
  <div class="fullWidth relative" *ngIf="uploadOption">
    <app-button size="sm" class="fullWidth" icon="upload">{{(uploadImageFile ? 'global.uploaded' : 'global.upload') | translate}}
    </app-button>
    <input class="draw-upload" type="file" id="image" name="image" (change)="uploadImage($event)">
  </div>
  <app-button size="sm" color="secondary" (btnClicked)="clear()" >{{'global.clear' | translate}}</app-button>
</div>
