import {Component} from '@angular/core';
import {NavigateService} from 'ngx-satoris';

@Component({
  selector: 'app-update-version',
  templateUrl: './update-version.component.html'
})
export class UpdateVersionComponent {
  constructor(public nav: NavigateService) {}
}
