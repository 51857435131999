<div id="layout" class="f_col container layout_sm" *ngIf="api.userInfo && api.pinUnlocked">
  <app-user-header *ngIf="api.userRole?.isCustomer"
                [title]="this.idClaim ? this.idClaim.first_name[0] + ' ' + this.idClaim?.last_name[0] : ''"
                [subtitle]="'userRoleDYN.' + api.userInfo?.role | translate" bgImage="assets-countries/header_background.png"
                [imageUrl]="idClaim?.image_url">
  </app-user-header>
  <app-user-header *ngIf="!api.userRole?.isCustomer"
                [title]="(api.userRole?.isWorker ? 'account.pro' : api.userRole?.isSuperAdmin ? 'account.super_admin' : 'account.admin') | translate"
                [subtitle]="api.currentPlace?.longName" bgImage="assets-countries/header_background.png"
                [imageUrl]="api.currentPlace?.b64Logo || 'assets-countries/img_admin_account.png'">
  </app-user-header>
  <div class="scroll_wrapper pt_20">
    <div *ngIf="api.userRole?.isCustomer">
      <ng-container *ngFor="let cc of api.env.countryClaims">
        <app-slide-element *ngIf="api.claims[cc.claimType] && cc?.userPage" [leftIcon]="cc.icon" (btnClicked)="nav.to('claim-card', undefined, {queryParams: {claimType: cc.claimType, isoMode: cc.isoMode, selectedClaims: cc.selectedClaims}})" class="mb_10">
          {{('claim.DYN.' + cc.claimType) | translate}}
        </app-slide-element>
      </ng-container>
      <app-slide-element [disabled]="!sync.isOnline" [colorRight]="!sync.isOnline ? '#f51d2a' : undefined" [rightIcon]="!sync.isOnline ? 'wifi' : 'arrow-right'" [leftIcon]="'database'" (btnClicked)="sync.isOnline ? nav.to('personnal-data') : sync.loaderOffline()" class="mb_10">
        {{'personnalData.title'| translate}}
      </app-slide-element>
    </div>
    <div *ngIf="api.userRole?.isAdmin || api.userRole?.isSuperAdmin">
      <app-slide-element [disabled]="!sync.isOnline" leftIcon="location-dot" [colorRight]="!sync.isOnline ? '#f51d2a' : undefined" [rightIcon]="!sync.isOnline ? 'wifi' : 'arrow-right'" leftIcon="users" (btnClicked)="sync.isOnline ? nav.to('manage-user')  : sync.loaderOffline()" class="mb_10">
        {{'user.manage_users' | translate}}
      </app-slide-element>
    </div>
    <div *ngIf="api.userRole?.isAdmin || api.userRole?.isSuperAdmin">
      <app-slide-element [disabled]="!sync.isOnline" leftIcon="location-dot" [colorRight]="!sync.isOnline ? '#f51d2a' : undefined" [rightIcon]="!sync.isOnline ? 'wifi' : 'arrow-right'" leftIcon="globe" (btnClicked)="sync.isOnline ? nav.to('/manage/manage-place')  : sync.loaderOffline()" class="mb_10">
        {{'user.manage_places' | translate}}
      </app-slide-element>
    </div>
    <div *ngIf="api.userRole?.isAdmin || api.userRole?.isSuperAdmin">
      <app-slide-element [disabled]="!sync.isOnline" leftIcon="newspaper" [colorRight]="!sync.isOnline ? '#f51d2a' : undefined" [rightIcon]="!sync.isOnline ? 'wifi' : 'arrow-right'" leftIcon="newspaper" (btnClicked)="sync.isOnline ? nav.to('manage/manage-news')  : sync.loaderOffline()" class="mb_10">
        {{'user.manage_news' | translate}}
      </app-slide-element>
    </div>
    <div *ngIf="api.userRole?.isWorker && api.userPlaceId !== undefined">
      <app-slide-element leftIcon="globe" (btnClicked)="nav.to('/manage/manage-place-worker')" class="mb_10">
        {{'user.manage_place_worker' | translate}}
      </app-slide-element>
    </div>
    <div *ngIf="(api.userRole?.isWorker || (api.userRole?.isAdmin && !api.userRole?.isSuperAdmin)) && api.userPlaceId">
      <app-slide-element [disabled]="!sync.isOnline" [colorRight]="!sync.isOnline ? '#f51d2a' : undefined" [rightIcon]="!sync.isOnline ? 'wifi' : 'arrow-right'" leftIcon="shield-halved" (btnClicked)="sync.isOnline ? nav.to('badge') : sync.loaderOffline()">
        {{'user.my_badge' | translate}}
      </app-slide-element>
    </div>
  </div>
</div>
