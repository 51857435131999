import {Component, ViewEncapsulation} from '@angular/core';
import {Article} from '../../../shared/models/article';
import {I18nLang, NavigateService} from 'ngx-satoris';
import {ApiService} from '../../../shared/services/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Results} from '../../../shared/models/backend-message';
import {checkImage} from '../../../shared/utils/checkImage';

@Component({
  selector: 'app-news-select',
  templateUrl: './news-select.component.html',
  styleUrls: ['./news-select.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NewsSelectComponent {
  data: {article: Article, fromRoute: string};
  article: Article;
  constructor(public nav: NavigateService,
              public api: ApiService,
              private route: ActivatedRoute,
              private router: Router) {
    this.data = <any> this.router.getCurrentNavigation()?.extras?.state;
    this.article = this.data?.article;
    if(!this.article && this.route.snapshot.paramMap.get('id')) {
      this.api.searchArticle(new Date(), Number(this.route.snapshot.queryParamMap.get('lang')), this.route.snapshot.paramMap.get('id')).then((articles: Results<Article[]>) => {
        this.article = articles.result[0];
        if(this.article) {
          this.imageCheck();
        }
      });
    } else if(this.article) {
      this.imageCheck();
    }
  }

  imageCheck() {
    checkImage(this.article?.image).then((isLoaded) => {
      if(!isLoaded) {
        this.article.image = './assets/images/article_blank.png';
      }
    });
  }

  protected readonly I18nLang = I18nLang;
}
