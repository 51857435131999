<div id="layout" class="f_col">
  <app-view-title bgImage="assets-countries/header_background.png" fromRoute="welcome-explain" toRoute="choose-lang" [chooseLangBtn]="true">{{'welcomeExplain.title' | translate: {name: api.env.name} }}</app-view-title>
  <div class="scroll_wrapper">
    <app-view-deco-image imageUrl="assets-countries/img_question.svg"></app-view-deco-image>
    <div class="f_col gap_20 mb_20">
      <app-paragraph>{{'welcomeExplain.desc1' | translate: {name: api.env.name} }}</app-paragraph>
      <app-paragraph>{{'welcomeExplain.desc2' | translate}}</app-paragraph>
    </div>
    <div class="f_align_end f_grow ">
      <div class="fullWidth ml_10 flex gap_10">
        <app-back-btn (btnClicked)="nav.to('welcome-hello')"></app-back-btn>
        <app-button (btnClicked)="biometric.biometric.isRegistered || !device.isDevices('cordova') || nav.demoMode? nav.to( 'welcome-done') : nav.to('welcome-biometric')"
                    class="fullWidth">
          {{'global.next' | translate}}
        </app-button>
      </div>
    </div>
  </div>
</div>
