import {Injectable} from '@angular/core';
import {PrivacyScreen} from '@capacitor-community/privacy-screen';
import {DevicesService, LangService, LoaderService, NavigateService} from 'ngx-satoris';
import {PermissionsService} from './permissions.service';
import {SecureStorageService} from './secure-storage.service';
import {SyncService} from './sync.service';
import {BiometricService} from './biometric.service';
import {SecurityProviderStatus} from '@capacitor-community/security-provider';
import {JailbreakRoot} from '@basecom-gmbh/capacitor-jailbreak-root-detection';
import {environment} from 'src/environments/environment';
// import 'cordova-plugin-purchase/www/store';

// const {store, ProductType, Platform} = window.CdvPurchase || {};

@Injectable({
  providedIn: 'root'
})
export class InitializeService {
  deviceReady = false;
  isSplashScreen = true;
  isCameraLoading = false;
  textSplashScreen = 'current.open';
  isScanning = false;
  firstLaunch = true;

  isPro = !environment.production;
  products: any[] = [];

  constructor(private secure: SecureStorageService,
              private devices: DevicesService,
              private permissions: PermissionsService,
              private sync: SyncService,
              private biometric: BiometricService,
              private loader: LoaderService,
              private lang: LangService,
              private nav: NavigateService
              /*private zone: NgZone*/) {}

  initialize(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.secure.initSecureStorage().then(() => {
        this.sync.init().then(() => {
          if(this.devices.isDevices('cordova')) {
            if(this.deviceReady) {
              resolve();
            } else {
              document.addEventListener('deviceready', () => {
                if(this.secure.securityStatus !== SecurityProviderStatus.Success && this.secure.securityStatus !== SecurityProviderStatus.NotImplemented) {
                  this.goToSecurity();
                }
                JailbreakRoot.isJailbrokenOrRooted().then(({result}) => {
                  if(result) return this.goToSecurity();
                  if(environment.production) {
                    PrivacyScreen.enable();
                  } else {
                    PrivacyScreen.disable();
                  }
                  this.deviceReady = true;
                  this.permissions.initPermissions();
                  this.biometric.initBiometrics().then(resolve);
                  /*
                  if(environment.PRODUCT_SUB_KEY_IOS && environment.PRODUCT_SUB_KEY_ANDROID) {
                    // Only adds product to be fetched on initialize
                    store.register([{
                      id: this.devices.isDevices('cordova-ios') ? environment.PRODUCT_SUB_KEY_IOS : environment.PRODUCT_SUB_KEY_ANDROID,
                      type: ProductType.PAID_SUBSCRIPTION,
                      platform: this.devices.isDevices('cordova-ios') ? Platform.APPLE_APPSTORE : Platform.GOOGLE_PLAY,
                      group: 'default'
                    }]);
                    store.restorePurchases();
                    store.when().approved(p => {
                      this.zone.run(() => {
                        this.isPro = true;
                      });
                      return p.verify();
                    }).verified((p: any) => p.finish());
                    // Fetches all products from store and purchases of current store user, on error: {isError, code, message}
                    store.initialize([this.devices.isDevices('cordova-ios') ? Platform.APPLE_APPSTORE : Platform.GOOGLE_PLAY]).then((failedProducts: any[]) => {
                      if(failedProducts && failedProducts.length) {
                        this.loader.loading(true, {type: 'error', message: failedProducts[0].message});
                      } else {
                        this.zone.run(() => {
                          this.products = store.products;
                          if(!this.products.length || this.products.some(p => p.owned)) this.isPro = true;
                        });
                      }
                    });
                  } else {
                    this.isPro = true;
                  }
                  */
                  this.isPro = true;
                });
              });
            }
          } else {
            resolve();
          }
        }).catch((error) => {
          reject(error);
        });
      });
    });
  }

  goToSecurity() {
    this.isSplashScreen = false;
    this.loader.loading(true, {type: 'error', message: this.lang.transform('security.loader')}).then(() => {
      this.nav.to('security-provider');
    });
  }

  purchase(product: any) {
    if(product) {
      this.loader.loading(true);
      return product.getOffer().order().then((response: any) => {
        this.loader.loading(false);
        return !response;
      }).catch(() => {
        this.loader.loading(true, {type: 'error', message: 'subscription.error'});
        return false;
      });
    } else {
      this.loader.loading(true, {type: 'error', message: 'subscription.noProduct'});
      return Promise.resolve(false);
    }
  }

  restore() {
    // return store.restorePurchases();
    return Promise.resolve();
  }

  triggerSplashScreen(text = 'current.open') {
    this.textSplashScreen = text;
    this.isSplashScreen = true;
  }

  removeSplashScreen() {
    this.isSplashScreen = false;
    this.textSplashScreen = 'current.open';
  }
}
